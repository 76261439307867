import { useQuery } from '@tanstack/react-query';
import { queryClient } from 'config/reactquery';
import { useSelector } from 'react-redux';
import { API_GRAPH_URL } from 'state/constants/api';
import { instance as axiosInstance } from 'config/axios';
import { FetchStatus, NodeData, NodeDataContainer, NodeType } from 'Components/Library/Graph/types';
import { getNodeType } from 'Components/Library/GraphElementResolver/helpers';
import { get } from 'lodash';

interface NodeDataResult {
  isLoading: boolean;
  error: Error;
  data?: NodeDataContainer;
}

function generateNodeDataContainer(apiResult): NodeDataContainer {
  const { name, id, ...rest } = apiResult;

  // backwards compatibility: handle rich text descriptions
  if (rest?.description && [NodeType.Commitment, NodeType.Interlock].includes(getNodeType(id))) {
    rest.description = JSON.parse(rest.description);
  }

  return {
    fetchStatus: FetchStatus.Ok,
    ok: true,
    data: {
      id,
      name,
      ...rest,
    } as NodeData,
  };
}

function useNodeData(graphId): NodeDataResult {
  const tenantID = useSelector((state: any) => state.auth.tenantID);

  const { isLoading, error, data } = useQuery({
    queryKey: ['node', graphId],

    staleTime: 1000 * 60 * 2,
    gcTime: 1000 * 60 * 60 * 48,
    queryFn: async () => {
      const response = await axiosInstance.get(
        `${API_GRAPH_URL}/${tenantID}/node?${new URLSearchParams({
          id: graphId,
        })}`,
      );
      return generateNodeDataContainer(response.data.result.node);
    },
  });

  return {
    isLoading,
    error,
    data,
  };
}

const persistNodeData = function persistNodeData(node: any): void {
  // For migration & batching use cases:
  // If we have data about a node, we can cache it in react-query using this
  // function.
  queryClient.setQueryData(['node', node.id], () => generateNodeDataContainer(node));
};

const mutateNodeData = function mutateNodeData(node: any): void {
  // For migration & batching use cases and mutations
  // If we have data about a node, we can cache it in react-query using this
  // function.
  console.log('mutateNode requested: ', node);
  queryClient.setQueryData(['node', node.id], (oldInstance: NodeDataContainer) => {
    const oldData = get(oldInstance, 'data', {});
    return generateNodeDataContainer({ ...oldData, ...node });
  });
};

export { persistNodeData, mutateNodeData };

export default useNodeData;
