import ThumbIcon from '@mui/icons-material/ThumbUp';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { VoteEntryProps } from './types';
import CandidateLabel from '../AiGroupingFlow/CandidateLabel';

function CategoryVoteEntry(props: VoteEntryProps) {
  const { sessionId, questionIndex, candidateIndex, ownvotes, candidateItem, onVoteToggle } = props;

  return (
    <FormControlLabel
      key={`live-dfac-panel-question-input-${questionIndex}-${candidateIndex}`}
      id={`live-dfac-panel-question-input-${questionIndex}-${candidateIndex}`}
      sx={{
        alignSelf: 'stretch',
        mb: 2,
        mr: 0,
        '& .MuiFormControlLabel-label': {
          flexGrow: 10,
        },
      }}
      control={
        <Checkbox
          icon={<ThumbIcon color="action" fontSize="small" />}
          checkedIcon={<ThumbIcon color="secondary" name="thumbsupicon" fontSize="small" />}
          checked={ownvotes.includes(candidateItem.category_id)}
          onChange={() => onVoteToggle(candidateItem.category_id)}
          sx={{
            color: 'action.active',
            mr: 0.5,
          }}
        />
      }
      label={
        <CandidateLabel
          questionIndex={questionIndex}
          candidateItem={candidateItem}
          highlight={ownvotes.includes(candidateItem.input_id)}
          sessionId={sessionId}
        />
      }
    />
  );
}

export default CategoryVoteEntry;
