import { Dialog } from '@mui/material';
import { useState } from 'react';
import SelectVotingStyleUi from './SelectVotingStyleUi';
import SuggesterUi from './SuggesterUi';
import ReviewUi from './ReviewUi';
import ErrorUi from './ErrorUi';

interface AiGroupingFlowProps {
  startClassicVote(): void;
  teamId: string;
  sessionId: string;
  onClose(): void;
}

function AiGroupingFlow(props: AiGroupingFlowProps) {
  const { startClassicVote, teamId, sessionId, onClose } = props;
  const [groupingData, setGroupingData] = useState({});
  const [stage, setStage] = useState(0);

  const onSuggestionSuccess = d => {
    setGroupingData(d);
    setStage(2);
  };

  return (
    <Dialog open>
      {stage === -1 && <ErrorUi onClose={onClose} />}
      {stage === 0 && (
        <SelectVotingStyleUi startClassicVote={startClassicVote} startAiFlow={() => setStage(1)} />
      )}
      {stage === 1 && (
        <SuggesterUi
          teamId={teamId}
          sessionId={sessionId}
          onSuccess={onSuggestionSuccess}
          onError={() => {
            setStage(-1);
          }}
        />
      )}
      {stage === 2 && (
        <ReviewUi
          teamId={teamId}
          sessionId={sessionId}
          data={groupingData}
          startClassicVote={startClassicVote}
          onClose={onClose}
        />
      )}
    </Dialog>
  );
}

export default AiGroupingFlow;
