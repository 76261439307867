import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { CloseFullscreen, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router';
import StandardDialog from 'Components/Library/StandardDialog';
import { gameplansSelectors } from 'state/ducks/gameplans';
import OutlinedIconButton from 'Components/Library/OutlinedIconButton';
import { openViewGameplanCardDialog } from 'config/ModalProvider/helpers';
import { GP_WIDGET_TYPE_COMMITMENTS, GP_WIDGET_TYPE_OKRS } from 'config/constants';
import { WIDGET_TYPE_MAPPING } from './Lib/map';
import WidgetFooter from './Lib/WidgetFooter';

function GameplanWidgetDialog({ onClose, zIndexModifier, domainId, gameplanId, widgetId }) {
  const gameplans = useSelector(state =>
    gameplansSelectors.selectGamePlans(state.main.gameplans, domainId),
  );
  const location = useLocation();
  const navigate = useNavigate();

  const gameplan = gameplans.ok ? gameplans.data.find(gp => gp.id === gameplanId) : {};
  const widget = get(gameplan, ['widget_data', widgetId]);

  if (!widget) {
    return null;
  }

  const flatWidgetOrder = [...gameplan.order[0], ...gameplan.order[1], ...gameplan.order[2]];
  const currentPosition = flatWidgetOrder.indexOf(widgetId);

  const hasPrev = currentPosition !== 0;
  const hasNext = currentPosition < flatWidgetOrder.length - 1;

  const openPrevious = () => {
    openViewGameplanCardDialog(
      domainId,
      gameplanId,
      flatWidgetOrder[currentPosition - 1],
      navigate,
      location,
      true,
    );
  };
  const openNext = () => {
    openViewGameplanCardDialog(
      domainId,
      gameplanId,
      flatWidgetOrder[currentPosition + 1],
      navigate,
      location,
      true,
    );
  };

  const WidgetElement = WIDGET_TYPE_MAPPING[widget.widget_type];

  return (
    <StandardDialog
      onBackdropClick={onClose}
      size="xl"
      fixedHeight
      zIndexModifier={zIndexModifier}
      open
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          height: 24,
          p: { xs: 1, sm: 2 },
          pr: { xs: 0, sm: 1 },
          borderBottom: '1px solid transparent',
          borderColor: { xs: 'divider', sm: 'transparent' },
        }}
      >
        <Stack direction="row" justifyContent="flex-start" spacing={1}>
          <OutlinedIconButton
            onClick={openPrevious}
            disabled={!hasPrev}
            name="gameplans-widget-dialog-open-previous"
          >
            <KeyboardArrowUp />
          </OutlinedIconButton>
          <OutlinedIconButton
            onClick={openNext}
            disabled={!hasNext}
            name="gameplans-widget-dialog-open-next"
          >
            <KeyboardArrowDown />
          </OutlinedIconButton>
        </Stack>
        <IconButton onClick={onClose} size="medium" name="gameplans-widget-dialog-close-fullscreen">
          <CloseFullscreen />
        </IconButton>
      </Stack>
      <Stack
        direction="column"
        alignItems="center"
        sx={{ overflow: 'hidden', flexGrow: 100, mb: { xs: 2, sm: 6 }, mt: { xs: 0, sm: 6 } }}
      >
        <Box sx={{ maxWidth: { xs: '100%', sm: '80%' }, width: 960, overflowY: 'scroll' }}>
          <WidgetElement
            data={widget}
            variant="dialog"
            teamId={domainId}
            gameplanId={gameplan.id}
          />
        </Box>
      </Stack>
      {![GP_WIDGET_TYPE_COMMITMENTS, GP_WIDGET_TYPE_OKRS].includes(widget.widget_type) && (
        <WidgetFooter data={widget} />
      )}
    </StandardDialog>
  );
}

GameplanWidgetDialog.propTypes = {
  onClose: PropTypes.func,
  gameplanId: PropTypes.string,
  domainId: PropTypes.string,
  widgetId: PropTypes.string,
  zIndexModifier: PropTypes.number,
};

export default GameplanWidgetDialog;
