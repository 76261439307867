import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Box from '@mui/material/Box';
import clsx from 'clsx';

import ObjectiveChip from 'Components/Features/Objectives/ObjectiveChip';
import HelperText from 'Components/Common/HelperText';

import { objectivesActions, objectivesSelectors } from 'state/ducks/objectives';

export const validate = () => true;

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  dark: {
    '& span': {
      color: 'rgba(255,255,255,0.87)',
      textDecoration: 'none',
    },
  },
});

const fetchData = (props, force = false) => {
  /*
      Fetch the OKRs for the requested domains
      called with force = True on mount to force the fetching on page refresh
  */
  const { contextID, selectedPeriod } = props;
  if (!!selectedPeriod) {
    props.dispatch(
      objectivesActions.getTeamObjectives({ teamId: contextID, stperiod: selectedPeriod, force }),
    );
  }
};

class CardTeamObjectives extends Component {
  componentDidMount() {
    fetchData(this.props, true);
  }

  componentDidUpdate() {
    fetchData(this.props);
  }

  render() {
    const { classes, className, key, id, teamObjectives, cardTheme, mode, t } = this.props;
    return (
      <div
        className={clsx(classes.root, classes[cardTheme], className)}
        data-tg-name="ctx-card-el-teamokrs"
        key={key}
        id={id}
      >
        {!!teamObjectives.ok &&
          teamObjectives.data.map(objectiveID => (
            <Box key={`${id}-okr-${objectiveID}`} sx={{ pt: 0.25, pb: 0.25 }}>
              <ObjectiveChip
                objectiveID={objectiveID}
                allowNavigate={mode === 'expanded'}
                actionable={mode === 'expanded'}
                color="transparent"
              />
            </Box>
          ))}
        {!!teamObjectives.ok && get(teamObjectives, 'data', []).length === 0 && (
          <HelperText>{t('objectives.noobjectivesyetinfotext')}</HelperText>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const currentPeriod = objectivesSelectors.selectCurrentPeriod(state.main.objectives);
  return {
    selectedPeriod: currentPeriod,
    teamObjectives: objectivesSelectors.selectTeamObjectives(
      state.main.objectives,
      ownProps.contextID,
      currentPeriod,
    ),
  };
};

CardTeamObjectives.propTypes = {
  classes: PropTypes.exact({
    root: PropTypes.string,
    dark: PropTypes.string,
    first: PropTypes.string,
  }),
  cardTheme: PropTypes.string,
  mode: PropTypes.string,
  key: PropTypes.string,
  id: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  selectedPeriod: PropTypes.string,
  teamObjectives: PropTypes.object,
  // eslint-disable-next-line react/no-unused-prop-types
  contextID: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  dispatch: PropTypes.func,
  t: PropTypes.func,
};

export default withStyles(styles)(connect(mapStateToProps)(withTranslation()(CardTeamObjectives)));
