import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';

const styles = theme => ({
  root: {
    color: theme.palette.background.box,
    '& span': {
      color: theme.palette.primary[400],
    },
    top: 0,
    left: 0,
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  downArrow: {
    width: 0,
    height: 0,
    borderLeft: '8px solid transparent',
    borderRight: '8px solid transparent',
    borderTop: `10px solid ${theme.palette.primary.main}`,
    marginBottom: 4,
    marginTop: -1,
  },
  content: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2),
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'row',
  },
});

class TimelineTooltip extends React.PureComponent {
  render() {
    const { classes, cx, rect, parentRect, children } = this.props;
    let contentDeltaX = 0;
    if (get(parentRect, 'left', 0) > get(rect, 'left', 0)) {
      contentDeltaX = parentRect.left - rect.left + 8;
    } else if (get(parentRect, 'right', 0) < get(rect, 'right', 0)) {
      contentDeltaX = parentRect.right - rect.right - 8;
    }
    return (
      <div
        style={{
          transform: `translateX(-50%) translateX(${cx}px) translateY(-100%)`,
          top: 0,
          left: 0,
        }}
        className={classes.root}
      >
        <div className={classes.content} style={{ transform: `translateX(${contentDeltaX}px)` }}>
          {children}
        </div>
        <div className={classes.downArrow} />
      </div>
    );
  }
}

TimelineTooltip.propTypes = {
  classes: PropTypes.exact({
    root: PropTypes.string,
    content: PropTypes.string,
    downArrow: PropTypes.string,
  }),
  cx: PropTypes.number,
  rect: PropTypes.object,
  parentRect: PropTypes.object,
};

export default withTranslation()(withStyles(styles, { withTheme: true })(TimelineTooltip));
