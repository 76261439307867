import { useRef, useEffect } from 'react';

function useTimeout() {
  const timerRef = useRef;

  const cancel = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  };

  const set = (cb, timeout) => {
    timerRef.current = setTimeout(cb, timeout);
  };

  useEffect(
    () => () => {
      // unMount, clear timers
      cancel();
    },
    [],
  );

  return [set, cancel];
}

export default useTimeout;
