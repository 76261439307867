import * as types from './types';

export const startSession = payload => ({
  type: types.START_FACILITATION_SESSION,
  payload,
});

export const deleteSession = payload => ({
  type: types.DELETE_FACILITATION_SESSION,
  payload,
});

export const sessionDeleted = payload => ({
  type: types.FACILITATION_SESSION_DELETED,
  payload,
});

export const startedSession = payload => ({
  type: types.STARTED_FACILITATION_SESSION,
  payload,
});

export const getSession = payload => ({
  type: types.FETCH_FACILITATION_SESSION,
  payload,
});

export const fetchedSession = payload => ({
  type: types.FETCHED_FACILITATION_SESSION,
  payload,
});

export const getInputs = payload => ({
  type: types.FETCH_FACILITATION_INPUTS,
  payload,
});

export const fetchedInputs = payload => ({
  type: types.FETCHED_FACILITATION_INPUTS,
  payload,
});

export const getCandidates = payload => ({
  type: types.FETCH_FACILITATION_CANDIDATES,
  payload,
});

export const fetchedCandidates = payload => ({
  type: types.FETCHED_FACILITATION_CANDIDATES,
  payload,
});
export const getVotes = payload => ({
  type: types.FETCH_FACILITATION_VOTES,
  payload,
});

export const fetchedVotes = payload => ({
  type: types.FETCHED_FACILITATION_VOTES,
  payload,
});

export const getSummary = payload => ({
  type: types.FETCH_FACILITATION_SUMMARY,
  payload,
});

export const fetchedSummary = payload => ({
  type: types.FETCHED_FACILITATION_SUMMARY,
  payload,
});

export const listSessions = payload => ({
  type: types.LIST_FACILITATION_SESSIONS,
  payload,
});

export const fetchedSessionList = payload => ({
  type: types.FETCHED_FACILITATION_SESSION_LIST,
  payload,
});

export const submitInput = payload => ({
  type: types.SUBMIT_INPUT,
  payload,
});

export const inputSubmitted = payload => ({
  type: types.SUBMITTED_INPUT,
  payload,
});

export const submitCategorization = payload => ({
  type: types.SUBMIT_CATEGORIZATION,
  payload,
});

export const categorizationSubmitted = payload => ({
  type: types.SUBMITTED_CATEGORIZATION,
  payload,
});

export const submitSummary = payload => ({
  type: types.SUBMIT_SUMMARY,
  payload,
});

export const summarySubmitted = payload => ({
  type: types.SUBMITTED_SUMMARY,
  payload,
});

export const submitVotes = payload => ({
  type: types.SUBMIT_VOTES,
  payload,
});

export const votesSubmitted = payload => ({
  type: types.SUBMITTED_VOTES,
  payload,
});

export const sendHeartbeat = payload => ({
  type: types.SEND_HEARTBEAT,
  payload,
});

export const receivedHeartbeat = payload => ({
  type: types.RECEIVED_HEARTBEAT,
  payload,
});

export const progressState = payload => ({
  type: types.SEND_PROGRESS_STATE,
  payload,
});

export const stateProgressed = payload => ({
  type: types.STATE_PROGRESSED,
  payload,
});

export const errorTryAgainLater = payload => ({
  type: types.ERROR_RECEIVED_FROM_API,
  payload,
});

export const errorFailedToFetchSession = payload => ({
  type: types.ERROR_FAILED_TO_FETCH_SESSION,
  payload,
});
