import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Form from 'Components/Library/Forms/';
import FormTextField from 'Components/Library/Forms/Elements/FormTextField';
import LinearIndicator from 'Components/Library/Forms/Elements/LinearIndicator';
import FormRichTextField from 'Components/Library/Forms/Elements/FormRichTextField';
import InlineEditInput from 'Components/Library/BaseComponents/InlineEditInput';
import Container from 'Components/Library/BaseComponents/Container';
import RichTextEditor from 'Components/Library/RichTextEditor';
import RichTextRenderer from 'Components/Library/RichTextRenderer';
import { SCHEMA_FULL } from 'Components/Library/RichTextEditor/schemas';
import {
  KR_DESCRIPTION_MAX_LENGTH,
  KR_NAME_MAX_LENGTH,
  KR_RT_DESCRIPTION_MAX_LENGTH,
} from 'config/constants';
import { objectivesActions } from 'state/ducks/objectives';
import { appstatusSelectors } from 'state/ducks/appstatus';
import { useSelector } from 'react-redux';

const schema = {
  type: 'object',
  properties: {
    keyresult: { type: 'string', minLength: 1, maxLength: KR_NAME_MAX_LENGTH },
    description: { type: 'string', maxLength: KR_DESCRIPTION_MAX_LENGTH },
  },
  required: ['keyresult'],
  additionalProperties: true,
};
const richTextSchema = {
  type: 'object',
  properties: {
    keyresult: { type: 'string', minLength: 1, maxLength: KR_NAME_MAX_LENGTH },
    description_rt: {
      richtextschema: SCHEMA_FULL,
      minLength: 0,
      maxLength: KR_RT_DESCRIPTION_MAX_LENGTH,
    },
  },
  required: ['keyresult'],
  additionalProperties: true,
};
function KeyresultHeadlineContainer(props) {
  const { keyresultData, canEdit } = props;
  const { t } = useTranslation();

  const appstatus = useSelector(state => appstatusSelectors.selectStatus(state.main.appstatus));
  const useRichText = appstatus?.data?.okr_rt;
  const descriptionFieldName = useRichText ? 'description_rt' : 'description';
  const descriptionFieldValue = useRichText
    ? keyresultData?.description_rt
    : keyresultData.description;

  return (
    <Form
      name="panel-view-kr-headline"
      debouncedAutoSubmit={950}
      submitActionCreator={objectivesActions.editKeyresult}
      stateSlice="main.objectives"
      allowRefreshData
      refreshedDataSafeDeltaSeconds={10}
      initialValues={
        !!keyresultData && {
          keyresult: keyresultData.keyresult,
          [descriptionFieldName]: descriptionFieldValue,
        }
      }
      additionalProperties={{
        keyresultID: keyresultData.keyresultID,
      }}
      schema={useRichText ? richTextSchema : schema}
    >
      <LinearIndicator positioning="absolute" />
      <Container spacing={3} m={0} alignItems="stretch">
        <FormTextField
          fieldName="keyresult"
          render={fieldProps => (
            <InlineEditInput
              id="keyresultNameEditField"
              fullWidth
              multiline
              variant="h4"
              disabled={!canEdit}
              label={t('objectives.createNewKrNameInputLabel')}
              {...fieldProps}
            />
          )}
        />

        {useRichText ? (
          canEdit ? (
            <FormRichTextField
              fieldName="description_rt"
              render={fieldProps => (
                <RichTextEditor
                  {...fieldProps}
                  label={t('objectives.createNewKrDescriptionInputLabel')}
                  placeholder={t('objectives.createNewKrDescriptionInputLabel')}
                  toolbarVariant="floating"
                  variant="inline"
                  schema={SCHEMA_FULL}
                />
              )}
            />
          ) : (
            <RichTextRenderer richTextContent={descriptionFieldValue} schema={SCHEMA_FULL} />
          )
        ) : (
          <FormTextField
            fieldName="description"
            render={fieldProps => (
              <InlineEditInput
                id="keyresultDescriptionField"
                fullWidth
                multiline
                variant="body1"
                disabled={!canEdit}
                label={canEdit ? t('objectives.createNewKrDescriptionInputLabel') : null}
                {...fieldProps}
              />
            )}
          />
        )}
      </Container>
    </Form>
  );
}

KeyresultHeadlineContainer.propTypes = {
  objectiveData: PropTypes.object,
  keyresultData: PropTypes.object,
  objectiveID: PropTypes.string,
  canEdit: PropTypes.bool,
};

export default KeyresultHeadlineContainer;
