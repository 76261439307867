import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Form from 'Components/Library/Forms/';
import ReactQueryForm from 'Components/Library/Forms/ReactQueryForm';
import FormTextField from 'Components/Library/Forms/Elements/FormTextField';
import LinearIndicator from 'Components/Library/Forms/Elements/LinearIndicator';
import InlineEditInput from 'Components/Library/BaseComponents/InlineEditInput';
import RichTextEditor from 'Components/Library/RichTextEditor';
import FormRichTextField from 'Components/Library/Forms/Elements/FormRichTextField';
import RichTextRenderer from '../RichTextRenderer';
import { SCHEMA_FULL } from '../RichTextEditor/schemas';
import Container from '../BaseComponents/Container';
import { API_GRAPH_URL } from 'state/constants/api';
import { useMutation } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { instance as axiosInstance } from 'config/axios';
import { mutateNodeData } from 'hooks/useNodeData';

function DialogHeadline(props) {
  const {
    dataObject,
    name,
    editSchema,
    canEdit,
    additionalProperties,
    submitActionCreator,
    onSubmitSuccess,
    mode,
  } = props;
  const { t } = useTranslation();

  let initialValues = {};
  if (dataObject?.ok) {
    initialValues = {
      name: dataObject.data.name,
      description: dataObject.data.description,
    };
  }

  const tenantID = useSelector(state => state.auth.tenantID);

  const mutation = useMutation({
    mutationFn: payload =>
      axiosInstance.post(`${API_GRAPH_URL}/${tenantID}/node`, {
        ...payload,
      }),
    onSuccess: data => {
      // TODO: clean submitted fields from the dirty fields object in RQForm?
      console.log('mutate onSuccess: ', data);
      mutateNodeData(data.data.result.node);
    },
    onError: err => {
      console.log('error: ', err);
    },
  });

  // Backwards compatibility, clean this after
  // interlocks have been migrated over to react-query
  let FormElementType = Form;

  const formProps = {
    name: `panel-view-${name}-headline`,
    debouncedAutoSubmit: 950,
    schema: editSchema,
    initialValues: initialValues,
    stateSlice: `main.${name}`,
    allowRefreshData: true,
    refreshedDataSafeDeltaSeconds: 10,
    submitActionCreator: submitActionCreator,
    additionalProperties: additionalProperties,
    onSubmitSuccess: () => {
      if (!!onSubmitSuccess) {
        onSubmitSuccess();
      }
    },
  };

  if (mode === 'react-query') {
    FormElementType = ReactQueryForm;
    formProps.apiEndpointBaseUrl = `${API_GRAPH_URL}`;
    formProps.submitActionCreator = undefined;
    formProps.mutation = mutation;
  }

  return (
    <FormElementType {...formProps}>
      <LinearIndicator positioning="absolute" />
      <Container spacing={3} m={0} alignItems="stretch">
        <FormTextField
          fieldName="name"
          render={fieldProps => (
            <InlineEditInput
              id={`${name}NameField`}
              fullWidth
              multiline
              variant="h4"
              label={t(`${name}.nameInputLabel`)}
              {...fieldProps}
              disabled={!canEdit}
            />
          )}
        />
        {canEdit ? (
          <FormRichTextField
            fieldName="description"
            render={fieldProps => (
              <RichTextEditor
                {...fieldProps}
                label={t(`${name}.descriptionInputLabel`)}
                placeholder={t(`${name}.descriptionInputLabel`)}
                toolbarVariant="floating"
                variant="inline"
                schema={SCHEMA_FULL}
              />
            )}
          />
        ) : (
          <RichTextRenderer richTextContent={initialValues.description} schema={SCHEMA_FULL} />
        )}
      </Container>
    </FormElementType>
  );
}

DialogHeadline.propTypes = {
  dataObject: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  editSchema: PropTypes.object.isRequired,
  additionalProperties: PropTypes.object,
  submitActionCreator: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  canEdit: PropTypes.bool,
  mode: PropTypes.oneOf(['react-query', 'redux-legacy']),
};

DialogHeadline.defaultProps = {
  canEdit: false,
  mode: 'redux-legacy',
};

export default DialogHeadline;
