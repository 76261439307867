import { FormControlLabel, Checkbox, Typography } from '@mui/material';
import FormCheckbox from 'Components/Library/Forms/Elements/FormCheckbox';
import {
  CheckCircle,
  MilitaryTech,
  ModeStandby,
  NotInterested,
  RadioButtonUnchecked,
  SquareFoot,
} from '@mui/icons-material';
import { Widget, WIDGET_TYPE_LOCALIZATION_KEYS } from './index';
import { useTranslation } from 'react-i18next';
import InterlockIcon from 'Components/Features/Interlocks/InterlockIcon';
import CommitmentsIcon from 'Components/Features/Commitments/CommitmentsIcon';
import ContentCardIcon from './ContentCardIcon';

interface WidgetSelectorProps {
  widgetData: Widget;
}

const ICONS = {
  VISION_AND_PURPOSE: <ModeStandby />,
  VALUES_AND_DRIVERS: <MilitaryTech />,
  METHODS: <SquareFoot />,
  WILL_NOT_DO: <NotInterested />,
  OKRS: 'okrOptionTitle',
  COMMITMENTS: <CommitmentsIcon />,
  INTERLOCKS: <InterlockIcon />,
  CUSTOM_CARD: <ContentCardIcon />,
};

export function WidgetSelector(props: WidgetSelectorProps) {
  const { widgetData } = props;
  const { t } = useTranslation();

  return (
    <FormCheckbox
      fieldName={`widgets_to_copy.${widgetData.id}`}
      render={fieldProps => (
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              {...fieldProps}
              icon={<RadioButtonUnchecked />}
              checkedIcon={<CheckCircle color="secondary" />}
            />
          }
          sx={{
            borderRadius: 1,
            bgcolor: fieldProps.checked ? 'background.surface' : 'transparent',
            minHeight: 40,
            '& .MuiFormControlLabel-label': {
              flexGrow: 10,
              ml: 2,
              color: 'text.secondary',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            },
          }}
          labelPlacement="start"
          label={
            <>
              {ICONS[widgetData.widget_type]}
              <Typography sx={{ ml: 2 }} variant="subtitle2" color="text.secondary">
                {widgetData.widget_type === 'CUSTOM_CARD'
                  ? widgetData.name
                  : t(`gameplans.addMenu.${WIDGET_TYPE_LOCALIZATION_KEYS[widgetData.widget_type]}`)}
              </Typography>
            </>
          }
        />
      )}
    />
  );
}
