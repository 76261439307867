import CardOverLine, { validate as validateOverline } from './CardOverLine';
import CardTitle, { validate as validateTitle } from './CardTitle';
import CardSubtitle, { validate as validateSubtitle } from './CardSubtitle';
import CardParagraph, { validate as validateParagraph } from './CardParagraph';
import CardSlider, { validate as validateSlider } from './CardSlider';
import CardVideo, { validate as validateVideo } from './CardVideo';
import CardImage, { validate as validateImage } from './CardImage';
import CardAttachment, { validate as validateAttachment } from './CardAttachment';
import CardObjective, { validate as validateObjective } from './CardObjective';
import CardDivider, { validate as validateDivider } from './CardDivider';
import CardTrafficLight, { validate as validateTrafficLight } from './CardTrafficLight';
import CardTeamObjectives, { validate as validateTeamObjectives } from './CardTeamObjectives';

export const types = {
  overline: CardOverLine,
  title: CardTitle,
  subtitle: CardSubtitle,
  paragraph: CardParagraph,
  slider: CardSlider,
  image: CardImage,
  video: CardVideo,
  attachment: CardAttachment,
  objective: CardObjective,
  teamokrs: CardTeamObjectives,
  divider: CardDivider,
  trafficlight: CardTrafficLight,
};

const validators = {
  overline: validateOverline,
  title: validateTitle,
  subtitle: validateSubtitle,
  paragraph: validateParagraph,
  slider: validateSlider,
  image: validateImage,
  video: validateVideo,
  attachment: validateAttachment,
  objective: validateObjective,
  teamokrs: validateTeamObjectives,
  divider: validateDivider,
  trafficlight: validateTrafficLight,
};

export const validate = item => {
  const isValid = validators[item.type](item);
  return isValid;
};
