import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { insightsSelectors } from 'state/ducks/insights';
import DialogDownloadSurveyResults from './DialogDownloadSurveyResults';

const processData = async (surveyStats, survey) =>
  /* flatten the survey results into an array that can be dumped to csv */
  new Promise(resolve => {
    function recurse(node, parentPath, data) {
      const path = `${parentPath}/${node.id}`;
      if (!!node.data && node.data.status === 'ok') {
        const scores = { ...node.data.data.scores };
        /* add items with comments */
        for (const comment of node.data.data.comments) {
          const commentScoreKey = ['quad', 'sliders'].includes(survey.data.topicType)
            ? JSON.stringify(comment.score)
            : !!comment.score
            ? comment.score.toString()
            : null;
          const row = {
            teamId: node.id,
            teamPath: path,
            teamName: node.name,
            comment: comment.comment.replace(/(\r\n|\n|\r)/gm, ''),
          };
          if (survey.data.topicType === 'quad') {
            row.scorex = comment.score.x;
            row.scorey = comment.score.y;
          } else if (survey.data.topicType === 'multiplechoice') {
            row.score = survey.data.choiceOptions[commentScoreKey];
          } else if (survey.data.topicType === 'sliders') {
            survey.data.sliders.forEach((slider, index) => {
              if (slider.type === 'delta') {
                row[`${slider.question}-current`] = comment.score[index][0];
                row[`${slider.question}-goal`] = comment.score[index][1];
              } else {
                row[slider.question] = comment.score[index];
              }
            });
          } else {
            row.score = commentScoreKey;
          }
          data.push(row);
          scores[commentScoreKey] -= 1;
        }

        /* add items without comments */
        if (survey.data.topicType !== 'sliders') {
          // Slider survey replies always have a comment, an empty string
          // is added if the user did not provide a comment.
          // This has been done to workaround limitations caused by poor
          // design choices :)
          for (const score in scores) {
            for (let i = 0; i < scores[score]; i++) {
              const row = {
                teamId: node.id,
                teamPath: path,
                teamName: node.name,
                comment: '',
              };
              if (survey.data.topicType === 'quad') {
                const scoreObject = JSON.parse(score);
                row.scorex = scoreObject.x;
                row.scorey = scoreObject.y;
              } else if (survey.data.topicType === 'multiplechoice') {
                row.score = survey.data.choiceOptions[score];
              } else {
                row.score = score.toString();
              }
              data.push(row);
            }
          }
        }
      }

      for (const childNode of node.children) {
        recurse(childNode, path, data);
      }
      return data;
    }

    let data = [];
    data = recurse(surveyStats.data.orgTree, '', data);
    resolve(data);
  });

class DialogDownloadSurveyResultsContainer extends React.Component {
  render() {
    const { surveyID, surveyStats, survey } = this.props;
    if (!surveyID) {
      return null;
    }
    return (
      <DialogDownloadSurveyResults
        onClose={this.props.onClose}
        processData={() => processData(surveyStats, survey)}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  survey:
    ownProps.surveyID && insightsSelectors.selectSurvey(state.main.insights, ownProps.surveyID),
  surveyStats:
    ownProps.surveyID &&
    insightsSelectors.selectSurveyStats(state.main.insights, ownProps.surveyID),
});

DialogDownloadSurveyResultsContainer.propTypes = {
  surveyID: PropTypes.string,
  surveyStats: PropTypes.object,
  survey: PropTypes.object,
  onClose: PropTypes.func,
};

export default connect(mapStateToProps)(DialogDownloadSurveyResultsContainer);
