import { Divider, Typography, Skeleton, Box } from '@mui/material';
import PeriodSelector from 'Components/Features/Objectives/PeriodSelector';
import Container from 'Components/Library/BaseComponents/Container';
import StandardDialog from 'Components/Library/StandardDialog';
import useGameplanData from 'hooks/useGameplanData';
import { findBestCopyableGameplan } from '../../helpers';
import { useSelector } from 'react-redux';
import { objectivesSelectors } from 'state/ducks/objectives';
import useDomainOkrs from 'hooks/useDomainOkrs';
import { get } from 'lodash';
import { useMemo, useState } from 'react';
import CopyForm from './CopyForm';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface CopyGamePlanDialogProps {
  targetPeriod: string;
  teamId: string;
  targetGameplan?: string;
  open: boolean;
  onClose(): void;
  onSuccess(): void;
  variant?: 'continue-flow' | 'copy';
}

export type Widget = {
  id: string;
  widget_type: string;
  name?: string;
};

export type FormOptions = {
  core: Array<Widget>;
  okr: Array<Widget>;
  contentCards: Array<Widget>;
  commitmentsAndInterlocks: Array<Widget>;
  otherAvailableOptions: Array<String>;
};

export const WIDGET_TYPE_LOCALIZATION_KEYS = {
  VISION_AND_PURPOSE: 'visionAndPurposeOptionTitle',
  VALUES_AND_DRIVERS: 'valuesOptionTitle',
  METHODS: 'methodsOptionTitle',
  WILL_NOT_DO: 'willNotDoOptionTitle',
  OKRS: 'okrOptionTitle',
  COMMITMENTS: 'commitmentsOptionTitle',
  INTERLOCKS: 'interlocksOptionTitle',
};

export const CORE_WIDGETS = ['VISION_AND_PURPOSE', 'VALUES_AND_DRIVERS', 'METHODS', 'WILL_NOT_DO'];
const COM_INT = ['INTERLOCKS', 'COMMITMENTS'];

function CopyGamePlanDialog(props: CopyGamePlanDialogProps) {
  const {
    variant = 'continue-flow',
    targetPeriod,
    teamId,
    open,
    onClose,
    onSuccess,
    targetGameplan,
  } = props;
  const { t } = useTranslation();

  const periodConfig = useSelector((state: any) =>
    objectivesSelectors.selectPeriodConfig(state.main.objectives),
  );

  const gameplans = useGameplanData(teamId);
  const gameplansPerPeriod = {};

  for (const gameplan of get(gameplans, 'data', [])) {
    if (gameplan.stperiod) {
      gameplansPerPeriod[`${gameplan.ltperiod}-${gameplan.stperiod}`] = gameplan;
    }
  }
  const previousPeriod = findBestCopyableGameplan(periodConfig, targetPeriod, gameplansPerPeriod);
  const [sourcePeriod, setSourcePeriod] = useState(previousPeriod);
  const sourceGameplan = gameplansPerPeriod[sourcePeriod];

  const domain = useMemo(() => ({ t: 'team', d: teamId }), [teamId]);

  const sourceStperiod = useMemo(
    () => `${sourceGameplan.ltperiod}-${sourceGameplan.stperiod}`,
    [sourceGameplan],
  );
  const { loading, okrs } = useDomainOkrs([domain], sourceStperiod);

  const periodFilterFunc = p => {
    // Filter out long term periods
    if (!p.stPeriodId) {
      return false;
    }
    if (p.stPeriodId === targetPeriod) {
      return null;
    }
    return p.stPeriodId in gameplansPerPeriod;
  };

  // Preprocess and sort list of widgets to select
  const widgets: any = Object.values(get(gameplansPerPeriod[sourcePeriod], 'widget_data', {}));

  const opts: FormOptions = {
    core: [],
    okr: [],
    contentCards: [],
    commitmentsAndInterlocks: [],
    otherAvailableOptions: [],
  };
  const initialValues: { widgets_to_copy: { [key: string]: boolean } } = { widgets_to_copy: {} };

  CORE_WIDGETS.forEach(widgetType => {
    const w = widgets.find(w => w.widget_type === widgetType);
    if (w) {
      opts.core.push(w);
      initialValues.widgets_to_copy[w.id] = true;
    }
  });

  const okrArr = get(okrs, [0, 'data'], []);

  const existingOkrWidget = widgets.find(w => w.widget_type === 'OKRS');
  if (existingOkrWidget) {
    opts.okr.push(existingOkrWidget);
    initialValues.widgets_to_copy[existingOkrWidget.id] = true;
    if (okrArr.length > 0) {
      opts.otherAvailableOptions.push('copy_objectives');
      const hasKrs = okrArr.find(o => o?.data?.keyresults && o.data.keyresults.length > 0);
      if (hasKrs) {
        opts.otherAvailableOptions.push('copy_keyresults');
      }
    }
  }

  COM_INT.forEach(widgetType => {
    const w = widgets.find(w => w.widget_type === widgetType);
    if (w) {
      opts.commitmentsAndInterlocks.push(w);
      initialValues.widgets_to_copy[w.id] = true;
    }
  });

  const customCards = widgets.filter(w => w.widget_type === 'CUSTOM_CARD') || [];
  for (const w of customCards) {
    initialValues.widgets_to_copy[w.id] = true;
  }
  opts.contentCards.push(...customCards);

  if (!gameplans.ok) {
    return null;
  }

  if (loading) {
    return <Skeleton height={120} />;
  }

  return (
    <StandardDialog open={open} onBackdropClick={onClose} size="small">
      <Box display="flex" flexDirection="column" sx={{ overflowY: 'hidden' }}>
        <Container m={3} spacing={2} alignItems="stretch">
          {variant === 'continue-flow' ? (
            <Typography variant="subtitle1">{t('gameplans.copyMenu.continueCTA')}</Typography>
          ) : (
            <Typography variant="subtitle1">
              {t('gameplans.addMenu.copyOptionDescription')}
            </Typography>
          )}
          <Container>
            {variant === 'continue-flow' ? (
              <Typography variant="subtitle2">{t('gameplans.copyMenu.menuSubtitle')}</Typography>
            ) : (
              <PeriodSelector
                onSelect={p => {
                  setSourcePeriod(p.stPeriodId);
                }}
                value={{ stPeriodId: sourcePeriod }}
                filterFunc={periodFilterFunc}
                IconComponent={ExpandMoreIcon}
              />
            )}
          </Container>
        </Container>
        <Divider />
        <CopyForm
          // Force re-mount of the form if the period changes
          key={`copy-gameplan-dialog-form+${sourcePeriod}`}
          sourceGameplan={sourceGameplan}
          okrs={okrs}
          initialValues={initialValues}
          targetPeriod={targetPeriod}
          targetGameplanId={targetGameplan}
          teamId={teamId}
          options={opts}
          onClose={onClose}
          onSuccess={onSuccess}
          variant={variant}
        />
      </Box>
    </StandardDialog>
  );
}

export default CopyGamePlanDialog;
