const imgFileToDataUrl = e => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException('Problem parsing input file.')); // TODO: solve how this can be translated, using other functions than withTranslation, or get t as parameter
    };

    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(e.target.files[0]);
  });
};

export default imgFileToDataUrl;
