import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { objectivesSelectors } from 'state/ducks/objectives';
import ObjectivesWidgetCard from './ObjectivesWidgetCard';
import ObjectivesWidgetExpanded from './ObjectivesWidgetExpanded';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { gameplansSelectors } from 'state/ducks/gameplans';

function ObjectivesWidget({
  variant,
  canEdit,
  teamId,
  dragHandleProps,
  requestRemove,
  name,
  gameplanId,
}) {
  const currentPeriod = useSelector(state =>
    objectivesSelectors.selectCurrentPeriod(state.main.objectives),
  );

  const gameplans = useSelector(state =>
    gameplansSelectors.selectGamePlans(state.main.gameplans, teamId),
  );
  const gameplan = gameplans.ok ? gameplans.data.find(gp => gp.id === gameplanId) : {};
  const gameplanPeriod = `${gameplan.ltperiod}-${gameplan.stperiod}`;

  const usePeriodicGameplans = useFeatureFlag('PERIODIC-GAMEPLANS');

  const [period, selectPeriod] = useState();
  const appliedPeriod = usePeriodicGameplans ? gameplanPeriod : period || currentPeriod;

  const nextShortTermPeriod = useSelector(state =>
    objectivesSelectors.selectNextShortTermPeriod(state.main.objectives, appliedPeriod),
  );
  const prevShortTermPeriod = useSelector(state =>
    objectivesSelectors.selectPrevShortTermPeriod(state.main.objectives, appliedPeriod),
  );

  if (variant === 'card') {
    return (
      <ObjectivesWidgetCard
        usePeriodicGameplans={usePeriodicGameplans}
        key={`okr-widget-${teamId}-appliedPeriod`}
        teamId={teamId}
        canEdit={canEdit}
        nextShortTermPeriod={nextShortTermPeriod}
        prevShortTermPeriod={prevShortTermPeriod}
        appliedPeriod={appliedPeriod}
        selectPeriod={selectPeriod}
        requestRemove={requestRemove}
        dragHandleProps={dragHandleProps}
        name={name}
      />
    );
  }
  return (
    <ObjectivesWidgetExpanded
      usePeriodicGameplans={usePeriodicGameplans}
      teamId={teamId}
      canEdit={canEdit}
      nextShortTermPeriod={nextShortTermPeriod}
      prevShortTermPeriod={prevShortTermPeriod}
      appliedPeriod={appliedPeriod}
      selectPeriod={selectPeriod}
      requestRemove={requestRemove}
      dragHandleProps={dragHandleProps}
      name={name}
    />
  );
}

ObjectivesWidget.propTypes = {
  teamId: PropTypes.string,
  gameplanId: PropTypes.string,
  dragHandleProps: PropTypes.object,
  requestRemove: PropTypes.func,
  variant: PropTypes.oneOf(['card', 'dialog']),
  canEdit: PropTypes.bool,
  name: PropTypes.string,
};

ObjectivesWidget.defaultProps = {
  variant: 'card',
};

export default ObjectivesWidget;
