import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withLocation, withNavigation } from 'withRouter';
import PanelViewObjective from 'Components/Features/Objectives/PanelViewObjective2';
import PanelViewKeyresult from 'Components/Features/Objectives/PanelViewKeyresult';
import PanelAddObjective from 'Components/Features/Objectives/PanelAddObjective';
import PanelAddKeyresult from 'Components/Features/Objectives/PanelAddKeyresult';

import PanelReplyTopic from 'Components/Features/Topics/PanelReplyTopic';
import PanelCreateTopic from 'Components/Features/Topics/PanelCreateTopic';
import PanelCreateSurvey from 'Components/Features/Topics/PanelCreateSurvey';
import SurveyDeleteDialog from 'Components/Features/Topics/SurveyDeleteDialog';
import PanelCreateInterpretation from 'Components/Features/CSI/PanelCreateInterpretation';
import PanelViewInterpretation from 'Components/Features/CSI/PanelViewInterpretation';
import PanelNotifications from 'Components/Common/NotificationsPanel';
import DialogObjectives from 'Components/Features/Objectives/DialogObjectives';
import DialogObjectiveReflection from 'Components/Features/Objectives/DialogObjectiveReflection';
import PanelStartFacilitation from 'Components/Features/DigitalFacilitation/PanelStartFacilitation';
import PanelFacilitationSession from 'Components/Features/DigitalFacilitation/PanelFacilitationSession';
import DeleteFacilitationSessionDialog from 'Components/Features/DigitalFacilitation/DeleteFacilitationSessionDialog';
import PanelCreateProgram from 'Components/Features/Programs/PanelCreateProgram';
import DialogTeamPage from 'Components/Features/Teams/DialogTeamPage';
import DialogAddCommitment from 'Components/Features/Commitments/DialogAddCommitment';
import DialogViewCommitment from 'Components/Features/Commitments/DialogViewCommitment';

import DialogAddInterlock from 'Components/Features/Interlocks/DialogAddInterlock';
import DialogViewInterlock from 'Components/Features/Interlocks/DialogViewInterlock';

import CustomWidgetEditor from 'Components/Features/GamePlans/Widgets/CustomWidget/CustomWidgetEditor';
import GameplanWidgetDialog from 'Components/Features/GamePlans/Widgets/GameplanWidgetDialog';
import * as modalController from './helpers';
import DialogKrCheckin from 'Components/Features/Objectives/DialogKrCheckin';

import ContextCardEditor from 'Components/Features/Contextv2/ContextCardEditor';
import DialogContextCard from 'Components/Features/Contextv2/DialogContextCard';
import PanelViewSurveyResults from 'Components/Features/Topics/PanelViewSurveyResults';

class ModalContainer extends Component {
  state = {
    modalState: [],
    hash: '',
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    // only change the state if the hash has changed
    if (nextProps.location.hash !== prevState.hash) {
      return {
        modalState: modalController.parseHash(nextProps.location.hash),
        hash: nextProps.location.hash,
      };
    }
    return null;
  }

  render() {
    const { modalState } = this.state;
    const { location, navigate } = this.props;

    return (
      <>
        {modalState.map((modal, index) => {
          if (modal.type === 'objectives.view')
            return (
              <PanelViewObjective
                open
                objectiveID={modal.objectiveID}
                key={`panel-view-objectives-${modal.objectiveID}`}
                zIndexModifier={index}
                period={modal.period}
                onClose={() => modalController.closeModal(navigate, location)}
              />
            );
          if (modal.type === 'objectives.viewkr')
            return (
              <PanelViewKeyresult
                open
                keyresultID={modal.keyresultID}
                key={`panel-view-keyresult-${modal.keyresultID}`}
                zIndexModifier={index}
                period={modal.period}
                onClose={() => modalController.closeModal(navigate, location)}
              />
            );
          if (modal.type === 'objectives.krcheckin')
            return (
              <DialogKrCheckin
                keyresultID={modal.keyresultID}
                key={`dialog-kr-checkin-${modal.keyresultID}`}
                zIndexModifier={index}
                onClose={() => modalController.closeModal(navigate, location)}
              />
            );
          if (modal.type === 'objectives.addkr')
            return (
              <PanelAddKeyresult
                open
                objectiveID={modal.objectiveID}
                key={`panel-add-keyresult-${modal.objectiveID}`}
                zIndexModifier={index}
                onClose={() => modalController.closeModal(navigate, location)}
                onKeyresultCreated={keyresultID => {
                  modalController.openViewKeyresultPanel(
                    '-',
                    keyresultID,
                    true,
                    navigate,
                    location,
                  );
                }}
              />
            );
          if (modal.type === 'objectives.add') {
            return (
              <PanelAddObjective
                open
                onCancel={() => modalController.closeModal(navigate, location)}
                type={modal.domain}
                stperiod={modal.stperiod}
                teamId={modal.teamId}
                zIndexModifier={index}
                key={`panel-add-objective-${modal.domain}-${modal.teamId}`}
                onObjectiveCreated={objectiveID => {
                  modalController.openViewObjectivePanel(
                    modal.stperiod,
                    objectiveID,
                    true,
                    navigate,
                    location,
                  );
                }}
                domains={modal.domains}
              />
            );
          }
          if (modal.type === 'objectives.dashboard')
            return (
              <DialogObjectives
                open
                period={modal.period}
                domain={modal.domain}
                zIndexModifier={index}
                key={`panel-view-objective-domain-widget-${modal.domain.t}-${modal.domain.d}`}
                onClose={() => modalController.closeModal(navigate, location)}
              />
            );
          if (modal.type === 'objectives.reflect')
            return (
              <DialogObjectiveReflection
                open
                objectiveID={modal.objectiveID}
                key="objectives.reflect"
                onClose={() => modalController.closeModal(navigate, location)}
              />
            );
          if (modal.type === 'notifications.view')
            return (
              <PanelNotifications
                open
                key="notifications.view"
                zIndexModifier={index}
                onClose={() => modalController.closeModal(navigate, location)}
              />
            );
          if (modal.type === 'insights.reply')
            return (
              <PanelReplyTopic
                open
                messageID={modal.surveyID}
                key={`insights.reply${modal.surveyID}`}
                zIndexModifier={index}
                onClose={() => modalController.closeModal(navigate, location)}
              />
            );
          if (modal.type === 'insights.create')
            return (
              <PanelCreateTopic
                open
                audienceID={modal.audienceID}
                audienceType={modal.audienceType}
                key={`insights.create${modal.audienceID}`}
                zIndexModifier={index}
                onClose={() => modalController.closeModal(navigate, location)}
              />
            );
          if (modal.type === 'insights.createsurvey')
            return (
              <PanelCreateSurvey
                open
                topicID={modal.topicID}
                key={`insights.createsurvey${modal.topicID}`}
                zIndexModifier={index}
                onClose={() => modalController.closeModal(navigate, location)}
              />
            );
          if (modal.type === 'insights.deletesurvey')
            return (
              <SurveyDeleteDialog
                open
                key={`insights.deletesurvey.${modal.surveyID}`}
                zIndexModifier={index}
                surveyID={modal.surveyID}
                onClose={() => modalController.closeModal(navigate, location)}
              />
            );
          if (modal.type === 'interpretation.viewteam')
            return (
              <PanelViewInterpretation
                open
                teamId={modal.teamId}
                csiID={modal.csiID}
                zIndexModifier={index}
                key={`interpretation.viewteam${modal.cpID}`}
                onClose={() => modalController.closeModal(navigate, location)}
              />
            );
          if (modal.type === 'interpretation.create')
            return (
              <PanelCreateInterpretation
                open
                cpID={modal.cpID}
                zIndexModifier={index}
                key={`interpretation.create${modal.cpID}`}
                onClose={() => modalController.closeModal(navigate, location)}
              />
            );
          if (modal.type === 'insights.viewsurvey')
            return (
              <PanelViewSurveyResults
                open
                surveyID={modal.surveyID}
                teamID={modal.teamID}
                zIndexModifier={index}
                doNotCascade={modal.doNotCascade}
                key="insights.viewsurvey"
                onClose={() => modalController.closeModal(navigate, location)}
              />
            );
          if (modal.type === 'context.view')
            return (
              <DialogContextCard
                open
                contextID={modal.contextID}
                zIndexModifier={index}
                contextType="program"
                cardID={modal.cardID}
                key={`context.view.${modal.cardID}`}
                onClose={() => modalController.closeModal(navigate, location)}
              />
            );
          if (modal.type === 'context.edit')
            return (
              <ContextCardEditor
                open
                contextID={modal.contextID}
                zIndexModifier={index}
                contextType="program"
                cardID={modal.cardID}
                key="context.edit"
                onClose={() => modalController.closeModal(navigate, location)}
              />
            );
          if (modal.type === 'context.create')
            return (
              <ContextCardEditor
                open
                contextID={modal.contextID}
                zIndexModifier={index}
                contextType="program"
                key="context.create"
                onClose={() => modalController.closeModal(navigate, location)}
              />
            );
          if (modal.type === 'context.viewteam')
            return (
              <DialogContextCard
                open
                contextID={modal.contextID}
                zIndexModifier={index}
                contextType="team"
                cardID={modal.cardID}
                key={`context.view.${modal.cardID}`}
                onClose={() => modalController.closeModal(navigate, location)}
              />
            );
          if (modal.type === 'context.editteam')
            return (
              <ContextCardEditor
                open
                contextID={modal.contextID}
                zIndexModifier={index}
                contextType="team"
                cardID={modal.cardID}
                key="context.edit"
                onClose={() => modalController.closeModal(navigate, location)}
              />
            );
          if (modal.type === 'context.createteam')
            return (
              <ContextCardEditor
                open
                contextID={modal.contextID}
                zIndexModifier={index}
                contextType="team"
                key="context.create"
                onClose={() => modalController.closeModal(navigate, location)}
              />
            );
          if (modal.type === 'facilitation.start')
            return (
              <PanelStartFacilitation
                open
                teamID={modal.teamID}
                zIndexModifier={index}
                key="facilitation.start"
                onClose={() => modalController.closeModal(navigate, location)}
              />
            );
          if (modal.type === 'facilitation.live')
            return (
              <PanelFacilitationSession
                open
                teamID={modal.teamID}
                zIndexModifier={index}
                sessionID={modal.sessionID}
                key="facilitation.live"
                onClose={() => modalController.closeModal(navigate, location)}
              />
            );
          if (modal.type === 'facilitation.delete')
            return (
              <DeleteFacilitationSessionDialog
                open
                teamID={modal.teamID}
                sessionID={modal.sessionID}
                zIndexModifier={index}
                key="facilitation.delete"
                onClose={() => modalController.closeModal(navigate, location)}
              />
            );
          if (modal.type === 'programs.create')
            return (
              <PanelCreateProgram
                open
                cpID={modal.cpID}
                zIndexModifier={index}
                key={`programs.create${modal.cpID}`}
                onClose={() => modalController.closeModal(navigate, location)}
              />
            );
          if (modal.type === 'team.viewteam') {
            return (
              <DialogTeamPage
                open
                teamId={modal.teamId}
                page={modal.page}
                subpage={modal.subpage}
                zIndexModifier={index}
                key={`team.view${modal.teamId}`}
                onClose={() => modalController.closeModal(navigate, location)}
              />
            );
          }
          if (modal.type === 'commitments.add') {
            return (
              <DialogAddCommitment
                open
                key="commitments.add"
                onClose={() => modalController.closeModal(navigate, location)}
                onCommitmentCreated={commitmentID => {
                  modalController.openViewCommitmentPanel(commitmentID, modal, navigate, location);
                }}
                domains={modal.domains}
                zIndexModifier={index}
                relatedItemID={modal?.related}
              />
            );
          }
          if (modal.type === 'commitments.view') {
            return (
              <DialogViewCommitment
                open
                commitmentID={modal.commitmentID}
                key={`commitments.view-${modal.commitmentID}`}
                onClose={() => modalController.closeModal(navigate, location)}
                zIndexModifier={index}
              />
            );
          }
          if (modal.type === 'interlocks.add') {
            return (
              <DialogAddInterlock
                open
                key="interlocks.add"
                onClose={() => modalController.closeModal(navigate, location)}
                onInterlockCreated={interlockID => {
                  modalController.openViewInterlockPanel(interlockID, modal, navigate, location);
                }}
                domains={modal.domains}
                zIndexModifier={index}
                relatedItemID={modal?.related}
              />
            );
          }
          if (modal.type === 'interlocks.view') {
            return (
              <DialogViewInterlock
                open
                interlockID={modal.interlockID}
                key={`interlocks.view-${modal.interlockID}`}
                onClose={() => modalController.closeModal(navigate, location)}
                zIndexModifier={index}
              />
            );
          }
          if (modal.type === 'gameplans.add') {
            return (
              <CustomWidgetEditor
                open
                isNew
                key={`gameplans.add.${modal.gameplanId}`}
                domainId={modal.domainId}
                gameplanId={modal.gameplanId}
                onClose={() => modalController.closeModal(navigate, location)}
                zIndexModifier={index}
              />
            );
          }
          if (modal.type === 'gameplans.edit') {
            return (
              <CustomWidgetEditor
                open
                isNew={false}
                key={`gameplans.edit.${modal.widgetId}`}
                domainId={modal.domainId}
                gameplanId={modal.gameplanId}
                widgetId={modal.widgetId}
                onClose={() => modalController.closeModal(navigate, location)}
                zIndexModifier={index}
              />
            );
          }
          if (modal.type === 'gameplans.view') {
            return (
              <GameplanWidgetDialog
                open
                key={`gameplans.view.${modal.widgetId}`}
                domainId={modal.domainId}
                gameplanId={modal.gameplanId}
                widgetId={modal.widgetId}
                onClose={() => modalController.closeModal(navigate, location)}
                zIndexModifier={index}
              />
            );
          }
          return null;
        })}
      </>
    );
  }
}

ModalContainer.propTypes = {
  location: PropTypes.object,
  navigate: PropTypes.func,
};

export default withLocation(withNavigation(ModalContainer));
