import React from 'react';
import PropTypes from 'prop-types';
import { useTheme, useMediaQuery } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import Autosuggest from 'react-autosuggest';
import TextField from '@mui/material/TextField';
import InputBase from '@mui/material/InputBase';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { withTranslation } from 'react-i18next';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import TeamSearchResult from './TeamSearchResult';
import UserSearchResult from './UserSearchResult';

const styles = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  mobilecontainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 48,
    width: 48,
    position: 'relative',
  },
  mobilecontainerWithLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 48,
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  sectionDivider: {
    marginBottom: theme.spacing(2),
    height: 1,
    width: '100%',
    backgroundColor: theme.palette.primary[200],
    '& :first-of-type': {
      display: 'none',
      margin: 0,
    },
  },
  input: {
    paddingRight: 0,
  },
  mobileInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 8,
    backgroundColor: theme.palette.primary.main,
    color: '#fff !important',
    height: 56,
    '& svg': {
      color: '#fff !important',
    },
  },
  mobileInput: {
    color: '#fff !important',
    height: 56,
  },
  mobileActionTarget: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
  },
});

function mediaQueryWrapper(Component) {
  return function WrappedComponent(props) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return <Component {...props} isMobile={isMobile} />;
  };
}

const renderSuggestion = suggestion => {
  if (suggestion.type === 'person') {
    return <UserSearchResult suggestion={suggestion} />;
  }
  if (suggestion.type === 'team') {
    return <TeamSearchResult suggestion={suggestion} />;
  }
  return null;
};

const getSuggestionValue = suggestion => {
  if (suggestion.type === 'person') {
    return suggestion.fullName;
  }
  if (suggestion.type === 'team') {
    return suggestion.teamName;
  }
  return '';
};

const getSectionSuggestions = section => {
  if (section) {
    return section.suggestions;
  }
  return [];
};

class OrganizationSearch extends React.Component {
  state = {
    mobileDialogOpen: false,
  };

  renderInput = inputProps => {
    const { classes, isMobile, t } = this.props;
    const { inputClasses, ref, variant, label, placeholder, fetching, ...other } = inputProps;
    const endAdornment = fetching ? (
      <InputAdornment style={{ marginRight: 8 }} position="end">
        <CircularProgress name="CircularProgress" size={20} />
      </InputAdornment>
    ) : (
      <InputAdornment style={{ marginRight: 8 }} position="end">
        <SearchIcon />
      </InputAdornment>
    );
    if (!isMobile) {
      const inputCls = {
        focused: inputClasses.focused,
      };
      if (variant !== 'outlined') {
        inputCls.underline = inputClasses.underline;
      }
      return (
        <TextField
          fullWidth
          variant={variant}
          label={label}
          placeholder={placeholder}
          name="org-search-desktop-input"
          InputProps={{
            inputRef: ref,
            classes: inputCls,
            endAdornment,
            ...other,
          }}
        />
      );
    }
    return (
      <div className={classes.mobileInputContainer}>
        <IconButton
          onClick={this.closeMobileSearch}
          size="large"
          aria-label={t('objectives.organizationSearchClose')}
        >
          <CloseIcon />
        </IconButton>
        <InputBase
          name="org-search-mobile-input"
          fullWidth
          type="text"
          value={other.value}
          onChange={other.onChange}
          onBlur={other.onBlur}
          onFocus={other.onFocus}
          onKeyDown={other.onKeyDown}
          autoComplete="off"
          autoFocus
          className={classes.mobileInput}
          placeholder={!!label ? label : placeholder}
        />
        {fetching ? <CircularProgress name="CircularProgress" size={20} /> : <SearchIcon />}
      </div>
    );
  };

  renderSuggestionsContainer = options => {
    const { containerProps, children } = options;
    return (
      <div
        key={containerProps.key}
        ref={containerProps.ref}
        role={containerProps.role}
        style={containerProps.style}
        className={containerProps.className}
        id="org-search-suggestions-container"
        aria-label={this.props.t('objectives.organizationSearchPlaceholder')}
      >
        {children}
      </div>
    );
  };

  renderAutosuggest = () => {
    const {
      classes,
      autoFocus,
      suggestions,
      containerClass,
      inputClass,
      inputVariant = 'standard',
      underlineClass,
      focusedClass,
      error,
      className,
      label,
      placeholder,
      fetching,
      isMobile,
      id,
    } = this.props;

    const cls = { ...classes, underline: underlineClass, focused: focusedClass };

    return (
      <Autosuggest
        theme={{
          container: clsx([classes.container, className]),
          input: clsx([classes.input, isMobile ? '' : inputClass]),
          suggestionsContainerOpen: clsx([classes.suggestionsContainerOpen, containerClass]),
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderInputComponent={
          isMobile ? inputProps => this.renderInput(inputProps) : this.renderInput
        }
        suggestions={suggestions}
        multiSection
        getSectionSuggestions={getSectionSuggestions}
        renderSectionTitle={this.renderSectionTitle}
        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        onSuggestionSelected={this.handleSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        focusInputOnSuggestionClick={!isMobile}
        id={id}
        inputProps={{
          inputClasses: isMobile ? classes : cls,
          error,
          value: this.props.value,
          onChange: this.props.handleChange,
          autoFocus,
          variant: inputVariant,
          label,
          placeholder,
          fetching,
          id: `react-autowhatever-${id}`, // this id is needed for the aria to work properly
        }}
      />
    );
  };

  handleSuggestionSelected = (event, { suggestion }) => {
    this.props.onSelect(suggestion);
    this.setState({ mobileDialogOpen: false });
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    this.props.onFetchRequested(value);
  };

  handleSuggestionsClearRequested = () => {
    this.props.onClearRequested();
  };

  renderSectionTitle = () => {
    const { classes } = this.props;

    return <div className={classes.sectionDivider} />;
  };

  openMobileSearch = () => {
    this.setState({ mobileDialogOpen: true });
  };

  closeMobileSearch = () => {
    this.setState({ mobileDialogOpen: false });
  };

  render() {
    const { classes, className, mobileLabel, mobileClass, isMobile } = this.props;
    const { mobileDialogOpen } = this.state;

    let returnCode = null;

    if (isMobile) {
      returnCode = (
        <>
          <div
            className={clsx([
              !!mobileLabel ? classes.mobilecontainerWithLabel : classes.mobilecontainer,
              className,
              mobileClass,
            ])}
          >
            <SearchIcon />
            {!!mobileLabel && <Typography variant="body2">{mobileLabel}</Typography>}
            <div
              id="org-search-mobile-open-btn"
              onClick={this.openMobileSearch}
              className={classes.mobileActionTarget}
            />
          </div>
          <Dialog fullScreen open={mobileDialogOpen}>
            {this.renderAutosuggest()}
          </Dialog>
        </>
      );
    } else {
      returnCode = this.renderAutosuggest();
    }
    return returnCode;
  }
}

OrganizationSearch.propTypes = {
  classes: PropTypes.exact({
    container: PropTypes.string,
    mobilecontainer: PropTypes.string,
    mobilecontainerWithLabel: PropTypes.string,
    suggestionsContainerOpen: PropTypes.string,
    suggestion: PropTypes.string,
    suggestionsList: PropTypes.string,
    sectionDivider: PropTypes.string,
    input: PropTypes.string,
    mobileInputContainer: PropTypes.string,
    mobileInput: PropTypes.string,
    mobileActionTarget: PropTypes.string,
    underline: PropTypes.string,
    focused: PropTypes.string,
  }),
  onSelect: PropTypes.func,
  onFetchRequested: PropTypes.func,
  onClearRequested: PropTypes.func,
  autoFocus: PropTypes.func,
  suggestions: PropTypes.array,
  containerClass: PropTypes.string,
  inputClass: PropTypes.string,
  inputVariant: PropTypes.string,
  underlineClass: PropTypes.string,
  focusedClass: PropTypes.string,
  mobileClass: PropTypes.string,
  error: PropTypes.bool,
  label: PropTypes.string,
  mobileLabel: PropTypes.string,
  placeholder: PropTypes.string,
  fetching: PropTypes.bool,
  t: PropTypes.func,
  isMobile: PropTypes.bool,
  id: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
};

export default mediaQueryWrapper(withTranslation()(withStyles(styles)(OrganizationSearch)));
