import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddLinkIcon from '@mui/icons-material/AddLink';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { graphSelectors, graphActions } from 'state/ducks/graph';

import GraphElementChip from 'Components/Library/GraphElementResolver/GraphElementChip';
import CanEditNodeWrapper from 'Components/Library/NodeRelations/CanEditNodeWrapper';
import NodeSelector from 'Components/Library/NodeSelector';
import ObjectivesIcon from 'Components/Features/Objectives/ObjectivesIcon';
import CommitmentsIcon from 'Components/Features/Commitments/CommitmentsIcon';
import InterlockIcon from 'Components/Features/Interlocks/InterlockIcon';
import RelationshipIcon from 'Components/Library/NodeRelations/RelationshipIcon';
import { RELATION_DOMAIN_TYPE } from 'config/constants';
import {
  openAddInterlockPanel,
  openAddCommitmentPanel,
  openAddObjectivePanel,
} from 'config/ModalProvider/helpers';
import CollapsibleDialogSection from 'Components/Library/CollapsibleDialogSection/index';
import AddLinkModal from './AddLinkModal';

const placeHolderContainerStyles = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  minHeight: 32,
  pl: 1,
  pr: 1,
  fontSize: '14px',
};

function NodeRelations(props) {
  const { graphId, cta, canEdit, onSelect, addNewContext } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();

  const menuRef = useRef(null);

  const [addingLink, setAddingLink] = useState(false);

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      dispatch(graphActions.fetchGraphRelations({ id: graphId, force: true }));
      mounted.current = true;
    } else {
      dispatch(graphActions.fetchGraphRelations({ id: graphId, force: false }));
    }
  });

  const relations = useSelector(
    state => graphSelectors.selectRelations(state.main.graph, graphId),
    // { stabilityCheck: 'never' },
  );

  const hasRelations = relations?.data?.length > 0;

  const removeRelation = (event, link) => {
    dispatch(graphActions.editRelations({ changeset: [{ ...link, action: 'DELETE' }] }));
    event.preventDefault();
    event.stopPropagation();
  };

  const onSelectHandler = searchResult => {
    if (searchResult?.type === 'placeholder') {
      searchResult.onSelect();
    } else {
      onSelect(searchResult);
    }
    return true;
  };

  const addNewItemDomains = [{ type: RELATION_DOMAIN_TYPE, id: graphId }].concat(
    addNewContext || [],
  );

  const menuOptions = useMemo(() => {
    const opts = [
      {
        type: 'placeholder',
        title: (
          <Box sx={placeHolderContainerStyles}>
            <AddLinkIcon fontSize="inherit" sx={{ mr: 1 }} />
            <Typography variant="subtitle2">{t('relations.addMenu.createLink')}</Typography>
          </Box>
        ),
        name: 'relations-create-link',
        value: 'relations-create-link',
        onSelect: () => setAddingLink(true),
      },
      {
        type: 'placeholder',
        title: (
          <Box sx={placeHolderContainerStyles}>
            <InterlockIcon color="featureInterlocks" fontSize="inherit" sx={{ mr: 1 }} />
            <Typography variant="subtitle2">{t('relations.addMenu.createInterlock')}</Typography>
          </Box>
        ),
        name: 'relations-create-interlock',
        value: 'relations-create-interlock',
        onSelect: () => openAddInterlockPanel(navigate, location, addNewItemDomains),
      },
      {
        type: 'placeholder',
        title: (
          <Box sx={placeHolderContainerStyles}>
            <CommitmentsIcon color="featureCommitments" fontSize="inherit" sx={{ mr: 1 }} />
            <Typography variant="subtitle2">{t('relations.addMenu.createCommitment')}</Typography>
          </Box>
        ),
        name: 'relations-create-commitment',
        value: 'relations-create-commitment',
        onSelect: () => openAddCommitmentPanel(navigate, location, addNewItemDomains),
      },
      {
        type: 'placeholder',
        title: (
          <Box sx={placeHolderContainerStyles}>
            <ObjectivesIcon color="featureOkr" fontSize="inherit" sx={{ mr: 1 }} />
            <Typography variant="subtitle2">{t('relations.addMenu.createObjective')}</Typography>
          </Box>
        ),
        name: 'relations-create-objective',
        value: 'relations-create-objective',
        onSelect: () =>
          openAddObjectivePanel(
            { type: 'personal', stperiod: 'current' },
            navigate,
            location,
            graphId,
          ),
      },
    ];
    return opts;
  }, [navigate, t, location, graphId, addNewItemDomains]);

  const infoSection = (
    <Box
      sx={{
        backgroundColor: 'action.hover',
        p: 2,
        m: 1,
        borderRadius: 1,
      }}
    >
      <Typography variant="body2" color="text.disabled" sx={{ p: 1, pt: 0 }}>
        {!hasRelations && !canEdit ? t('relations.placeholderNoItems') : t('relations.placeholder')}
      </Typography>
    </Box>
  );

  return (
    <CollapsibleDialogSection
      iconNode={
        <RelationshipIcon fontSize="small" sx={{ marginRight: 0, color: 'text.disabled' }} />
      }
      title={t('relations.relationships')}
    >
      {!!relations?.ok ? (
        <>
          {hasRelations ? (
            <Stack spacing={1}>
              {relations.data.map(r => {
                const otherId = r.from === graphId ? r.to : r.from;
                return (
                  <CanEditNodeWrapper
                    key={`node-relations-${otherId}`}
                    graphID={otherId}
                    render={canEditChild => (
                      <GraphElementChip
                        key={`node-relations-${otherId}`}
                        graphId={otherId}
                        color="white"
                        raised
                        allowNavigate
                        canEdit={canEditChild || canEdit}
                        secondaryActions={
                          canEditChild || canEdit
                            ? [
                                {
                                  name: 'remove-relation',
                                  label: t('general.unlink'),
                                  Icon: HighlightOffIcon,
                                  onClick: e => removeRelation(e, r),
                                },
                              ]
                            : undefined
                        }
                      />
                    )}
                  />
                );
              })}
            </Stack>
          ) : (
            infoSection
          )}
          {cta}
        </>
      ) : (
        infoSection
      )}
      {canEdit && (
        <NodeSelector
          ctaNode={
            <Button
              variant="text"
              color="secondary"
              sx={{
                justifyContent: 'start',
                alignSelf: 'flex-start',
                mt: 1,
              }}
              ref={menuRef}
            >
              {t('relations.add')}
            </Button>
          }
          onSelect={onSelectHandler}
          placeHolderOptions={menuOptions}
        />
      )}
      {addingLink && (
        <AddLinkModal
          sourceNode={graphId}
          modalAnchorElement={menuRef?.current}
          onClose={() => setAddingLink(false)}
        />
      )}
    </CollapsibleDialogSection>
  );
}

NodeRelations.propTypes = {
  graphId: PropTypes.string,
  cta: PropTypes.node,
  canEdit: PropTypes.bool,
  onSelect: PropTypes.func,
  addNewContext: PropTypes.array,
};

export default NodeRelations;
