import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { nanoid } from 'nanoid';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import OptionsIcon from '@mui/icons-material/MoreVert';
import ResponsiveDialog from 'Components/Common/ResponsiveDialog';
import SavingIndicator from 'Components/Common/SavingIndicatorv2';

import InteractableCard from 'Components/Library/InteractableCard';
import { contextActions } from 'state/ducks/context';

import { settingsSelectors } from 'state/ducks/usersettings';
import { types } from './CardComponents';

const styles = theme => ({
  root: {
    margin: 0,
    padding: 0,
    position: 'relative',
  },
  dialogcontent: {
    margin: 0,
    padding: 0,
    position: 'relative',
    flexGrow: 10,
    overflowY: 'auto',
  },
  optionsIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: theme.palette.background.default,
  },
  dark: {
    backgroundColor: theme.palette.primary.main,
    '& $optionsIcon': {
      color: '#fff',
      backgroundColor: 'rgba(0,0,0,0.02)',
    },
  },
  actionbar: {
    display: 'none',
    flexGrow: 0,
    flexShrink: 0,
  },
  dialogpaper: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '100%',
      margin: 0,
      padding: 0,
      maxHeight: '100%',
      maxWidth: '100%',
      borderRadius: 0,
      '& $actionbar': {
        display: 'block',
      },
    },
  },
  savingIndicator: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
  },
});

class ContextCard extends Component {
  state = {
    optionsOpen: null,
    openDelete: false,
    requestID: '',
    submitted: false,
    submitStatus: 0,
    errorText: '',
  };

  static getDerivedStateFromProps(nextProps, state) {
    if (state.submitted === true) {
      if (state.requestID in nextProps.actionlog) {
        if (nextProps.actionlog[state.requestID].result === 'ok') {
          return {
            submitStatus: 0,
            submitted: false,
            errorText: '',
          };
        }
        return {
          submitStatus: -1,
          submitted: false,
          errorText: nextProps.actionlog[state.requestID].message,
        };
      }
    }
    return null;
  }

  openDelete = () => {
    this.setState({
      openDelete: true,
    });
  };

  closeDelete = () => {
    this.setState({
      openDelete: false,
      optionsOpen: null,
      requestID: '',
      submitted: false,
      submitStatus: 0,
      errorText: '',
    });
  };

  onClick = () => {
    const { disabled, onExpand, card } = this.props;
    if (!!!disabled) {
      onExpand(card.id);
    }
  };

  openOptions = e => {
    this.setState({ optionsOpen: e.currentTarget });
    e.preventDefault();
    e.stopPropagation();
  };

  closeOptions = () => {
    this.setState({ optionsOpen: null });
  };

  toggleEdit = () => {
    this.closeOptions();
    this.props.toggleEdit();
  };

  requestDelete = () => {
    this.closeOptions();
    const requestID = nanoid(10);
    this.setState({ submitted: true, submitStatus: 0, requestID });
    this.props.dispatch(
      contextActions.deleteContextCard({
        id: this.props.id,
        requestID,
        contextType: this.props.contextType,
        contextID: this.props.contextID,
      }),
    );
  };

  render() {
    const {
      classes,
      className,
      card,
      disabled,
      mode = 'minified',
      canEdit = true,
      t,
      darkmode,
      contextID,
    } = this.props;
    const { optionsOpen, openDelete, submitted, submitStatus, errorText } = this.state;
    const { cardtheme } = card;
    const appliedTheme = darkmode ? 'light' : cardtheme;
    return (
      <InteractableCard name="context-card">
        <div
          onClick={this.onClick}
          className={clsx([classes.root, classes[appliedTheme], className, 'context-card'])}
        >
          {card.elements.map((element, index) => {
            if (!!!element[`hide_${mode}`]) {
              const Element = types[element.type];
              return (
                <Element
                  mode={mode}
                  key={`context-card-el-${card.id}-${element.id}`}
                  id={`context-card-el-${card.id}-${element.id}`}
                  cardId={card.id}
                  isLast={index + 1 === card.elements.length}
                  item={element}
                  cardTheme={appliedTheme}
                  contextID={contextID}
                />
              );
            }
            return null;
          })}
        </div>
        {!disabled && canEdit && (
          <IconButton
            className={classes.optionsIcon}
            onClick={this.openOptions}
            name="context-card-toggle-options-button"
            id={`context-card-toggle-options-button-${card.id}`}
            aria-label={t('contextv2.cards.ariaLabelForCardOptions')}
            size="large"
          >
            <OptionsIcon />
          </IconButton>
        )}
        {Boolean(optionsOpen) && (
          <Menu
            data-tg-name="context-card-options-menu"
            anchorEl={optionsOpen}
            open={Boolean(optionsOpen)}
            onClose={this.closeOptions}
          >
            <MenuItem name="context-card-options-edit" onClick={this.toggleEdit}>
              {t('general.edit')}
            </MenuItem>
            <MenuItem name="context-card-options-remove" onClick={() => this.openDelete()}>
              {t('general.delete')}
            </MenuItem>
          </Menu>
        )}
        {openDelete && (
          <ResponsiveDialog
            title={t('general.confirmDeleteTitle')}
            open={openDelete}
            name="dialog-confirm-delete-context-card"
            closeOnBackDropClick
            secondaryAction={{
              label: t('general.cancel'),
              onClick: this.closeDelete,
              disabled: !openDelete,
            }}
            primaryAction={{
              label: t('general.confirmDelete'),
              onClick: this.requestDelete,
              disabled: !openDelete,
            }}
          >
            <SavingIndicator
              submitted={submitted}
              submitStatus={submitStatus}
              errorText={errorText}
              className={classes.savingIndicator}
            />
            {t('contextv2.cards.confirmDeleteText')}
          </ResponsiveDialog>
        )}
      </InteractableCard>
    );
  }
}

const mapStateToProps = state => ({
  actionlog: state.main.context.actionlog,
  darkmode: settingsSelectors.selectAttribute(state.usersettings, 'darkmode'),
});

ContextCard.propTypes = {
  actionlog: PropTypes.object,
  disabled: PropTypes.bool,
  onExpand: PropTypes.func,
  card: PropTypes.object,
  toggleEdit: PropTypes.func,
  mode: PropTypes.string,
  canEdit: PropTypes.bool,
  classes: PropTypes.exact({
    root: PropTypes.string,
    dialogcontent: PropTypes.string,
    optionsIcon: PropTypes.string,
    savingIndicator: PropTypes.string,
    dark: PropTypes.string,
    actionbar: PropTypes.string,
    dialogpaper: PropTypes.string,
  }),
  t: PropTypes.func,
  dispatch: PropTypes.func,
  contextType: PropTypes.string,
  contextID: PropTypes.string,
  id: PropTypes.string,
  darkmode: PropTypes.bool,
};

export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(ContextCard)));
