import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { nanoid } from 'nanoid';
import { peopleActions, peopleSelectors } from 'state/ducks/people';

import OrganizationSearch from './OrganizationSearch';

export class OrganizationSearchWrapper extends Component {
  state = {
    requestID: '',
    searchString: '',
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.actionlog && prevState.requestID && prevState.requestID in nextProps.actionlog) {
      return { requestID: null };
    }
    return null;
  }

  getPeople(searchString) {
    const requestID = nanoid(10);
    this.setState({ searchString, requestID });
    if (searchString.length > 1) {
      this.props.dispatch(peopleActions.searchPeople({ searchstring: searchString, requestID }));
    }
  }

  clearSearch = () => {
    this.setState({ searchString: '' });
  };

  handleChange = (event, { newValue }) => {
    this.setState({
      searchString: newValue,
    });
  };

  getSuggestions = () => {
    const { selectTeamsManagedBy, hideUsers } = this.props;
    const people = { title: 'People', suggestions: [] };
    const teams = { title: 'Teams', suggestions: [] };

    const results = [];

    const { searchString } = this.state;
    if (searchString.length > 1) {
      for (const sub in this.props.people.subs) {
        if (Object.prototype.hasOwnProperty.call(this.props.people.subs, sub)) {
          if (
            this.props.people.subs[sub].fullName &&
            this.props.people.subs[sub].fullName.toLowerCase().includes(searchString.toLowerCase())
          ) {
            const person = JSON.parse(JSON.stringify(this.props.people.subs[sub])); // Clone object
            person.type = 'person';
            people.suggestions.push(person);
          }
        }
      }
    }

    if (!hideUsers && people.suggestions.length > 0) {
      results.push(people);
    }
    if (searchString.length > 1) {
      for (const teamId in this.props.teamNames) {
        const teamName = this.props.teamNames[teamId];
        if (teamName.toLowerCase().includes(searchString.toLocaleLowerCase())) {
          teams.suggestions.push({
            teamName,
            teamId,
            type: 'team',
          });
        }
      }
    }

    for (const user of people.suggestions) {
      const usersTeams = selectTeamsManagedBy(user.sub);
      if (!!usersTeams && usersTeams.length > 0) {
        for (const managedTeamId of usersTeams) {
          // ENG-1266 Add teams managed by user matching search string to results
          // if said team is not already in the search results
          // this could happen if the search string matches both the team name
          // and manager name
          if (teams.suggestions.filter(t => t.teamId === managedTeamId).length === 0) {
            const teamName = this.props.teamNames[managedTeamId];
            teams.suggestions.push({
              teamName,
              teamId: managedTeamId,
              type: 'team',
            });
          }
        }
      }
    }

    if (teams.suggestions.length > 0) {
      results.push(teams);
    }
    return results;
  };

  render() {
    const suggestions = this.getSuggestions();
    return (
      <OrganizationSearch
        underlineClass={this.props.underlineClass}
        label={this.props.label}
        placeholder={this.props.placeholder}
        mobileLabel={this.props.mobileLabel}
        error={this.props.error}
        inputClass={this.props.inputClass}
        inputVariant={this.props.inputVariant}
        containerClass={this.props.containerClass}
        mobileClass={this.props.mobileClass}
        focusedClass={this.props.focusedClass}
        suggestions={suggestions}
        onSelect={sub => this.props.onSelect(sub)}
        onClearRequested={() => this.clearSearch()}
        onFetchRequested={searchstring => this.getPeople(searchstring)}
        fetching={!!this.state.requestID && this.state.searchString.length > 1}
        className={this.props.className}
        hideUsers={this.props.hideUsers}
        id={this.props.id}
        value={this.state.searchString}
        handleChange={this.handleChange}
      />
    );
  }
}

OrganizationSearchWrapper.propTypes = {
  actionlog: PropTypes.object,
  dispatch: PropTypes.func,
  people: PropTypes.object, // TODO: refactor this to use selectors..
  teamNames: PropTypes.object,
  selectTeamsManagedBy: PropTypes.func,
  hideUsers: PropTypes.bool,
  underlineClass: PropTypes.string,
  mobileClass: PropTypes.string,
  label: PropTypes.object,
  mobileLabel: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.func,
  inputClass: PropTypes.string,
  inputVariant: PropTypes.string,
  containerClass: PropTypes.string,
  focusedClass: PropTypes.string,
  onSelect: PropTypes.func,
  id: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  actionlog: state.main.people.actionlog,
  people: state.main.people,
  teamNames: state.main.people.teamNames,
  selectTeamsManagedBy: userId => peopleSelectors.selectTeamsManagedBy(state.main.people, userId),
});

export default connect(mapStateToProps)(OrganizationSearchWrapper);
