import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import withStyles from '@mui/styles/withStyles';
import Fab from 'Components/Common/Fab';
import { withLocation, withNavigation } from 'withRouter';
import MediaQueryWrapper from 'Components/Common/MediaQueryWrapper';
import { gameplansSelectors } from 'state/ducks/gameplans';
import { connectionSelectors } from 'state/ducks/connection';

import AddGamePlanMenu from './AddGamePlanMenu';
import { isEnabled } from 'config/flags';
import { appstatusSelectors } from 'state/ducks/appstatus';
import { objectivesSelectors } from 'state/ducks/objectives';

const styles = theme => ({
  fabIcon: {
    color: theme.palette.secondary.contrastText,
  },
});

class AddGamePlanButton extends PureComponent {
  state = { anchorEl: null };

  handleOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const {
      classes,
      isOnline,
      wideScreen,
      gameplans,
      usePeriodicGameplans,
      ltperiod,
      stperiod,
      teamId,
      t,
    } = this.props;
    const { anchorEl } = this.state;
    if (!isOnline || !gameplans.ok) {
      return null;
    }

    // The backend supports having more than one game plan for a team,
    // this will not initially be supported by the frontend for the MVP:

    const gameplansPerPeriod = {};
    let gameplan;
    if (usePeriodicGameplans) {
      for (const gameplan of gameplans.data) {
        if (gameplan.stperiod) {
          gameplansPerPeriod[`${gameplan.ltperiod}-${gameplan.stperiod}`] = gameplan;
        }
      }
      gameplan = gameplansPerPeriod[stperiod];
    } else {
      gameplan = get(gameplans, ['data', 0], {});
    }

    const gameplanId = gameplan?.id;

    const visibility = get(gameplan, ['widget_visibility'], {});

    if (!wideScreen) {
      return (
        <div>
          <Tooltip
            PopperProps={{ 'data-tg-name': 'ctx-fab-mobile-tooltip' }}
            title={t('contextv2.unsupportedmobileediterror')}
            aria-label={t('contextv2.unsupportedmobileediterror')}
            enterTouchDelay={20}
          >
            <div>
              <Fab name="add-gameplan-fab" color="default" aria-label={t('contextv2.addItem')}>
                <AddIcon className={classes.fabIcon} />
              </Fab>
            </div>
          </Tooltip>
        </div>
      );
    }
    return (
      <div>
        <Fab name="add-gameplan-fab" onClick={this.handleOpen} aria-label={t('contextv2.addItem')}>
          <AddIcon className={classes.fabIcon} />
        </Fab>
        {Boolean(anchorEl) && (
          <AddGamePlanMenu
            anchorEl={anchorEl}
            onClose={this.handleClose}
            stperiod={stperiod}
            ltperiod={ltperiod}
            usePeriodicGameplans={usePeriodicGameplans}
            gameplanId={gameplanId}
            visibility={visibility}
            teamId={teamId}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const gameplans = gameplansSelectors.selectGamePlans(state.main.gameplans, ownProps.teamId);
  const appstatus = appstatusSelectors.selectStatus(state.main.appstatus);
  const usePeriodicGameplans = isEnabled('PERIODIC-GAMEPLANS', appstatus);
  const ltperiod = objectivesSelectors.selectLtPeriodForStPeriod(
    state.main.objectives,
    ownProps.selectedPeriod,
  );

  return {
    isOnline: connectionSelectors.selectOnlineStatus(state.main.connection),
    stperiod: ownProps.selectedPeriod,
    ltperiod,
    usePeriodicGameplans,
    gameplans,
  };
};

AddGamePlanButton.propTypes = {
  classes: PropTypes.exact({
    fabIcon: PropTypes.string,
  }),
  isOnline: PropTypes.bool,
  wideScreen: PropTypes.bool,
  selectedPeriod: PropTypes.string,
  gameplans: PropTypes.object,
  // Used in mapStateToProps
  // eslint-disable-next-line react/no-unused-prop-types
  teamId: PropTypes.string,
  t: PropTypes.func,
  stperiod: PropTypes.string,
  ltperiod: PropTypes.string,
  usePeriodicGameplans: PropTypes.bool,
};

export default withLocation(
  withNavigation(
    withTranslation()(
      withStyles(styles)(MediaQueryWrapper()(connect(mapStateToProps)(AddGamePlanButton))),
    ),
  ),
);
