import { get } from 'lodash';
import * as apiConstants from 'state/constants/api';

const recursivelyFilterMentions = doc => {
  if (doc.type === 'mentionAtom') {
    doc.attrs = {
      id: doc.attrs.id,
    };
  }
  get(doc, 'content', []).forEach(childDoc => recursivelyFilterMentions(childDoc));
};

export const filterMentions = doc => {
  if (doc === undefined) {
    return doc;
  }
  const copy = JSON.parse(JSON.stringify(doc));
  recursivelyFilterMentions(copy);
  return copy;
};

const recursivelyParseMentions = (doc, selectUser, missingUsers) => {
  if (doc && doc.type === 'mentionAtom') {
    const userData = selectUser(doc.attrs.id);
    if ([apiConstants.OK, apiConstants.DOES_NOT_EXIST].includes(userData.fetchStatus)) {
      doc.attrs = {
        id: doc.attrs.id,
        name: userData.fullName,
        label: userData.fullName,
      };
    } else {
      missingUsers.add(doc.attrs.id);
      doc.attrs = {
        id: doc.attrs.id,
        name: '...',
        label: '...',
      };
    }
  }
  get(doc, 'content', []).forEach(childDoc =>
    recursivelyParseMentions(childDoc, selectUser, missingUsers),
  );
};

export const handleMentions = (doc, selectUser) => {
  if (!doc) {
    return [doc, []];
  }
  const copy = JSON.parse(JSON.stringify(doc));
  const missingUsers = new Set();
  recursivelyParseMentions(copy, selectUser, missingUsers);
  return [copy, [...missingUsers]];
};
