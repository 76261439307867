import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';

import EditableTypography from 'Components/Common/EditableTypography';
import { CTX_ELEMENT_MAX_STR_LENGTH } from 'config/constants';

export const validate = item => !!item.text;

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(),
  },
  text: {
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
    display: 'inline-block !important',
  },
  padBottom: {
    paddingBottom: theme.spacing(2),
  },
});

class CardOverLine extends Component {
  render() {
    const {
      classes,
      className,
      item,
      onEdit,
      cardTheme,
      editable = false,
      isLast = false,
      key,
      id,
      autoFocus = false,
    } = this.props;
    return (
      <div
        className={clsx([classes.root, className, isLast ? classes.padBottom : null])}
        data-tg-name="ctx-card-el-overline"
        key={key}
        id={id}
      >
        <EditableTypography
          maxLength={CTX_ELEMENT_MAX_STR_LENGTH}
          variant="overline"
          placeholder="overline"
          name="ctx-card-editor-inline-editable-overline"
          autoFocus={autoFocus}
          className={classes.text}
          editable={editable}
          value={item.text}
          theme={cardTheme}
          onChange={text => onEdit({ text })}
        />
      </div>
    );
  }
}

CardOverLine.propTypes = {
  classes: PropTypes.exact({
    root: PropTypes.string,
    text: PropTypes.string,
    padBottom: PropTypes.string,
  }),
  item: PropTypes.object,
  onEdit: PropTypes.func,
  cardTheme: PropTypes.string,
  editable: PropTypes.bool,
  isLast: PropTypes.bool,
  key: PropTypes.string,
  id: PropTypes.string,
  autoFocus: PropTypes.bool,
};

export default withStyles(styles)(CardOverLine);
