import React from 'react';
import { getKeyResultCompletionPct } from 'config/helpers';
import { getKeyResultConfidenceString } from 'state/ducks/objectives/helpers';
import { Box, useTheme, SxProps } from '@mui/material';
import { IKeyresultData } from 'state/ducks/objectives/types';
import { get } from 'lodash';

export interface KeyresultStatusChipProps {
  keyresultData: IKeyresultData;
  sx?: SxProps;
  size?: 'medium' | 'large';
}

function KeyresultStatusChip(props: KeyresultStatusChipProps) {
  const { keyresultData, sx, size = 'medium' } = props;
  const confidence = getKeyResultConfidenceString(keyresultData);
  const theme = useTheme();

  const confidenceColor = get(theme, ['palette', 'confidence', confidence], 'grey');
  const font = size === 'large' ? theme.typography.subtitle1 : theme.typography.body2;
  return (
    <Box
      sx={{
        height: size === 'large' ? 28 : 24,
        pl: 1,
        pr: 1,
        textAlign: 'center',
        borderRadius: '8px',
        color: 'white',
        fontSize: font.fontSize,
        letterSpacing: font.letterSpacing,
        fontWeight: font.fontWeight,
        fontFamily: font.fontFamily,
        lineHeight: size === 'large' ? '28px' : '24px',
        backgroundColor: confidenceColor,
        width: 'min-content',
        ...sx,
      }}
      data-tg-name={`kr-status-${confidence}`}
    >
      {`${getKeyResultCompletionPct(keyresultData)}%`}
    </Box>
  );
}

export default KeyresultStatusChip;
