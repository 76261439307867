import React from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { withTranslation } from 'react-i18next';

class DialogDownloadSurveyResults extends React.Component {
  state = {
    data: null,
  };

  componentDidMount() {
    this.props.processData().then(data => {
      this.setState({ data }, () => {});
    });
  }

  render() {
    const { onClose, t } = this.props;
    return (
      <Dialog open onClose={onClose}>
        <DialogTitle>{t('insights.downloaddialogtitle')}</DialogTitle>
        <DialogActions>
          {!!this.state.data && (
            <CSVLink data={this.state.data} filename="TG-survey.csv" target="_blank">
              <Button name="download-as-csv" color="secondary">
                {t('general.download')}
              </Button>
            </CSVLink>
          )}
          <Button name="download-close" onClick={onClose} color="primary">
            {t('general.close')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

DialogDownloadSurveyResults.propTypes = {
  onClose: PropTypes.func,
  processData: PropTypes.func,
  t: PropTypes.func,
};

export default withTranslation()(DialogDownloadSurveyResults);
