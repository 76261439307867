import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';

const styles = () => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  legendBox: {
    width: 12,
    height: 12,
    borderRadius: 6,
    marginRight: 8,
    flexShrink: 0,
    flexGrow: 0,
  },
});

class Legend extends Component {
  render() {
    const { keys, classes, theme, className } = this.props;
    return (
      <div className={className}>
        {Object.keys(keys).map((key, index) => (
          <div className={classes.row} key={`survey-chart-legend-${index}`}>
            <div
              className={classes.legendBox}
              style={{ backgroundColor: theme.palette.visualizations.pie[index] }}
            />
            <Typography variant="caption">{keys[key]}</Typography>
          </div>
        ))}
      </div>
    );
  }
}

Legend.propTypes = {
  classes: PropTypes.exact({
    row: PropTypes.string,
    legendBox: PropTypes.string,
  }),
  keys: PropTypes.object,
  theme: PropTypes.object,
  className: PropTypes.string,
};

export default withStyles(styles, { withTheme: true })(Legend);
