export function findBestCopyableGameplan(
  periodConfig,
  selectedPeriod,
  gameplans,
  checkFuture = true,
) {
  if (!periodConfig || !periodConfig.ok || !selectedPeriod || !gameplans) {
    return null;
  }
  const sortedStPeriodIds = periodConfig.data.stperiods;
  for (let i = sortedStPeriodIds.indexOf(selectedPeriod); i >= 0; i--) {
    if (selectedPeriod !== sortedStPeriodIds[i] && gameplans[sortedStPeriodIds[i]]) {
      return sortedStPeriodIds[i];
    }
  }
  if (checkFuture) {
    for (let i = sortedStPeriodIds.indexOf(selectedPeriod); i < sortedStPeriodIds.length; i++) {
      if (selectedPeriod !== sortedStPeriodIds[i] && gameplans[sortedStPeriodIds[i]]) {
        return sortedStPeriodIds[i];
      }
    }
  }
  return null;
}
