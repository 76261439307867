import MenuOption from '../MenuOption';
import AiIcon from '../Icons/AiIcon';
import { Divider } from '@mui/material';
import AiGameplanSuggestionsFlow from './index';
import { useState } from 'react';

interface AiMenuOptionProps {
  teamId: string;
  stperiod: string;
  ltperiod: string;
  gameplanId?: string;
  onSuccess(): void;
}

function AiMenuOption(props: AiMenuOptionProps) {
  const { teamId, stperiod, ltperiod, gameplanId, onSuccess } = props;
  const [flowOpen, setFlowOpen] = useState(false);
  return (
    <>
      <Divider />
      <MenuOption
        title="Ask AI"
        Icon={AiIcon}
        description="Use artificial intelligence to suggest a Game Plan"
        name="gameplans-ask-ai"
        onClick={() => setFlowOpen(true)}
      />
      {flowOpen && (
        <AiGameplanSuggestionsFlow
          teamId={teamId}
          stperiod={stperiod}
          ltperiod={ltperiod}
          gameplanId={gameplanId}
          onSuccess={onSuccess}
          onClose={() => setFlowOpen(false)}
        />
      )}
    </>
  );
}

export default AiMenuOption;
