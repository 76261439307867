import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, orderBy } from 'lodash';
import memoizeOne from 'memoize-one';
import { withLocation, withNavigation } from 'withRouter';
import { insightsSelectors, insightsActions } from 'state/ducks/insights';
import { peopleSelectors } from 'state/ducks/people';
import { openCreateSurveyPanel, openTopicViewSurveyPanel } from 'config/ModalProvider/helpers';
import { connectionSelectors } from 'state/ducks/connection';
import PanelViewSurveyResults from './PanelViewSurveyResults';
import { getTeamData } from './helpers';

const areArgsEqual = (newArgsArr, lastArgsArr) => {
  const newArgs = newArgsArr[0];
  const lastArgs = lastArgsArr[0];
  // Start with the cheapest comparisons
  if (!!newArgs && !lastArgs) {
    return false;
  }
  if (newArgs.teamID !== lastArgs.teamID) {
    return false;
  }
  if (newArgs.doNotCascade !== lastArgs.doNotCascade) {
    return false;
  }
  if (newArgs.topicID !== lastArgs.topicID) {
    return false;
  }
  if (newArgs.surveys.length !== lastArgs.surveys.length) {
    return false;
  }
  if (!newArgs.isPulse && get(newArgs, 'surveyStats.ok') !== get(lastArgs, 'surveyStats.ok')) {
    return false;
  }
  // stats will only be present for fetched surveys,
  // it's enough to compare the length
  if (
    (!lastArgs.stats && !!newArgs.stats) ||
    (!!newArgs.stats &&
      !!lastArgs.stats &&
      Object.keys(newArgs.stats).length !== Object.keys(lastArgs.stats).length)
  ) {
    return false;
  }
  return true;
};

const filterAndComputeValues = args => {
  const values = [];
  for (const survey of args.surveys) {
    if (survey.surveyID in args.stats) {
      if (args.stats[survey.surveyID].orgTree) {
        const data = getTeamData(
          survey,
          args.stats[survey.surveyID].orgTree,
          !args.doNotCascade,
          args.teamID,
          args?.stats[survey.surveyID]?.schema_version,
        );
        values.push({
          timestamp: survey.timestamp,
          surveyID: survey.surveyID,
          outcome: data.outcome,
          comments: data.comments,
          numReceived: data.numReceived,
          numSent: data.numSent,
          answerPct: data.answerPct,
          scores: data.scores,
        });
      }
    }
  }
  return orderBy(values, ['timestamp'], 'asc');
};

class PanelViewSurveyResultsContainer extends React.Component {
  memoizedCompute = memoizeOne(filterAndComputeValues, areArgsEqual);

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate() {
    this.fetchData();
  }

  selectTeam = (teamID, doNotCascade) => {
    openTopicViewSurveyPanel(
      this.props.surveyID,
      true,
      teamID,
      doNotCascade,
      this.props.navigate,
      this.props.location,
    );
  };

  openCreateSurveyPanel = () => {
    openCreateSurveyPanel(
      this.props.survey.data.topicID,
      true,
      this.props.navigate,
      this.props.location,
    );
  };

  onClose = () => {
    this.props.onClose();
  };

  fetchData() {
    this.props.dispatch(insightsActions.getSingleSurvey({ surveyID: this.props.surveyID }));
    // fetch the topic & latest survey if the user is a change manager to figure out whether we
    // should enable asking again
    if (this.props.survey && this.props.survey.ok) {
      this.props.dispatch(
        insightsActions.getSingleTopic({ topicID: this.props.survey.data.topicID }),
      );
    }
    if (this.props.isChangeManager && this.props.topic && this.props.topic.ok) {
      this.props.dispatch(
        insightsActions.getSingleSurvey({ surveyID: this.props.topic.data.curSurveyID }),
      );
    }

    // Is this a pulse?
    if (this.props.isPulse) {
      this.props.dispatch(
        insightsActions.getTopicSurveys({ topicID: this.props.topic.data.topicID }),
      );

      if (!!this.props.surveyIDs && this.props.surveyIDs.ok) {
        for (const survey of this.props.surveys) {
          if (survey.status !== 'started') {
            this.props.dispatch(insightsActions.getSurveyStats({ surveyID: survey.surveyID }));
          }
        }
      }
    }
    if (this.props.survey && this.props.survey.ok && this.props.survey.data.status === 'parsed') {
      this.props.dispatch(
        insightsActions.getSurveyStats({ surveyID: this.props.survey.data.surveyID }),
      );
    }
  }

  render() {
    const {
      doNotCascade,
      teamID,
      pulsesFetched,
      surveys,
      topicID,
      stats,
      isPulse,
      surveyStats,
      zIndexModifier,
    } = this.props;
    if (!this.props.survey) {
      return null;
    }
    const values = this.memoizedCompute({
      surveys,
      teamID,
      topicID,
      doNotCascade,
      stats,
      isPulse,
      surveyStats,
    });

    const lastPulseIsFinished =
      !!this.props.topicLatestSurvey &&
      this.props.topicLatestSurvey.ok &&
      this.props.topicLatestSurvey.data.status === 'parsed';

    const canTriggerPulse =
      lastPulseIsFinished &&
      ((this.props.survey.data.audienceType === 'PRG' && this.props.isChangeManager) ||
        (this.props.survey.data.audienceType === 'TEAM' &&
          (this.props.isManager || this.props.isChangeManager)));

    return (
      <PanelViewSurveyResults
        open={this.props.open}
        onClose={this.onClose}
        surveyID={this.props.surveyID}
        teamID={teamID}
        doNotCascade={doNotCascade}
        survey={this.props.survey.data}
        surveys={this.props.surveys}
        surveyStats={surveyStats}
        canTriggerPulse={canTriggerPulse}
        topic={this.props.topic}
        isPulse={isPulse}
        openCreateSurveyPanel={this.openCreateSurveyPanel}
        selectTeam={this.selectTeam}
        isOnline={this.props.isOnline}
        stats={this.props.stats}
        pulsesFetched={pulsesFetched}
        values={values}
        zIndexModifier={zIndexModifier}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const survey = insightsSelectors.selectSurvey(state.main.insights, ownProps.surveyID);
  let topic;
  let latestSurvey;
  let surveyStats;
  let isManager = false;
  let isPulse = false;
  const surveys = [];
  const stats = {};
  const topicID = get(survey, 'data.topicID');
  if (survey.ok) {
    topic = insightsSelectors.selectTopic(state.main.insights, topicID);
    if (topic.ok) {
      latestSurvey = insightsSelectors.selectSurvey(state.main.insights, topic.data.curSurveyID);
      isPulse = topic.data.surveyCount > 1;
    }
    surveyStats = insightsSelectors.selectSurveyStats(state.main.insights, survey.data.surveyID);
    if (survey.data.audienceType === 'TEAM') {
      isManager =
        state.auth.userID ===
          peopleSelectors.selectTeamManager(state.main.people, survey.data.audienceID) ||
        peopleSelectors
          .selectManagedTeams(state.main.people, state.auth.myTeams, state.auth.userID)
          .includes(survey.data.audienceID);
    }
  }
  let surveyIDs;
  let pulsesFetched = true;
  if (isPulse) {
    surveyIDs = insightsSelectors.selectSurveysForTopic(state.main.insights, topicID);
    if (!!surveyIDs && !!surveyIDs.data) {
      for (const surveyID of surveyIDs.data) {
        const pastSurvey = insightsSelectors.selectSurvey(state.main.insights, surveyID);
        if (pastSurvey.ok) {
          surveys.push(pastSurvey.data);

          if (pastSurvey.data.status !== 'started') {
            const pastSurveyStats = insightsSelectors.selectSurveyStats(
              state.main.insights,
              surveyID,
            );
            if (pastSurveyStats.ok) {
              stats[surveyID] = pastSurveyStats.data;
            } else {
              pulsesFetched = false;
            }
          }
        } else {
          pulsesFetched = false;
        }
      }
    }
  } else if (survey.ok) {
    /* Handle non-pulse */
    surveys.push(survey.data);
    if (surveyStats.ok) {
      stats[ownProps.surveyID] = surveyStats.data;
    }
  }
  return {
    isChangeManager: state.auth.isChangeManager,
    survey,
    surveyStats,
    isManager,
    isPulse,
    topic,
    topicLatestSurvey: latestSurvey,
    isOnline: connectionSelectors.selectOnlineStatus(state.main.connection),
    surveyIDs,
    surveys,
    topicID,
    stats,
    pulsesFetched,
  };
};

PanelViewSurveyResultsContainer.propTypes = {
  topicLatestSurvey: PropTypes.object,
  isOnline: PropTypes.bool,
  location: PropTypes.object,
  navigate: PropTypes.func,
  survey: PropTypes.object,
  surveyID: PropTypes.string,
  dispatch: PropTypes.func,
  isChangeManager: PropTypes.bool,
  isManager: PropTypes.bool,
  topic: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  teamID: PropTypes.string,
  doNotCascade: PropTypes.bool,
  surveyStats: PropTypes.object,
  pulsesFetched: PropTypes.bool,
  isPulse: PropTypes.bool,
  stats: PropTypes.object,
  surveys: PropTypes.array,
  topicID: PropTypes.string,
  surveyIDs: PropTypes.object,
  zIndexModifier: PropTypes.number,
};

export default withLocation(
  withNavigation(connect(mapStateToProps)(PanelViewSurveyResultsContainer)),
);
