import { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gameplansActions, gameplansSelectors } from 'state/ducks/gameplans';

function useGameplanData(domainId) {
  const gameplans = useSelector(state =>
    gameplansSelectors.selectGamePlans(state.main.gameplans, domainId),
  );

  const dispatch = useDispatch();

  const mounted = useRef();
  const domainRef = useRef();

  useEffect(() => {
    /* 
       Dispatch actions to fetch the data.
       Fetch with force: true
        - on initial mount to allow users to refresh the data with F5
        - if the period prop changes

       TODO: handle large domain arrays of several dozen domains, where dispatching all
       actions in one go is too much    
    */
    const domainChanged = domainRef.current && domainRef.current !== domainId;

    const force = !Boolean(mounted.current) || domainChanged;

    if (domainId) {
      dispatch(gameplansActions.fetchGamePlans({ domain_id: domainId, force }));
    }
    if (force) {
      mounted.current = true;
    }
    domainRef.current = domainId;
  });

  return gameplans;
}

export default useGameplanData;
