import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';
import clsx from 'clsx';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { withLocation, withNavigation } from 'withRouter';
import OrganizationSearch from 'Components/Common/OrganizationSearch';

import ObjectiveChip from 'Components/Features/Objectives/ObjectiveChip';
import { objectivesSelectors, objectivesActions } from 'state/ducks/objectives';

import { openViewObjectivePanel } from 'config/ModalProvider/helpers';
import * as apiconstants from 'state/constants/api';

import ObjectivesList from './ObjectivesList';

export const validate = item => !!item.objectiveID;

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  deleted: {
    padding: theme.spacing(3),
  },
  searchFieldPaper: {
    flexGrow: 0.2,
    height: 64,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      flexGrow: 0,
      height: 48,
      width: 48,
    },
    position: 'relative',
    overflow: 'visible',
  },
  searchFieldInput: {
    border: '0px !important',
    '& :hover': {},
  },
  searchFieldResultsContainer: {
    backgroundColor: theme.palette.background.paper,
    ...theme.shape,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    boxShadow: theme.shadows[4],
    zIndex: 10,
  },
  dialogpaper: {
    overflow: 'hidden',
    height: '80%',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
  },
  dialogroot: {
    overflow: 'visible',
    width: '100%',
    height: '100%',
    margin: 0,
  },
  dialogcontent: {
    overflow: 'auto',
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'stretch',
  },
  dialogActions: {
    flexGrow: 0,
    flexShrink: 0,
  },
  content: {
    flexGrow: 5,
    overflowY: 'scroll',
    overflowX: 'hidden',
    backgroundColor: theme.palette.primary[200],
  },
  selectors: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    overflow: 'visible',
    borderBottom: `1px solid ${theme.palette.primary[400]}`,
  },
  row: {
    flexGrow: 2,
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'center',
    marginBottom: theme.spacing(),
    marginRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  dialogTitle: {
    marginBottom: theme.spacing(),
    marginRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  select: {
    marginRight: theme.spacing(2),
    '&:last-child': {
      marginRight: 0,
    },
  },
  okr: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  dark: {
    '& $okr span': {
      color: 'rgba(255,255,255,0.87)',
      textDecoration: 'none',
    },
  },
  actionable: {
    cursor: 'pointer',
  },
});

class CardObjective extends Component {
  state = {
    okrtype: 'CO',
    okrdomain: null,
    period: null,
    selectedObjectiveID: null,
  };

  componentDidMount() {
    this.setState({ selectedObjectiveID: this.props.item.objectiveID });
    this.props.dispatch(objectivesActions.getCompanyObjectives({ stperiod: this.state.period }));
  }

  static getDerivedStateFromProps(props, state) {
    if (
      !state.period &&
      !!props.periodcfg &&
      !!props.periodcfg.ok &&
      !!props.periodcfg.data &&
      !!props.periodcfg.data.activeperiod
    ) {
      const activeperiodProp = get(props, 'periodcfg.data.activeperiod.stperiod');
      if (!!activeperiodProp) {
        return { period: activeperiodProp };
      }
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.okrtype !== this.state.okrtype ||
      prevState.period !== this.state.period ||
      prevState.okrdomain !== this.state.okrdomain
    ) {
      if (!!this.state.period) {
        if (this.state.okrtype === 'CO') {
          this.props.dispatch(
            objectivesActions.getCompanyObjectives({ stperiod: this.state.period }),
          );
        } else if (!!this.state.okrdomain) {
          if (this.state.okrdomain.type === 'personal') {
            this.props.dispatch(
              objectivesActions.getRelatedObjectives({
                stperiod: this.state.period,
                sub: this.state.okrdomain.sub,
              }),
            );
          } else {
            this.props.dispatch(
              objectivesActions.getTeamObjectives({
                stperiod: this.state.period,
                teamId: this.state.okrdomain.teamId,
              }),
            );
          }
        }
      }
    }
    if (!!this.props.item && !!this.props.item.objectiveID && !this.props.objectiveData.ok) {
      this.props.dispatch(
        objectivesActions.getObjective({ objectiveIDs: [this.props.item.objectiveID] }),
      );
    }
  }

  closeDialog = () => {
    const { item, closeSettings, deleteItem } = this.props;
    const { src } = item;
    if (!src) {
      // this item was just being created, let's abort creating it
      deleteItem(true);
    } else {
      closeSettings();
    }
  };

  selectOkrType = okrtype => {
    this.setState({ okrtype, selectedObjectiveID: null });
  };

  selectOkrPeriod = period => {
    this.setState({ period, selectedObjectiveID: null });
  };

  selectOkrDomain = result => {
    const state = {};
    if (result.type === 'person') {
      state.okrdomain = { type: 'personal', sub: result.sub, selectedObjectiveID: null };
    } else {
      state.okrdomain = { type: 'team', teamId: result.teamId, selectedObjectiveID: null };
    }
    this.setState(state);
  };

  selectObjective = selectedObjectiveID => {
    this.setState({ selectedObjectiveID });
  };

  openObjective = () => {
    openViewObjectivePanel(
      this.props.selectBestStPeriodForObjective(this.props.item.objectiveID),
      this.props.item.objectiveID,
      false,
      this.props.navigate,
      this.props.location,
    );
  };

  save = () => {
    this.props.onEdit({
      objectiveID: this.state.selectedObjectiveID,
      __cfg: { settingsOpen: false },
    });
  };

  render() {
    const {
      classes,
      className,
      item,
      mode,
      editable,
      cardTheme,
      periodcfg,
      objectiveData,
      key,
      id,
      t,
    } = this.props;
    const { objectiveID, __cfg = {} } = item;
    const { settingsOpen } = __cfg;
    const { okrtype, period, okrdomain, selectedObjectiveID } = this.state;

    return (
      <div
        className={clsx([
          classes[cardTheme],
          className,
          mode === 'expanded' ? classes.actionable : null,
        ])}
        data-tg-name="ctx-card-el-okr"
        onClick={() => (mode === 'expanded' ? this.openObjective() : null)}
        key={key}
        id={id}
      >
        {!!objectiveID && (
          <div className={classes.okr}>
            {objectiveData.ok && (
              <ObjectiveChip
                objectiveData={objectiveData}
                color="transparent"
                allowNavigate={mode === 'expanded'}
                actionable={mode === 'expanded'}
              />
            )}
            {!objectiveData.ok && objectiveData.fetchStatus === apiconstants.DELETED && (
              <div className={classes.deleted}>
                <Typography variant="caption">{t('contextv2.cards.objectivedeleted')}</Typography>
              </div>
            )}
          </div>
        )}
        {!!editable && !!settingsOpen && (
          <Dialog
            classes={{ root: classes.root, paper: classes.dialogpaper }}
            fullWidth
            maxWidth="md"
            open
            id="ctx-card-editor-okr-select-dialog"
          >
            <DialogContent className={classes.dialogcontent}>
              <div className={classes.dialogTitle}>
                <Typography variant="h6">{t('contextv2.cards.selectobjectivetitle')}</Typography>
              </div>
              <br />
              <div className={classes.selectors}>
                <div className={classes.row}>
                  {!!periodcfg && !!periodcfg.ok && (
                    <FormControl className={classes.select} variant="filled">
                      <InputLabel id="ctx-card-editor-okr-period-selector-label">
                        {t('general.period')}
                      </InputLabel>
                      <Select
                        labelId="ctx-card-editor-okr-period-selector-label"
                        id="ctx-card-editor-okr-period-selector"
                        value={period}
                        onChange={e => this.selectOkrPeriod(e.target.value)}
                      >
                        {periodcfg.data.stperiods.map(stperiod => (
                          <MenuItem
                            key={`ctx-card-editor-okr-period-selector-${stperiod}`}
                            value={stperiod}
                          >
                            {objectivesSelectors.selectStPeriodName(null, periodcfg, stperiod)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  <FormControl className={classes.select} variant="filled">
                    <InputLabel id="ctx-card-editor-okr-type-selector-label">
                      {t('contextv2.cards.selectobjectivetypelabel')}
                    </InputLabel>
                    <Select
                      labelId="ctx-card-editor-okr-type-selector-label"
                      id="ctx-card-editor-okr-type-selector"
                      value={okrtype}
                      onChange={e => this.selectOkrType(e.target.value)}
                    >
                      <MenuItem name="ctx-card-editor-okr-type-selector-company" value="CO">
                        {t('contextv2.cards.selectoptcompany')}
                      </MenuItem>
                      <MenuItem
                        name="ctx-card-editor-okr-type-selector-team-or-person"
                        value="PO/TO"
                      >
                        {t('contextv2.cards.selectoptteampersonal')}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className={classes.row}>
                  {okrtype === 'PO/TO' && (
                    <div className={classes.searchFieldPaper}>
                      <OrganizationSearch
                        label={null}
                        placeholder={t('general.orgSearchLabel')}
                        inputVariant="filled"
                        inputClass={classes.searchFieldInput}
                        containerClass={classes.searchFieldResultsContainer}
                        onSelect={this.selectOkrDomain}
                        id="org-search-context-okr-editor"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.content}>
                <ObjectivesList
                  stperiod={period}
                  type={okrtype}
                  domain={okrdomain}
                  objectiveID={selectedObjectiveID}
                  onSelect={this.selectObjective}
                />
              </div>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button name="ctx-card-editor-okr-cancel-btn" onClick={this.closeDialog}>
                {t('general.cancel')}
              </Button>
              <Button
                name="ctx-card-editor-okr-save-btn"
                disabled={!!!selectedObjectiveID}
                sx={
                  !!selectedObjectiveID
                    ? {}
                    : { backgroundColor: theme => `${theme.palette.secondary.main}5F` }
                }
                disableElevation={!selectedObjectiveID}
                variant="contained"
                onClick={this.save}
              >
                {t('general.save')}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  periodcfg: objectivesSelectors.selectPeriodConfig(state.main.objectives),
  selectBestStPeriodForObjective: objectiveID =>
    objectivesSelectors.selectBestStPeriodForObjective(state.main.objectives, objectiveID),
  objectiveData: objectivesSelectors.selectObjective(
    state.main.objectives,
    ownProps.item.objectiveID,
  ),
});

CardObjective.propTypes = {
  item: PropTypes.object,
  classes: PropTypes.exact({
    root: PropTypes.string,
    icon: PropTypes.string,
    deleted: PropTypes.string,
    searchFieldPaper: PropTypes.string,
    searchFieldInput: PropTypes.string,
    searchFieldResultsContainer: PropTypes.string,
    dialogpaper: PropTypes.string,
    dialogroot: PropTypes.string,
    dialogcontent: PropTypes.string,
    dialogActions: PropTypes.string,
    content: PropTypes.string,
    selectors: PropTypes.string,
    row: PropTypes.string,
    dialogTitle: PropTypes.string,
    select: PropTypes.string,
    okr: PropTypes.string,
    dark: PropTypes.string,
    actionable: PropTypes.string,
  }),
  dispatch: PropTypes.func,
  periodcfg: PropTypes.object,
  ok: PropTypes.bool,
  location: PropTypes.object,
  navigate: PropTypes.func,
  objectiveData: PropTypes.object,
  closeSettings: PropTypes.func,
  deleteItem: PropTypes.func,
  selectBestStPeriodForObjective: PropTypes.func,
  onEdit: PropTypes.func,
  mode: PropTypes.string,
  editable: PropTypes.bool,
  cardTheme: PropTypes.string,
  key: PropTypes.string,
  id: PropTypes.string,
  t: PropTypes.func,
};

export default withLocation(
  withNavigation(withTranslation()(connect(mapStateToProps)(withStyles(styles)(CardObjective)))),
);
