import * as types from './types';

// Context v2
export const fetchGamePlans = payload => ({
  type: types.FETCH_GAMEPLANS,
  payload,
});

export const createGameplan = payload => ({
  type: types.CREATE_GAMEPLAN,
  payload,
});

export const copyGameplan = payload => ({
  type: types.COPY_GAMEPLAN,
  payload,
});

export const editGamePlanWidget = payload => ({
  type: types.EDIT_GAMEPLAN_WIDGET,
  payload,
});

export const editedGamePlanWidget = payload => ({
  type: types.EDITED_GAMEPLAN_WIDGET,
  payload,
});

export const moveWidget = payload => ({
  type: types.MOVE_GAMEPLAN_WIDGET,
  payload,
});

export const deleteWidget = payload => ({
  type: types.DELETE_GAMEPLAN_WIDGET,
  payload,
});

export const editGamePlanWidgetsVisibility = payload => ({
  type: types.EDIT_GAMEPLAN_WIDGETS_VISIBILITY,
  payload,
});

export const createBlankCard = payload => ({
  type: types.CREATE_BLANK_CARD,
  payload,
});
/*
export const createContextCard = payload => ({
  type: types.CREATE_CONTEXT_CARD,
  payload,
});

export const contextCardCreated = payload => ({
  type: types.CREATED_CONTEXT_CARD,
  payload,
});


export const contextCardMoved = payload => ({
  type: types.MOVED_CONTEXT_CARD,
  payload,
});

export const deleteContextCard = payload => ({
  type: types.DELETE_CONTEXT_CARD,
  payload,
});

export const contextCardDeleted = payload => ({
  type: types.DELETED_CONTEXT_CARD,
  payload,
});

*/
export const errorTryAgainLater = payload => ({
  type: types.ERROR_RECEIVED_FROM_API,
  payload,
});
