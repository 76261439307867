import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';

import EditableTypography from 'Components/Common/EditableTypography';

import { CTX_ELEMENT_MAX_STR_LENGTH } from 'config/constants';

export const validate = item => !!item.text;

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(),
  },
  padBottom: {
    paddingBottom: theme.spacing(2),
  },
});

class CardTitle extends Component {
  render() {
    const {
      classes,
      className,
      item,
      onEdit,
      cardTheme,
      editable = false,
      isLast = false,
      key,
      id,
      autoFocus,
    } = this.props;
    return (
      <div
        className={clsx([classes.root, className, isLast ? classes.padBottom : null])}
        data-tg-name="ctx-card-el-title"
        key={key}
        id={id}
      >
        <EditableTypography
          maxLength={CTX_ELEMENT_MAX_STR_LENGTH}
          variant="h5"
          placeholder="Title"
          name="ctx-card-editor-inline-editable-title"
          autoFocus={autoFocus}
          editable={editable}
          value={item.text}
          theme={cardTheme}
          onChange={text => onEdit({ text })}
        />
      </div>
    );
  }
}

CardTitle.propTypes = {
  item: PropTypes.object,
  classes: PropTypes.exact({
    root: PropTypes.string,
    padBottom: PropTypes.string,
  }),
  onEdit: PropTypes.func,
  cardTheme: PropTypes.string,
  editable: PropTypes.bool,
  isLast: PropTypes.bool,
  key: PropTypes.string,
  id: PropTypes.string,
  autoFocus: PropTypes.bool,
};

export default withStyles(styles)(CardTitle);
