import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Container from 'Components/Library/BaseComponents/Container';
import AiLoadingIndicator from 'Components/Features/TgAI/AiLoadingIndicator';
import { gameplansActions } from 'state/ducks/gameplans';
import { objectivesActions } from 'state/ducks/objectives';
import { nanoid } from 'nanoid';
import useFeatureFlag from 'hooks/useFeatureFlag';

interface SavingResultsUiProps {
  ltPeriodId: string;
  stPeriodId: string;
  teamId: string;
  data: object;
  openSuccessUi(): void;
  onError(): void;
  gameplanId: string;
}

function SavingResultsUi(props: SavingResultsUiProps) {
  const { gameplanId, teamId, data, ltPeriodId, stPeriodId, onError, openSuccessUi } = props;
  const [requestID, setRequestID] = useState();

  const usePeriodicGameplans = useFeatureFlag('PERIODIC-GAMEPLANS');

  const actionLog = useSelector((state: any) => state.main.gameplans.actionlog);

  const hasError = requestID && get(actionLog, [requestID, 'result']) === 'error';
  const isDone = requestID && get(actionLog, [requestID, 'result']) === 'ok';

  const dispatch = useDispatch();

  const start = shortId => {
    setRequestID(shortId);
    const args: any = {
      domain_id: teamId,
      requestID: shortId,
      initial_content: data,
      ltperiod: ltPeriodId,
      stperiod: stPeriodId.split('-')[1],
      use_periodic_gameplans: usePeriodicGameplans,
    };
    if (gameplanId) {
      args.gameplan_id = gameplanId;
    }
    dispatch(gameplansActions.createGameplan(args));
  };

  // executed on mount only:
  useEffect(() => {
    start(nanoid(10));
  }, []);

  useEffect(() => {
    if (hasError === true) {
      onError();
    }
    if (isDone === true) {
      // Refresh the OKRs
      dispatch(
        objectivesActions.getTeamObjectives({
          teamId,
          stperiod: stPeriodId,
          force: true,
        }),
      );
      openSuccessUi();
    }
  }, [hasError, isDone]);

  return (
    <Container alignItems="stretch" p={1}>
      <AiLoadingIndicator />
      <Typography variant="h6">Decoding your strategy</Typography>
      <Typography variant="body2">Storing digitized wisdom</Typography>
    </Container>
  );
}

export default SavingResultsUi;
