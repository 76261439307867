import { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { objectivesActions, objectivesSelectors } from 'state/ducks/objectives';

function useDomainOkrs(domains, period) {
  const hasArgs = domains && period;

  const okrData = useSelector(state =>
    hasArgs ? objectivesSelectors.selectOkrsForDomains(state.main.objectives, period, domains) : [],
  );
  const dispatch = useDispatch();

  const mounted = useRef();
  const periodRef = useRef();

  useEffect(() => {
    /* 
       Dispatch actions to fetch the data.
       Fetch with force: true
        - on initial mount to allow users to refresh the data with F5
        - if the period prop changes

       TODO: handle large domain arrays of several dozen domains, where dispatching all
       actions in one go is too much    
    */
    const periodChanged = periodRef.current && periodRef.current !== period;

    const force = !Boolean(mounted.current) || periodChanged;

    if (domains && period) {
      domains.forEach(domain => {
        if (domain.t === 'team') {
          dispatch(
            objectivesActions.getTeamObjectives({
              teamId: domain.d,
              stperiod: period,
              force,
            }),
          );
        } else if (domain.t === 'company') {
          dispatch(
            objectivesActions.getCompanyObjectives({
              stperiod: period,
              force,
            }),
          );
        } else if (domain.t === 'personal') {
          dispatch(
            objectivesActions.getRelatedObjectives({
              sub: domain.d,
              stperiod: period,
              force,
            }),
          );
        }
      });
    }
    if (force) {
      mounted.current = true;
    }
    periodRef.current = period;
  });
  const isLoading = hasArgs && okrData.some(domainData => domainData.loading);

  return { isLoading, okrs: okrData };
}

export default useDomainOkrs;
