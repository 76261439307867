import Typography from '@mui/material/Typography';
import Panel from 'Components/Library/BaseComponents/Panel';
import { Box, Collapse, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Container from 'Components/Library/BaseComponents/Container';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { facilitationSelectors } from 'state/ducks/facilitation';
import DfacIcon from 'Components/Features/DigitalFacilitation/DfacIcon';

interface CandidateLabelProps {
  candidateItem: any;
  highlight?: boolean;
  sessionId: string;
  questionIndex: number;
}

function CandidateLabel(props: CandidateLabelProps) {
  const { questionIndex, candidateItem, highlight, sessionId } = props;

  const inputs = useSelector((state: any) =>
    facilitationSelectors.selectFacilitationInputs(state.main.facilitation, sessionId),
  );

  const [showInputs, setShowInputs] = useState(false);
  const ownInputs = useMemo(() => {
    if (inputs?.ok) {
      return inputs.inputs[questionIndex].filter(i => candidateItem.inputs.includes(i.input_id));
    }
  }, [inputs, questionIndex, candidateItem]);
  return (
    <Panel
      sx={{
        color: highlight ? 'secondary.main' : 'text.primary',
        wordWrap: 'break-word',
        wordBreak: 'normal',
        overflow: 'hidden',
        bgcolor: 'background.paper',
        border: '2px solid',
        borderColor: highlight ? 'secondary.main' : 'transparent',
        justifySelf: 'stretch',
        lineHeight: '1.25rem',
        flexGrow: 10,
      }}
      alignItems={'stretch'}
    >
      <Container direction="row" alignItems="flex-start" justifyContent="space-between">
        <Typography variant="subtitle1">{`${candidateItem.category_name}`}</Typography>
        <IconButton
          size="medium"
          onClick={e => {
            e.preventDefault();
            setShowInputs(!showInputs);
          }}
        >
          {showInputs ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Container>
      {candidateItem.category_description && (
        <Typography variant="caption">{`${candidateItem.category_description}`}</Typography>
      )}
      <Collapse in={showInputs} timeout={50}>
        <Container spacing={1.5}>
          {ownInputs &&
            ownInputs.map(input => (
              <Container
                key={`${candidateItem.category_id}-candidate-label-${input.input_id}`}
                direction="row"
                alignItems="flex-start"
              >
                <Box sx={{ fontSize: '12px', p: 0.5 }}>
                  <DfacIcon color="disabled" fontSize="inherit" />
                </Box>
                <Typography variant="caption" color="text.secondary">
                  {input.input}
                </Typography>
              </Container>
            ))}
        </Container>
      </Collapse>
    </Panel>
  );
}

export default CandidateLabel;
