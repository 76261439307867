import { Typography } from '@mui/material';
import QuestionVoteResults from './QuestionVoteResults';
import Container from 'Components/Library/BaseComponents/Container';
import Panel from 'Components/Library/BaseComponents/Panel';
import { get } from 'lodash';

interface FacilitationCompleteProps {
  facilitationData: any;
  sessionId: string;
  votes: any;
}

function FacilitationComplete(props: FacilitationCompleteProps) {
  const { facilitationData, votes, sessionId } = props;

  if (!facilitationData.ok || !votes.ok) {
    return null;
  }

  let votingType = 0;
  // determine how the voting should happen
  // It's possible we haven't got a heartbeat response yet,
  // try to determine the correct voting type from the candidates
  // response

  const { questions } = facilitationData;
  const { questions: perQuestionVotes } = votes;
  if (facilitationData?.voting_type === 1 || get(perQuestionVotes, [0, 'category_id'])) {
    votingType = 1;
  }

  return (
    <Container m={0} spacing={3} alignItems={'stretch'} className="live-dfac-panel-votes-list">
      {questions &&
        questions.map((question, i) => (
          <Panel
            alignItems="stretch"
            className={'live-dfac-panel-question-item'}
            key={`live-dfac-panel-question-${i}`}
            spacing={2}
            sx={{ flexGrow: 10 }}
          >
            <Typography
              variant="subtitle1"
              component="span"
              display="block"
              color="text.strong"
              sx={{ mb: 2 }}
            >
              {`${question}`}
            </Typography>
            <QuestionVoteResults
              votingType={votingType}
              sessionId={sessionId}
              index={i}
              voteData={perQuestionVotes[i]}
            />
          </Panel>
        ))}
    </Container>
  );
}

export default FacilitationComplete;
