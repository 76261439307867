import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';

import EditableTypography from 'Components/Common/EditableTypography';
import TgDeltaSlider from 'Components/Common/TgDeltaSlider';

import { CTX_ELEMENT_MAX_SLIDER_STR_LENGTH } from 'config/constants';

export const validate = item =>
  !!item.leftLabel && !!item.rightLabel && !!item.values && item.values.length === 2;

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
  },
  wrapper: {
    border: 0,
    background: 'none',
    width: '100%',
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
  },
  captionsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(2),
    '& > div': {
      maxWidth: '40%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  label: {
    textOverflow: 'ellipsis',
    overflowWrap: 'break-word',
    overflow: 'hidden',
  },
});

class CardSlider extends Component {
  render() {
    const {
      classes,
      className,
      item,
      onEdit,
      cardTheme,
      editable,
      mode = 'minified',
      key,
      id,
    } = this.props;
    const labelVariant = mode === 'minified' ? 'caption' : 'subtitle1';

    return (
      <div
        className={clsx([classes.root, className])}
        data-tg-name="ctx-card-el-slider"
        key={key}
        id={id}
      >
        {/*
          TODO: fix this. react-beautiful-dnd does not trigger drag when the click happens in an "interactive" element
          for now we're wrapping the slider in a button to make that happen. This is a very poor way to do this.
        */}
        <button className={clsx([classes.wrapper, classes[cardTheme]])} type="button">
          <div className={classes.captionsRow}>
            <EditableTypography
              variant={labelVariant}
              onChange={text => onEdit({ leftLabel: text })}
              maxLength={CTX_ELEMENT_MAX_SLIDER_STR_LENGTH}
              className={classes.label}
              editable={editable}
              value={item.leftLabel}
              theme={cardTheme}
              name="ctx-card-el-slider-leftlabel"
            />
            <EditableTypography
              align="right"
              variant={labelVariant}
              onChange={text => onEdit({ rightLabel: text })}
              className={classes.label}
              maxLength={CTX_ELEMENT_MAX_SLIDER_STR_LENGTH}
              editable={editable}
              value={item.rightLabel}
              theme={cardTheme}
              name="ctx-card-el-slider-rightlabel"
            />
          </div>
          <TgDeltaSlider
            value={item.values}
            disabled={!!!editable}
            onChange={(event, values) => onEdit({ values })}
          />
        </button>
      </div>
    );
  }
}

CardSlider.propTypes = {
  item: PropTypes.object,
  classes: PropTypes.exact({
    root: PropTypes.string,
    wrapper: PropTypes.string,
    captionsRow: PropTypes.string,
    label: PropTypes.string,
  }),
  onEdit: PropTypes.func,
  cardTheme: PropTypes.string,
  editable: PropTypes.bool,
  mode: PropTypes.string,
  key: PropTypes.string,
  id: PropTypes.string,
};

export default withStyles(styles)(CardSlider);
