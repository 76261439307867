export const DEFAULT_FONT_VARIANT_MAPPING = {
  p: 'body2',
  h1: 'h3',
  h2: 'h4',
  h3: 'h6',
};

const getEditorStyles = (variant, minRows, fontVariantMap) => ({
  borderRadius: '10px',
  flexGrow: 10,
  boxSizing: 'border-box',
  border:
    variant === 'filled' || variant === 'standard' || variant === 'gameplan'
      ? theme => `1px solid ${theme.palette.action.border}`
      : 'none',
  backgroundColor: variant === 'filled' ? theme => theme.palette.background.box : 'inherit',
  overflowX: 'hidden',
  transition: 'background-color 0.25s ease, border-color 0.25s ease',
  '& *::selection': {
    backgroundColor: theme =>
      theme.palette.mode === 'dark' ? '#424242 !important' : 'rgba(0, 0, 0, 0.08)',
  },
  '& .TG-texteditor-button': {
    height: 32,
    opacity: 0.54,
    transition: 'opacity 0.25s ease',
  },
  '&:focus-within, :hover': theme => ({
    backgroundColor: variant === 'filled' ? theme.palette.background.box : 'inherit',
    border:
      variant === 'filled' || variant === 'standard' || variant === 'gameplan'
        ? `1px solid ${theme.palette.action.active}`
        : 'none',
    '& .TG-texteditor-button-active': {
      backgroundColor: theme.palette.action.selected,
    },
    '& .TG-texteditor-button': {
      opacity: 1,
    },
  }),
  '&.editor-error-state': {
    border: '1px solid',
    borderColor: 'error.main',
  },
  '& p': theme => ({
    ...theme.typography[fontVariantMap.p],
    '& a': { color: theme.palette.secondary.main },
    '& a:hover': { color: theme.palette.secondary.dark },
    '& a:visited': { color: theme.palette.secondary.main },
    marginBlockStart: '0',
    marginBlockEnd: '0.5em',
  }),
  '& h1, h2, h3, h4, h5, h6': {
    marginBlockStart: '0.5em',
    marginBlockEnd: '0.5em',
    '&:first-of-type': {
      marginBlockStart: 0,
    },
  },
  '& h1': theme => ({
    ...theme.typography[fontVariantMap.h1],
  }),
  '& h2': theme => ({
    ...theme.typography[fontVariantMap.h2],
  }),
  '& h3': theme => ({
    ...theme.typography[fontVariantMap.h3],
  }),
  '& strong': { fontWeight: 600 },
  '& .remirror-editor-wrapper': {
    paddingTop: '0px !important',
    color: theme => theme.palette.primary.main,
  },
  '& .remirror-editor': theme => ({
    ...theme.typography[fontVariantMap.p],
    color: theme.palette.text.primary,
    boxShadow: 'none !important',
    paddingTop:
      variant === 'inline' || variant === 'standard' ? theme.spacing(0.5) : theme.spacing(1.5),
    paddingLeft:
      variant === 'inline' ? 0 : variant === 'standard' ? theme.spacing(1) : theme.spacing(2),
    paddingRight:
      variant === 'inline' ? 0 : variant === 'standard' ? theme.spacing(1) : theme.spacing(2),
    overflowY: 'auto !important',
    '& .ProseMirror-selectednode': {
      outline: `1px solid ${theme.palette.secondary.main} !important`,
      borderRadius: '5px',
    },
    '& .remirror-list-item-checkbox': {
      filter: 'grayscale(1)',
    },
    '& .remirror-list-item-marker-container': {
      position: 'absolute',
      left: -26,
      width: 24,
      top: -1,
      display: 'inline-block',
      textAlign: 'center',
    },
    '& .remirror-list-item-with-custom-mark': {
      position: 'relative',
      listStyleType: 'none',
    },
  }),
  '& .remirror-editor.ProseMirror[contenteditable="true"]': {
    minHeight: minRows * 20,
  },
  '& .remirror-floating-popover': {
    zIndex: 9999,
  },
  '& .tg-mention': {
    color: 'secondary.main',
    padding: '2px',
  },
  '& .remirror-is-empty:first-of-type::before': {
    // Placeholder style, override remirror italic:
    fontStyle: 'normal !important',
    color: theme => `${theme.palette.text.disabled} !important`,
  },
  '& ul': {
    marginBlockStart: '0.25em',
    marginBlockEnd: '0.25em',
    paddingInlineStart: '1.75rem',
    '& p': {
      marginBlockStart: '0.25em',
      marginBlockEnd: '0.25em',
    },
  },
  '& ol': {
    marginBlockStart: '0.25em',
    marginBlockEnd: '0.25em',
    paddingInlineStart: '1.75rem',
    '& p': {
      marginBlockStart: '0.25em',
      marginBlockEnd: '0.25em',
    },
  },
});

export default getEditorStyles;
