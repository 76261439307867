import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';

import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';

import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { NestedMenuItem } from 'mui-nested-menu';

const styles = () => ({
  menuPaper: {
    overflow: 'visible !important',
  },
  disabled: {
    opacity: 0.6,
  },
  arrow: {
    paddingRight: 12,
  },
});

const clearImageInput = () => {
  const inputElement = document.getElementById('ctx-card-editor-add-opt-image');
  if (inputElement) {
    inputElement.value = ''; // Clear the value of the input field
  }
};

class AddItemMenu extends Component {
  render() {
    const { classes, menuOpen, addItem, addImageItem, onClose, t, totalImages, contextType } =
      this.props;

    return (
      <>
        <input
          accept="image/bmp, image/jpeg, image/png, image/webp, image/avif, image/apng, image/gif"
          type="file"
          style={{ display: 'none' }}
          id="ctx-card-editor-add-opt-image"
          onChange={addImageItem}
        />
        <Menu
          id="ctx-card-editor-add-item-menu"
          classes={{ paper: classes.menuPaper }}
          anchorEl={menuOpen}
          open={Boolean(menuOpen)}
          onClose={onClose}
        >
          <NestedMenuItem
            id="ctx-card-editor-add-open-submenu-text"
            rightIcon={<ArrowRightIcon className={classes.arrow} />}
            label={t('contextv2.cards.types.text')}
            parentMenuOpen={Boolean(menuOpen)}
          >
            <MenuItem id="ctx-card-editor-add-opt-overline" onClick={() => addItem('overline')}>
              <Typography variant="overline" className="paneltitle">
                {t('contextv2.cards.types.overline')}
              </Typography>
            </MenuItem>
            <MenuItem id="ctx-card-editor-add-opt-title" onClick={() => addItem('title')}>
              <Typography variant="h5">{t('contextv2.cards.types.title')}</Typography>
            </MenuItem>
            <MenuItem id="ctx-card-editor-add-opt-subtitle" onClick={() => addItem('subtitle')}>
              <Typography variant="h6">{t('contextv2.cards.types.subtitle')}</Typography>
            </MenuItem>
            <MenuItem id="ctx-card-editor-add-opt-paragraph" onClick={() => addItem('paragraph')}>
              <Typography variant="body2">{t('contextv2.cards.types.paragraph')}</Typography>
            </MenuItem>
          </NestedMenuItem>

          <NestedMenuItem
            id="ctx-card-editor-add-open-submenu-media"
            rightIcon={<ArrowRightIcon className={classes.arrow} />}
            label={t('contextv2.cards.types.media')}
            parentMenuOpen={Boolean(menuOpen)}
          >
            {totalImages < 50 ? (
              <label htmlFor="ctx-card-editor-add-opt-image">
                <MenuItem onClick={clearImageInput}>{t('contextv2.cards.types.image')}</MenuItem>
              </label>
            ) : (
              <Tooltip title={t('contextv2.cards.imagelimitreached')} placement="right">
                {/* Cannot set the menuitem as disabled as the tooltip would not work */}
                <MenuItem className={classes.disabled} id="ctx-card-editor-add-opt-image">
                  {t('contextv2.cards.types.image')}
                </MenuItem>
              </Tooltip>
            )}
            <MenuItem id="ctx-card-editor-add-opt-video" onClick={() => addItem('video')}>
              {t('contextv2.cards.types.video')}
            </MenuItem>
            <MenuItem id="ctx-card-editor-add-opt-link" onClick={() => addItem('attachment')}>
              {t('contextv2.cards.types.link')}
            </MenuItem>
          </NestedMenuItem>

          <NestedMenuItem
            id="ctx-card-editor-add-open-submenu-status"
            rightIcon={<ArrowRightIcon className={classes.arrow} />}
            label={t('contextv2.cards.types.status')}
            parentMenuOpen={Boolean(menuOpen)}
          >
            <MenuItem id="ctx-card-editor-add-opt-slider" onClick={() => addItem('slider')}>
              {t('contextv2.cards.types.slider')}
            </MenuItem>
            <MenuItem id="ctx-card-editor-add-opt-okr" onClick={() => addItem('objective')}>
              {t('contextv2.cards.types.okr')}
            </MenuItem>
            {contextType === 'team' && (
              <MenuItem id="ctx-card-editor-add-opt-okr" onClick={() => addItem('teamokrs')}>
                {t('contextv2.cards.types.teamokrs')}
              </MenuItem>
            )}
            <MenuItem
              id="ctx-card-editor-add-opt-trafficlight"
              onClick={() => addItem('trafficlight')}
            >
              {t('contextv2.cards.types.traffic')}
            </MenuItem>
          </NestedMenuItem>
          <MenuItem id="ctx-card-editor-add-opt-divider" onClick={() => addItem('divider')}>
            {t('contextv2.cards.types.divider')}
          </MenuItem>
        </Menu>
      </>
    );
  }
}

AddItemMenu.propTypes = {
  menuOpen: PropTypes.instanceOf(Element),
  classes: PropTypes.exact({
    menuPaper: PropTypes.string,
    disabled: PropTypes.string,
    arrow: PropTypes.string,
  }),
  addItem: PropTypes.func,
  addImageItem: PropTypes.func,
  onClose: PropTypes.func,
  totalImages: PropTypes.number,
  contextType: PropTypes.string,
  t: PropTypes.func,
};

export default withTranslation()(withStyles(styles)(AddItemMenu));
