import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityOnIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DragIndicator from '@mui/icons-material/DragIndicator';

import AddItemMenu from './AddItemMenu';

import imgFileToDataUrl from '../CardComponents/CardImage/helpers';

const styles = theme => ({
  actionsContainerLeft: {
    position: 'absolute',
    top: 0,
    left: -80,
    bottom: 0,
    height: '100%',
    width: 'auto',
    visibility: 'hidden',
  },
  actionsContainerRight: {
    position: 'absolute',
    top: 0,
    right: -80,
    bottom: 0,
    height: '100%',
    width: 'auto',
    visibility: 'hidden',
  },
  addElementBtn: {
    position: 'absolute',
    opacity: 1,
    left: 0,
    top: '50%',
    transform: 'translate(0, -50%)',
  },
  dragIndicator: {
    position: 'absolute',
    opacity: 1,
    left: 48,
    top: '50%',
    transform: 'translate(0, -50%)',
  },
  firstItemActionBtn: {
    position: 'absolute',
    opacity: 1,
    right: 16,
    top: '50%',
    transform: 'translate(0, -50%)',
  },
  secondItemActionBtn: {
    position: 'absolute',
    opacity: 1,
    right: -32,
    top: '50%',
    transform: 'translate(0, -50%)',
  },
  thirdItemActionBtn: {
    position: 'absolute',
    opacity: 1,
    right: -80,
    top: '50%',
    transform: 'translate(0, -50%)',
  },
  subMenu: {
    opacity: '0',
    position: 'absolute',
    left: '100%',
    transform: 'scale(0.75, 0.5625)',
    transformOrigin: 'top right',
    transition: `opacity ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeInOut} 0ms, transform ${theme.transitions.duration.shorter}ms ${theme.transitions.easing.easeInOut} 0ms`, // match Menu transition
    top: '-8px',
    visibility: 'hidden',
    paddingTop: theme.spacing(),
  },
  subMenuOpen: {
    transform: 'scale(1, 1) translateZ(0px)',
    visibility: 'visible',
    opacity: '1',
  },
  menuItem: {
    overflow: 'visible',
    position: 'relative',
  },
  menuPaper: {
    overflow: 'visible !important',
  },
});

class ActionsContainer extends Component {
  state = {
    menuOpen: false,
  };

  openMenu = e => {
    this.setState({ menuOpen: e.currentTarget });
  };

  closeMenu = () => {
    this.setState({ menuOpen: null });
  };

  addItem = type => {
    this.props.addItem(type, this.props.index);
    this.closeMenu();
  };

  addImageItem = async e => {
    this.closeMenu();
    const b64img = await imgFileToDataUrl(e);
    this.props.addItem('image', this.props.index, { originalSrc: b64img });
  };

  tooltipTextSelector = (visibleInCurrentMode, editMode) => {
    if (editMode === 'expanded') {
      if (visibleInCurrentMode) {
        return this.props.t('contextv2.shownwhenexpanded');
      }
      return this.props.t('contextv2.hiddenwhenexpanded');
    }
    if (editMode === 'minified') {
      if (visibleInCurrentMode) {
        return this.props.t('contextv2.shownwhenminified');
      }
      return this.props.t('contextv2.hiddenwhenminified');
    }
    return null;
  };

  render() {
    const { classes, index, item, editMode, t, totalImages, contextType } = this.props;
    const { type } = item;
    const { menuOpen } = this.state;

    const visibleInCurrentMode = !!!item[`hide_${editMode}`];

    return (
      <>
        <div className={clsx([classes.actionsContainerLeft, 'actionsContainer'])}>
          <Tooltip placement="bottom" title={t('contextv2.addelement')}>
            <IconButton
              aria-controls="ctx-card-editor-add-item-menu"
              id={`ctx-card-editor-add-item-btn-${index}`}
              name="ctx-card-editor-add-item-btn"
              onClick={this.openMenu}
              color="primary"
              className={classes.addElementBtn}
              size="large"
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
          <Tooltip placement="bottom" title={t('contextv2.moveelement')}>
            <DragIndicator color="primary" className={classes.dragIndicator} />
          </Tooltip>
        </div>
        <div className={clsx([classes.actionsContainerRight, 'actionsContainer'])}>
          {type === 'image' || type === 'video' || type === 'objective' || type === 'attachment' ? (
            <React.Fragment>
              <Tooltip placement="bottom" title={t('contextv2.editelement')}>
                <IconButton
                  color="primary"
                  className={classes.firstItemActionBtn}
                  onClick={() => this.props.openSettings(index)}
                  name={`ctx-card-editor-edit-item-btn-${index}`}
                  size="large"
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                placement="bottom"
                title={this.tooltipTextSelector(visibleInCurrentMode, editMode)}
              >
                <IconButton
                  color="primary"
                  className={classes.secondItemActionBtn}
                  onClick={() => this.props.setVisibility(editMode, index, visibleInCurrentMode)}
                  name="overlay-set-visibility-button"
                  size="large"
                >
                  {!!visibleInCurrentMode ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </Tooltip>
              <Tooltip placement="bottom" title={t('contextv2.removeelement')}>
                <IconButton
                  color="primary"
                  className={classes.thirdItemActionBtn}
                  onClick={() => this.props.deleteItem(index)}
                  name="overlay-delete-item-button"
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Tooltip
                placement="bottom"
                title={this.tooltipTextSelector(visibleInCurrentMode, editMode)}
              >
                <IconButton
                  color="primary"
                  className={classes.firstItemActionBtn}
                  onClick={() => this.props.setVisibility(editMode, index, visibleInCurrentMode)}
                  name="overlay-set-visibility-button"
                  size="large"
                >
                  {!!visibleInCurrentMode ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </Tooltip>
              <Tooltip placement="bottom" title={t('contextv2.removeelement')}>
                <IconButton
                  color="primary"
                  className={classes.secondItemActionBtn}
                  onClick={() => this.props.deleteItem(index)}
                  name="overlay-delete-item-button"
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </React.Fragment>
          )}
          {Boolean(menuOpen) && (
            <AddItemMenu
              index={0}
              menuOpen={menuOpen}
              totalImages={totalImages}
              addItem={this.addItem}
              addImageItem={this.addImageItem}
              onClose={this.closeMenu}
              contextType={contextType}
            />
          )}
        </div>
      </>
    );
  }
}

ActionsContainer.propTypes = {
  addItem: PropTypes.func,
  contextType: PropTypes.string,
  index: PropTypes.number,
  editMode: PropTypes.string,
  item: PropTypes.object,
  openSettings: PropTypes.func,
  setVisibility: PropTypes.func,
  deleteItem: PropTypes.func,
  classes: PropTypes.exact({
    actionsContainerLeft: PropTypes.string,
    actionsContainerRight: PropTypes.string,
    addElementBtn: PropTypes.string,
    dragIndicator: PropTypes.string,
    firstItemActionBtn: PropTypes.string,
    secondItemActionBtn: PropTypes.string,
    thirdItemActionBtn: PropTypes.string,
    subMenu: PropTypes.string,
    subMenuOpen: PropTypes.string,
    menuItem: PropTypes.string,
    menuPaper: PropTypes.string,
  }),
  totalImages: PropTypes.number,
  t: PropTypes.func,
};

export default withTranslation()(withStyles(styles)(ActionsContainer));
