import React, { PropsWithChildren } from 'react';
import MuiButton from '@mui/material/Button';
import BaseIconWrapper from 'Components/Library/BaseComponents/Icon';
import { styled } from '@mui/material/styles';
import { borderRadius, spacingUnit } from 'config/theme';

import type { ButtonProps } from './Button.types';

const StyledMuiButton = styled(MuiButton)(
  // eslint-disable-next-line no-unused-vars
  ({ theme, variant, ...rest }) =>
    `&.MuiButton-root {
      min-width: auto;
    }
    &.MuiButton-contained {
      border: 1px solid transparent;
    }
    &.MuiButton-outlined {
      background-color: ${theme.palette.background.paper};
    }
    &.MuiButton-text {
      border: 1px solid transparent;
    }
    &.MuiButton-sizeSmall {
      height: ${spacingUnit * 2.5}px; 
      padding: ${0}px ${spacingUnit * 1.5 - 1}px;
      border-radius: ${borderRadius.sm};
      font-size: ${theme.typography.subtitle2.fontSize};
      font-weight: ${theme.typography.subtitle2.fontWeight};
      letter-spacing: ${theme.typography.subtitle2.letterSpacing};
      line-height: 16px;
      & .BaseButton-icon {
        margin: ${-2}px ${spacingUnit * -1.5}px;
      }
    }
    &.MuiButton-sizeMedium {
      height: ${spacingUnit * 3}px; 
      padding: ${3}px ${spacingUnit * 1.5 - 1}px;
      font-size: ${theme.typography.subtitle2.fontSize};
      font-weight: ${theme.typography.subtitle2.fontWeight};
      letter-spacing: ${theme.typography.subtitle2.letterSpacing};
      line-height: 16px;
      & .BaseButton-icon {
        margin: ${-4}px ${spacingUnit * -1.5}px;
      }
    }
    &.MuiButton-sizeLarge {
      height: ${spacingUnit * 4}px; 
      padding: ${7}px ${spacingUnit * 2 - 1}px;
      font-size: ${theme.typography.subtitle2.fontSize};
      font-weight: ${theme.typography.subtitle2.fontWeight};
      letter-spacing: ${theme.typography.subtitle2.letterSpacing};
      line-height: 16px;
      & .BaseButton-icon {
        margin: ${-8}px ${spacingUnit * -2}px;
      }
    }
`,
);

function Button(props: PropsWithChildren<ButtonProps>) {
  const { children, Icon = null, size = 'medium', variant = 'contained', ...rest } = props;

  return (
    <StyledMuiButton variant={variant} size={size} {...rest}>
      {Icon !== null && (
        <BaseIconWrapper className="BaseButton-icon" size={size}>
          {Icon}
        </BaseIconWrapper>
      )}
      {children}
    </StyledMuiButton>
  );
}
export { ButtonProps };
export default Button;
