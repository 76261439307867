import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';

import Typography from '@mui/material/Typography';
import { roundPercent } from 'config/helpers';

const styles = theme => ({
  scoreBar: {
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    transition: 'all 75ms ease-in',
  },
  scoreBarContainer: {
    height: 80,
  },
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  barContainer: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
  },
  scoresRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    '& span': {
      // color the labels:
      color: theme.palette.primary[600],
    },
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
    },
  },
  legendContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
  },
  column: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    margin: 8 / 2,
    textAlign: 'center',
    '&:first-of-type': {
      marginLeft: 0,
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  npsScoreColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexGrow: 0,
    margin: theme.spacing(2),
    '&:first-of-type': {
      marginLeft: 0,
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  centeredText: {
    flexGrow: 0,
    textAlign: 'center',
    maxWidth: 2,
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 3,
    '& span': {
      marginLeft: -20,
      marginRight: -20,
      textAlign: 'center',
    },
  },
  npsScores: {
    [theme.breakpoints.down('sm')]: {
      ...theme.typography.subheading,
    },
  },
  promoter: {
    color: theme.palette.visualizations.nps[10],
  },
  detractor: {
    color: theme.palette.visualizations.nps[0],
  },
  passive: {
    color: theme.palette.visualizations.nps[7],
  },
  subtitle: {
    marginBottom: -5,
  },
});

class NpsChart extends Component {
  render() {
    const { t, values, outcome, classes, theme } = this.props;

    if (!values) {
      return null;
    }

    return (
      <div className={classes.root}>
        <div className={classes.scoresRow}>
          <div className={clsx([classes.column, classes.npsScoreColumn])}>
            <Typography variant="h5" className={clsx([classes.detractor, classes.npsScores])}>
              {outcome.sum !== 0 ? `${roundPercent(outcome.detractors / outcome.sum)}%` : '-'}
            </Typography>
            <Typography variant="caption">{t('insights.npsdetractorslabel')}</Typography>
          </div>
          <div className={clsx([classes.column, classes.npsScoreColumn])}>
            <Typography variant="h5" className={clsx([classes.passive, classes.npsScores])}>
              {outcome.sum !== 0 ? `${roundPercent(outcome.passives / outcome.sum)}%` : '-'}
            </Typography>
            <Typography variant="caption">{t('insights.npspassiveslabel')}</Typography>
          </div>
          <div className={clsx([classes.column, classes.npsScoreColumn])}>
            <Typography variant="h5" className={clsx([classes.promoter, classes.npsScores])}>
              {outcome.sum !== 0 ? `${roundPercent(outcome.promoters / outcome.sum)}%` : '-'}
            </Typography>
            <Typography variant="caption">{t('insights.npspromoterslabel')}</Typography>
          </div>
        </div>
        <div className={classes.barContainer}>
          {outcome &&
            outcome.scores &&
            Object.keys(outcome.scores).map(key => (
              <div
                key={`nps-chart-bars-${key}`}
                className={clsx([classes.column, classes.scoreBarContainer])}
              >
                <div
                  className={classes.scoreBar}
                  style={{
                    backgroundColor: theme.palette.visualizations.nps[key],
                    height: `${Math.max((80 * outcome.scores[key]) / outcome.sum, 1)}px`,
                  }}
                >
                  &nbsp;
                </div>
                <div className={classes.centeredText}>
                  <Typography variant="caption">{key}</Typography>
                </div>
              </div>
            ))}
        </div>
        <div className={classes.legendContainer}>
          <Typography variant="caption">{t('insights.npsaxisgood')}</Typography>
          <Typography variant="caption">{t('insights.npsaxisbad')}</Typography>
        </div>
      </div>
    );
  }
}

NpsChart.propTypes = {
  classes: PropTypes.exact({
    scoreBar: PropTypes.string,
    scoreBarContainer: PropTypes.string,
    root: PropTypes.string,
    barContainer: PropTypes.string,
    scoresRow: PropTypes.string,
    legendContainer: PropTypes.string,
    column: PropTypes.string,
    npsScoreColumn: PropTypes.string,
    centeredText: PropTypes.string,
    npsScores: PropTypes.string,
    promoter: PropTypes.string,
    detractor: PropTypes.string,
    passive: PropTypes.string,
    subtitle: PropTypes.string,
  }),
  values: PropTypes.object,
  outcome: PropTypes.object,
  theme: PropTypes.object,
  t: PropTypes.func,
};

export default withTranslation()(withStyles(styles, { withTheme: true })(NpsChart));
