import React, { Component } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';

import {
  KR_DESCRIPTION_MAX_LENGTH,
  KR_NAME_MAX_LENGTH,
  KR_RT_DESCRIPTION_MAX_LENGTH,
} from 'config/constants';
import { objectivesActions, objectivesSelectors } from 'state/ducks/objectives';

import Form from 'Components/Library/Forms/';
import FormTextField from 'Components/Library/Forms/Elements/FormTextField';
import FormSelectorField from 'Components/Library/Forms/Elements/FormSelectorField';
import FormButton from 'Components/Library/Forms/Elements/FormButton';
import LinearIndicator from 'Components/Library/Forms/Elements/LinearIndicator';
import InlineEditInput from 'Components/Library/BaseComponents/InlineEditInput';
import StandardDialog from 'Components/Library/StandardDialog';
import UserSelector from 'Components/Library/UserSelector';
import CreateDialogActions from 'Components/Library/CreateDialogActions';
import { AiAssistCreateKr } from 'Components/Features/TgAI/AiPopover';
import { appstatusSelectors } from 'state/ducks/appstatus';
import { SCHEMA_FULL } from 'Components/Library/RichTextEditor/schemas';
import RichTextEditor from 'Components/Library/RichTextEditor';
import FormRichTextField from 'Components/Library/Forms/Elements/FormRichTextField';

const styles = theme => ({
  content: {
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: theme.spacing(3),
    flex: 10,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
    paddingBottom: theme.spacing(),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  formContainer: {
    marginBottom: theme.spacing(3),
  },
});

const schema = {
  type: 'object',
  properties: {
    keyresult: { type: 'string', minLength: 1, maxLength: KR_NAME_MAX_LENGTH },
    description: { type: 'string', maxLength: KR_DESCRIPTION_MAX_LENGTH },
    description_rt: {
      richtextschema: SCHEMA_FULL,
      minLength: 0,
      maxLength: KR_RT_DESCRIPTION_MAX_LENGTH,
    },
    owner: { type: 'string' },
  },
  required: ['keyresult', 'owner'],
  additionalProperties: true,
};

class PanelAddKeyresult extends Component {
  onKrCreated = result => {
    const keyresultID = get(result, 'data.keyresultID');
    if (!!this.props.onKeyresultCreated && keyresultID) {
      this.props.onKeyresultCreated(keyresultID);
    } else {
      this.props.onClose();
    }
  };

  render() {
    const { useRichText, classes, onClose, t, objectiveID, objectiveData, sub, zIndexModifier } =
      this.props;
    const canAssign = !(objectiveID && objectiveID.substring(0, 2) === 'PO');

    const owner = !!canAssign ? sub : get(objectiveData, 'data.owner', null);

    return (
      <Form
        name="add-kr-form"
        schema={schema}
        initialValues={{
          owner,
          keyresult: '',
        }}
        stateSlice="main.objectives"
        submitActionCreator={objectivesActions.createKeyresult}
        additionalProperties={{ objectiveID, status: 0, target: 100, baseline: 0, type: '%' }}
        onSubmitSuccess={this.onKrCreated}
      >
        <StandardDialog
          open
          onBackdropClick={onClose}
          preventBackDropCloseFunc={this.preventBackDropCloseFunc}
          size="small"
          zIndexModifier={zIndexModifier}
        >
          <LinearIndicator positioning="absolute" />
          <div className={classes.content}>
            <div className={classes.formContainer}>
              <FormTextField
                fieldName="keyresult"
                render={fieldProps => (
                  <InlineEditInput
                    autoFocus
                    id="add-kr-form-krname"
                    label={t('objectives.createNewKrNameInputLabel')}
                    fullWidth
                    multiline
                    variant="h4"
                    {...fieldProps}
                    InputProps={{
                      endAdornment: (
                        <AiAssistCreateKr
                          objectiveData={objectiveData}
                          value={fieldProps.value}
                          onChange={fieldProps.onChange}
                        />
                      ),
                    }}
                    sx={{ mb: 2 }}
                  />
                )}
              />

              {useRichText ? (
                <FormRichTextField
                  fieldName="description_rt"
                  render={fieldProps => (
                    <RichTextEditor
                      {...fieldProps}
                      label={t('objectives.createNewKrDescriptionInputLabel')}
                      placeholder={t('objectives.createNewKrDescriptionInputLabel')}
                      toolbarVariant="floating"
                      variant="inline"
                      schema={SCHEMA_FULL}
                    />
                  )}
                />
              ) : (
                <FormTextField
                  fieldName="description"
                  render={fieldProps => (
                    <InlineEditInput
                      id="add-kr-form-krdescription"
                      label={t('objectives.createNewKrDescriptionInputLabel')}
                      fullWidth
                      multiline
                      variant="body1"
                      {...fieldProps}
                    />
                  )}
                />
              )}
            </div>
            <CreateDialogActions>
              <FormSelectorField
                fieldName="owner"
                render={fieldProps => <UserSelector disabled={!canAssign} {...fieldProps} />}
              />
            </CreateDialogActions>
          </div>
          <DialogActions>
            <Button onClick={onClose} id="add-kr-form-cancel-btn">
              {t('general.cancel')}
            </Button>
            <FormButton
              render={buttonProps => (
                <Button
                  id="add-kr-form-submit-btn"
                  variant="contained"
                  color="secondary"
                  {...buttonProps}
                >
                  {t('objectives.createkeyresultcta')}
                </Button>
              )}
            />
          </DialogActions>
        </StandardDialog>
      </Form>
    );
  }
}

PanelAddKeyresult.propTypes = {
  classes: PropTypes.exact({
    content: PropTypes.string,
    formContainer: PropTypes.string,
  }),
  handleCancel: PropTypes.func,
  objectiveData: PropTypes.object,
  objectiveID: PropTypes.string,
  onKeyresultCreated: PropTypes.func,
  onClose: PropTypes.func,
  sub: PropTypes.string,
  t: PropTypes.func,
  zIndexModifier: PropTypes.number,
  useRichText: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
  const objectiveData = objectivesSelectors.selectObjective(
    state.main.objectives,
    ownProps.objectiveID,
  );
  return {
    useRichText: appstatusSelectors.selectStatus(state.main.appstatus)?.data?.okr_rt,
    objectiveData,
    sub: state.auth.userID,
  };
};

export default withTranslation()(withStyles(styles)(connect(mapStateToProps)(PanelAddKeyresult)));
