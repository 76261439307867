import React from 'react';
import { Typography } from '@mui/material';
import Container from 'Components/Library/BaseComponents/Container';
import Button from 'Components/Library/BaseComponents/Button';

interface ErrorUiProps {
  onClose(): void;
}

function ErrorUi(props: ErrorUiProps) {
  const { onClose } = props;

  const close = () => {
    onClose();
  };
  return (
    <Container alignItems="stretch" p={1}>
      <Typography variant="h6">Uh oh.</Typography>
      <Typography variant="body2">The computer said no :(</Typography>

      <Container direction="row" sx={{ pt: 2 }} justifyContent="flex-end">
        <Button variant="text" onClick={close}>
          Close
        </Button>
      </Container>
    </Container>
  );
}

export default ErrorUi;
