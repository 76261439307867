import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import FormRadioGroup from 'Components/Library/Forms/Elements/FormRadioGroup';

const styles = theme => ({
  radioContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexBasis: '100%',
    justifySelf: 'stretch',
    alignSelf: 'stretch',
    textAlign: 'center',
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& > span': {
      lineHeight: '30px',
      height: 30,
    },
  },
  // These empty classes need to exist:
  green: {},
  amber: {},
  red: {},
  radioOpt: {
    ...theme.shape,
    width: 'auto',
    overflow: 'hidden',
    flexShrink: 5,
    flexGrow: 5,
    flexBasis: '30%',
    height: 32,
    marginTop: 0,
    marginBottom: 4,
    padding: 0,
    border: '1px solid',
    borderColor: theme.palette.action.border,
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
      flexShrink: 0,
      flexGrow: 10,
    },
    '& .MuiTouchRipple-root': {
      color: theme.palette.background.box,
    },
    '& .MuiTypography-root': {
      color: theme.palette.text.disabled,
    },
    '& $radioLabel': {
      color: theme.palette.text.disabled,
    },
    marginRight: 8,
    '&:last-of-type': {
      marginRight: 0,
    },
    '&:hover $radioContent': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-checked': {
      border: '2px solid',
      '&$red': {
        color: theme.palette.confidence.red,
        borderColor: theme.palette.confidence.red,
        '& $radioLabel': {
          color: theme.palette.confidence.red,
          borderColor: theme.palette.confidence.red,
        },
        '& .MuiTypography-root': {
          color: theme.palette.confidence.red,
        },
      },
      '&$amber': {
        color: theme.palette.confidence.amber,
        borderColor: theme.palette.confidence.amber,
        '& $radioLabel': {
          color: theme.palette.confidence.amber,
          borderColor: theme.palette.confidence.amber,
        },
        '& .MuiTypography-root': {
          color: theme.palette.confidence.amber,
        },
      },
      '&$green': {
        color: theme.palette.confidence.green,
        borderColor: theme.palette.confidence.green,
        '& $radioLabel': {
          color: theme.palette.confidence.green,
          borderColor: theme.palette.confidence.green,
        },
        '& .MuiTypography-root': {
          color: theme.palette.confidence.green,
        },
      },
    },
  },
  radioLabel: {
    color: theme.palette.text.secondary,
  },
  radioGroup: {
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap !important',
    },
  },
});

const CONFIDENCE_OPTIONS = ['red', 'amber', 'green'];

const createLabel = (t, key) => (
  <>
    <Box sx={{ mr: 1, width: 16, height: 16, borderRadius: 0.5, bgcolor: `confidence.${key}` }} />
    <Typography variant="subtitle2" component="span">
      {`${t(`objectives.${key}.name`)}`}
    </Typography>
  </>
);

function KrConfidenceWidget(props) {
  const { canEdit, classes } = props;

  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'no-wrap',
        overflow: 'hidden',
        justifyContent: 'stretch',
        width: '100%',
        minWidth: 0,
        mt: 2,
        mb: 2,
        '& .MuiFormGroup-root': {
          flexWrap: 'nowrap',
          overflow: 'hidden',
          justifyContent: 'stretch',
          flexGrow: 100,
        },
      }}
    >
      <FormRadioGroup
        fieldName="confidence"
        render={fieldProps => (
          <RadioGroup {...fieldProps} row className={classes.radioGroup}>
            {CONFIDENCE_OPTIONS.map(key => (
              <Tooltip
                key={`dialog-update-kr-options-${key}`}
                title={t(`objectives.${key}.tooltip`)}
                placement="bottom"
              >
                <Radio
                  disabled={!canEdit}
                  className={clsx(classes.radioOpt, classes[key])}
                  checkedIcon={<div className={classes.radioContent}>{createLabel(t, key)}</div>}
                  icon={<div className={classes.radioContent}>{createLabel(t, key)}</div>}
                  value={key.toLowerCase()}
                />
              </Tooltip>
            ))}
          </RadioGroup>
        )}
      />
    </Box>
  );
}

KrConfidenceWidget.propTypes = {
  classes: PropTypes.exact({
    radioGroup: PropTypes.string.isRequired,
    radioOpt: PropTypes.string.isRequired,
    radioLabel: PropTypes.string.isRequired,
    radioContent: PropTypes.string.isRequired,
    red: PropTypes.string.isRequired,
    amber: PropTypes.string.isRequired,
    green: PropTypes.string.isRequired,
  }),
  canEdit: PropTypes.bool,
  keyresultData: PropTypes.object,
  t: PropTypes.func,
};

export default withStyles(styles)(KrConfidenceWidget);
