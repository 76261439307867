import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Pie } from '@visx/shape';
import { Group } from '@visx/group';
import { AutoSizer } from 'react-virtualized';
import withStyles from '@mui/styles/withStyles';

const styles = () => ({
  root: {
    width: '100%',
    height: '100%',
    minHeight: 240,
    display: 'flex',
    flexDirection: 'column',
  },
});

class SurveyPieChart extends Component {
  render() {
    const { classes, keys, values, theme } = this.props;

    if (!keys || !values) {
      return null;
    }
    const data = [];
    let total = 0;
    for (const key in keys) {
      data.push({ index: key, label: keys[key], value: values[key] });
      total = !!values[key] ? total + parseInt(values[key], 10) : total;
    }
    return (
      <div className={classes.root}>
        <AutoSizer defaultHeight={200}>
          {({ width, height }) =>
            width === 0 || height === 0 ? null : (
              <svg width={width} height={height}>
                <Group top={height / 2} left={width / 2}>
                  <Pie
                    data={data}
                    pieValue={d => d.value}
                    pieSort={(a, b) => a.index - b.index}
                    outerRadius={Math.min(width, height) / 2 - Math.min(width, height) * 0.075}
                    innerRadius={Math.min(width, height) / 2 - Math.min(width, height) * 0.2}
                    padAngle={0}
                  >
                    {pie =>
                      pie.arcs.map((arc, i) => {
                        const [centroidX, centroidY] = pie.path.centroid(arc);
                        return (
                          <g key={`letters-${arc.data.label}-${i}`}>
                            <path d={pie.path(arc)} fill={theme.palette.visualizations.pie[i]} />
                            <text
                              fill="white"
                              textAnchor="middle"
                              x={centroidX}
                              y={centroidY}
                              dy=".33em"
                              fontSize={9}
                            >
                              {!!arc.data.value &&
                                `${Math.round((1000 * (arc.data.value / total)) / 10)}%`}
                            </text>
                          </g>
                        );
                      })
                    }
                  </Pie>
                </Group>
              </svg>
            )
          }
        </AutoSizer>
      </div>
    );
  }
}

SurveyPieChart.propTypes = {
  classes: PropTypes.exact({
    root: PropTypes.string,
  }),
  keys: PropTypes.object,
  values: PropTypes.object,
  theme: PropTypes.object,
};

export default withStyles(styles, { withTheme: true })(SurveyPieChart);
