import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import { withBoundingRects } from '@visx/bounds';
import Typography from '@mui/material/Typography';
import { round } from 'config/helpers';
import TimelineTooltip from './TimelineTooltip';

const styles = theme => ({
  left: {
    maxWidth: 190,
    marginRight: theme.spacing(2),
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  right: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  deltaGreen: {
    marginLeft: theme.spacing(),
  },
  deltaRed: {
    marginLeft: theme.spacing(),
  },
  scoreContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    '& span': {
      color: theme.palette.background.box,
    },
    '& $deltaGreen': {
      color: theme.palette.confidence.green,
    },
    '& $deltaRed': {
      color: theme.palette.confidence.red,
    },
  },
  title: {
    marginBottom: 6,
  },
});

class QuadTimelineTooltip extends React.PureComponent {
  render() {
    const { classes, t, topic, tooltipData, cx, rect, parentRect } = this.props;
    const scoreDeltaX = round(
      get(tooltipData, 'selected.outcome.x', 0) - get(tooltipData, 'prev.outcome.x', 0),
    );
    const scoreDeltaY = round(
      get(tooltipData, 'selected.outcome.y', 0) - get(tooltipData, 'prev.outcome.y', 0),
    );
    const scoreNow = get(tooltipData, 'selected.outcome.score', 0);
    const scoreDelta = round(
      get(tooltipData, 'selected.outcome.score', 0) - get(tooltipData, 'prev.outcome.score', 0),
    );

    return (
      <TimelineTooltip cx={cx} rect={rect} parentRect={parentRect}>
        <div className={classes.left}>
          <div className={classes.title}>
            <Typography variant="subtitle1" color="primary.contrastText">
              {t('insights.panelViewResults.quadResultsScoreLabel')}
            </Typography>
          </div>
          <Typography noWrap display="block" variant="caption">
            {get(topic, 'data.quadOptions.xquestion')}
          </Typography>
          <Typography noWrap display="block" variant="caption">
            {get(topic, 'data.quadOptions.yquestion')}
          </Typography>
        </div>
        <div className={classes.right}>
          <div className={classes.title}>
            <div className={classes.scoreContainer}>
              <Typography variant="subtitle1" color="primary.contrastText">
                {scoreNow}
              </Typography>

              {!!tooltipData.prev && (
                <Typography
                  variant="caption"
                  className={scoreDelta >= 0 ? classes.deltaGreen : classes.deltaRed}
                >
                  {`${scoreDelta >= 0 ? '+' : ''}${scoreDelta}`}
                </Typography>
              )}
            </div>
          </div>

          <div className={classes.scoreContainer}>
            <Typography variant="caption">
              {round(get(tooltipData, 'selected.outcome.x', 0))}
            </Typography>

            {!!tooltipData.prev && (
              <Typography
                variant="caption"
                className={scoreDeltaX >= 0 ? classes.deltaGreen : classes.deltaRed}
              >
                {`${scoreDeltaX >= 0 ? '+' : ''}${scoreDeltaX}`}
              </Typography>
            )}
          </div>
          <div className={classes.scoreContainer}>
            <Typography variant="caption">
              {round(get(tooltipData, 'selected.outcome.y', 0))}
            </Typography>

            {!!tooltipData.prev && (
              <Typography
                variant="caption"
                className={scoreDeltaY >= 0 ? classes.deltaGreen : classes.deltaRed}
              >
                {`${scoreDeltaY >= 0 ? '+' : ''}${scoreDeltaY}`}
              </Typography>
            )}
          </div>
        </div>
      </TimelineTooltip>
    );
  }
}

QuadTimelineTooltip.propTypes = {
  classes: PropTypes.exact({
    title: PropTypes.string,
    left: PropTypes.string,
    right: PropTypes.string,
    deltaGreen: PropTypes.string,
    deltaRed: PropTypes.string,
    scoreContainer: PropTypes.string,
  }),
  tooltipData: PropTypes.object,
  cx: PropTypes.number,
  t: PropTypes.func,
  topic: PropTypes.object,
  rect: PropTypes.object,
  parentRect: PropTypes.object,
};

export default withTranslation()(
  withStyles(styles, { withTheme: true })(withBoundingRects(QuadTimelineTooltip)),
);
