import { createLogic } from 'redux-logic';
import * as types from 'state/ducks/tags/types';
import { instance as axios } from 'config/axios';
import { API_TAGS_URL, APIGW_URL } from 'state/constants/api';
import * as actions from 'state/ducks/tags/actions';
import { IState } from 'state/ducks/types';
import { AnyAction } from 'redux';
import { tagsActions } from 'state/ducks/tags/index';
import { createDefaultListQueryLogic } from 'state/defaultLogic';
import * as selectors from './selectors';
import { get } from 'lodash';
import { persistNodeData } from 'hooks/useNodeData';

export const getInstanceTagsLogic = createLogic({
  type: types.GET_INSTANCE_TAGS,
  process: async ({ getState, action }, dispatch, done) => {
    const { payload } = action as AnyAction;
    const state: IState = getState();
    axios
      .get(`${API_TAGS_URL}/${state.auth.tenantID}/listtags`, {
        params: { id: payload.id },
        headers: { Authorization: `Bearer ${state.auth.tokens.access_token}` },
      })
      .then(res => {
        dispatch(actions.instanceTagsFetchSuccess({ ...res.data.result, id: payload.id }));
      })
      .catch(() => {
        dispatch(actions.failedInstanceTags({ ...payload, id: payload.id }));
      })
      .then(() => done());
  },
});

export const getAllTagsLogic = createLogic({
  type: types.GET_ALL_TAGS,

  process: async ({ getState, action }, dispatch, done) => {
    const { payload } = action as AnyAction;
    const state: IState = getState();
    axios
      .get(`${API_TAGS_URL}/${state.auth.tenantID}/tags`, {
        headers: { Authorization: `Bearer ${state.auth.tokens.access_token}` },
      })
      .then(res => {
        dispatch(actions.allTagsFetchSuccess(res.data.result));
      })
      .catch(() => {
        dispatch(actions.failedInstanceTags(payload));
      })
      .then(() => done());
  },
});

export const addTagLogic = createLogic({
  type: types.ADD_TAG,
  process: async ({ getState, action }, dispatch, done) => {
    const { payload } = action as AnyAction;
    const state: IState = getState();
    const { name, color, requestID } = payload;

    const apiPayload: any = { name };
    if (color) {
      apiPayload.color = color;
    }

    axios
      .post(`${APIGW_URL}/tags/${state.auth.tenantID}/createtag`, apiPayload, {
        headers: { Authorization: `Bearer ${state.auth.tokens.access_token}` },
      })
      .then(response => {
        dispatch(actions.addedTag(response.data.result));
      })
      .catch(e => {
        const errorPayload = payload;
        errorPayload.requestID = requestID;
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});

export const deleteTagLogic = createLogic({
  type: types.DELETE_TAG,
  process: async ({ getState, action }, dispatch, done) => {
    const { payload } = action as AnyAction;
    const state: IState = getState();
    const { id, requestID } = payload;

    axios
      .post(
        `${APIGW_URL}/tags/${state.auth.tenantID}/deletetag`,
        { id },
        {
          headers: { Authorization: `Bearer ${state.auth.tokens.access_token}` },
        },
      )
      .then(response => {
        dispatch(actions.deletedTag(response.data.result));
      })
      .catch(e => {
        const errorPayload = payload;
        errorPayload.requestID = requestID;
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});

export const editTagLogic = createLogic({
  type: types.EDIT_TAG,
  process: async ({ getState, action }, dispatch, done) => {
    const { payload } = action as AnyAction;
    const state: IState = getState();
    const { id, name, color, requestID } = payload;

    axios
      .post(
        `${APIGW_URL}/tags/${state.auth.tenantID}/edittag`,
        { id, name, color },
        {
          headers: { Authorization: `Bearer ${state.auth.tokens.access_token}` },
        },
      )
      .then(result => {
        dispatch(actions.editedTag(result.data.result));
      })
      .catch(e => {
        const errorPayload = payload;
        errorPayload.requestID = requestID;
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});

export const linkTagLogic = createLogic({
  type: types.LINK_TAG,
  process: async ({ getState, action }, dispatch, done) => {
    const { payload } = action as AnyAction;
    const state: IState = getState();
    // eslint-disable-next-line camelcase
    const { id, target_id, requestID } = payload;

    axios
      .post(
        `${APIGW_URL}/tags/${state.auth.tenantID}/linknode`,
        // eslint-disable-next-line camelcase
        { id, target_id },
        {
          headers: { Authorization: `Bearer ${state.auth.tokens.access_token}` },
        },
      )
      .then(response => {
        const { result } = response.data;
        dispatch(
          actions.linkedTag({
            nodes: [{ id: result.id, name: result.name, color: result.color }],
            // eslint-disable-next-line camelcase
            id: target_id,
          }),
        );
        // eslint-disable-next-line camelcase
        dispatch(tagsActions.getInstanceTags({ id: target_id }));
      })
      .catch(e => {
        const errorPayload = payload;
        errorPayload.requestID = requestID;
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});

export const unlinkTagLogic = createLogic({
  type: types.UNLINK_TAG,
  process: async ({ getState, action }, dispatch, done) => {
    const { payload } = action as AnyAction;
    const state: IState = getState();
    // eslint-disable-next-line camelcase
    const { id, target_id, requestID } = payload;

    axios
      .post(
        `${APIGW_URL}/tags/${state.auth.tenantID}/removenode`,
        // eslint-disable-next-line camelcase
        { id, target_id },
        {
          headers: { Authorization: `Bearer ${state.auth.tokens.access_token}` },
        },
      )
      .then(response => {
        const { result } = response.data;
        dispatch(
          actions.unlinkedTag({
            nodes: [{ id: result.id, name: result.name, color: result.color }],
            // eslint-disable-next-line camelcase
            id: target_id,
          }),
        );
        // eslint-disable-next-line camelcase
        dispatch(tagsActions.getInstanceTags({ id: target_id }));
      })
      .catch(e => {
        const errorPayload = payload;
        errorPayload.requestID = requestID;
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});

const postListFetchHook = result => {
  // Cache the node data with react-query to avoid
  // hitting the API for rendering the nodes
  get(result, 'nodes', []).forEach(node => {
    persistNodeData(node);
  });
};

export const getNodesForTagLogic = createDefaultListQueryLogic({
  endpoint: {
    api: `${APIGW_URL}/graph`,
    method: 'neighbors',
    additionalProperties: { return_data: true },
  },
  actionTypes: {
    fetch: types.GET_LINKED_TAGS,
    success: types.LINKED_TAGS_FETCH_SUCCESS,
    fail: types.FAILED_LINKED_TAGS,
  },
  selector: selectors.selectLinkedToTag,
  sliceName: 'tags',
  onSuccess: postListFetchHook,
});
