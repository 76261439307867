import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import withStyles from '@mui/styles/withStyles';
import { withBoundingRects } from '@visx/bounds';
import Typography from '@mui/material/Typography';
import { round } from 'config/helpers';
import TimelineTooltip from './TimelineTooltip';

const styles = theme => ({
  left: {
    maxWidth: 190,
    marginRight: theme.spacing(2),
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  right: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  deltaGreen: {
    marginLeft: theme.spacing(),
  },
  deltaRed: {
    marginLeft: theme.spacing(),
  },
  scoreContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    '& span': {
      color: theme.palette.background.box,
    },
    '& $deltaGreen': {
      color: theme.palette.confidence.green,
    },
    '& $deltaRed': {
      color: theme.palette.confidence.red,
    },
  },
});

class MultichoiceTimelineTooltip extends React.PureComponent {
  render() {
    const { classes, topic, tooltipData, cx, rect, parentRect } = this.props;
    return (
      <TimelineTooltip cx={cx} rect={rect} parentRect={parentRect}>
        <div className={classes.left}>
          {Object.keys(get(topic, 'data.choiceOptions', {})).map(i => (
            <Typography
              key={`multichoice-tooltip-label-${i}`}
              noWrap
              display="block"
              variant="caption"
            >
              {topic.data.choiceOptions[i]}
            </Typography>
          ))}
        </div>
        <div className={classes.right}>
          {Object.keys(get(topic, 'data.choiceOptions', {})).map(i => {
            const score = round(get(tooltipData, 'selected.outcome', {})[i] || 0);
            const scoreDelta = round(score - (get(tooltipData, 'prev.outcome', {})[i] || 0));
            return (
              <div key={`multichoice-score-${i}`} className={classes.scoreContainer}>
                <Typography noWrap display="block" variant="caption">
                  {`${score}%`}
                </Typography>
                {!!tooltipData.prev && (
                  <Typography
                    variant="caption"
                    className={scoreDelta >= 0 ? classes.deltaGreen : classes.deltaRed}
                  >
                    {`${scoreDelta >= 0 ? '+' : ''}${scoreDelta}`}
                  </Typography>
                )}
              </div>
            );
          })}
        </div>
      </TimelineTooltip>
    );
  }
}

MultichoiceTimelineTooltip.propTypes = {
  classes: PropTypes.exact({
    left: PropTypes.string,
    right: PropTypes.string,
    deltaGreen: PropTypes.string,
    deltaRed: PropTypes.string,
    scoreContainer: PropTypes.string,
  }),
  tooltipData: PropTypes.object,
  cx: PropTypes.number,
  topic: PropTypes.object,
  rect: PropTypes.object,
  parentRect: PropTypes.object,
};

export default withStyles(styles, { withTheme: true })(
  withBoundingRects(MultichoiceTimelineTooltip),
);
