import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { withParams, withNavigation, withLocation } from 'withRouter';
import { peopleSelectors } from 'state/ducks/people';
import { facilitationActions, facilitationSelectors } from 'state/ducks/facilitation';
import { objectivesSelectors, objectivesActions } from 'state/ducks/objectives';
import { openViewTeamPanel } from 'config/ModalProvider/helpers';
import TeamPage from './TeamPage';
import { appstatusSelectors } from 'state/ducks/appstatus';
import { isEnabled } from 'config/flags';

const fetchData = (props, teamId, page, subpage, force) => {
  if (!!teamId) {
    if (!!props.isMember || !!props.isManager) {
      props.dispatch(facilitationActions.listSessions({ teamID: teamId, force }));
    }
    if (page === 'objectives' && !!subpage) {
      props.dispatch(
        objectivesActions.getTeamObjectives({
          teamId,
          stperiod: subpage,
          force,
        }),
      );
    }
  }
};

class TeamPageContainer extends Component {
  componentDidMount() {
    fetchData(this.props, this.props.teamId, this.props.page, this.props.subpage, true);
  }

  componentDidUpdate() {
    fetchData(this.props, this.props.teamId, this.props.page, this.props.subpage, false);
    const { page, teamIsInvitable, isManager, members } = this.props;
    if (page === 'managemembers' && !!members.ok && (!teamIsInvitable || !isManager)) {
      this.navigate('overview');
    }
  }

  componentWillUnmount() {
    clearTimeout(this.navigateTimeout);
  }

  fetchSessions = nextKey => {
    const { teamId, dispatch } = this.props;
    dispatch(facilitationActions.listSessions({ teamID: teamId, startkey: nextKey }));
  };

  navigate = (tab, subpage) => {
    const {
      navigate,
      location,
      teamId,
      currentPeriod,
      usePeriodicGameplans,
      isDialog = false,
    } = this.props;
    if (!isDialog) {
      let newPath = `/home/teams/${teamId}/${tab}`;
      if (tab === 'objectives' || (usePeriodicGameplans && tab === 'gameplan')) {
        newPath = `${newPath}/${subpage || currentPeriod}`;
      } else if (!!subpage) {
        newPath = `${newPath}/${subpage}`;
      }
      navigate(newPath);
    } else {
      openViewTeamPanel(
        navigate,
        location,
        teamId,
        tab,
        tab === 'objectives' || (usePeriodicGameplans && tab === 'gameplan')
          ? subpage || currentPeriod
          : subpage,
      );
    }
  };

  render() {
    const {
      teamId,
      teamName,
      isManager,
      isManagerOfManager,
      isChangeManager,
      isMember,
      facilitationSessions,
      currentPeriod,
      periodConfig,
      page,
      subpage,
      teamIsInvitable,
      isDialog,
      isProvisioned,
      onClose,
      usePeriodicGameplans,
    } = this.props;

    /*
      Handle legacy link to objectives tab without a period selected
      By redirecting to the active period once that information is available
      timeout needed to prevent "Cannot update a component (`%s`) while rendering a different component"
    */
    if (
      (page === 'objectives' || (usePeriodicGameplans && page === 'gameplan')) &&
      !subpage &&
      !!currentPeriod
    ) {
      this.navigateTimeout = setTimeout(() => {
        this.navigate(page);
      });
    }

    if (page === 'managemembers' && (!teamIsInvitable || !isManager)) {
      return null;
    }

    return (
      <TeamPage
        key={`team-page-${teamId}`}
        teamId={teamId}
        teamName={teamName}
        page={page}
        subpage={subpage}
        isManager={isManager}
        isManagerOfManager={isManagerOfManager}
        isMember={isMember}
        isChangeManager={isChangeManager}
        facilitationSessions={facilitationSessions}
        fetchSessions={this.fetchSessions}
        stperiod={currentPeriod}
        navigate={this.navigate}
        periodConfig={periodConfig}
        teamIsInvitable={teamIsInvitable}
        isDialog={isDialog}
        onClose={onClose}
        isProvisioned={isProvisioned}
        usePeriodicGameplans={usePeriodicGameplans}
      />
    );
  }
}

TeamPageContainer.propTypes = {
  teamId: PropTypes.string,
  teamName: PropTypes.string,
  navigate: PropTypes.func,
  location: PropTypes.object,
  dispatch: PropTypes.func,
  isManager: PropTypes.bool,
  isManagerOfManager: PropTypes.bool,
  isChangeManager: PropTypes.bool,
  isMember: PropTypes.bool,
  isProvisioned: PropTypes.bool,
  facilitationSessions: PropTypes.object,
  // We need this as a prop to make sure we re-render when
  // the OKR data changes
  // eslint-disable-next-line react/no-unused-prop-types
  objectives: PropTypes.object,
  currentPeriod: PropTypes.string,
  page: PropTypes.string,
  subpage: PropTypes.string,
  isDialog: PropTypes.bool,
  onClose: PropTypes.func,
  periodConfig: PropTypes.object,
  teamIsInvitable: PropTypes.bool,
  members: PropTypes.object,
  usePeriodicGameplans: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
  const subpage = get(ownProps, 'params.subpage') || ownProps.subpage;
  const currentPeriod = objectivesSelectors.selectCurrentPeriod(state.main.objectives);
  const appstatus = appstatusSelectors.selectStatus(state.main.appstatus);
  const usePeriodicGameplans = isEnabled('PERIODIC-GAMEPLANS', appstatus);

  return {
    isChangeManager: state.auth.isChangeManager,
    usePeriodicGameplans: usePeriodicGameplans,
    teamIsInvitable: peopleSelectors.canRequestToJoin(state.main.people, ownProps.teamId),
    isManager:
      state.auth.userID === peopleSelectors.selectTeamManager(state.main.people, ownProps.teamId),
    isManagerOfManager: peopleSelectors
      .selectManagedTeams(state.main.people, state.auth.myTeams, state.auth.userID)
      .includes(ownProps.teamId),
    isMember: peopleSelectors.isMember(
      state.main.people,
      ownProps.teamId,
      state.auth.userID,
      state.auth.teamID,
    ),
    facilitationSessions: facilitationSelectors.selectTeamSessionList(
      state.main.facilitation,
      ownProps.teamId,
    ),
    objectives: objectivesSelectors.selectTeamObjectives(
      state.main.objectives,
      ownProps.teamId,
      subpage || currentPeriod,
    ),
    currentPeriod,
    periodConfig: objectivesSelectors.selectPeriodConfig(state.main.objectives),
    teamName: peopleSelectors.selectTeamName(state.main.people, ownProps.teamId),
    page: get(ownProps, 'params.page') || ownProps.page,
    members: peopleSelectors.selectTeamMembers(state.main.people, ownProps.teamId),
    subpage,
    isProvisioned: state?.auth?.isProvisioned,
  };
};

export default withLocation(
  withNavigation(withParams(connect(mapStateToProps)(TeamPageContainer))),
);
