import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';
import { connect } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarFilledIcon from '@mui/icons-material/Star';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import ButtonBase from '@mui/material/ButtonBase';
import { peopleActions, peopleSelectors } from 'state/ducks/people';
import PersonasAvatar from 'Components/Common/PersonasAvatar';
import SavingIndicator from 'Components/Common/SavingIndicatorv2';
import PageTitleBar from 'Components/Library/PageTitleBar';
import { withNavigation, withLocation } from 'withRouter';
import FeatureFlag from 'Components/Library/FeatureFlag';
import RelatedTeams from './RelatedTeams';
import TeamMembers from './TeamMembers';

const styles = theme => ({
  hoverItem: {
    ...theme.shape,
    padding: 8,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      '& $icon': {
        transform: 'scale(1.15)',
      },
    },
    cursor: 'pointer',
    color: theme.palette.primary[600],
  },
  rowItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    flexShrink: 0,
    height: 24,
    minWidth: 2,
    padding: theme.spacing(0.5),
    '& div,span': {
      marginRight: 4,
    },
  },
  button: {
    height: 32,
    width: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.text.disabled,
  },
  icon: {
    fontSize: '16px',
  },
  pendoContainer: {
    marginRight: theme.spacing(),
  },
  savingIndicator: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
});

class Headline extends Component {
  state = {
    requestID: '',
    submitStatus: 0,
    submitted: false,
    errorText: '',
  };

  static getDerivedStateFromProps(nextProps, state) {
    if (state.submitted === true) {
      if (state.requestID in nextProps.actionlog) {
        if (nextProps.actionlog[state.requestID].result === 'ok') {
          return {
            submitStatus: 1,
            submitted: false,
            errorText: '',
          };
        }
        return {
          submitStatus: -1,
          submitted: false,
          errorText: nextProps.actionlog[state.requestID].message,
        };
      }
    }
    return null;
  }

  componentDidMount() {
    this.fetchData(true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.teamId !== this.props.teamId) {
      this.fetchData();
    }
  }

  fetchData = (force = false) => {
    const { dispatch, teamId, teamIsInvitable } = this.props;
    dispatch(peopleActions.getTeam({ teamId }));
    if (teamIsInvitable) {
      dispatch(peopleActions.getMyJoinRequests({ force }));
    }
  };

  followTeam = () => {
    const { teamId, dispatch } = this.props;
    const { submitted } = this.state;
    if (!submitted) {
      const requestID = nanoid(10);
      this.setState({ submitted: true, submitStatus: 0, requestID });
      dispatch(peopleActions.followTeam({ teamID: teamId, requestID }));
    }
  };

  unFollowTeam = () => {
    const { teamId, dispatch } = this.props;
    const { submitted } = this.state;
    if (!submitted) {
      const requestID = nanoid(10);
      this.setState({ submitted: true, submitStatus: 0, requestID });
      dispatch(peopleActions.unFollowTeam({ teamID: teamId, requestID }));
    }
  };

  navigateToTeam = id => {
    const { navigate, location, page, usePeriodicGameplans } = this.props;
    let url = location.pathname + location.hash;
    if (page === 'objectives' || (usePeriodicGameplans && page === 'gameplan')) {
      // remove three last elements of the url (teamID, page and period)
      let urlList = url.split('/');
      const period = urlList.pop();
      urlList = urlList.slice(0, -2);
      url = urlList.join('/');
      // add the correct new teamID back as well as the page and period
      navigate(`${`${url}/${id}`}/${page}/${period}`);
    } else {
      // remove two last elements of the url (teamID and page)
      const urlList = url.split('/').slice(0, -2);
      url = urlList.join('/');
      // add the correct new teamID back as well as the page
      navigate(`${`${url}/${id}`}/${page}`);
    }
  };

  renderFollowButton = () => {
    const { t, classes, followedTeams, teamId, isMember } = this.props;
    const { submitStatus, submitted, errorText } = this.state;
    let followButton;
    if (!!followedTeams && !!followedTeams.ok) {
      if (followedTeams.data.includes(teamId)) {
        followButton = (
          <Tooltip
            title={t('teamHeaderInformation.unfollowTeamTooltip')}
            onClick={this.unFollowTeam}
          >
            <ButtonBase className={clsx([classes.rowItem, classes.hoverItem, classes.button])}>
              <StarFilledIcon
                color="secondary"
                className={classes.icon}
                name="team-information-follow-button-following"
              />
            </ButtonBase>
          </Tooltip>
        );
      } else if (isMember) {
        followButton = (
          <Tooltip title={t('teamHeaderInformation.memberTooltip')}>
            <ButtonBase className={clsx([classes.rowItem, classes.hoverItem, classes.button])}>
              <StarFilledIcon
                color="disabled"
                className={classes.icon}
                name="team-information-follow-button-member"
              />
            </ButtonBase>
          </Tooltip>
        );
      } else {
        followButton = (
          <Tooltip title={t('teamHeaderInformation.followTeamTooltip')} onClick={this.followTeam}>
            <ButtonBase className={clsx([classes.rowItem, classes.hoverItem, classes.button])}>
              <StarBorderIcon
                color="action"
                className={classes.icon}
                name="team-information-follow-button-cta"
              />
            </ButtonBase>
          </Tooltip>
        );
      }
    }
    return (
      <>
        <SavingIndicator
          submitStatus={submitStatus}
          errorText={errorText}
          submitted={submitted}
          className={classes.savingIndicator}
        />
        {followButton}
      </>
    );
  };

  render() {
    const { t, classes, teamName, teamId, page, isDialog, onClose, fab, tabs, isProvisioned } =
      this.props;

    return (
      <PageTitleBar
        name="team-page-titlebar"
        titleIcon={<PersonasAvatar sub={teamId} size="tiny" type="team" />}
        titleStr={teamName}
        context={<TeamMembers classes={classes} teamId={teamId} />}
        actions={
          <>
            <RelatedTeams classes={classes} teamId={teamId} onTeamSelected={this.navigateToTeam} />
            {isProvisioned && this.renderFollowButton()}
            <FeatureFlag flag="PENDO">
              <Tooltip title={t('general.pendo')}>
                <ButtonBase
                  className={clsx([
                    classes.hoverItem,
                    classes.rowItem,
                    classes.button,
                    classes.pendoContainer,
                    `tg-pendo-teampage-${page}`,
                  ])}
                >
                  <InfoIcon className={classes.icon} />
                </ButtonBase>
              </Tooltip>
            </FeatureFlag>
            {isDialog && (
              <IconButton
                onClick={onClose}
                size="small"
                sx={{ mr: 1 }}
                name="dialog-team-view-secondary-action"
                aria-label={t('general.close')}
              >
                <CloseIcon />
              </IconButton>
            )}
          </>
        }
        fab={fab}
        tabs={tabs}
      />
    );
  }
}

Headline.propTypes = {
  classes: PropTypes.exact({
    rowItem: PropTypes.string,
    savingIndicator: PropTypes.string,
    buttonGreen: PropTypes.string,
    buttonRed: PropTypes.string,
    buttonAmber: PropTypes.string,
    hoverItem: PropTypes.string,
    icon: PropTypes.string,
    pendoContainer: PropTypes.string,
    button: PropTypes.string,
  }),
  isMember: PropTypes.bool,
  teamName: PropTypes.string,
  dispatch: PropTypes.func,
  teamId: PropTypes.string,
  t: PropTypes.func,
  followedTeams: PropTypes.object,
  actionlog: PropTypes.object,
  teamIsInvitable: PropTypes.bool,
  page: PropTypes.string,
  navigate: PropTypes.func,
  location: PropTypes.object,
  isDialog: PropTypes.bool,
  isProvisioned: PropTypes.bool,
  onClose: PropTypes.func,
  fab: PropTypes.node,
  tabs: PropTypes.node,
  usePeriodicGameplans: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => ({
  teamName: peopleSelectors.selectTeamName(state.main.people, ownProps.teamId),
  followedTeams: peopleSelectors.selectFollowedTeams(state.main.people),
  actionlog: state.main.people.actionlog,
  teamIsInvitable: peopleSelectors.canRequestToJoin(state.main.people, ownProps.teamId),
  isMember: peopleSelectors.isMember(
    state.main.people,
    ownProps.teamId,
    state.auth.userID,
    state.auth.teamID,
  ),
  isProvisioned: state.auth.isProvisioned,
  myJoinRequests: peopleSelectors.selectMyJoinRequests(state.main.people),
  teamJoinRequests: peopleSelectors.selectTeamJoinRequests(state.main.people, ownProps.teamId),
});

export default withLocation(
  withNavigation(connect(mapStateToProps)(withTranslation()(withStyles(styles)(Headline)))),
);
