export const KR_DESCRIPTION_MAX_LENGTH = 768;
export const KR_RT_DESCRIPTION_MAX_LENGTH = 4096;
export const KR_NAME_MAX_LENGTH = 128;
export const KR_UNIT_MAX_LENGTH = 64;
export const OBJ_DESCRIPTION_MAX_LENGTH = 768;
export const OBJ_RT_DESCRIPTION_MAX_LENGTH = 4096;
export const OBJ_REFLECTION_MAX_LENGTH = 5000;
export const OBJ_NAME_MAX_LENGTH = 128;

export const KR_OUTDATED_TRESHOLD = 14;

export const KR_UPDATE_MSG_MAX_LENGTH = 768;
export const NUMBER_REASONABLE_MAX = 99999999999999;
export const NUMBER_REASONABLE_MIN = -99999999999999;

export const SRV_COMMENT_MAX_LENGTH = 1024;
export const TPC_SRV_MAX_TTL = 60;
export const TPC_SRV_MIN_TTL = 1;
export const TPC_MAX_QUESTION_LENGTH = 300;
export const TPC_MAX_DESCRIPTION_LENGTH = 768;
export const TPC_MULTI_MAX_QUESTIONS = 10;
export const TPC_SLIDERS_MAX_QUESTIONS = 40;
export const TPC_SRV_MAX_INPUT_LABEL_LENGTH = 64;
export const TPC_MAX_SLIDER_QUESTION_LENGTH = 512;
export const TPC_MAX_SLIDER_LABEL_LENGTH = 150;

export const CP_MISSION_MAX_LENGTH = 256;
export const CP_NAME_MAX_LENGTH = 128;

export const ADMIN_TEAM_ID_MAX_LENGTH = 40;

// Context
export const CTX_CARD_MAX_ELEMENTS = 60;
export const CTX_ELEMENT_MAX_STR_LENGTH = 5000;
export const CTX_ELEMENT_MAX_SLIDER_STR_LENGTH = 48;
export const CTX_ELEMENT_MAX_URL_STR_LENGTH = 1024;
export const CTX_IMAGE_UPLOAD_MAX_SIZE = 2000000;

// DFAC
export const DFAC_MAX_QUESTIONS_IN_SESS = 20;
export const DFAC_MAX_TITLE_STR_LENGTH = 512;
export const DFAC_MAX_DESCRIPTION_STR_LENGTH = 1024;
export const DFAC_MAX_QUESTION_STR_LENGTH = 512;

// Custom filter
export const CUSTOM_FILTER_NAME_MAX_LENGTH = 64;

// Commitments
export const COM_NAME_MAX_LENGTH = 128;
export const COM_DESCRIPTION_MAX_LENGTH = 4096;
export const COM_STATUS_COLORS = {
  PENDING: 'warning',
  IN_PROGRESS: 'secondary',
  HELP_NEEDED: 'error',
  COMPLETED: 'primary',
};
export const INTERLOCK_STATUS_COLORS = {
  UNRESOLVED: 'error',
  RESOLVED: 'primary',
};
export const COM_MAX_CONTRIBUTORS = 20;
export const COM_ID_MAX_LENGTH = 40;
export const COM_OWNER_MAX_LENGTH = 40;
export const COM_TYPE_MAX_LENGTH = 5;

export const EVENT_TYPE_COMMENT = 'COMMENT';
export const EVENT_TYPE_ACTIVITYLOG = 'ACTIVITYLOG';
export const EVENT_TYPE_KREVENT = 'KREVENT';
export const EVENT_TYPE_STATE_SNAPSHOT = 'STATE_SNAPSHOT';
export const EVENT_TYPE_RELATIONS_CHANGESET = 'RELATIONS_CHANGESET';

export const NODE_TYPE_USER = 'USER';
export const NODE_TYPE_TEAM = 'TEAM';
export const NODE_TYPE_COMMITMENT = 'COMMITMENT';
export const NODE_TYPE_INTERLOCK = 'INTERLOCK';
export const NODE_TYPE_KR = 'KEYRESULT';
export const NODE_TYPE_OBJECTIVE = 'OBJECTIVE';
export const CONTRIBUTOR_TYPES = {
  USER: 'USER',
  TEAM: 'TEAM',
  OWNER: 'OWNER',
  CREATOR: 'CREATOR',
};
export const RELATION_DOMAIN_TYPE = 'RELATION';

export const COM_CONTRIBUTOR_STATUS_COLORS_THEME = {
  'NO-STATUS': 'primary',
  'SOME-HICKUPS': 'warning',
  'HELP-NEEDED': 'error',
  'ALL-GOOD': 'secondary',
};

export const MAX_DAYS_BETWEEN_BEFORE_DATE_DISPLAY = 13;

// gameplans:

export const GP_WIDGET_TYPE_OKRS = 'OKRS';
export const GP_WIDGET_TYPE_COMMITMENTS = 'COMMITMENTS';
export const GP_WIDGET_TYPE_VISION_AND_PURPOSE = 'VISION_AND_PURPOSE';
export const GP_WIDGET_TYPE_VALUES_AND_DRIVERS = 'VALUES_AND_DRIVERS';
export const GP_WIDGET_TYPE_WILL_NOT_DO = 'WILL_NOT_DO';
export const GP_WIDGET_TYPE_CUSTOM_CARD = 'CUSTOM_CARD';
export const GP_WIDGET_TYPE_INTERLOCKS = 'INTERLOCKS';
export const GP_WIDGET_TYPE_METHODS = 'METHODS';

export const TAG_MAX_LENGTH = 64;
export const EXT_LINK_MAX_NAME_LENGTH = 128;
export const EXT_LINK_MAX_URL_LENGTH = 4096;
