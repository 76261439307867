import ThumbIcon from '@mui/icons-material/ThumbUp';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { VoteEntryProps } from './types';

function ClassicVoteEntry(props: VoteEntryProps) {
  const { questionIndex, candidateIndex, ownvotes, candidateItem, onVoteToggle } = props;

  return (
    <FormControlLabel
      key={`live-dfac-panel-question-input-${questionIndex}-${candidateIndex}`}
      id={`live-dfac-panel-question-input-${questionIndex}-${candidateIndex}`}
      sx={{
        alignSelf: 'stretch',
        mb: 1,
        mr: 0,
      }}
      control={
        <Checkbox
          icon={<ThumbIcon color="action" fontSize="small" />}
          checkedIcon={<ThumbIcon color="secondary" name="thumbsupicon" fontSize="small" />}
          checked={ownvotes.includes(candidateItem.input_id)}
          onChange={() => onVoteToggle(candidateItem.input_id)}
          sx={{
            color: 'action.active',
            mr: 0.5,
          }}
        />
      }
      label={
        <Typography
          variant="body1"
          sx={{
            color: ownvotes.includes(candidateItem.input_id) ? 'secondary.main' : 'text.primary',
            wordWrap: 'break-word',
            wordBreak: 'normal',
            overflow: 'hidden',
            borderRadius: 1,
            bgcolor: 'background.paper',
            justifySelf: 'stretch',
            flexGrow: 5,
            lineHeight: '1.25rem',
            padding: '12px',
            paddingTop: '8ox',
            paddingBottom: '8px',
          }}
        >
          {`${candidateItem.input}`}
        </Typography>
      }
    />
  );
}

export default ClassicVoteEntry;
