import React from 'react';
import { Typography } from '@mui/material';
import Container from 'Components/Library/BaseComponents/Container';
import Button from 'Components/Library/BaseComponents/Button';

// TODO: this will be added later as a step between getting the assistant results
// and saving to game plan

interface WarningUiProps {
  onClose(): void;
  openSuggesterUi(): void;
}

function WarningUi(props: WarningUiProps) {
  const { openSuggesterUi, onClose } = props;

  const close = () => {
    onClose();
  };
  return (
    <Container alignItems="stretch" p={1}>
      <Typography variant="h6">Are you sure?</Typography>
      <Typography variant="body2">
        Your team has an existing game plan, using this feature would overwrite existing content.
      </Typography>
      <Typography variant="body2">
        Your existing OKRs, Commitments and Interlocks will be kept.
      </Typography>

      <Container direction="row" sx={{ pt: 2 }} justifyContent="flex-end">
        <Button variant="text" onClick={close}>
          Close
        </Button>
        <Button color="secondary" onClick={openSuggesterUi}>
          I'm sure
        </Button>
      </Container>
    </Container>
  );
}

export default WarningUi;
