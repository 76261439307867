/* eslint-disable camelcase */
import { createLogic, createDefaultListQueryLogic } from 'state/defaultLogic';
import { instance as axios } from 'config/axios';
import {
  onCardAdded,
  onGameplanCreated,
  onWidgetEdited,
  onWidgetVisibilityChange,
} from 'state/ducks/gameplans/logic-handlers';
import { APIGW_URL } from '../../constants/api';
import * as types from './types';
import * as actions from './actions';
import * as selectors from './selectors';

const SLICE_NAME = 'gameplans';

export const editWidgetLogic = createLogic({
  type: types.EDIT_GAMEPLAN_WIDGET,
  process: async ({ getState, action }, dispatch, done) => {
    const { requestID, domain_id, gameplan_id, widget_id, elements, name } = action.payload;
    const payload = {
      requestID,
      widget_id,
    };

    payload.elements = elements.map(el => {
      const element = { ...el };
      if ('content' in element) {
        element.content = JSON.stringify(element.content);
      }
      if (element.element_type === 'IMAGE' && element.src) {
        element.b64str = element.src;
        delete element.src;
      }
      return element;
    });

    if (name) {
      payload.name = name;
    }

    axios
      .post(`${APIGW_URL}/gameplans/${getState().auth.tenantID}/editwidget`, payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        onWidgetEdited(res, requestID, gameplan_id, domain_id, dispatch);
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        errorPayload.requestID = requestID;
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});

export const deleteCardLogic = createLogic({
  type: types.DELETE_GAMEPLAN_WIDGET,
  process: async ({ getState, action }, dispatch, done) => {
    const { requestID, widget_id, domain_id } = action.payload;
    const payload = {
      requestID,
      widget_id,
    };

    axios
      .post(`${APIGW_URL}/gameplans/${getState().auth.tenantID}/removecard`, payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        const result = {
          requestID,
          domain_id,
          gameplan: res.data.result,
        };
        dispatch({ type: types.DELETED_GAMEPLAN_WIDGET, payload: result });
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        errorPayload.requestID = requestID;
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});

export const createBlankCardLogic = createLogic({
  type: types.CREATE_BLANK_CARD,
  process: async ({ getState, action }, dispatch, done) => {
    const { requestID, domain_id, gameplan_id, elements, name } = action.payload;
    const payload = {
      requestID,
      gameplan_id,
      name,
    };

    payload.elements = elements.map(el => {
      const element = { ...el };
      if ('content' in element) {
        element.content = JSON.stringify(element.content);
        delete element.id;
      }
      if (element.element_type === 'IMAGE') {
        element.b64str = element.src;
        delete element.src;
      }
      return element;
    });

    axios
      .post(`${APIGW_URL}/gameplans/${getState().auth.tenantID}/addcard`, payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        onCardAdded(requestID, gameplan_id, domain_id, res, dispatch);
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        errorPayload.requestID = requestID;
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});

export const editWidgetVisibilityLogic = createLogic({
  type: types.EDIT_GAMEPLAN_WIDGETS_VISIBILITY,
  process: async ({ getState, action }, dispatch, done) => {
    const { requestID, gameplan_id, widget_visibility } = action.payload;
    const payload = {
      requestID,
      widget_visibility,
      gameplan_id,
    };

    axios
      .post(`${APIGW_URL}/gameplans/${getState().auth.tenantID}/setwidgetvisibility`, payload, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        onWidgetVisibilityChange(res, requestID, dispatch);
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        errorPayload.requestID = requestID;
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});

export const editWidgetOrderLogic = createLogic({
  type: types.MOVE_GAMEPLAN_WIDGET,
  process: async ({ getState, action }, dispatch, done) => {
    const { requestID, gameplan_id, widget_id, position } = action.payload;
    const payload = {
      requestID,
      widget_id,
      gameplan_id,
      position,
    };

    axios
      .post(
        `${APIGW_URL}/gameplans/${getState().auth.tenantID}/movewidget`,
        { ...payload },
        {
          headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
        },
      )
      .then(res => {
        const { result } = res.data;
        result.requestID = requestID;
        dispatch({ type: types.MOVED_GAMEPLAN_WIDGET, payload: result });
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        errorPayload.requestID = requestID;
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});

export const getTeamGameplansLogic = createDefaultListQueryLogic({
  endpoint: { api: `${APIGW_URL}/gameplans`, method: 'getgameplans' },
  actionTypes: {
    fetch: types.FETCH_GAMEPLANS,
    success: types.RECEIVED_GAMEPLANS,
    fail: types.FAILED_GAMEPLANS,
  },
  selector: selectors.selectGamePlans,
  sliceName: SLICE_NAME,
  domainIdKey: 'domain_id',
});

export const createGameplanLogic = createLogic({
  type: types.CREATE_GAMEPLAN,
  process: async ({ getState, action }, dispatch, done) => {
    const {
      requestID,
      domain_id,
      widget_visibility,
      use_periodic_gameplans,
      initial_content,
      gameplan_id,
      ltperiod,
      stperiod,
    } = action.payload;

    const payload = {
      requestID,
      domain_id,
    };
    let endPoint = 'creategameplan';
    if (initial_content) {
      endPoint = 'creategameplanwithcontent';
      payload.ltperiod = ltperiod;
      payload.stperiod = stperiod;
      payload.widget_content = initial_content;
      payload.gameplan_id = gameplan_id;
    } else {
      payload.initial_widget_visibility = widget_visibility;
      if (ltperiod && stperiod) {
        payload.ltperiod = ltperiod;
        payload.stperiod = stperiod;
      }
    }

    if (use_periodic_gameplans) {
      payload.use_periodic_gameplans = true;
    }

    axios
      .post(
        `${APIGW_URL}/gameplans/${getState().auth.tenantID}/${endPoint}`,
        { ...payload },
        {
          headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
        },
      )
      .then(res => {
        onGameplanCreated(res, requestID, domain_id, dispatch);
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        errorPayload.requestID = requestID;
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});

export const copyGameplanLogic = createLogic({
  type: types.COPY_GAMEPLAN,
  process: async ({ getState, action }, dispatch, done) => {
    const {
      requestID,
      source_gameplan_id,
      target_gameplan_id,
      widgets_to_copy,
      ltperiod,
      stperiod,
    } = action.payload;

    const pl = { requestID, widgets_to_copy };
    let method;
    if (target_gameplan_id) {
      pl.target_gameplan_id = target_gameplan_id;
      pl.source_gameplan_id = source_gameplan_id;
      method = 'copywidgets';
    } else {
      pl.gameplan_id = source_gameplan_id;
      pl.ltperiod = ltperiod;
      pl.stperiod = stperiod;
      method = 'copytoperiod';
    }
    axios
      .post(`${APIGW_URL}/gameplans/${getState().auth.tenantID}/${method}`, pl, {
        headers: { Authorization: `Bearer ${getState().auth.tokens.access_token}` },
      })
      .then(res => {
        console.log('res: ', res);
        onGameplanCreated(res, requestID, res?.data?.result?.domain_id, dispatch);
      })
      .catch(e => {
        const errorPayload = { ...action.payload };
        errorPayload.requestID = requestID;
        if (e.response && e.response.data && e.response.data.error) {
          errorPayload.error = e.response.data.error;
        }
        dispatch(actions.errorTryAgainLater(errorPayload));
      })
      .then(() => done());
  },
});
