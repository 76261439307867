import AiGameplanSuggestionsFlow from './index';
import { useState } from 'react';
import Button from 'Components/Library/BaseComponents/Button';
import { useTranslation } from 'react-i18next';
import { AutoFixHigh } from '@mui/icons-material';

interface AskAiButtonProps {
  teamId: string;
  stperiod: string;
  ltperiod: string;
  gameplanId?: string;
  onSuccess(): void;
}

function AskAiButton(props: AskAiButtonProps) {
  const { teamId, stperiod, ltperiod, gameplanId, onSuccess } = props;
  const [flowOpen, setFlowOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <Button
        color="secondary"
        size="large"
        startIcon={<AutoFixHigh />}
        onClick={() => setFlowOpen(true)}
      >
        {t('ai.askAiChip')}
      </Button>
      {flowOpen && (
        <AiGameplanSuggestionsFlow
          teamId={teamId}
          stperiod={stperiod}
          ltperiod={ltperiod}
          gameplanId={gameplanId}
          onSuccess={onSuccess}
          onClose={() => setFlowOpen(false)}
        />
      )}
    </>
  );
}

export default AskAiButton;
