import React from 'react';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { objectivesSelectors } from 'state/ducks/objectives';

import ChipAutocomplete from 'Components/Library/Shared/ChipAutocomplete';
import DataObjectChip from 'Components/Library/DataObjectChip';

export const isPast = periodStart => {
  if (!periodStart) {
    return false;
  }

  const d = new Date();
  const dStr = d.toISOString().substring(0, 10);
  return periodStart < dStr;
};

export const groupSuggestionsByDate = suggestions => {
  if (!suggestions?.length) {
    return [];
  }
  const current = [];
  const next = [];
  const past = [];
  suggestions.forEach(suggestion => {
    const isCurrent = !isPast(suggestion.periodEnd) && isPast(suggestion.periodStart);

    if (isCurrent) {
      current.push(suggestion);
    }

    if (!isPast(suggestion.periodStart)) {
      next.push(suggestion);
    }

    if (isPast(suggestion.periodEnd)) {
      past.push(suggestion);
    }
  });

  const currentSection = {
    title: 'current',
    suggestions: current,
  };
  const nextSection = {
    title: 'next',
    suggestions: next,
  };
  const pastSection = {
    title: 'past',
    suggestions: past,
  };

  return [currentSection, nextSection, pastSection];
};

function SearchOverlay(props) {
  const { showPast = false, filterFunc, onSelect, name } = props;
  const [searchString, setSearchString] = React.useState('');
  const { t } = useTranslation();

  const sortedPeriods = useSelector(
    state => objectivesSelectors.selectPeriodsAsSortedLists(state.main.objectives),
    // { stabilityCheck: 'never' },
  );

  // The original array is an array of the long term periods, flatten it
  let periodList = [];
  for (const ltPeriod of sortedPeriods) {
    periodList.push({
      displayName: ltPeriod.displayName,
      ltPeriodId: ltPeriod.id,
      periodEnd: ltPeriod.periodEnd,
      periodStart: ltPeriod.periodStart,
      text: ltPeriod.displayName,
    });
    const sortedStPeriods = orderBy(
      Object.keys(ltPeriod.stperiods).map(stPeriodId => ({
        displayName: ltPeriod.stperiods[stPeriodId].displayName,
        stPeriodId,
        ltPeriodId: ltPeriod.id,
        periodEnd: ltPeriod.stperiods[stPeriodId].periodEnd,
        periodStart: ltPeriod.stperiods[stPeriodId].periodStart,
        text: ltPeriod.stperiods[stPeriodId].displayName,
      })),
      ['periodEnd'],
      ['asc'],
    );
    periodList = periodList.concat(sortedStPeriods);
  }

  // Filter past periods
  periodList = periodList.filter(p => showPast || !isPast(p.periodEnd));
  // Apply filter func
  if (!!filterFunc) {
    periodList = periodList.filter(p => filterFunc(p));
  }

  let suggestions = [];
  if (!!searchString && searchString !== '') {
    /* User is searching, only show the search results */
    const highPriorityMatches = [];
    const lowPriorityMatches = [];
    for (const period of periodList) {
      if (period.displayName.toLowerCase().startsWith(searchString.toLocaleLowerCase())) {
        highPriorityMatches.push(period);
      } else if (period.displayName.toLowerCase().includes(searchString.toLocaleLowerCase())) {
        lowPriorityMatches.push(period);
      }
    }
    suggestions = highPriorityMatches.concat(lowPriorityMatches);
  } else {
    suggestions = periodList;
  }

  return (
    <ChipAutocomplete
      name={name}
      suggestions={groupSuggestionsByDate(suggestions)}
      alwaysRenderSuggestions={!!suggestions && suggestions.length > 0}
      onSelect={suggestion => onSelect(suggestion)}
      renderSuggestion={(suggestion, { isHighlighted }) => (
        <DataObjectChip
          name="okrperiod-selector-menuitem"
          id={`okrperiod-selector-result-${suggestion.ltPeriodId}-${suggestion.stPeriodId || ''}`}
          text={suggestion.displayName}
          color={isHighlighted ? 'grey' : 'transparent'}
          actionable
        />
      )}
      onClearRequested={() => setSearchString('')}
      onFetchRequested={searchstring => setSearchString(searchstring)}
      id={props.id}
      placeholder={t('objectives.selectPeriodSearchLabel')}
      multiSection
    />
  );
}

SearchOverlay.propTypes = {
  onSelect: PropTypes.func,
  filterFunc: PropTypes.func,
  id: PropTypes.string,
  showPast: PropTypes.bool,
  name: PropTypes.string,
};

export default SearchOverlay;
