import { Divider, Typography } from '@mui/material';
import Container from 'Components/Library/BaseComponents/Container';
import StandardDialog from 'Components/Library/StandardDialog';
import RichTextEditor from 'Components/Library/RichTextEditor';
import FormRichTextField from 'Components/Library/Forms/Elements/FormRichTextField';
import KrConfidenceWidget from './KrConfidenceWidget';
import FormButton from 'Components/Library/Forms/Elements/FormButton';
import { useTranslation } from 'react-i18next';
import FormNumberField from 'Components/Library/Forms/Elements/FormNumberField';
import { objectivesSelectors } from 'state/ducks/objectives';
import { useDispatch, useSelector } from 'react-redux';
import KrStatusForm from '../PanelViewKeyresult/KeyresultUpdateForm/KrStatusForm';
import Button from 'Components/Library/BaseComponents/Button';
import Input from 'Components/Library/BaseComponents/Input';
import { commentsActions } from 'state/ducks/comments';

interface DialogKrCheckinProps {
  keyresultID: string;
  zIndexModifier: number;
  onClose(): void;
}

function DialogKrCheckin(props: DialogKrCheckinProps) {
  const { keyresultID, zIndexModifier, onClose } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const keyresultData = useSelector((state: any) =>
    objectivesSelectors.selectKeyresult(state.main.objectives, keyresultID),
  );

  const onSubmitSuccess = () => {
    // refresh comments
    dispatch(commentsActions.fetchNodeComments({ nodeId: keyresultData.graph_id, force: true }));
    onClose();
  };

  return (
    <StandardDialog size="small" open zIndexModifier={zIndexModifier} onBackDropClick={onClose}>
      <KrStatusForm
        keyresultData={keyresultData}
        useButton={true}
        name="kr-checkin-form"
        onSuccess={onSubmitSuccess}
      >
        <Container alignItems={'stretch'} m={3} spacing={2}>
          <Typography variant="subtitle1">{t('objectives.dialogKrCheckin.title')}</Typography>
          <Container alignItems={'stretch'} spacing={2}>
            <FormNumberField
              fieldName="status"
              render={fieldProps => (
                <Input
                  id="dialog-update-kr-status-field"
                  variant="outlined"
                  label={t('objectives.dialogKrCheckin.statusFieldLabel')}
                  size="large"
                  fullWidth
                  autoFocus
                  {...fieldProps}
                />
              )}
            />
            <FormRichTextField
              fieldName="comment"
              render={fieldProps => (
                <RichTextEditor
                  {...fieldProps}
                  label={t('objectives.dialogKrCheckin.commentFieldLabel')}
                  placeholder={t('objectives.dialogKrCheckin.commentFieldLabel')}
                  toolbarVariant="floating"
                  variant="standard"
                  schema="comment"
                  minRows={4}
                />
              )}
            />
          </Container>
          <Container alignItems={'stretch'}>
            <Typography variant="caption" color="text.secondary">
              {t('objectives.dialogKrCheckin.confidenceFieldLabel')}
            </Typography>
            <KrConfidenceWidget keyresultData={keyresultData} canEdit={true} withForm={false} />
          </Container>
          <Divider />
          <Container direction="row" justifyContent="flex-end">
            <Button size="large" variant="text" onClick={onClose}>
              {t('general.cancel')}
            </Button>
            <FormButton
              render={buttonProps => (
                <Button
                  size="large"
                  id="dialog-krcheckin-submit"
                  variant="contained"
                  color="secondary"
                  {...buttonProps}
                >
                  {t('objectives.dialogKrCheckin.checkInButtonLabel')}
                </Button>
              )}
            />
          </Container>
        </Container>
      </KrStatusForm>
    </StandardDialog>
  );
}

export default DialogKrCheckin;
