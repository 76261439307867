export const FETCH_GAMEPLANS = 'FETCH_GAMEPLANS';
export const RECEIVED_GAMEPLANS = 'GAMEPLANS_RECEIVED';
export const FAILED_GAMEPLANS = 'FAILED_GAMEPLANS';

export const CREATE_GAMEPLAN = 'CREATE_GAMEPLAN';

export const COPY_GAMEPLAN = 'COPY_GAMEPLAN';

export const CREATED_GAMEPLAN = 'CREATED_GAMEPLAN';

export const EDIT_GAMEPLAN_WIDGET = 'EDIT_GAMEPLAN_WIDGET';
export const EDITED_GAMEPLAN_WIDGET = 'EDITED_GAMEPLAN_WIDGET';

export const MOVE_GAMEPLAN_WIDGET = 'MOVE_GAMEPLAN_WIDGET';
export const MOVED_GAMEPLAN_WIDGET = 'MOVED_GAMEPLAN_WIDGET';

export const DELETE_GAMEPLAN_WIDGET = 'DELETE_GAMEPLAN_WIDGET';
export const DELETED_GAMEPLAN_WIDGET = 'DELETED_GAMEPLAN_WIDGET';

export const CREATE_GAMEPLAN_WIDGET = 'CREATE_GAMEPLAN_WIDGET';
export const CREATED_GAMEPLAN_WIDGET = 'CREATED_GAMEPLAN_WIDGET';

export const EDIT_GAMEPLAN_WIDGETS_VISIBILITY = 'EDIT_GAMEPLAN_WIDGETS_VISIBILITY';
export const EDITED_GAMEPLAN_WIDGETS_VISIBILITY = 'EDITED_GAMEPLAN_WIDGETS_VISIBILITY';

export const CREATE_BLANK_CARD = 'CREATE_BLANK_CARD';
export const CREATED_BLANK_CARD = 'CREATED_BLANK_CARD';

export const ERROR_RECEIVED_FROM_API = 'GAMEPLANS_API_ERROR_RECEIVED';
