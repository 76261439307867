import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { AutoSizer } from 'react-virtualized';
import { Bar } from '@visx/shape';
import { Group } from '@visx/group';
import { scaleLinear } from '@visx/scale';

import { countBy } from 'lodash';

const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    minHeight: 240,
    display: 'flex',
    flexDirection: 'column',
  },
  spreadRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  label: {
    width: `calc(50% - ${theme.spacing()})`,
    '&:last-child': {
      textAlign: 'right',
    },
  },
  chart: {
    flexGrow: 10,
    display: 'flex',
    height: 60,
  },
  deltaChart: {
    height: 120,
  },
  svgcontainer: {
    display: 'inline-block',
    width: '100%',
    height: '100%',
    backgroundColor: '#FFFFFF', // For some reason SVG doesn't render on mobile without this..
    '&:hover': {
      backgroundColor: theme.palette.primary[200],
    },
  },
  sliderArea: {
    marginBottom: theme.spacing(6),
  },
});

const renderSlider = (sliderData, index, theme, invert = false) => (
  <AutoSizer defaultHeight={100} defaultWidth={100}>
    {({ width }) => {
      if (width === 0) {
        return null;
      }
      if (!sliderData) {
        return null;
      }
      const height = 60;
      const barWidth = width / 20 - 2;
      const xScale = scaleLinear({
        range: [0, width],
        domain: [0, 105],
      });
      const yScale = scaleLinear({
        range: [60, 0],
        domain: [0, Math.max(...sliderData.map(dp => dp.y), 8)],
      });

      return (
        <svg width={width} height={height} style={{ overflow: 'visible' }}>
          <Group>
            {sliderData.map((datapoint, i) => {
              const barHeight = height - yScale(datapoint.y);

              const barX = xScale(datapoint.x);
              const barY = invert ? height : height - barHeight;
              return (
                <Bar
                  key={`bar-${index}-${i}-${datapoint.x}`}
                  x={barX}
                  y={barY}
                  width={barWidth}
                  height={barHeight}
                  fill={invert ? theme.palette.primary[600] : theme.palette.secondary.main}
                />
              );
            })}
          </Group>
          <rect width={width} height={1} x={0} y={height - 1} fill={theme.palette.primary[500]} />
        </svg>
      );
    }}
  </AutoSizer>
);

class SliderChart extends React.Component {
  render() {
    const { values, classes, survey, theme } = this.props;

    if (!values) {
      return null;
    }

    // TODO: this is no good performance wise, but currently handling it elsewhere
    // would require massive refactoring:
    const computedDataPerSlider = {};
    for (const sliderIndex of Object.keys(values)) {
      const sliderType = survey.sliders[sliderIndex].type;
      if (sliderType === 'basic') {
        computedDataPerSlider[sliderIndex] = [];
        const counts = countBy(values[sliderIndex]);
        for (const value in counts) {
          computedDataPerSlider[sliderIndex].push({ x: value, y: counts[value] });
        }
      } else if (sliderType === 'delta') {
        computedDataPerSlider[sliderIndex] = { current: [], goal: [] };
        for (const key of ['current', 'goal']) {
          const counts = countBy(values[sliderIndex][key]);
          for (const value in counts) {
            computedDataPerSlider[sliderIndex][key].push({ x: value, y: counts[value] });
          }
        }
      }
    }
    return (
      <div id="sliderchart" className={classes.root}>
        {survey.sliders.map((slider, index) => (
          <div key={`slider-${index}`} className={classes.sliderArea}>
            <Typography variant="subtitle1" gutterBottom>
              {slider.question}
            </Typography>
            <div className={classes.spreadRow}>
              <Typography variant="caption" className={classes.label}>
                {slider.minLabel}
              </Typography>
              <Typography variant="caption" className={classes.label}>
                {slider.maxLabel}
              </Typography>
            </div>
            <div
              className={clsx([classes.chart, slider.type === 'delta' ? classes.deltaChart : null])}
            >
              <div className={classes.svgcontainer}>
                {slider.type === 'basic' &&
                  !!computedDataPerSlider[index] &&
                  renderSlider(computedDataPerSlider[index], index, theme)}
                {slider.type === 'delta' &&
                  !!computedDataPerSlider[index] &&
                  renderSlider(computedDataPerSlider[index].goal, index, theme)}
                {slider.type === 'delta' &&
                  !!computedDataPerSlider[index] &&
                  renderSlider(computedDataPerSlider[index].current, index, theme, true)}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

SliderChart.propTypes = {
  classes: PropTypes.exact({
    root: PropTypes.string,
    spreadRow: PropTypes.string,
    label: PropTypes.string,
    chart: PropTypes.string,
    deltaChart: PropTypes.string,
    svgcontainer: PropTypes.string,
    sliderArea: PropTypes.string,
  }),
  values: PropTypes.object,
  survey: PropTypes.object,
  theme: PropTypes.object,
};

export default withStyles(styles, { withTheme: true })(SliderChart);
