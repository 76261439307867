import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import CircularProgress from '@mui/material/CircularProgress';
import TimelineChart from './charts/TimelineChart';
import QuadTimelineChart from './charts/QuadTimelineChart';
import MultichoiceTimelineChart from './charts/MultichoiceTimelineChart';

const styles = () => ({
  container: {
    height: 120,
  },
  loading: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
});

class PulseSelector extends React.Component {
  onSelect = survey => {
    this.props.navigate(survey.surveyID);
  };

  render() {
    const { className, classes, topic, selected, teamID, cascade, fetched, xExtent, values } =
      this.props;

    let ChartComponent = TimelineChart;
    let yDomain;
    if (topic.data.topicType === 'quad') {
      ChartComponent = QuadTimelineChart;
    } else if (topic.data.topicType === 'nps') {
      yDomain = [-100, 100];
    } else if (topic.data.topicType === 'csat') {
      yDomain = [1, 5];
    } else if (topic.data.topicType === 'multiplechoice') {
      ChartComponent = MultichoiceTimelineChart;
    }

    return (
      <div className={className} name="panel-view-survey-results-pulseselector">
        <div className={classes.container}>
          {fetched ? (
            <ChartComponent
              selected={selected}
              onSelect={this.onSelect}
              xExtent={xExtent}
              values={values}
              yDomain={yDomain}
              teamID={teamID}
              cascade={cascade}
              topic={topic}
            />
          ) : (
            /* Todo: implement timeline without scores as loading state */
            <div className={classes.loading}>
              <CircularProgress name="CircularProgress" />
            </div>
          )}
        </div>
      </div>
    );
  }
}

PulseSelector.propTypes = {
  classes: PropTypes.exact({
    container: PropTypes.string,
    loading: PropTypes.string,
  }),
  navigate: PropTypes.func,
  className: PropTypes.string,
  topic: PropTypes.object,
  selected: PropTypes.string,
  teamID: PropTypes.string,
  cascade: PropTypes.bool,
  fetched: PropTypes.bool,
  xExtent: PropTypes.array,
  values: PropTypes.array,
};

export default withStyles(styles)(PulseSelector);
