import React from 'react';

const FormContext = React.createContext({
  formState: {},
  dirtyFields: {},
  fieldErrors: {},
  schema: {},
  values: {},
  apiErrorMessage: null,
  submitted: false,
  submitSuccess: false,
  apiError: false,
  isValid: false,
  showErrors: false,
});

export function withFormStatus(Component) {
  return function FormComponent(props) {
    return (
      <FormContext.Consumer>
        {formContext => (
          <Component
            {...props}
            isValid={formContext.isValid}
            submitting={formContext.submitting}
            submitSuccess={formContext.submitSuccess}
            apiError={formContext.apiError}
            apiErrorMessage={formContext.apiErrorMessage}
          />
        )}
      </FormContext.Consumer>
    );
  };
}

export default FormContext;
