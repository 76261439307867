import { DialogActions, DialogContent, Typography } from '@mui/material';
import Button from 'Components/Library/BaseComponents/Button';

interface SelectVotingStyleUiProps {
  startClassicVote(): void;
  startAiFlow(): void;
}

function SelectVotingStyleUi(props: SelectVotingStyleUiProps) {
  const { startClassicVote, startAiFlow } = props;
  return (
    <>
      <DialogContent>
        <Typography variant="h5" sx={{ mb: 2 }}>
          How shall we vote?
        </Typography>
        <Typography variant="body1" paragraph>
          Do you want participants to vote on individual answers, or should we use AI to group
          answers by themes first?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => startClassicVote()}
          name="dfac-confirm-managerdialog-cancel-btn"
          size="large"
          variant="text"
        >
          Let's just vote on answers
        </Button>
        <Button
          name="dfac-confirm-managerdialog-submit-btn"
          variant="contained"
          size="large"
          color="secondary"
          onClick={() => startAiFlow()}
        >
          Ooh, AI magic!
        </Button>
      </DialogActions>
    </>
  );
}

export default SelectVotingStyleUi;
