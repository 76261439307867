import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, get } from 'lodash';
import { useSelector } from 'react-redux';

import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';
import TextIcon from '@mui/icons-material/TextSnippet';
import ImageIcon from '@mui/icons-material/Image';
import VideoIcon from '@mui/icons-material/VideoLibrary';

import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { SCHEMA_FULL } from 'Components/Library/RichTextEditor/schemas';

import RootDndContextProvider from 'Components/Library/DragAndDrop/RootDndContext';
import StandardDialog from 'Components/Library/StandardDialog';
import Form from 'Components/Library/Forms/Form';
import FormRichTextField from 'Components/Library/Forms/Elements/FormRichTextField';
import FormTextField from 'Components/Library/Forms/Elements/FormTextField';
import FormContext from 'Components/Library/Forms/FormContext';
import FormButton from 'Components/Library/Forms/Elements/FormButton';
import InlineEditInput from 'Components/Library/BaseComponents/InlineEditInput';
import RichTextEditor from 'Components/Library/RichTextEditor';
import { gameplansActions, gameplansSelectors } from 'state/ducks/gameplans';
import ImageElement from './ImageElement';
import ImageEditor from './ImageElement/Editor';
import VideoElement from './VideoElement';
import VideoEditor from './VideoElement/Editor';
import { imgFileToDataUrl } from './ImageElement/helpers';
import DraggableElement from '../Lib/DraggableElement';
import DragContainer from '../Lib/DragContainer';
import CustomWidget from './index';

import { ITEMTYPE_IMAGE, ITEMTYPE_RICHTEXT, ITEMTYPE_VIDEO } from './itemtypes';

const schema = {
  type: 'object',
  properties: {
    name: { type: 'string', minLength: 1, maxLength: 64 },
    elements: {
      type: 'array',
      minLength: 1,
      items: {
        anyOf: [
          {
            type: 'object',
            properties: {
              itemType: { const: ITEMTYPE_RICHTEXT },
              content: { richtextschema: SCHEMA_FULL, minLength: 1, maxLength: 3000 },
            },
          },
          {
            type: 'object',
            properties: {
              itemType: { const: ITEMTYPE_IMAGE },
              src: { type: 'string' },
            },
          },
          {
            type: 'object',
            properties: {
              itemType: { const: ITEMTYPE_VIDEO },
              src: { type: 'string' },
              type: { type: 'string' },
            },
          },
        ],
      },
    },
  },
  required: ['name', 'elements'],
};

function CustomWidgetEditor({ isNew, domainId, gameplanId, widgetId, onClose }) {
  const [addMenuState, setAddMenuState] = React.useState({});

  const sub = useSelector(state => state.auth.userID);
  const gameplans = useSelector(state =>
    gameplansSelectors.selectGamePlans(state.main.gameplans, domainId),
  );

  let widget = {};

  const { t } = useTranslation();

  useEffect(() => {
    const handlePaste = async (e, index) => {
      if (e.clipboardData.files.length) {
        const b64img = await imgFileToDataUrl(e.clipboardData.files[0]);
        setAddMenuState({ imageEditMenuOpen: true, index, b64img });
      }
    };

    window.addEventListener('paste', handlePaste);

    return () => {
      window.removeEventListener('paste', handlePaste);
    };
  }, []);

  if (!isNew) {
    const gameplan = gameplans.ok ? gameplans.data.find(gp => gp.id === gameplanId) : {};
    widget = gameplan.widget_data[widgetId];

    // If we get no widget (wrong url etc.) return null, do not crash
    if (!widget) {
      return null;
    }
  }

  const openImageCropMenu = async (e, index) => {
    const b64img = await imgFileToDataUrl(e.target.files[0]);
    setAddMenuState({ imageEditMenuOpen: true, index, b64img });
  };

  const clearImageInput = () => {
    const inputElement = document.getElementById('gameplan-card-editor-add-opt-image');
    if (inputElement) {
      inputElement.value = ''; // Clear the value of the input field
    }
  };

  const openAddVideoMenu = (formContext, index) => {
    setAddMenuState({ videoEditMenuOpen: true, index });
  };

  const addItemAt = (formContext, index, itemType, rest) => {
    const elements = [...formContext.values.elements];
    const item = { element_type: itemType, title: null, id: nanoid(10), ...rest };
    elements.splice(index + 1, 0, item);
    formContext.onFieldChange('elements', elements);
    setAddMenuState({});
  };

  const removeItemAt = (formContext, index) => {
    const elements = [...formContext.values.elements];
    elements.splice(index, 1);
    formContext.onFieldChange('elements', elements);
  };

  const additionalProperties = { domain_id: domainId };
  const initialValues = {};
  if (isNew) {
    additionalProperties.gameplan_id = gameplanId;
    initialValues.name = '';
    initialValues.elements = [];
  } else {
    additionalProperties.widget_id = widgetId;
    additionalProperties.gameplan_id = gameplanId;
    initialValues.name = widget.name;
    initialValues.elements = cloneDeep(widget.elements);
  }

  const dragContextId = widgetId || 'NEW-GP-WIDGET';

  return (
    <RootDndContextProvider>
      <Form
        name="gameplan-customwidget"
        schema={schema}
        preventSaveIncomplete
        stateSlice="main.gameplans"
        submitActionCreator={
          isNew ? gameplansActions.createBlankCard : gameplansActions.editGamePlanWidget
        }
        allowRefreshData={false}
        additionalProperties={additionalProperties}
        initialValues={initialValues}
        onSubmitSuccess={onClose}
      >
        <StandardDialog open size="large" fixedHeight onBackDropClick={onClose}>
          <Box
            sx={{
              height: 60,
              flexGrow: 0,
              flexShrink: 0,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: theme => `1px solid ${theme.palette.divider}`,
              paddingLeft: '36px',
              paddingRight: '12px',
            }}
          >
            <Typography variant="h5">
              {isNew
                ? t('gameplans.customWidget.editorTitleNew')
                : t('gameplans.customWidget.editorTitleEdit')}
            </Typography>
            <IconButton size="medium" aria-label={t('general.close')} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              overflow: 'hidden',
              position: 'relative', // to allow absolute positioning of the saving indicator
              justifyContent: 'stretch',
              flexBasis: '100%',
              display: 'flex',
            }}
          >
            <Box
              sx={{
                width: 'calc(100% - 344px)',
                paddingRight: '14px',
                paddingLeft: '14px',
                boxSizing: 'border-box',
                borderRight: theme => `1px solid ${theme.palette.divider}`,
                overflowY: 'auto',
                overflowX: 'clip',
              }}
              className="scrollable"
            >
              <Typography variant="subtitle2" color="text.secondary" sx={{ mt: 2, ml: 2 }}>
                {t('gameplans.customWidget.editorPresentationViewSection')}
              </Typography>
              <Box sx={{ minWidth: 600, p: 6, boxSizing: 'border-box' }}>
                <Box
                  sx={{
                    backgroundColor: 'background.paper',
                    borderRadius: 1,
                    boxShadow: theme => theme.shadows[24],
                  }}
                >
                  <Box
                    sx={{
                      mt: 0,
                      p: 2,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      height: '24px',
                    }}
                  >
                    <FormTextField
                      fieldName="name"
                      render={fieldProps => (
                        <InlineEditInput
                          {...fieldProps}
                          variant="subtitle2"
                          fullWidth
                          label={t('gameplans.customWidget.titleLabel')}
                        />
                      )}
                    />
                  </Box>
                  <Box
                    sx={{
                      ml: 1,
                      mr: 1,
                      mb: 1.5,
                      pb: 1,
                      borderRadius: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'stretch',
                    }}
                  >
                    <FormContext.Consumer>
                      {formContext => (
                        <>
                          <DragContainer
                            fieldName="elements"
                            type="GAMEPLAN_WIDGET_WILLNOTDO"
                            widgetId={dragContextId}
                            elements={formContext.values.elements}
                            onFieldChange={formContext.onFieldChange}
                          >
                            <div>
                              {get(formContext, 'values.elements', []).length === 0 && (
                                <ButtonBase
                                  sx={{
                                    p: 2,
                                    pt: 1.5,
                                    width: '100%',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                  }}
                                  name="custom-widget-add-menu-state"
                                  onClick={event =>
                                    setAddMenuState({
                                      anchorEl: event.currentTarget,
                                      index: 0,
                                    })
                                  }
                                >
                                  <Typography variant="body2" color="action.disabled">
                                    {t('gameplans.customWidget.emptyStateCta')}
                                  </Typography>
                                </ButtonBase>
                              )}

                              {formContext.values?.elements?.map((contentItem, index) => (
                                <DraggableElement
                                  key={`custom-widget-element-${contentItem.id}`}
                                  index={index}
                                  draggableId={contentItem.id}
                                  onAddClick={event =>
                                    setAddMenuState({ anchorEl: event.currentTarget, index })
                                  }
                                  onDelete={() => removeItemAt(formContext, index)}
                                  isActive={addMenuState.index === index}
                                >
                                  {contentItem.element_type === ITEMTYPE_RICHTEXT && (
                                    <Box sx={{ p: 1, pt: 0.5, pb: 0.5 }}>
                                      <FormRichTextField
                                        fieldName={`elements.${index}.content`}
                                        render={fieldProps => (
                                          <RichTextEditor
                                            {...fieldProps}
                                            label={t('gameplans.customWidget.richtextContentLabel')}
                                            placeholder="..."
                                            toolbarVariant="floating"
                                            variant="inline"
                                            schema={SCHEMA_FULL}
                                            minRows={1}
                                          />
                                        )}
                                      />
                                    </Box>
                                  )}
                                  {contentItem.element_type === ITEMTYPE_IMAGE && (
                                    <ImageElement item={contentItem} widgetId={widgetId} />
                                  )}
                                  {contentItem.element_type === ITEMTYPE_VIDEO && (
                                    <VideoElement item={contentItem} />
                                  )}
                                </DraggableElement>
                              ))}
                            </div>
                          </DragContainer>

                          <Menu
                            id="gameplan-add-element-menu"
                            anchorEl={addMenuState.anchorEl}
                            open={Boolean(addMenuState.anchorEl)}
                            onClose={() => setAddMenuState({})}
                            keepMounted
                            variant="menu"
                          >
                            <MenuItem
                              onClick={() => {
                                addItemAt(formContext, addMenuState.index, ITEMTYPE_RICHTEXT);
                              }}
                              name="menuitem-text"
                            >
                              <ListItemIcon>
                                <TextIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>
                                {t('gameplans.customWidget.textElementName')}
                              </ListItemText>
                            </MenuItem>
                            <MenuItem
                              component="label"
                              name="menuitem-image"
                              onClick={clearImageInput}
                            >
                              <ListItemIcon>
                                <ImageIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>
                                {t('gameplans.customWidget.imageElementName')}
                              </ListItemText>
                              <input
                                accept="image/bmp, image/jpeg, image/png, image/webp, image/avif, image/apng, image/gif"
                                type="file"
                                style={{ display: 'none' }}
                                id="gameplan-card-editor-add-opt-image"
                                onChange={e => {
                                  openImageCropMenu(e, addMenuState.index);
                                }}
                                data-testid="image-file-upload-custom-widget"
                              />
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                openAddVideoMenu(formContext, addMenuState.index);
                              }}
                              name="menuitem-video"
                            >
                              <ListItemIcon>
                                <VideoIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>
                                {t('gameplans.customWidget.videoElementName')}
                              </ListItemText>
                            </MenuItem>
                          </Menu>
                          <ImageEditor
                            menuState={addMenuState}
                            onSave={imgSrc =>
                              addItemAt(formContext, addMenuState.index, ITEMTYPE_IMAGE, {
                                src: imgSrc,
                              })
                            }
                            onClose={() => setAddMenuState({})}
                          />
                          <VideoEditor
                            menuState={addMenuState}
                            onSave={videoDetails =>
                              addItemAt(formContext, addMenuState.index, ITEMTYPE_VIDEO, {
                                src: videoDetails.id,
                                videotype: videoDetails.type,
                              })
                            }
                            onClose={() => setAddMenuState({})}
                          />
                        </>
                      )}
                    </FormContext.Consumer>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '344px',
                flexDirection: 'column',
                alignItems: 'stretch',
                flexGrow: 0,
                flexShrink: 0,
              }}
            >
              <Typography variant="subtitle2" color="text.secondary" sx={{ mt: 2, ml: 4 }}>
                {t('gameplans.customWidget.editorPreviewCardSection')}
              </Typography>
              <Box sx={{ bgcolor: 'background.box', borderRadius: '15px', m: 4, mt: 6 }}>
                <FormContext.Consumer>
                  {formContext => (
                    <CustomWidget
                      data={{ ...formContext.values, id: widgetId, updated_by: sub }}
                      canEdit={false}
                    />
                  )}
                </FormContext.Consumer>
              </Box>
            </Box>
          </Box>

          <DialogActions sx={{ borderTop: theme => `1px solid ${theme.palette.divider}` }}>
            <FormButton
              render={buttonProps => (
                <Button
                  id="content-card-save-btn"
                  variant="contained"
                  color="secondary"
                  {...buttonProps}
                >
                  {t('general.save')}
                </Button>
              )}
            />
          </DialogActions>
        </StandardDialog>
      </Form>
    </RootDndContextProvider>
  );
}

CustomWidgetEditor.propTypes = {
  domainId: PropTypes.string,
  widgetId: PropTypes.string,
  gameplanId: PropTypes.string,
  isNew: PropTypes.bool,
  data: PropTypes.object,
  onClose: PropTypes.func,
};
export default CustomWidgetEditor;
