import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import EditIcon from '@mui/icons-material/Edit';
import MenuIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';

import PersonasAvatar from 'Components/Common/PersonasAvatar';
import ContainedIconButton from 'Components/Library/ContainedIconButton';
import StandardConfirmationDialog from 'Components/Library/StandardConfirmationDialog';
import RichTextEditor from 'Components/Library/RichTextEditor/index';
import FormButton from 'Components/Library/Forms/Elements/FormButton';
import FormRichTextField from 'Components/Library/Forms/Elements/FormRichTextField';
import Form from 'Components/Library/Forms/Form';
import Panel from 'Components/Library/BaseComponents/Panel';

import { getLastModifiedStringFromTimestamp } from 'config/helpers';
import { peopleSelectors } from 'state/ducks/people';
import { commentsActions } from 'state/ducks/comments/index';

const schema = {
  type: 'object',
  additionalProperties: true,
  properties: {
    content: { type: 'object' },
  },
};
function ActivityEntry(props) {
  const {
    sub,
    title,
    metaDataMessage,
    message,
    timestamp,
    className,
    collapseConfig,
    name = 'activity-entry',
    id,
    content,
    targetNode,
    triggerUpdate,
    isDeleted,
  } = props;

  const { t } = useTranslation();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const [collapsed, toggleCollapsed] = useState(
    !!collapseConfig && collapseConfig.initialState === 'collapsed',
  );
  const [isEditing, setIsEditing] = useState(false);
  const [isMenuOpen, setisMenuOpen] = React.useState(false);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();

  const toggleMenu = el => {
    setisMenuOpen(!isMenuOpen);
    setAnchorEl(el);
  };

  const closeMenu = () => {
    setisMenuOpen(false);
    setAnchorEl(null);
  };

  const isChangeManager = useSelector(state => state.auth.isChangeManager);
  const userName = useSelector(state => peopleSelectors.selectFirstName(state.main.people, sub));
  const currentUserID = useSelector(state => state.auth.userID);
  const canEdit = currentUserID === sub;
  const canDelete = canEdit || isChangeManager;

  return (
    <Box
      className={className}
      name={name}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'stretch',
        alignItems: 'flex-start',
        '&:hover .comment-title-box .edit-expand-control': {
          opacity: 1,
        },
        '&:focus-within .comment-title-box .edit-expand-control': {
          opacity: 1,
        },
      }}
      onKeyDown={event => {
        if (isEditing) {
          if (event.code === 'Escape') {
            setIsEditing(false);
            event.stopPropagation(); // <- the esc event has been handled, we can stop it's bubbling and that will prevent the dialogs from closing
          }
        }
      }}
    >
      {/* Left column, avatar and vertical line */}
      <Box
        sx={{
          alignSelf: 'stretch',
          flexDirection: 'column',
          justifyContent: 'stretch',
          alignItems: 'center',
          width: '32px',
          flexBasis: 32,
          flexGrow: 0,
          display: 'flex',
          paddingRight: 1,
        }}
      >
        <Box sx={{ height: 24, display: 'flex', justifyContent: 'stretch', alignItems: 'center' }}>
          <PersonasAvatar sub={sub} size="xtiny" />
        </Box>
        <Box
          sx={{
            backgroundColor: th => th.palette.grey[400],
            flexGrow: 100,
            mt: 0.5,
            width: '1px',
          }}
        />
        {/* End left column */}
      </Box>

      {/* Right column */}
      <Box
        sx={{
          flexBasis: 'auto',
          flexGrow: 10,
          flexShrink: 1000,
          minWidth: '1px', // !!important to ensure ellipsis works in children
        }}
      >
        {/* Title row */}
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            height: '24px',
            justifyContent: 'flex-start',
            mb: 0.5,
            overflow: 'hidden',
          }}
          className="comment-title-box"
        >
          <Typography
            variant="body2"
            component="span"
            color="text.strong"
            sx={{ flexShrink: 0, flexBasis: 'auto', flexGrow: 0 }}
          >
            {userName}
          </Typography>
          {title && (
            <Typography
              variant="body2"
              component="span"
              color="text.secondary"
              sx={{
                alignItems: 'center',
                display: 'inline-flex',
                flexShrink: 10,
                minWidth: '1px', // !!important to ensure ellipsis works in children
                whiteSpace: 'nowrap',
              }}
            >
              &nbsp;
              {title}
            </Typography>
          )}
          <Typography
            variant="tiny"
            color="text.primary"
            sx={{
              flexBasis: 'auto',
              flexGrow: 0,
              flexShrink: 0,
              minWidth: '1px',
              ml: 1,
              height: '24px',
              lineHeight: '24px',
            }}
          >
            {getLastModifiedStringFromTimestamp(timestamp)}
          </Typography>
          {(canEdit || canDelete) && !!message && !isDeleted && (
            <>
              <ContainedIconButton
                onClick={evt => {
                  if (isEditing) {
                    setIsEditing(false);
                    closeMenu();
                  } else {
                    toggleMenu(evt.currentTarget);
                  }
                }}
                aria-controls={isMenuOpen ? `${name}-header-menu` : undefined}
                aria-haspopup="true"
                aria-expanded={isMenuOpen ? 'true' : undefined}
                sx={{ marginLeft: 'auto', opacity: isXs ? 1 : 0 }}
                className="edit-expand-control"
              >
                {isEditing ? <CloseIcon /> : <MenuIcon />}
              </ContainedIconButton>
              <Menu
                open={isMenuOpen}
                onClose={closeMenu}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                sx={
                  !process.env.JEST_WORKER_ID
                    ? {
                        '& .MuiIconButton-root': {
                          ml: 0.25,
                          mr: 0.25,
                        },
                        '& .MuiIconButton-root:first-child': {
                          ml: 1,
                        },
                        '& .MuiIconButton-root:last-child': {
                          mr: 1,
                        },
                      }
                    : {}
                }
              >
                {canEdit && (
                  <Tooltip title={t('general.edit')}>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setIsEditing(true);
                        closeMenu();
                      }}
                      name="activity-entry-chip-edit"
                      aria-label={t('general.edit')}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title={t('general.delete')}>
                  <IconButton
                    size="small"
                    onClick={() => {
                      toggleMenu();
                      setIsEditing(false);
                      setIsConfirmDeleteOpen(true);
                    }}
                    name="activity-entry-chip-delete"
                    aria-label={t('general.delete')}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Menu>
              {isConfirmDeleteOpen && (
                <StandardConfirmationDialog
                  title={t('general.confirmDeleteTitle')}
                  open
                  name="dialog-confirm-delete-comment"
                  closeOnBackDropClick
                  secondaryAction={{
                    label: t('general.cancel'),
                    onClick: () => setIsConfirmDeleteOpen(false),
                  }}
                  primaryAction={{
                    label: t('general.confirmDelete'),
                    onClick: () => {
                      dispatch(commentsActions.deleteComment({ id, targetNodeId: targetNode }));
                      triggerUpdate();
                    },
                  }}
                >
                  {t('general.comments.confirmDeleteText')}
                  <Panel sx={{ mt: 2, maxHeight: '240px', overflowY: 'scroll' }}>{message}</Panel>
                </StandardConfirmationDialog>
              )}
            </>
          )}
          {!!collapseConfig && !!collapseConfig.allowChange && (
            <Button
              variant="text"
              onClick={() => toggleCollapsed(!collapsed)}
              sx={th => ({
                ...th.typography.tiny,
                color: th.palette.text.primary,
                display: 'inline',
                flexShrink: 0,
                height: '18px',
                lineHeight: '18px',
                minWidth: 0,
                ml: 0.5,
                padding: 0,
                pl: 0.5,
                pr: 0.5,
                textTransform: 'lowercase',
                whiteSpace: 'nowrap',
              })}
            >
              {!!collapsed ? collapseConfig.expandCta : collapseConfig.collapseCta}
            </Button>
          )}
        </Box>
        {/* Content box */}
        {!collapsed && !isEditing && (
          <Box
            sx={{
              borderRadius: 1,
              flexBasis: 'auto',
              flexDirection: 'column',
              flexGrow: 10,
              flexShrink: 10,
            }}
          >
            {!!metaDataMessage && (
              <Box
                sx={{
                  borderRadius: 1,
                  flexBasis: 'auto',
                  flexDirection: 'column',
                  flexGrow: 10,
                  flexShrink: 1000,
                  mb: 1,
                  mt: 1,
                }}
              >
                {metaDataMessage}
              </Box>
            )}
            {!!message && !isDeleted && (
              <Box
                sx={{
                  backgroundColor: th => th.palette.background.box,
                  borderRadius: 1,
                  flexBasis: 'auto',
                  flexDirection: 'column',
                  flexGrow: 10,
                  flexShrink: 1000,
                  padding: 2,
                }}
              >
                {message}
              </Box>
            )}
          </Box>
        )}
        {isEditing && (
          <Form
            schema={schema}
            name={`comment-form-${timestamp}`}
            submitActionCreator={commentsActions.editComment}
            initialValues={{ content }}
            additionalProperties={{ id, target_node_id: targetNode }}
            formIdentifier={timestamp}
            key={timestamp}
            stateSlice="main.comments"
            onSubmitSuccess={() => {
              setIsEditing(false);
              triggerUpdate();
            }}
            preventSaveIncomplete
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'stretch',
                alignItems: 'flex-start',
                mb: 2,
                overflowX: 'hidden',
              }}
            >
              <FormRichTextField
                fieldName="content"
                render={fieldProps => (
                  <RichTextEditor
                    {...fieldProps}
                    label={t('general.comments.commentEditorPlaceHolder')}
                    placeholder={t('general.comments.commentEditorPlaceHolder')}
                    submitButton={
                      <FormButton
                        render={buttonProps => (
                          <Button {...buttonProps} color="secondary">
                            {t('general.save')}
                          </Button>
                        )}
                      />
                    }
                    toolbarVariant="bottom"
                    variant="filled"
                    schema="comment"
                    stringHandler="markdown"
                  />
                )}
              />
            </Box>
          </Form>
        )}
      </Box>
    </Box>
  );
}

ActivityEntry.propTypes = {
  className: PropTypes.string,
  collapseConfig: PropTypes.exact({
    collapseCta: PropTypes.string,
    allowChange: PropTypes.bool,
    expandCta: PropTypes.string,
    initialState: PropTypes.string,
  }),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string,
  isDeleted: PropTypes.bool,
  message: PropTypes.node,
  metaDataMessage: PropTypes.node,
  name: PropTypes.string,
  sub: PropTypes.string,
  timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.node,

  targetNode: PropTypes.string,
  triggerUpdate: PropTypes.func,
};

export default ActivityEntry;
