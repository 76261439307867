import React, { PropsWithChildren } from 'react';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import BulbIcon from '@mui/icons-material/EmojiObjectsOutlined';
import PollIcon from '@mui/icons-material/PollOutlined';
import WarnIcon from '@mui/icons-material/WarningAmber';
import SvgIcon from '@mui/material/SvgIcon';
import { Box, useTheme } from '@mui/material';
import Container from '../BaseComponents/Container';

interface InfoElementProps {
  variant?: 'info' | 'hint' | 'warning' | 'bestpractice' | 'recommendation';
}

function InfoElement(props: PropsWithChildren<InfoElementProps>) {
  const { children, variant = 'info' } = props;
  const theme = useTheme();

  let color;
  let icon;
  switch (variant) {
    case 'bestpractice':
      color = theme.palette.secondary.main;
      icon = (
        <SvgIcon sx={{ mr: 1.5 }} htmlColor={color} viewBox="0 0 24 24">
          <path
            d="M 19,3 H 5 C 4,3 3,4 3,5 V 19 C 3,20 4,21 5,21 H 19 C 20,21 21,20 21,19 V 5 C 21,4 20,3 19,3 Z M 9,19 h -2 v -2.5 l 2,2.4e-5 z m 4,4e-6 h -2 v -6 h 2 l 0,6 h 0 z m 4,-4e-6 h -2 V 9 h 2 z"
            stroke="null"
          />
        </SvgIcon>
      );
      break;
    case 'recommendation':
      color = theme.palette.action.active;
      icon = <PollIcon sx={{ mr: 1.5 }} htmlColor={color} />;
      break;
    case 'hint':
      color = theme.palette.action.active;
      icon = <BulbIcon sx={{ mr: 1.5 }} htmlColor={color} />;
      break;
    case 'warning':
      color = theme.palette.warning.main;
      icon = <WarnIcon sx={{ mr: 1.5 }} htmlColor={color} />;
      break;
    default:
      color = theme.palette.action.active;
      icon = <InfoIcon sx={{ mr: 1.5 }} htmlColor={color} />;
  }

  return (
    <Box
      sx={{
        borderRadius: 1,
        border: '2px solid',
        borderColor: color,
      }}
    >
      <Container direction="row">
        {icon}
        <Typography variant="subtitle2" color="text.secondary">
          {children}
        </Typography>
      </Container>
    </Box>
  );
}

export default InfoElement;
