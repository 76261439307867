import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';

import Typography from '@mui/material/Typography';

const styles = theme => ({
  scoreBar: {
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    transition: 'all 75ms ease-in',
  },
  scoreBarContainer: {
    height: 160,
  },
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  barContainer: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
  },
  legendContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
  },
  column: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    margin: 8 / 2,
    textAlign: 'center',
    '&:first-of-type': {
      marginLeft: 0,
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  overallColumn: {
    marginRight: theme.spacing(2),
    textAlign: 'left',
  },
  centeredText: {
    flexGrow: 0,
    textAlign: 'center',
    maxWidth: 2,
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 3,
    '& span': {
      marginLeft: -20,
      marginRight: -20,
      textAlign: 'center',
    },
  },
  csat5: {
    color: theme.palette.visualizations.csat[5],
  },
  csat4: {
    color: theme.palette.visualizations.csat[4],
  },
  csat3: {
    color: theme.palette.visualizations.csat[3],
  },
  csat2: {
    color: theme.palette.visualizations.csat[2],
  },
  csat1: {
    color: theme.palette.visualizations.csat[1],
  },
});

class CsatChart extends Component {
  render() {
    const { t, values, outcome, classes, theme } = this.props;

    if (!values) {
      return null;
    }
    return (
      <div className={classes.root}>
        <div className={classes.barContainer}>
          {outcome &&
            outcome.scores &&
            Object.keys(outcome.scores).map(key => (
              <div
                id={`nps-chart-bars-${key}`}
                key={`nps-chart-bars-${key}`}
                className={clsx([classes.column, classes.scoreBarContainer])}
              >
                <div
                  className={classes.scoreBar}
                  style={{
                    backgroundColor: theme.palette.visualizations.csat[parseInt(key - 1, 10)],
                    height: `${Math.max((160 * outcome.scores[key]) / outcome.amount, 1)}px`,
                  }}
                >
                  &nbsp;
                </div>
                <div className={classes.centeredText}>
                  <Typography variant="caption">{key}</Typography>
                </div>
              </div>
            ))}
        </div>
        <div className={classes.legendContainer}>
          <Typography variant="caption">{t('insights.csatunsatisfiedlabel')}</Typography>
          <Typography variant="caption">{t('insights.csatsatisfiedlabel')}</Typography>
        </div>
      </div>
    );
  }
}

CsatChart.propTypes = {
  classes: PropTypes.exact({
    scoreBar: PropTypes.string,
    scoreBarContainer: PropTypes.string,
    root: PropTypes.string,
    barContainer: PropTypes.string,
    legendContainer: PropTypes.string,
    column: PropTypes.string,
    overallColumn: PropTypes.string,
    centeredText: PropTypes.string,
    csat5: PropTypes.string,
    csat4: PropTypes.string,
    csat3: PropTypes.string,
    csat2: PropTypes.string,
    csat1: PropTypes.string,
  }),
  values: PropTypes.object,
  outcome: PropTypes.object,
  theme: PropTypes.object,
  t: PropTypes.func,
};

export default withTranslation()(withStyles(styles, { withTheme: true })(CsatChart));
