import { get } from 'lodash';
import moment from 'moment';

export const getConfidenceString = value => {
  if ((!value && value !== 0) || value >= 70) {
    return 'green';
  }
  if (value >= 50) {
    return 'amber';
  }
  return 'red';
};

export function dateToSeconds(dateStr) {
  const timeStamp = moment(dateStr).unix() * 1000;
  return timeStamp;
}

export function linearRegression(data) {
  /* Linear regression of y value from multiple datapoints */
  const lr = {};
  const n = data.length;
  let sumX = 0;
  let sumY = 0;
  let sumXy = 0;
  let sumXx = 0;
  for (let i = 0; i < data.length; i++) {
    const x = dateToSeconds(data[i].timestamp);
    sumX += x;
    sumY += data[i].y;
    sumXy += x * data[i].y;
    sumXx += x * x;
  }

  lr.slope = (n * sumXy - sumX * sumY) / (n * sumXx - sumX * sumX);
  lr.intercept = (sumY - lr.slope * sumX) / n;
  return lr;
}

export function lerp(dp1, dp2, date) {
  /* Linear extrapolation of an y value at date based on two datapoints */
  if (!dp1 || !dp2) {
    // less than two datapoints available to extrapolate
    return get(dp1, 'y', get(dp2, 'y')) || 0;
  }
  const y1 = dp1.y;
  const y2 = dp2.y;
  if (y1 === y2) {
    // avoid div by zero
    return y1;
  }
  const x1 = dateToSeconds(dp1.timestamp);
  const x2 = dateToSeconds(dp2.timestamp);
  const x = dateToSeconds(date);
  return y1 + (x - x1) * ((y2 - y1) / (x2 - x1));
}

export function parseRtDescription(jsonString) {
  const r = JSON.parse(jsonString);
  // Cleanup possible conversion errors from the rt migration
  if (r.content && r.content.length > 0) {
    r.content.forEach(c => {
      if (c && c.type === 'paragraph') {
        if (c.content && c.content.length === 1 && c.content[0].type === 'text') {
          if (c.content[0].text === '') {
            c.content[0].text = ' ';
          }
        }
      }
    });
  }
  return r;
}
