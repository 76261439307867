import Container from 'Components/Library/BaseComponents/Container';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { facilitationSelectors } from 'state/ducks/facilitation';
import ThumbIcon from '@mui/icons-material/ThumbUp';
import CandidateLabel from '../AiGroupingFlow/CandidateLabel';
import HelperText from 'Components/Common/HelperText';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

interface QuestionVoteResultsProps {
  sessionId: string;
  index: number;
  voteData: Array<any>;
  votingType: number;
}

function QuestionVoteResults(props: QuestionVoteResultsProps) {
  const { sessionId, index, voteData, votingType } = props;
  const { t } = useTranslation();

  return (
    <>
      {!voteData || voteData.length === 0 ? (
        <HelperText key={`vote-inputs-${index}-no-answers`} name="no-answers-yet">
          {t('dfac.sessionnoanswerstovoteon')}
        </HelperText>
      ) : (
        voteData.map(item => (
          <Container direction="row" alignItems="stretch" sx={{ flexGrow: 10 }}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              mr={1.5}
            >
              <ThumbIcon color="secondary" fontSize="small" />
              <Box>
                <Typography color="secondary.main" variant="caption">
                  {item.votes}
                </Typography>
              </Box>
            </Box>
            {votingType === 1 ? (
              <CandidateLabel questionIndex={index} sessionId={sessionId} candidateItem={item} />
            ) : (
              <Typography
                variant="body1"
                sx={{
                  wordWrap: 'break-word',
                  wordBreak: 'normal',
                  overflow: 'hidden',
                  borderRadius: 1,
                  bgcolor: 'background.paper',
                  justifySelf: 'stretch',
                  flexGrow: 5,
                  lineHeight: '1.25rem',
                  padding: '12px',
                  paddingTop: '8ox',
                  paddingBottom: '8px',
                }}
              >
                {`${item.input}`}
              </Typography>
            )}
          </Container>
        ))
      )}
    </>
  );
}

export default QuestionVoteResults;
