import ChipAutocomplete from 'Components/Library/Shared/ChipAutocomplete/index';
import React, { useEffect, useState } from 'react';

import { tagsActions, tagsSelectors } from 'state/ducks/tags/index';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'state/ducks/types';
import HelperText from 'Components/Common/HelperText';
import { ITag, ITagNodesData } from 'state/ducks/tags/types';
import { SearchItemTag } from 'Components/Library/Tags/Details/SearchItemTag';
import { useTranslation } from 'react-i18next';

interface ITagsSearchOverlayProps {
  existingTags: Array<ITag>;
  // eslint-disable-next-line no-unused-vars
  onSelect: (tagData: ITag) => void;
}

export const getSuggestions = (
  existingTags: Array<ITag>,
  allTags: ITagNodesData | Record<PropertyKey, never> = {},
  searchString = '',
) => {
  let suggestedTags = allTags?.nodes || [];

  if (!!existingTags?.length) {
    suggestedTags = suggestedTags.filter(
      tag => !existingTags.some(exisitingTag => exisitingTag.id === tag.id),
    );
  }

  if (!!searchString?.length) {
    const ss = searchString.toLowerCase();
    suggestedTags = suggestedTags.filter(tag => tag.name.toLowerCase().indexOf(ss) > -1);
  }
  return suggestedTags;
};

export default function TagsSearchOverlay(props: ITagsSearchOverlayProps) {
  const { existingTags, onSelect } = props;
  const { t } = useTranslation();
  const [searchString, setSearchString] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(tagsActions.getAllTags());
  }, [dispatch]);

  let tagsData: ITagNodesData = null;
  tagsData = useSelector((state: IState) => tagsSelectors.selectAllTags(state?.main?.tags))?.data;

  if (!tagsData?.nodes?.length) {
    return <HelperText>{t('tags.noTagsAvailable')}</HelperText>;
  }
  return (
    <ChipAutocomplete
      suggestions={getSuggestions(existingTags, tagsData, searchString)}
      alwaysRenderSuggestions={!!tagsData?.nodes && tagsData?.nodes?.length > 0}
      onSelect={(tag: ITag) => onSelect(tag)}
      renderSuggestion={(tag: ITag) => <SearchItemTag tagText={tag.name} tagColor={tag.color} />}
      onClearRequested={() => setSearchString('')}
      onFetchRequested={value => setSearchString(value)}
      id="id"
      placeholder={t('tags.search')}
    />
  );
}
