import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

import HelperText from 'Components/Common/HelperText';
import ClassicVoteEntry from './ClassicVoteEntry';
import CategoryVoteEntry from './CategoryVoteEntry';
import { get, orderBy } from 'lodash';

const styles = theme => ({
  question: {
    marginBottom: theme.spacing(4),
    backgroundColor: theme.palette.background.box,
    ...theme.shape,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
});

class FormVote extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.sessionId !== this.props.sessionId) {
      return true;
    }
    if (nextProps.facilitationData.ok !== this.props.facilitationData.ok) {
      return true;
    }
    if (nextProps.candidates.ok !== this.props.candidates.ok) {
      return true;
    }
    if (nextProps.ownvotes !== this.props.ownvotes) {
      return true;
    }
    return false;
  }

  render() {
    const { classes, facilitationData, onVoteToggle, candidates, ownvotes, t, sessionId } =
      this.props;
    let questions = [];
    if (facilitationData.ok) {
      // eslint-disable-next-line prefer-destructuring
      questions = facilitationData.questions;
    }

    let votingType = 0;
    // determine how the voting should happen
    // It's possible we haven't got a heartbeat response yet,
    // try to determine the correct voting type from the candidates
    // response

    if (
      facilitationData?.voting_type === 1 ||
      (candidates.ok && get(candidates, ['candidates', 0, 0, 'category_id']))
    ) {
      votingType = 1;
    }

    const CandidateElement = votingType === 1 ? CategoryVoteEntry : ClassicVoteEntry;

    if (!questions || !candidates || !candidates.ok) {
      return null;
    }
    const dataArrays = votingType === 1 ? candidates.candidates : candidates.inputs;
    if (dataArrays) {
      Object.keys(dataArrays).forEach(questionIndex => {
        if (votingType === 1) {
          dataArrays[questionIndex] = orderBy(
            dataArrays[questionIndex],
            [i => i.inputs.length, 'category_id'],
            'desc',
          );
        }
      });
    }
    return (
      <div name="live-dfac-panel-vote-form">
        {questions &&
          questions.map((question, i) => (
            <div className={classes.question} key={`live-dfac-panel-question-${i}`}>
              <Typography
                variant="h6"
                color="text.strong"
                component="span"
                display="block"
                sx={{ mb: 2 }}
              >
                {`${i + 1}/${questions.length}: ${question}`}
              </Typography>
              {dataArrays[i].length === 0 && (
                <HelperText name="no-answers-yet">{t('dfac.sessionnoanswerstovoteon')}</HelperText>
              )}
              {dataArrays[i].map((candidateItem, index) => (
                <CandidateElement
                  sessionId={sessionId}
                  questionIndex={i}
                  candidateIndex={index}
                  candidateItem={candidateItem}
                  ownvotes={ownvotes}
                  onVoteToggle={onVoteToggle}
                />
              ))}
            </div>
          ))}
      </div>
    );
  }
}

FormVote.propTypes = {
  facilitationData: PropTypes.object,
  candidates: PropTypes.object,
  sessionId: PropTypes.string,
  classes: PropTypes.exact({
    question: PropTypes.string,
    inputItem: PropTypes.string,
  }),
  ownvotes: PropTypes.arrayOf(PropTypes.string),
  onVoteToggle: PropTypes.func,
  t: PropTypes.func,
};

export default withTranslation()(withStyles(styles)(FormVote));
