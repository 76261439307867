import React from 'react';
import PropTypes from 'prop-types';
import { extent } from 'd3-array';
import moment from 'moment';
import { withLocation, withNavigation } from 'withRouter';
import { openTopicReplyPanel, openTopicViewSurveyPanel } from 'config/ModalProvider/helpers';
import PulseSelector from './PulseSelector';

class PulseSelectorContainer extends React.Component {
  state = {};

  navigate = surveyID => {
    // Check if this survey is done or in progress and open the appropriate panel
    for (const survey of this.props.surveys) {
      if (survey.surveyID === surveyID) {
        if (survey.status === 'started') {
          openTopicReplyPanel(surveyID, true, this.props.navigate, this.props.location);
        } else {
          openTopicViewSurveyPanel(
            surveyID,
            true,
            this.props.teamID,
            !this.props.cascade,
            this.props.navigate,
            this.props.location,
          );
        }
      }
    }
  };

  render() {
    const { topic, teamID, cascade, values } = this.props;

    if (!topic) {
      return null;
    }
    if (!!!values || values.length === 0) {
      return null;
    }

    // add some buffer to the left of the chart to ensure it renders correctly
    // calculate the total amount of time between the first survey and today, and add 10% of that
    // as "margin"
    const firstSurveyMoment = moment(values[0].timestamp);
    const todayMoment = moment();

    const leftDeltaMinutes = todayMoment.diff(firstSurveyMoment, 'minutes') / 10;
    const leftExtentMoment = moment(firstSurveyMoment).subtract(leftDeltaMinutes, 'minutes');

    const visibleStartDate = leftExtentMoment;
    // add some buffer to the right of the chart
    const rightDeltaMinutes = todayMoment.diff(leftExtentMoment, 'minutes') / 10;
    const rightExtentMoment = moment(todayMoment).add(rightDeltaMinutes, 'minutes');
    const today = rightExtentMoment;

    const xExtent = extent([visibleStartDate, today]);

    return (
      <PulseSelector
        topic={topic}
        selected={this.props.selected}
        teamID={teamID}
        cascade={cascade}
        fetched={this.props.fetched}
        xExtent={xExtent}
        values={values}
        navigate={this.navigate}
        className={this.props.className}
      />
    );
  }
}

PulseSelectorContainer.propTypes = {
  teamID: PropTypes.string,
  topic: PropTypes.object,
  selected: PropTypes.string,
  cascade: PropTypes.bool,
  location: PropTypes.object,
  navigate: PropTypes.func,
  className: PropTypes.string,
  values: PropTypes.array,
  surveys: PropTypes.array,
  fetched: PropTypes.bool,
};

export default withLocation(withNavigation(PulseSelectorContainer));
