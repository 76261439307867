import React, { useEffect, useRef } from 'react';
import { Typography } from '@mui/material';
import Container from 'Components/Library/BaseComponents/Container';
import { instance as axiosInstance } from 'config/axios';
import { APIGW_URL } from 'state/constants/api';
import { useDispatch, useSelector } from 'react-redux';
import AiLoadingIndicator from 'Components/Features/TgAI/AiLoadingIndicator';
import { nanoid } from 'nanoid';
import { facilitationActions, facilitationSelectors } from 'state/ducks/facilitation';

interface SuggesterUiProps {
  sessionId: string;
  teamId: string;
  onSuccess(object): void;
  onError(): void;
}

function makeRequest(retryCount, options) {
  if (!options.context.answers || options.context.answers.length === 0) {
    // there were no answers to this question - no point in sending anything to the API
    return new Promise(function (resolve) {
      resolve({
        config: {
          data: JSON.stringify(options),
        },
        data: {
          result: {
            categories: [],
            categorization: [],
          },
        },
      });
    });
  }
  return axiosInstance
    .post(`${APIGW_URL}/assistant/${options.tenantID}/executeworkflow`, options)
    .catch(err =>
      retryCount < 2 ? makeRequest(retryCount + 1, options) : console.log(err.message),
    );
}

function SuggesterUi(props: SuggesterUiProps) {
  const { sessionId, teamId, onSuccess, onError } = props;

  const tenantID = useSelector((state: any) => state.auth.tenantID);
  const inputs = useSelector((state: any) =>
    facilitationSelectors.selectFacilitationInputs(state.main.facilitation, sessionId),
  );

  const facilitationData = useSelector((state: any) =>
    facilitationSelectors.selectFacilitationSession(state.main.facilitation, sessionId),
  );

  const questionsArr = facilitationData?.questions;

  const dispatch = useDispatch();

  const hasDispatchedRef = useRef(false);

  const start = async () => {
    // Advance session state
    dispatch(
      facilitationActions.progressState({
        requestID: nanoid(10),
        teamID: teamId,
        sessionID: sessionId,
        fromState: 0,
        toState: 1,
      }),
    );
    // Get facilitation answers
    dispatch(
      facilitationActions.getInputs({
        requestID: nanoid(10),
        teamID: teamId,
        sessionID: sessionId,
      }),
    );
  };

  const dispatchAssistantRequests = () => {
    const requests = [];
    Object.keys(inputs.inputs).forEach(questionId => {
      const inputArr = inputs.inputs[questionId].map(d => ({ text: d.input, id: d.input_id }));
      const questionText = questionsArr[questionId];
      requests.push(
        makeRequest(0, {
          tenantID: tenantID,
          workflow_name: 'dfac_grouping',
          context: {
            question: questionText,
            answers: inputArr,
            session_id: sessionId,
            team_id: teamId,
          },
        }),
      );
    });

    Promise.all(requests)
      .then(function (results) {
        const res = {};
        results.forEach(result => {
          const sentPayload = JSON.parse(result.config.data);
          const questionId = questionsArr.indexOf(sentPayload.context.question);
          const response = result.data.result;
          res[questionId] = response;
        });
        onSuccess(res);
      })
      .catch(function (err) {
        console.log(err);
        onError();
      });
  };

  useEffect(() => {
    start();
  }, []);

  useEffect(() => {
    if (inputs.ok && !hasDispatchedRef.current) {
      hasDispatchedRef.current = true;
      dispatchAssistantRequests();
    }
  }, [inputs]);

  return (
    <Container alignItems="stretch" p={1}>
      <AiLoadingIndicator />
      <Typography variant="h6">Facilitating the facilitation</Typography>
      <Typography variant="body2"></Typography>
    </Container>
  );
}

export default SuggesterUi;
