import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { withLocation, withNavigation } from 'withRouter';
import { gameplansActions } from 'state/ducks/gameplans';
import RootDndContextProvider from 'Components/Library/DragAndDrop/RootDndContext';
import NestedDndContext from 'Components/Library/DragAndDrop/NestedDndContext';
import EmphasizedSection from 'Components/Library/EmphasizedSection';
import ConfirmDeleteDialog from 'Components/Library/ConfirmDeleteDialog';
import {
  openEditGameplanCardDialog,
  openViewGameplanCardDialog,
} from 'config/ModalProvider/helpers';
import { peopleSelectors } from 'state/ducks/people';
import { isEnabled } from 'config/flags';
import { appstatusSelectors } from 'state/ducks/appstatus';
import { GP_WIDGET_TYPE_INTERLOCKS } from 'config/constants';
import { WIDGET_TYPE_MAPPING } from '../Widgets/Lib/map';

export class GamePlanDashboard extends Component {
  state = { deleteDialogOpen: false, deleteTarget: null };

  onDragEnd = result => {
    const { dispatch, teamId, gameplan } = this.props;
    const { draggableId, destination, isDestination } = result;
    if (!isDestination) {
      // Do not process the event twice
      return;
    }
    const { index } = destination;

    // The drag event is passed to both the source dropzone
    // and destination dropzone handlers - here they are the same

    // TODO: the game plan ID needs to be included in the dropzone ID
    // when we implement support for more than one gameplan
    const gameplanId = gameplan.id;

    const widgetId = draggableId.replace('gameplan-widget-item-', '');
    const column = parseInt(destination.droppableId.replace('ctx-card-editor-dropzone-', ''), 10);
    dispatch(
      gameplansActions.moveWidget({
        domain_id: teamId,
        gameplan_id: gameplanId,
        widget_id: widgetId,
        position: { column, index },
      }),
    );
  };

  hideWidget = (e, widget) => {
    e.stopPropagation();
    const { dispatch, teamId, gameplan } = this.props;
    if (widget.widget_type === 'CUSTOM_CARD') {
      this.setState({ deleteDialogOpen: true, deleteTarget: widget.id });
    } else {
      const visibility = { ...gameplan.widget_visibility };
      visibility[widget.widget_type] = false;

      dispatch(
        gameplansActions.editGamePlanWidgetsVisibility({
          domain_id: teamId,
          gameplan_id: gameplan.id,
          widget_visibility: visibility,
        }),
      );
    }
  };

  render() {
    const { canEdit, teamId, gameplan, navigate, location, appstatus } = this.props;
    const { deleteDialogOpen, deleteTarget } = this.state;

    return (
      <RootDndContextProvider>
        <Grid container spacing={3}>
          {[0, 1, 2].map(columnIndex => (
            <NestedDndContext
              key={`gameplan-dnd-column-${columnIndex}`}
              onDragEnd={this.onDragEnd}
              droppableId={`ctx-card-editor-dropzone-${columnIndex}`}
            >
              <Droppable
                droppableId={`ctx-card-editor-dropzone-${columnIndex}`}
                type="GAMEPLAN_WIDGET"
              >
                {(provided, snapshot) => (
                  <Grid
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={snapshot.isDraggingOver ? null : null}
                    item
                    xs={12}
                    md={4}
                  >
                    {get(gameplan, ['order', columnIndex], []).map((widgetId, index) => {
                      const widget = get(gameplan, ['widget_data', widgetId]);
                      if (!widget) {
                        return null;
                      }

                      const WidgetType = WIDGET_TYPE_MAPPING[widget.widget_type];
                      if (
                        widget.widget_type === GP_WIDGET_TYPE_INTERLOCKS &&
                        !isEnabled('INTERLOCKS', appstatus)
                      ) {
                        return null;
                      }

                      if (!WidgetType) {
                        return null;
                      }

                      return (
                        <Draggable
                          key={`gameplan-widget-item-${widgetId}`}
                          draggableId={`gameplan-widget-item-${widgetId}`}
                          index={index}
                          isDragDisabled={!canEdit}
                        >
                          {draggableProvided => (
                            <div
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}
                              name={`gameplan-dashboard-${widget.widget_type}-widget`}
                            >
                              <Box sx={{ pb: 3 }}>
                                <EmphasizedSection
                                  tabIndex={0}
                                  sx={{
                                    p: 0,
                                    borderRadius: '15px',
                                    transition: 'box-shadow .15s ease-out',
                                    '& .widget-content': {
                                      transition: 'box-shadow .15s ease-out',
                                    },
                                    '&:not(:hover):not(:focus-within)': {
                                      '& .gp-widget-actions': { opacity: 0 },
                                    },
                                    '&:hover, &:focus-within': {
                                      boxShadow: theme =>
                                        `inset 0px 0px 0px 1000px ${theme.palette.action.hover}`,
                                      '& .widget-content': {
                                        boxShadow: theme =>
                                          `inset 0px 0px 0px 1000px ${theme.palette.action.hover}`,
                                      },
                                    },
                                  }}
                                  onClick={e => {
                                    // react-beautiful-dnd lets events propagate, but marks defaultPrevented
                                    // as true - ignore those events as we can assume that they are related
                                    // to the DnD functionality
                                    if (!e.defaultPrevented) {
                                      openViewGameplanCardDialog(
                                        teamId,
                                        gameplan.id,
                                        widget.id,
                                        navigate,
                                        location,
                                      );
                                    }
                                  }}
                                >
                                  <WidgetType
                                    teamId={teamId}
                                    gameplanId={gameplan.id}
                                    data={widget}
                                    index={index}
                                    dragHandleProps={draggableProvided.dragHandleProps}
                                    key={`gameplan-dashboard-${widget.id}`}
                                    requestEdit={e => {
                                      e.stopPropagation();
                                      openEditGameplanCardDialog(
                                        teamId,
                                        gameplan.id,
                                        widget.id,
                                        navigate,
                                        location,
                                      );
                                    }}
                                    requestRemove={e => this.hideWidget(e, widget)}
                                    canEdit={canEdit}
                                  />
                                </EmphasizedSection>
                                {deleteDialogOpen && deleteTarget === widget.id && (
                                  <ConfirmDeleteDialog
                                    onCancel={() =>
                                      this.setState({
                                        deleteDialogOpen: false,
                                        deleteTarget: null,
                                      })
                                    }
                                    name={widget.name}
                                    actionProperties={{
                                      widget_id: widget.id,
                                      domain_id: teamId,
                                    }}
                                    instanceName="gameplans"
                                    submitActionCreator={gameplansActions.deleteWidget}
                                  />
                                )}
                              </Box>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </Grid>
                )}
              </Droppable>
            </NestedDndContext>
          ))}
        </Grid>
      </RootDndContextProvider>
    );
  }
}

GamePlanDashboard.propTypes = {
  dispatch: PropTypes.func,
  gameplan: PropTypes.object,
  teamId: PropTypes.string,
  location: PropTypes.object,
  navigate: PropTypes.func,
  canEdit: PropTypes.bool,
  periodConfig: PropTypes.object,
  appstatus: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => {
  const appstatus = appstatusSelectors.selectStatus(state.main.appstatus);

  return {
    appstatus,
    canEdit:
      state.auth.isChangeManager ||
      peopleSelectors
        .selectManagedTeams(state.main.people, state.auth.myTeams, state.auth.userID)
        .includes(ownProps.teamId),
  };
};

export default connect(mapStateToProps)(withLocation(withNavigation(GamePlanDashboard)));
