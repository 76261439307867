import FormContext from 'Components/Library/Forms/FormContext';
import InfoElement from 'Components/Library/InfoElement';
import useGameplanData from 'hooks/useGameplanData';
import { CORE_WIDGETS, WIDGET_TYPE_LOCALIZATION_KEYS } from './index';
import { intersection } from 'lodash';
import { useTranslation } from 'react-i18next';

interface OverwriteWarningProps {
  targetGameplanId: string;
  sourceGameplan: any;
  teamId: string;
}

function OverwriteWarning(props: OverwriteWarningProps) {
  const { sourceGameplan, targetGameplanId, teamId } = props;
  const { t } = useTranslation();
  const gameplans = useGameplanData(teamId);

  if (!targetGameplanId || !gameplans.ok) {
    return null;
  }

  const targetGameplanData = gameplans.data.find(gp => gp.id === targetGameplanId);

  return (
    <FormContext.Consumer>
      {formContext => {
        const enabledWidgets = Object.keys(formContext.values.widgets_to_copy)
          .map(widgetId => widgetId)
          .filter(widgetId => formContext.values.widgets_to_copy[widgetId]);
        const selectedCoreWidgets = enabledWidgets
          .map(widgetId => sourceGameplan.widget_data[widgetId])
          .filter(
            widgetData =>
              widgetData &&
              CORE_WIDGETS.includes(widgetData.widget_type) &&
              formContext.values.widgets_to_copy[widgetData.id],
          )
          .map(widgetData => widgetData.widget_type);

        const existingCoreWidgets = Object.values(targetGameplanData.widget_data)
          // @ts-ignore
          .filter(w => CORE_WIDGETS.includes(w.widget_type))
          // @ts-ignore
          .map(widgetData => widgetData.widget_type);

        const clash = intersection(selectedCoreWidgets, existingCoreWidgets);
        if (!clash || clash.length === 0) {
          return null;
        }

        return (
          <InfoElement variant="warning">
            {t('gameplans.copyMenu.overwriteWarning')}
            <br />
            <ul>
              {clash.map(widgetType => (
                <li key={`copyform-overwrite-warning${widgetType}`}>
                  {t(`gameplans.addMenu.${WIDGET_TYPE_LOCALIZATION_KEYS[widgetType]}`)}
                </li>
              ))}
            </ul>
          </InfoElement>
        );
      }}
    </FormContext.Consumer>
  );
}

export default OverwriteWarning;
