import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import ObjectiveChip from 'Components/Features/Objectives/ObjectiveChip';

import { objectivesSelectors } from 'state/ducks/objectives';

const styles = theme => ({
  root: {
    minHeight: 200,
    paddingLeft: theme.spacing(2),
  },
});

class ObjectivesList extends Component {
  render() {
    const {
      classes,
      stperiod,
      type,
      domain,
      selectCompanyObjectives,
      selectTeamObjectives,
      selectPersonalObjectives,
      onSelect,
      objectiveID = '',
    } = this.props;
    let objectiveData;
    if (!!stperiod && !!type) {
      if (type === 'CO') {
        objectiveData = selectCompanyObjectives(stperiod);
      } else if (!!domain) {
        if (domain.type === 'personal') {
          objectiveData = selectPersonalObjectives(stperiod, domain.sub);
        } else if (domain.type === 'team') {
          objectiveData = selectTeamObjectives(stperiod, domain.teamId);
        }
      }
    }
    return (
      <div className={classes.root}>
        <RadioGroup
          aria-label="gender"
          name="gender1"
          value={objectiveID}
          onChange={e => onSelect(e.target.value)}
        >
          {!!objectiveData &&
            !!objectiveData.ok &&
            objectiveData.data.map(objectiveID2 => (
              <FormControlLabel
                value={objectiveID2}
                key={`ctx-okr-card-editor-result-${objectiveID2}`}
                control={<Radio id={`ctx-okr-card-editor-result-${objectiveID2}`} />}
                onClick={() => onSelect(objectiveID2)}
                label={
                  <div>
                    <ObjectiveChip
                      fetch
                      name={`ctx-okr-card-editor-result-${objectiveID2}`}
                      objectiveID={objectiveID2}
                      color="transparent"
                    />
                  </div>
                }
              />
            ))}
        </RadioGroup>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectCompanyObjectives: stperiod =>
    objectivesSelectors.selectCompanyObjectives(state.main.objectives, stperiod),
  selectTeamObjectives: (stperiod, teamID) =>
    objectivesSelectors.selectTeamObjectives(state.main.objectives, teamID, stperiod),
  selectPersonalObjectives: (stperiod, sub) =>
    objectivesSelectors.selectRelatedObjectives(state.main.objectives, sub, stperiod),
});

ObjectivesList.propTypes = {
  stperiod: PropTypes.string,
  classes: PropTypes.exact({
    root: PropTypes.string,
  }),
  type: PropTypes.string,
  domain: PropTypes.object,
  selectCompanyObjectives: PropTypes.func,
  selectTeamObjectives: PropTypes.func,
  selectPersonalObjectives: PropTypes.func,
  onSelect: PropTypes.func,
  objectiveID: PropTypes.string,
};

export default connect(mapStateToProps)(withStyles(styles)(ObjectivesList));
