import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';

import LinkIcon from '@mui/icons-material/Link';
import { roundPercent } from 'config/helpers';
import AiGroupingFlow from './AiGroupingFlow';

class ManagerMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      managerConfirmOpen: false,
      selectVotingStyleDialogOpen: false,
      linkCopied: false,
    };
  }

  getSessionUrl = () => {
    const url = window.location.href;
    const { tenantID } = this.props;
    return url.replace('://', `://${tenantID}.`);
  };

  closeSnackbar = () => {
    this.setState({ linkCopied: false });
  };

  copyUrl = () => {
    navigator.clipboard.writeText(this.getSessionUrl());
    this.setState({ linkCopied: true });
  };

  managerGoForward = () => {
    this.managerCloseConfirm();
    this.props.action();
  };

  managerRequestForward = () => {
    if (this.props.ownState < 2 && this.props.enableAi) {
      // Check if the facilitator wants to merge data
      this.setState({ selectVotingStyleDialogOpen: true });
    } else if (this.arePeopleDone(this.props.ownState)) {
      this.props.action();
    } else {
      this.setState({ managerConfirmOpen: true });
    }
  };

  managerCloseConfirm = () => {
    this.setState({ managerConfirmOpen: false });
  };

  arePeopleDone = () => {
    if (this.props.ownState === 1 || this.props.ownState === 3) {
      if (!!this.props.members) {
        return this.props.doneUsers.length === this.props.members.length;
      }
      return false;
    }
    return true;
  };

  managerClickLogic = () => {
    if (this.props.ownState === 0 || this.props.ownState === 2) {
      if (this.props.confirmationDialogOpen) {
        this.props.action();
      } else {
        this.props.requestForward(this.props.ownState);
      }
    }
    if (this.props.ownState === 1 || this.props.ownState === 3 || this.props.ownState === 4) {
      this.managerRequestForward();
    }
    return null;
  };

  getRates = () => {
    if (!!this.props.members) {
      if (this.props.ownState === 1 && this.props.members.length > 0) {
        return {
          answerrate: roundPercent(this.props.doneUsers.length / this.props.members.length),
        };
      }
      if (this.props.ownState === 3 && this.props.members.length > 0) {
        return {
          voterate: roundPercent(this.props.doneUsers.length / this.props.members.length),
        };
      }
    }
    return null;
  };

  render() {
    const {
      ownState,
      label,
      t,
      teamID,
      sessionID,
      disabled = false,
      submitDisabled = false,
    } = this.props;
    const { linkCopied } = this.state;
    return (
      <DialogActions
        id="manager-menu"
        sx={{
          borderTop: theme => `1px solid ${theme.palette.divider}`,
          justifyContent: 'space-between',
        }}
      >
        <Snackbar
          open={linkCopied}
          message={t('dfac.share')}
          onClose={this.closeSnackbar}
          autoHideDuration={5000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        />
        <Button
          variant="text"
          startIcon={<LinkIcon />}
          onClick={this.copyUrl}
          id="manager-menu-copy-link"
        >
          {t('dfac.copylink')}
        </Button>
        {!disabled && (
          <Button
            color="secondary"
            variant="contained"
            onClick={this.managerClickLogic}
            id="manager-menu-submit-answers"
            disabled={!!submitDisabled}
          >
            {label}
          </Button>
        )}
        {this.state.managerConfirmOpen && (ownState === 1 || ownState === 3) && (
          <Dialog open>
            <DialogContent>
              <Typography variant="h5" sx={{ mb: 2 }}>
                {t(`dfac.managerdialog.${ownState}.title`)}
              </Typography>
              <Typography variant="body1" paragraph>
                {t(`dfac.managerdialog.${ownState}.p1`, this.getRates())}
              </Typography>
              <Typography variant="body1" paragraph>
                {t(`dfac.managerdialog.${ownState}.p2`)}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.managerCloseConfirm}
                name="dfac-confirm-managerdialog-cancel-btn"
                variant="text"
              >
                {t(`dfac.managerdialog.${ownState}.cancel`)}
              </Button>
              <Button
                name="dfac-confirm-managerdialog-submit-btn"
                variant="contained"
                onClick={() => this.managerGoForward(ownState)}
                color="secondary"
              >
                {t(`dfac.managerdialog.${ownState}.moveto`)}
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {this.state.selectVotingStyleDialogOpen && (
          <AiGroupingFlow
            startClassicVote={() => {
              this.managerGoForward(ownState);
              this.setState({ selectVotingStyleDialogOpen: false });
            }}
            onClose={() => {
              this.setState({ selectVotingStyleDialogOpen: false });
            }}
            teamId={teamID}
            sessionId={sessionID}
          />
        )}
      </DialogActions>
    );
  }
}

ManagerMenu.propTypes = {
  ownState: PropTypes.number,
  action: PropTypes.func,
  label: PropTypes.string,
  t: PropTypes.func,
  requestForward: PropTypes.func,
  confirmationDialogOpen: PropTypes.bool,
  doneUsers: PropTypes.array,
  members: PropTypes.array,
  disabled: PropTypes.bool,
  submitDisabled: PropTypes.bool,
  tenantID: PropTypes.string,
  teamID: PropTypes.string,
  sessionID: PropTypes.string,
  enableAi: PropTypes.bool,
};

export default withTranslation()(ManagerMenu);
