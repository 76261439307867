import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import AttachmentIcon from '@mui/icons-material/CallMade';

import { CTX_ELEMENT_MAX_URL_STR_LENGTH } from 'config/constants';

const isValidUrl = require('is-url');

export const validate = item => !!item.src && isValidUrl(item.src);

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  light: {
    '& a': {
      color: theme.palette.text.primary,
      textDecoration: 'none',
    },
  },
  dark: {
    '& a': {
      color: 'rgba(255,255,255,0.87)',
      textDecoration: 'none',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    overflow: 'hidden',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  wrap: {
    wordWrap: 'break-word',
  },
});

class CardAttachment extends Component {
  state = {
    urlParseResult: null,
    error: false,
    stateText: '',
    stateSrc: '',
  };

  componentDidMount() {
    this.setState({ stateText: this.props.item.text });
    if (!!this.props.item && !!this.props.item.src) {
      this.setState({ stateSrc: this.props.item.src });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  urlChange = e => {
    const isValid = isValidUrl(e.target.value);
    this.setState({
      stateSrc: e.target.value,
      urlParseResult: { message: isValid ? 'ok' : 'url invalid' },
    });
  };

  nameChange = e => {
    this.setState({ stateText: e.target.value });
  };

  closeDialog = () => {
    const { item, closeSettings, deleteItem } = this.props;
    const { src } = item;
    if (!src) {
      // this item was just being created, let's abort creating it
      deleteItem(true);
    } else {
      closeSettings();
    }
  };

  setUrl = () => {
    const { stateSrc, stateText } = this.state;
    if (!!stateSrc) {
      this.props.onEdit({ src: stateSrc, text: stateText, __cfg: { settingsOpen: false } });
    } else {
      this.setState({
        error: 'Could not parse URL',
      });
      this.timeout = setTimeout(
        function settingstate() {
          this.setState({ error: null });
        }.bind(this),
        1500,
      );
    }
  };

  render() {
    const { classes, className, item, editable, cardTheme, key, id, t } = this.props;
    const { src, __cfg = {}, text } = item;
    const { settingsOpen } = __cfg;
    const { error, urlParseResult, stateSrc, stateText } = this.state;

    return (
      <div
        className={clsx([classes.root, classes[cardTheme], className])}
        data-tg-name="ctx-card-el-attachment"
        key={key}
        id={id}
      >
        {!!src && (
          <a href={src} rel="noopener noreferrer" target="_blank">
            <div className={classes.content}>
              <AttachmentIcon className={classes.icon} />
              <Typography variant="body2" className={classes.wrap}>
                {!!text ? text : src}
              </Typography>
            </div>
          </a>
        )}
        {!!editable && !!settingsOpen && (
          <Dialog classes={{ root: classes.root }} fullWidth maxWidth="sm" open>
            <DialogContent>
              <Typography variant="h6" gutterBottom>
                {t('contextv2.cards.addlinktitle')}
              </Typography>
              <br />
              <TextField
                value={stateSrc}
                onChange={this.urlChange}
                required
                maxLength={CTX_ELEMENT_MAX_URL_STR_LENGTH}
                label={t('contextv2.cards.linkurlinputlabel')}
                variant="filled"
                fullWidth
                error={Boolean(error)}
                FormHelperTextProps={{
                  name: 'ctx-card-editor-attachment-url-input-helper',
                }}
                helperText={urlParseResult && urlParseResult.message ? urlParseResult.message : ' '}
              />
              <br />
              <br />
              <TextField
                value={stateText}
                onChange={this.nameChange}
                maxLength={CTX_ELEMENT_MAX_URL_STR_LENGTH}
                label={t('contextv2.cards.linkdisplaynameinputlabel')}
                variant="filled"
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button name="ctx-card-attachment-cancel-btn" onClick={this.closeDialog}>
                {t('general.cancel')}
              </Button>
              <Button
                color="secondary"
                disabled={error || !stateSrc}
                variant="contained"
                onClick={this.setUrl}
                name="ctx-card-attachment-save-btn"
              >
                {t('general.save')}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    );
  }
}

CardAttachment.propTypes = {
  item: PropTypes.object,
  classes: PropTypes.exact({
    root: PropTypes.string,
    light: PropTypes.string,
    dark: PropTypes.string,
    content: PropTypes.string,
    icon: PropTypes.string,
    wrap: PropTypes.string,
  }),
  closeSettings: PropTypes.func,
  deleteItem: PropTypes.func,
  onEdit: PropTypes.func,
  editable: PropTypes.bool,
  cardTheme: PropTypes.string,
  key: PropTypes.string,
  id: PropTypes.string,
  t: PropTypes.func,
};

export default withTranslation()(withStyles(styles)(CardAttachment));
