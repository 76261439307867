import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import Dialog from '@mui/material/Dialog';
import Zoom from '@mui/material/Zoom';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { contextSelectors } from 'state/ducks/context';
import { settingsSelectors } from 'state/ducks/usersettings';
import { types } from './CardComponents';

const styles = theme => ({
  root: {
    margin: 0,
    padding: 0,
    position: 'relative',
  },
  dialogcontent: {
    margin: 0,
    padding: 0,
    position: 'relative',
    flexGrow: 10,
    overflowY: 'auto',
  },
  dark: {
    backgroundColor: theme.palette.primary.main,
  },
  closeButton: {
    display: 'none',
    position: 'absolute',
    top: 0,
    right: 0,
    '& > button': {
      backgroundColor: 'rgba(255,255,255,0.1)',
    },
  },
  dialogpaper: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '100%',
      margin: 0,
      padding: 0,
      maxHeight: '100%',
      maxWidth: '100%',
      borderRadius: 0,
      '& $closeButton': {
        display: 'block',
      },
    },
  },
});

class DialogContextCard extends Component {
  onClose = () => {
    this.props.onClose();
  };

  render() {
    const { classes, card, contextID, zIndexModifier, t, darkmode } = this.props;
    if (!!!card || !!!card.id) {
      return null;
    }

    const appliedTheme = darkmode ? 'light' : card.cardtheme;
    return (
      <Dialog
        TransitionComponent={Zoom}
        open
        onClose={this.onClose}
        classes={{ paper: classes.dialogpaper }}
        fullWidth
        maxWidth="md"
        id={`ctx-card-dialog-${card.id}`}
        name="ctx-card-dialog"
        sx={{ zIndex: 1250 + zIndexModifier }}
      >
        <div className={clsx([classes.dialogcontent, classes[appliedTheme]])}>
          {card.elements.map((element, index) => {
            if (!!!element.hide_expanded) {
              const Element = types[element.type];
              return (
                <Element
                  item={element}
                  cardTheme={appliedTheme}
                  isLast={index + 1 === card.elements.length}
                  key={`ctx-card-dialog-el-${card.id}-${element.id}`}
                  id={`ctx-card-dialog-el-${card.id}-${element.id}`}
                  cardId={card.id}
                  contextID={contextID}
                  mode="expanded"
                />
              );
            }
            return null;
          })}
        </div>
        <div className={classes.closeButton}>
          <IconButton
            name="ctx-card-dialog-close-btn"
            onClick={this.onClose}
            aria-label={t('general.close')}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let cardProp = ownProps.card;
  if (!!!cardProp && ownProps.cardID) {
    cardProp = contextSelectors.selectContextCard(
      state.main.context,
      ownProps.contextID,
      ownProps.contextType,
      ownProps.cardID,
    );
  }
  return {
    card: cardProp,
    darkmode: settingsSelectors.selectAttribute(state.usersettings, 'darkmode'),
  };
};

DialogContextCard.propTypes = {
  card: PropTypes.object,
  classes: PropTypes.exact({
    root: PropTypes.string,
    dialogcontent: PropTypes.string,
    dark: PropTypes.string,
    closeButton: PropTypes.string,
    dialogpaper: PropTypes.string,
  }),
  onClose: PropTypes.func,
  contextID: PropTypes.string,
  zIndexModifier: PropTypes.number,
  t: PropTypes.func,
  darkmode: PropTypes.bool,
};

export default withTranslation()(connect(mapStateToProps)(withStyles(styles)(DialogContextCard)));
