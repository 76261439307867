import EmptyState from '../EmptyState';
import { findBestCopyableGameplan } from '../helpers';
import useFeatureFlag from 'hooks/useFeatureFlag';
import Button from 'Components/Library/BaseComponents/Button';
import { ContentCopy } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { peopleSelectors } from 'state/ducks/people';
import AskAiButton from '../AddGamePlanButton/AiGameplanSuggestionsFlow/AskAiButton';
import { objectivesSelectors } from 'state/ducks/objectives';
import CopyGamePlanDialog from '../AddGamePlanButton/CopyGamePlanDialog';
import { useState } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import AddGamePlanMenu from '../AddGamePlanButton/AddGamePlanMenu';

type Period = {
  stPeriodId: string;
};
interface EmptyStateWithActionsProps {
  isPastPeriod: Boolean;
  selectedPeriod: string;
  periodConfig: any;
  gameplansPerPeriod: any;
  teamId: string;
  isEmptyGameplan: Boolean;
  handlePeriodChange(arg0: Period): void;
}

function EmptyStateWithActions(props: EmptyStateWithActionsProps) {
  const {
    handlePeriodChange,
    isPastPeriod,
    selectedPeriod,
    periodConfig,
    gameplansPerPeriod,
    teamId,
    isEmptyGameplan,
  } = props;

  const [copyFlowOpen, setCopyFlowOpen] = useState(false);
  const [addMenuAnchorElement, setAddMenuAnchorElement] = useState(null);

  const currentStPeriod = periodConfig?.data?.activeperiod?.stperiod;
  const ltPeriod = useSelector((state: any) =>
    objectivesSelectors.selectLtPeriodForStPeriod(state.main.objectives, selectedPeriod),
  );

  const { t, i18n } = useTranslation();
  const usePeriodicGameplans = useFeatureFlag('PERIODIC-GAMEPLANS');
  const aiEnabled = useFeatureFlag('OTHER-AI');

  const isTeamLead = useSelector(
    (state: any) =>
      state.auth.userID === peopleSelectors.selectTeamManager(state.main.people, teamId),
  );

  const periodName = useSelector((state: any) =>
    objectivesSelectors.selectPeriodName(state.main.objectives, { stPeriodId: selectedPeriod }),
  );

  if (!usePeriodicGameplans) {
    // Legacy use case, periodic game plans in use but no game plan: show empty state
    // without actions
    return <EmptyState title={t('gameplans.emptystate.noGameplanGenericTitle')} />;
  }

  // Periodic game plans, there are a few possible scenarios to handle:
  let actions = [];
  let title;
  let subtitle = '';

  if (isTeamLead && !isEmptyGameplan && !isPastPeriod) {
    if (currentStPeriod !== selectedPeriod) {
      // Future period
      const startDate = moment(
        new Date(periodConfig.data.stperiodconfigs[selectedPeriod]?.periodStart),
      );
      const now = moment();
      now.locale(i18n.language);

      const startsIn = t('general.startsIn', {
        localizedDeltaStr: now.to(startDate),
      });
      title = (
        <>
          {startsIn}
          <br />
          {t('gameplans.emptystate.noCurrentGameplanEditTitle', {
            period: periodName,
          })}
        </>
      );
    } else {
      title = t('gameplans.emptystate.noCurrentGameplanEditTitle', {
        period: periodName,
      });
    }
    subtitle = aiEnabled
      ? t('gameplans.emptystate.copyGameplanWithAiSubtitle')
      : t('gameplans.emptystate.copyGameplanSubtitle');

    if (findBestCopyableGameplan(periodConfig, selectedPeriod, gameplansPerPeriod, false)) {
      actions.push(
        <>
          <Button
            key="gameplan-emptystate-copy-button"
            size="large"
            color="secondary"
            variant="outlined"
            startIcon={<ContentCopy fontSize="inherit" />}
            onClick={() => setCopyFlowOpen(true)}
          >
            {t('gameplans.emptystate.continueCTA')}
          </Button>
          {copyFlowOpen && (
            <CopyGamePlanDialog
              targetPeriod={selectedPeriod}
              teamId={teamId}
              open
              onClose={() => setCopyFlowOpen(false)}
              onSuccess={() => setCopyFlowOpen(false)}
              variant="continue-flow"
            />
          )}
        </>,
      );
    } else {
      actions.push(
        <Button
          key="gameplan-emptystate-generic-add-button"
          size="large"
          color="secondary"
          variant="outlined"
          onClick={e => setAddMenuAnchorElement(e.target)}
        >
          {t('gameplans.emptystate.genericAddGameplanCTA')}
        </Button>,
      );
    }
    if (aiEnabled) {
      actions.push(
        <AskAiButton
          key="gameplan-emptystate-ask-ai-button"
          teamId={teamId}
          stperiod={selectedPeriod}
          ltperiod={ltPeriod}
          onSuccess={() => {}}
        />,
      );
    }
  } else {
    title = isEmptyGameplan
      ? t('gameplans.emptystate.emptyGameplanTitle')
      : t('gameplans.emptystate.noGameplanGenericTitle');

    if (!isEmpty(gameplansPerPeriod)) {
      const linkTo =
        currentStPeriod in gameplansPerPeriod && selectedPeriod !== currentStPeriod
          ? currentStPeriod
          : findBestCopyableGameplan(periodConfig, currentStPeriod, gameplansPerPeriod);
      if (linkTo) {
        subtitle = isEmptyGameplan
          ? t('gameplans.emptystate.emptyGameplanSubtitle')
          : t('gameplans.emptystate.viewLatestSubtitle');
        actions.push(
          <Button
            key="gameplan-emptystate-view-latest-button"
            size="large"
            color="secondary"
            onClick={() =>
              handlePeriodChange({
                stPeriodId: linkTo,
              })
            }
          >
            {t('gameplans.emptystate.viewLatestCTA')}
          </Button>,
        );
      }
    }
  }
  return (
    <>
      <EmptyState title={title} subtitle={subtitle} actions={actions} />
      {Boolean(addMenuAnchorElement) && (
        <AddGamePlanMenu
          anchorEl={addMenuAnchorElement}
          onClose={() => setAddMenuAnchorElement(null)}
          stperiod={selectedPeriod}
          ltperiod={ltPeriod}
          usePeriodicGameplans={usePeriodicGameplans}
          teamId={teamId}
        />
      )}
    </>
  );
}

export default EmptyStateWithActions;
