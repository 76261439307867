import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Skeleton, Box, Stack, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import NestedDndContext from 'Components/Library/DragAndDrop/NestedDndContext';
import PeriodNavigator from 'Components/Features/Objectives/PeriodNavigator';
import ObjectiveChip from 'Components/Features/Objectives/ObjectiveChip';
import FeatureIcon from 'Components/Features/Objectives/FeatureIcon';
import AddObjectiveButton from 'Components/Features/Objectives/AddObjectiveButton';
import ContainedIconButton from 'Components/Library/ContainedIconButton';
import useDomainOkrs from 'hooks/useDomainOkrs';
import useObjectiveSorting from 'hooks/useObjectiveSorting';

function ObjectivesWidgetCard({
  dragHandleProps,
  canEdit,
  requestRemove,
  appliedPeriod,
  nextShortTermPeriod,
  prevShortTermPeriod,
  usePeriodicGameplans,
  teamId,
  selectPeriod,
  name,
}) {
  const { t } = useTranslation();

  const domain = useMemo(() => ({ t: 'team', d: teamId }), [teamId]);

  const { okrs } = useDomainOkrs([domain], appliedPeriod);
  const objectiveData = okrs[0];

  const domainStr = useMemo(() => `${domain.t}-${domain.d}`, [domain]);

  const { onDragEnd, getDraggableId } = useObjectiveSorting(
    objectiveData,
    domain,
    appliedPeriod,
    `gameplan-okr-widget-${domainStr}`,
  );

  return (
    <>
      <Box
        sx={{
          p: 2,
          pb: 1,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'stretch',
        }}
        {...dragHandleProps}
        name={name}
      >
        <Stack
          direction="column"
          alignItems="stretch"
          spacing={2}
          sx={{ flexBasis: '100%', overflowX: 'hidden' }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center">
              <FeatureIcon sx={{ mr: 1 }} />
              <Typography variant="subtitle2">{t('objectives.pageTitle')}</Typography>
            </Stack>
            {canEdit && (
              <Stack
                direction="row"
                spacing={1}
                className="gp-widget-actions"
                onClick={e => e.stopPropagation()}
              >
                <ContainedIconButton onClick={requestRemove} name="gameplans-remove-widget-button">
                  <DeleteIcon />
                </ContainedIconButton>
                <AddObjectiveButton stperiod={appliedPeriod} teamId={teamId} variant="contained" />
              </Stack>
            )}
          </Stack>
          {!usePeriodicGameplans && (
            <PeriodNavigator
              color="white"
              selectedPeriod={appliedPeriod}
              handlePeriodChange={p => selectPeriod(p.stPeriodId)}
              prevPeriod={prevShortTermPeriod}
              nextPeriod={nextShortTermPeriod}
              fullWidth
            />
          )}
        </Stack>
      </Box>
      <NestedDndContext onDragEnd={onDragEnd} droppableId={`okr-table-droppable-${domainStr}`}>
        <Droppable droppableId={`okr-table-droppable-${domainStr}`} type="objective">
          {(provided, snapshot) => (
            <Box sx={{ pl: 2, pr: 2 }} {...provided.droppableProps} ref={provided.innerRef}>
              {objectiveData &&
                objectiveData.ok &&
                objectiveData.data.map((objective, index) => (
                  <Draggable
                    draggableId={getDraggableId(objective.data.objectiveID)}
                    index={index}
                    key={getDraggableId(objective.data.objectiveID)}
                    isDragDisabled={!canEdit}
                    disableInteractiveElementBlocking
                  >
                    {(prov, snap) => (
                      <Box
                        key={`objectives-widget-${objective.data.objectiveID}`}
                        sx={{
                          mt: 1,
                          backgroundColor: 'background.paper',
                          borderRadius: 1,
                          p: 1,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'stretch',
                          boxShadow: snap.isDragging ? 5 : 0,
                        }}
                        className="widget-content"
                        ref={prov.innerRef}
                        {...prov.draggableProps}
                        {...prov.dragHandleProps}
                      >
                        <ObjectiveChip
                          objectiveData={objective}
                          canEdit={canEdit}
                          color="transparent"
                          ContextIcon={null}
                          allowNavigate
                          variant="xl"
                        />
                      </Box>
                    )}
                  </Draggable>
                ))}
              {objectiveData && !objectiveData.ok && objectiveData.loading && (
                <Skeleton
                  height={80}
                  variant="rounded"
                  sx={{ mb: 2, bgcolor: 'background.paper' }}
                />
              )}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </NestedDndContext>
      <Box sx={{ height: 16 }} {...dragHandleProps} />
    </>
  );
}

ObjectivesWidgetCard.propTypes = {
  dragHandleProps: PropTypes.object,
  canEdit: PropTypes.bool,
  requestRemove: PropTypes.func,
  appliedPeriod: PropTypes.string,
  usePeriodicGameplans: PropTypes.bool,
  nextShortTermPeriod: PropTypes.string,
  prevShortTermPeriod: PropTypes.string,
  teamId: PropTypes.string,
  selectPeriod: PropTypes.func,
  name: PropTypes.string,
};

export default ObjectivesWidgetCard;
