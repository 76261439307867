import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';

import Input from '@mui/material/Input';
import { Typography } from '@mui/material';

const styles = theme => {
  const classes = {
    disabled: {
      backgroundColor: 'rgba(0,0,0,0)',
    },
    input: {
      padding: 0,
    },
    multiline: {
      backgroundColor: 'rgba(0,0,0,0)',
    },
    focused: {
      backgroundColor: 'rgba(0,0,0,0.05)',
    },
    dark: {
      color: 'white',
      '& textarea, input, p, span, h1, h2, h3, h4, h5, h6': {
        color: 'rgba(255,255,255,0.87)',
      },
    },
    light: {
      color: '#000',
    },
    alignright: {
      textAlign: 'right !important',
    },
    alignleft: {
      textAlign: 'left !important',
    },
    row: {
      display: 'block',
    },
  };
  Object.keys(theme.typography).forEach(key => {
    classes[key] = { ...theme.typography[key] };
    classes[`multiline${key}`] = {
      letterSpacing: theme.typography[key].letterSpacing,
    };
  });
  /*  overrides for specific cases */
  classes.overline = {
    ...classes.overline,
    paddingBottom: 0,
  };
  return classes;
};

class EditableTypography extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
  }

  componentDidMount() {
    // TODO: remove this once autofocus works as in eng-611
    this.focusTextInput();
  }

  componentDidUpdate() {
    // set the carret to correct location if needed
    if (
      this.textInput.current &&
      this.state &&
      this.state.selectionStart &&
      this.state.selectionEnd
    ) {
      this.textInput.current.setSelectionRange(this.state.selectionStart, this.state.selectionEnd);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  // TODO: remove this once autofocus works as in eng-611
  focusTextInput = () => {
    if (!!this.textInput.current && this.props.autoFocus) {
      this.timeout = setTimeout(() => {
        this.textInput.current.focus();
      }, 1);
    }
  };

  placeholderTextSelector = (placeholder, variant) => {
    if (variant === 'overline' && !!placeholder) {
      return this.props.t(`contextv2.cards.types.${placeholder}`).toUpperCase();
    }
    if (placeholder === 'Lorem ipsum') {
      return 'Lorem ipsum';
    }
    return this.props.t(`contextv2.cards.types.${placeholder.toLowerCase()}`);
  };

  onChange = event => {
    this.props.onChange(event.target.value);
    // save the carret position to set in the correct location after the update
    this.setState({
      selectionStart: event.target.selectionStart,
      selectionEnd: event.target.selectionEnd,
    });
  };

  render() {
    const {
      classes,
      theme = 'light',
      className,
      name,
      id,
      disabled,
      value,
      align = 'left',
      placeholder = 'Lorem ipsum',
      variant = 'body1',
      editable,
      autoFocus = false,
      maxLength,
    } = this.props;

    if (!editable) {
      return (
        <div
          name={name}
          className={clsx([
            classes[theme],
            variant === 'overline' ? className : null,
            classes[`align${align}`],
          ])}
        >
          {!!value &&
            value.split('\n').map((row, index) => {
              if (!!row) {
                return (
                  <Typography
                    key={`editable-typo-${index}`}
                    align={align}
                    variant={variant}
                    className={clsx([variant !== 'overline' ? className : null, classes.row])}
                  >
                    {row}
                  </Typography>
                );
              }
              return (
                <Typography
                  key={`editable-typo-${index}`}
                  align={align}
                  variant={variant}
                  className={clsx([variant !== 'overline' ? className : null])}
                >
                  &nbsp;
                </Typography>
              );
            })}
        </div>
      );
    }
    return (
      <div>
        <Input
          className={clsx([classes.input, classes[theme], classes[variant], className])}
          id={id}
          fullWidth
          autoFocus={autoFocus}
          multiline
          name={name}
          disabled={disabled}
          disableUnderline
          onChange={this.onChange}
          inputProps={{ maxLength }}
          classes={{
            focused: classes.focused,
            disabled: classes.disabled,
            multiline: clsx([
              classes.multiline,
              classes[`multiline${variant}`],
              classes[`align${align}`],
            ]),
            input: clsx([
              classes.multiline,
              classes[`multiline${variant}`],
              classes[`align${align}`],
            ]),
          }}
          value={variant === 'overline' && !!value ? value.toUpperCase() : value}
          placeholder={this.placeholderTextSelector(placeholder, variant)}
          inputRef={this.textInput}
        />
      </div>
    );
  }
}

EditableTypography.propTypes = {
  classes: PropTypes.shape({
    disabled: PropTypes.string,
    input: PropTypes.string,
    multiline: PropTypes.string,
    focused: PropTypes.string,
    dark: PropTypes.string,
    alignright: PropTypes.string,
    alignleft: PropTypes.string,
    row: PropTypes.string,
  }),
  theme: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  align: PropTypes.string,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  onChange: PropTypes.func,
  editable: PropTypes.bool,
  autoFocus: PropTypes.bool,
  maxLength: PropTypes.number,
  t: PropTypes.func,
};

export default withTranslation()(withStyles(styles)(EditableTypography));
