import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';

import Divider from '@mui/material/Divider';

export const validate = () => true;

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
  },
  dark: {
    '& hr': {
      backgroundColor: 'rgba(255,255,255,0.12)',
    },
  },
});

class CardDivider extends Component {
  render() {
    const { classes, className, cardTheme, key, id } = this.props;
    return (
      <div
        className={clsx([classes.root, className, classes[cardTheme]])}
        data-tg-name="ctx-card-el-video"
        key={key}
        id={id}
      >
        <Divider />
      </div>
    );
  }
}

CardDivider.propTypes = {
  cardTheme: PropTypes.string,
  classes: PropTypes.exact({
    root: PropTypes.string,
    dark: PropTypes.string,
  }),
  key: PropTypes.string,
  id: PropTypes.string,
};

export default withStyles(styles)(CardDivider);
