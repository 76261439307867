import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DataObjectChip from 'Components/Library/DataObjectChip';
import StandardSurveyDialog from 'Components/Library/StandardSurveyDialog';
import UserChip from 'Components/Library/UserChip';
import SavingIndicator from 'Components/Common/SavingIndicatorv2';
import DfacIcon from 'Components/Features/DigitalFacilitation/DfacIcon';

import MemberStatusList from './MemberStatusList';
import FormSubmitInput from './FormSubmitInput';
import FormVote from './FormVote';
import Fantastic from './Fantastic';
import ManagerMenu from './ManagerMenu';
import FormSubmitSummary from './FormSubmitSummary';
import FacilitationComplete from './FacilitationComplete';

const styles = theme => ({
  root: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'stretch',
    flexGrow: 1,
  },
  summaryContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    backgroundColor: theme.palette.background.box,
    ...theme.shape,
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  summary: {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    wordBreak: 'normal',
    overflow: 'hidden',
    alignSelf: 'stretch',
    ...theme.shape,
    backgroundColor: theme.palette.background.paper,
    flexGrow: 5,
    lineHeight: '1.25rem',
    padding: 12,
    paddingTop: 8,
    paddingBottom: 8,
  },
  ownerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  savingIndicator: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
    zIndex: 100,
  },
});

class PanelFacilitationSession extends Component {
  constructor(props) {
    super(props);
    this.LABELS = [
      props.t('dfac.submitanswers'),
      props.t('dfac.nextstep'),
      props.t('dfac.submitvotes'),
      props.t('dfac.nextstep'),
      props.t('dfac.conclude'),
    ];
    this.state = {
      confirmationDialogOpen: false,
    };
    this.contentRef = React.createRef();
    this.secondRef = React.createRef();
  }

  requestClose = () => {
    this.props.handleCancel();
  };

  goForward = ownState => {
    this.closeConfirm();
    const action = this.getAction(ownState);
    action();
  };

  requestForward = ownState => {
    if (this.hasChanged(ownState)) {
      const action = this.getAction(ownState);
      action();
    } else {
      this.setState({ confirmationDialogOpen: true });
    }
  };

  closeConfirm = () => {
    this.setState({ confirmationDialogOpen: false });
  };

  hasAnswersChanged = () => {
    for (const key in this.props.answers) {
      const arrayLength = this.props.answers[key].length;
      for (let i = 0; i < arrayLength; i++) {
        if (!!this.props.answers[key][i]) {
          return true;
        }
      }
    }
    return false;
  };

  areCandidatesEmpty = () => {
    const inputs = get(this.props, 'candidates', {});
    for (const key in inputs) {
      if (inputs[key].length > 0) {
        return false;
      }
    }
    return true;
  };

  hasChanged = ownState => {
    if (ownState === 0) {
      return this.hasAnswersChanged();
    }
    if (ownState === 1 || ownState === 3 || ownState === 4) {
      return true;
    }
    if (ownState === 2) {
      // there are votes or there is nothing to vote on
      return this.props.ownvotes.length > 0 || this.areCandidatesEmpty();
    }
    return false;
  };

  getAction = ownState => {
    if (ownState === 0) {
      return this.props.handleSubmitInput;
    }
    if (ownState === 2) {
      return this.props.handleSubmitVote;
    }
    if (ownState === 4) {
      return this.props.handleSubmitSummary;
    }
    return this.props.handleManagerSubmit;
  };

  renderActionBar = ownState => {
    const {
      submitDisabled,
      members,
      isSessionOwner,
      sessionOwnerSub,
      doneUsers,
      notFound,
      tenantID,
      teamID,
      sessionID,
      enableAi,
    } = this.props;

    if (notFound) {
      return null;
    }

    if (isSessionOwner && ownState !== 5) {
      const managerAction = this.getAction(ownState);

      return (
        <ManagerMenu
          action={managerAction}
          ownState={ownState}
          label={this.LABELS[ownState]}
          requestForward={this.requestForward}
          confirmationDialogOpen={this.state.confirmationDialogOpen}
          doneUsers={doneUsers}
          members={members}
          sessionOwnerSub={sessionOwnerSub}
          submitDisabled={submitDisabled}
          tenantID={tenantID}
          teamID={teamID}
          sessionID={sessionID}
          enableAi={enableAi}
        />
      );
    }

    return (
      <DialogActions sx={{ borderTop: theme => `1px solid ${theme.palette.divider}` }}>
        {(ownState === 0 || ownState === 2) && (
          <Button
            id="live-dfac-panel-submit-btn"
            disabled={submitDisabled}
            variant="contained"
            color="secondary"
            onClick={() => this.requestForward(ownState)}
          >
            {this.LABELS[ownState]}
          </Button>
        )}
      </DialogActions>
    );
  };

  getOwnState = () => {
    const { facilitationData, heartbeat, sub } = this.props;

    let ownState = facilitationData.status;
    if (heartbeat) {
      heartbeat.users.forEach(user => {
        if (user.user_id === sub) {
          ownState = user.state;
        }
      });
      if (heartbeat.session_state > ownState) {
        ownState = heartbeat.session_state;
      }
    }
    return ownState;
  };

  render() {
    const {
      classes,
      submitStatus,
      facilitationData,
      onAnswerTextChange,
      onAddAnswer,
      onRemoveAnswer,
      onVoteToggle,
      answers,
      members,
      heartbeat,
      ownvotes,
      candidates,
      isSessionOwner,
      sessionOwnerSub,
      summary,
      summaryString,
      onSummaryChange,
      votes,
      sessionID,
      submitted,
      serverError,
      t,
      doneUsers,
      notFound,
      teamID,
      zIndexModifier,
    } = this.props;
    const ownState = this.getOwnState();

    const content = !!notFound ? (
      <div id="dfac-panel-notfound">
        {t('dfac.notFound')}
        {` ${String.fromCodePoint(0x1f622)}`}
      </div>
    ) : (
      <div className={classes.root} ref={this.contentRef}>
        <SavingIndicator
          submitted={submitted}
          submitStatus={submitStatus}
          errorText={serverError}
          className={classes.savingIndicator}
        />

        {ownState === 1 && <Fantastic bodyStringName="sessionWaitingAnswersParagraph" />}
        {ownState === 3 && <Fantastic bodyStringName="sessionWaitingVotesParagraph" />}
        {(ownState === 0 || ownState === 2 || ownState === 4 || ownState === 5) && (
          <div>
            <Typography
              variant={facilitationData.topic.length > 100 ? 'h5' : 'h4'}
              id="live-dfac-panel-topic"
              sx={{ mb: 2 }}
            >
              {facilitationData.topic}
            </Typography>
            <Typography
              variant="subtitle1"
              component="span"
              display="block"
              gutterBottom
              id="live-dfac-panel-description"
              color="text.secondary"
              sx={{ mb: 3 }}
            >
              {facilitationData.description}
            </Typography>
            {!isSessionOwner ||
              (ownState === 5 && (
                <div className={classes.ownerContainer}>
                  <UserChip sub={sessionOwnerSub} />
                </div>
              ))}
            {ownState === 4 && isSessionOwner && (
              <FormSubmitSummary summaryString={summaryString} onSummaryChange={onSummaryChange} />
            )}
            {ownState === 5 && summary && summary.ok && summary.summary !== 'NO-SUMMARY-GIVEN' && (
              <div className={classes.summaryContainer}>
                <Typography variant="h6" color="text.strong" sx={{ mb: 2 }}>
                  {t('dfac.summary')}
                </Typography>
                <div className={classes.summary} id="live-dfac-panel-summary">
                  <Typography variant="body1">{summary.summary}</Typography>
                </div>
              </div>
            )}
          </div>
        )}
        <div ref={this.secondRef}>
          {ownState === 0 && (
            <FormSubmitInput
              onAnswerTextChange={onAnswerTextChange}
              onAddAnswer={onAddAnswer}
              onRemoveAnswer={onRemoveAnswer}
              facilitationData={facilitationData}
              answers={answers}
              contentRef={this.contentRef}
              secondRef={this.secondRef}
            />
          )}
          {ownState === 2 && (
            <FormVote
              sessionId={sessionID}
              onVoteToggle={onVoteToggle}
              facilitationData={facilitationData}
              ownvotes={ownvotes}
              candidates={candidates}
            />
          )}
          {ownState >= 4 && (
            <FacilitationComplete
              facilitationData={facilitationData}
              votes={votes}
              sessionId={sessionID}
            />
          )}
        </div>
      </div>
    );

    return (
      <>
        <StandardSurveyDialog
          open
          size="xxl"
          name="dfac"
          onBackdropClick={this.requestClose}
          onCloseClick={this.requestClose}
          closeButtonProps={{
            id: 'live-dfac-panel-close-btn',
          }}
          menuItems={null}
          zIndexModifier={zIndexModifier}
          title={<DataObjectChip Icon={DfacIcon} text={t('dfac.featureName')} variant="normal" />}
          sidebarContent={
            <div>
              {typeof sessionOwnerSub === 'string' && (
                <MemberStatusList
                  members={members}
                  heartbeat={heartbeat}
                  doneUsers={doneUsers}
                  teamId={teamID}
                />
              )}
            </div>
          }
          actionbarContent={this.renderActionBar(ownState)}
        >
          {content}
        </StandardSurveyDialog>
        {this.state.confirmationDialogOpen && (ownState === 0 || ownState === 2) && (
          <Dialog open>
            <DialogContent>
              <Typography variant="h5" sx={{ mb: 2 }}>
                {t(`dfac.dialog.${ownState}.title`)}
              </Typography>
              <Typography variant="body1" paragraph>
                {t(`dfac.dialog.${ownState}.p1`)}
              </Typography>
              <Typography variant="body1" paragraph>
                {t(`dfac.dialog.${ownState}.p2`)}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                name="dfac-confirm-dialog-cancel-btn"
                onClick={this.closeConfirm}
                variant="text"
              >
                {t(`dfac.dialog.${ownState}.cancel`)}
              </Button>
              <Button
                name="dfac-confirm-dialog-submit-btn"
                variant="contained"
                onClick={() => this.goForward(ownState)}
                color="secondary"
              >
                {t(`dfac.dialog.${ownState}.moveto`)}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </>
    );
  }
}

PanelFacilitationSession.propTypes = {
  tenantID: PropTypes.string,
  onAnswerTextChange: PropTypes.func,
  submitStatus: PropTypes.number,
  notFound: PropTypes.bool,
  submitDisabled: PropTypes.bool,
  sub: PropTypes.string,
  classes: PropTypes.exact({
    root: PropTypes.string,
    summaryContainer: PropTypes.string,
    summary: PropTypes.string,
    ownerContainer: PropTypes.string,
    savingIndicator: PropTypes.string,
  }),
  handleCancel: PropTypes.func,
  facilitationData: PropTypes.object,
  onAddAnswer: PropTypes.func,
  onRemoveAnswer: PropTypes.func,
  onVoteToggle: PropTypes.func,
  answers: PropTypes.object,
  members: PropTypes.array,
  votes: PropTypes.object,
  heartbeat: PropTypes.object,
  ownvotes: PropTypes.arrayOf(PropTypes.string),
  candidates: PropTypes.object,
  isSessionOwner: PropTypes.bool,
  sessionOwnerSub: PropTypes.string,
  summary: PropTypes.object,
  summaryString: PropTypes.string,
  onSummaryChange: PropTypes.func,
  handleManagerSubmit: PropTypes.func,
  handleSubmitInput: PropTypes.func,
  handleSubmitSummary: PropTypes.func,
  handleSubmitVote: PropTypes.func,
  serverError: PropTypes.string,
  submitted: PropTypes.bool,
  t: PropTypes.func,
  doneUsers: PropTypes.array,
  teamID: PropTypes.string,
  sessionID: PropTypes.string,
  zIndexModifier: PropTypes.number,
  enableAi: PropTypes.bool,
};

export default withTranslation()(withStyles(styles)(PanelFacilitationSession));
