import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import ButtonBase from '@mui/material/ButtonBase';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import EditableTypography from 'Components/Common/EditableTypography';

export const validate = item => !!item.text && !!item.status;

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'center',
  },
  padBottom: {
    paddingBottom: theme.spacing(2),
  },
  trafficLightShape: {
    width: 24,
    height: 24,
    borderRadius: 12,
    marginRight: theme.spacing(2),
    border: 0,
    padding: 0,
    overflow: 'hidden',
    flexGrow: 0,
    flexShrink: 0,
  },
  editable: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  menuItemShape: {
    width: 24,
    height: 24,
    borderRadius: 12,
    overflow: 'hidden',
  },
  green: {
    backgroundColor: theme.palette.secondary.main,
  },
  amber: {
    backgroundColor: theme.palette.warning.main,
  },
  red: {
    backgroundColor: theme.palette.error.main,
  },
  text: {
    flexGrow: 3,
  },
});

class CardTrafficLight extends Component {
  state = {
    menuOpen: null,
  };

  onStatusClick = e => {
    this.setState({ menuOpen: e.currentTarget });
  };

  setStatus = status => {
    this.setState({ menuOpen: null });
    this.props.onEdit({ status });
  };

  onClose = () => {
    this.setState({ menuOpen: null });
  };

  render() {
    const {
      classes,
      className,
      item,
      onEdit,
      cardTheme,
      editable = false,
      isLast = false,
      key,
      id,
      t,
    } = this.props;
    const { menuOpen } = this.state;
    return (
      <div
        className={clsx([classes.root, className, isLast ? classes.padBottom : null])}
        data-tg-name="ctx-card-el-trafficlight"
        key={key}
        id={id}
      >
        <ButtonBase
          name="ctx-card-traffic-lights-button"
          aria-label={t('contextv2.openStatusViewLabel')}
          onClick={e => this.onStatusClick(e)}
          className={clsx(
            [classes.trafficLightShape, !!editable ? classes.editable : null, classes[item.status]],
            `color-${item.status}`,
          )}
        />
        {!!editable && !!menuOpen && (
          <Menu
            open
            anchorEl={menuOpen}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            onClose={this.onClose}
          >
            <MenuItem
              id="ctx-card-traffic-lights-opt-green"
              onClick={() => this.setStatus('green')}
            >
              <div className={clsx([classes.menuItemShape, classes.green])} />
            </MenuItem>
            <MenuItem
              id="ctx-card-traffic-lights-opt-amber"
              onClick={() => this.setStatus('amber')}
            >
              <div className={clsx([classes.menuItemShape, classes.amber])} />
            </MenuItem>
            <MenuItem id="ctx-card-traffic-lights-opt-red" onClick={() => this.setStatus('red')}>
              <div className={clsx([classes.menuItemShape, classes.red])} />
            </MenuItem>
          </Menu>
        )}
        <div className={classes.text}>
          <EditableTypography
            variant="subtitle2"
            editable={editable}
            fullWidth
            name="ctx-card-traffic-lights-text-input"
            value={item.text}
            theme={cardTheme}
            onChange={text => onEdit({ text })}
          />
        </div>
      </div>
    );
  }
}

CardTrafficLight.propTypes = {
  item: PropTypes.object,
  classes: PropTypes.exact({
    root: PropTypes.string,
    padBottom: PropTypes.string,
    trafficLightShape: PropTypes.string,
    editable: PropTypes.string,
    menuItemShape: PropTypes.string,
    green: PropTypes.string,
    amber: PropTypes.string,
    red: PropTypes.string,
    text: PropTypes.string,
  }),
  onEdit: PropTypes.func,
  cardTheme: PropTypes.string,
  editable: PropTypes.bool,
  isLast: PropTypes.bool,
  key: PropTypes.string,
  id: PropTypes.string,
  t: PropTypes.func,
};

export default withTranslation()(withStyles(styles)(CardTrafficLight));
