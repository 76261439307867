import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';

import EmphasizedSection from 'Components/Library/EmphasizedSection';

const styles = theme => ({
  content: {
    flexGrow: 1,
    minHeight: 20,
    minWidth: 20,
    overflow: 'hidden',
    height: 'auto',
    display: 'block',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  contentArea: {
    display: 'block',
    width: '100%',
    overflowX: 'hidden',
    paddingBottom: theme.spacing(3),
    flex: 0,
    height: 'auto',
    overflowY: 'hidden',
  },
  commentBox: {
    ...theme.shape,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    marginBottom: theme.spacing(3),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  answer: {
    paddingBottom: theme.spacing(),
  },
  bar: {
    backgroundColor: 'rgba(255,255,255,0) !important',
    height: 4,
  },
  caption: {
    color: theme.palette.text.secondary,
    marginTop: 8,
    marginBottom: 8 / 2,
  },
  quadbars: {
    marginBottom: theme.spacing(2),
  },
});

class SurveyComments extends React.Component {
  render() {
    const { comments, survey, keys, classes } = this.props;
    if (!comments || comments.length === 0) {
      return null;
    }
    return (
      <div className={classes.content}>
        <div className={classes.contentArea}>
          <EmphasizedSection>
            {comments.map((comment, i) => {
              if (!comment.comment) {
                return null;
              }
              return (
                <div className={classes.commentBox} key={`survey-comments-${i}`}>
                  {['nps', 'csat', 'multiplechoice'].includes(survey.topicType) && (
                    <Typography variant="overline" className={classes.answer}>
                      {keys[comment.score]}
                    </Typography>
                  )}
                  {survey.topicType === 'quad' && (
                    <div className={classes.quadbars}>
                      <Typography className={classes.caption} variant="caption">
                        {survey.quadOptions.xquestion}
                      </Typography>
                      <LinearProgress
                        classes={{ determinate: classes.bar }}
                        color="secondary"
                        variant="determinate"
                        value={comment.score.x}
                      />

                      <Typography className={classes.caption} variant="caption">
                        {survey.quadOptions.yquestion}
                      </Typography>
                      <LinearProgress
                        classes={{ determinate: classes.bar }}
                        color="secondary"
                        variant="determinate"
                        value={comment.score.y}
                      />
                    </div>
                  )}

                  <Typography variant="body1">{comment.comment}</Typography>
                </div>
              );
            })}
          </EmphasizedSection>
        </div>
      </div>
    );
  }
}

SurveyComments.propTypes = {
  classes: PropTypes.exact({
    content: PropTypes.string,
    contentArea: PropTypes.string,
    commentBox: PropTypes.string,
    answer: PropTypes.string,
    bar: PropTypes.string,
    caption: PropTypes.string,
    quadbars: PropTypes.string,
  }),
  comments: PropTypes.array,
  survey: PropTypes.object,
  keys: PropTypes.object,
};

export default withStyles(styles)(SurveyComments);
