import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import { nanoid } from 'nanoid';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography/Typography';
import { Navigate } from 'react-router-dom';
import queryString from 'query-string';
import { withNavigation, withLocation } from 'withRouter';
import TgProgress from 'Components/Common/TgProgress';
import { authActions, authSelectors } from '../state/ducks/auth';

const styles = () => ({
  wrapper: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  loginBox: {
    width: 400,
    textAlign: 'center',
    margin: 'auto',
  },
  button: {
    marginRight: 8,
  },
  textField: {
    marginLeft: 8,
    marginRight: 8,
    minWidth: 400,
  },
});

class Loading extends Component {
  state = { redirectTo: '/home', error: null, codeSent: false, getTokensWithCodeError: false };

  componentDidMount() {
    const parsed = queryString.parse(this.props.location.search);
    if (isEmpty(parsed)) {
      // eslint-disable-next-line no-console
      console.log('No auth code received, redirecting to login');
      this.setState({ redirectTo: '/login', getTokensWithCodeError: true });
    }
    if (parsed.error) {
      const error = { errorCode: parsed.error };
      if (parsed.error_description) {
        error.errorDescription = parsed.error_description;
      }
      this.setState({ error });
    } else if (parsed.state) {
      const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
      // test that the state is a valid base64
      if (base64regex.test(parsed.state)) {
        const stringState = window.atob(parsed.state);
        // if we miss something above test that we have a string and it contains at least one of the required elements
        if (
          typeof stringState === 'string' &&
          (stringState.includes('tenantId') ||
            stringState.includes('idpName') ||
            stringState.includes('cognitoSubDomain') ||
            stringState.includes('loginType') ||
            stringState.includes('clientId'))
        ) {
          const passedState = JSON.parse(stringState);
          if (passedState.from) {
            this.setState({ redirectTo: passedState.from });
          }
          const { code } = parsed;
          if (!!code) {
            const requestID = nanoid(10);
            this.props.dispatch(
              authActions.getTokensWithCode({
                code,
                cognitoSubDomain: passedState.tenant.cognitoSubDomain,
                clientId: passedState.tenant.cognitoClientId,
                loginType: passedState.tenant.loginType,
                idpName: passedState.tenant.idpName,
                requestID,
              }),
            );
            this.setState({ getTokensWithCodeRequestID: requestID, codeSent: true });
          }
        }
      }
    }

    // if something is wrong and the callback takes more then 20s. let's redirect to login page for a new try
    this.refreshtimer = setTimeout(() => {
      this.setState({ redirectTo: '/login', getTokensWithCodeError: true });
    }, 1000 * 20);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.codeSent && 'auth' in nextProps) {
      if (
        nextProps.auth.actionlog &&
        this.state.getTokensWithCodeRequestID in nextProps.auth.actionlog
      ) {
        if (
          nextProps.auth.actionlog[this.state.getTokensWithCodeRequestID].result === 'error' &&
          get(nextProps.auth.actionlog[this.state.getTokensWithCodeRequestID], 'payload.status') ===
            400 &&
          get(
            nextProps.auth.actionlog[this.state.getTokensWithCodeRequestID],
            'payload.data.error',
          ) === 'invalid_grant'
        ) {
          // getting tokens using a code failed, maybe user clicked on a old link with outdated code etc.
          // redirecting to login
          this.setState({ redirectTo: '/login', getTokensWithCodeError: true });
        }
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.refreshtimer);
  }

  render() {
    const { t, classes, authValid } = this.props;
    const { redirectTo = '/home', error, getTokensWithCodeError } = this.state;
    return (
      <div className="App">
        <div className={classes.wrapper}>
          {!error && authValid && <Navigate to={redirectTo} />}
          {getTokensWithCodeError && <Navigate to={redirectTo} />}
          <div className={classes.loginBox}>
            {error && (
              <React.Fragment>
                <Typography variant="h5">{t('general.oops')}</Typography>
                <br />
                <Typography variant="body2">
                  {t('loading.ssoErrorCodeBody', { code: error.errorCode })}
                </Typography>
                {error.errorDescription && (
                  <Typography variant="body2">
                    {t('loading.ssoErrorMessageBody', { description: error.errorDescription })}
                  </Typography>
                )}
              </React.Fragment>
            )}
            {!error && (
              <React.Fragment>
                <TgProgress size={100} color="primary" />
                <br />
                <Typography>{t('loading.loggingInBody')}</Typography>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Loading.propTypes = {
  classes: PropTypes.exact({
    wrapper: PropTypes.string,
    loginBox: PropTypes.string,
    button: PropTypes.string,
    textField: PropTypes.string,
  }),
  navigate: PropTypes.func,
  location: PropTypes.object,
  auth: PropTypes.object,
  dispatch: PropTypes.func,
  authValid: PropTypes.bool,
  t: PropTypes.func,
};

const mapStateToProps = state => ({
  auth: state.auth,
  authValid: authSelectors.isAuthValid(state) === authSelectors.VALID,
  appstate: state.main,
});

export default withLocation(
  withNavigation(withTranslation()(connect(mapStateToProps)(withStyles(styles)(Loading)))),
);
