import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import Container from 'Components/Library/BaseComponents/Container';
import { instance as axiosInstance } from 'config/axios';
import { APIGW_URL } from 'state/constants/api';
import { useSelector } from 'react-redux';
import AiLoadingIndicator from 'Components/Features/TgAI/AiLoadingIndicator';
import { WIDGETS } from './constants';

interface SuggesterUiProps {
  ltPeriodId: string;
  teamId: string;
  openSavingResultsUi(object): void;
  onError(): void;
}

function makeRequest(retryCount, options) {
  return axiosInstance
    .post(`${APIGW_URL}/assistant/${options.tenantID}/executeworkflow`, {
      context: options.context,
      workflow_name: options.workflow_name,
    })
    .catch(err =>
      retryCount < 3 ? makeRequest(retryCount + 1, options) : console.log(err.message),
    );
}

function SuggesterUi(props: SuggesterUiProps) {
  const { ltPeriodId, teamId, openSavingResultsUi, onError } = props;

  const tenantID = useSelector((state: any) => state.auth.tenantID);

  const start = async () => {
    const requests = [];

    for (const widget of WIDGETS) {
      requests.push(
        makeRequest(0, {
          tenantID: tenantID,
          workflow_name: 'suggest_gameplan',
          context: {
            lt_period: ltPeriodId,
            domain_id: teamId,
            gameplan_element: widget,
            index_name: 'default',
          },
        }),
      );
    }

    Promise.all(requests)
      .then(function (results) {
        const res = {};
        console.log('result: ', results);
        results.forEach(result => {
          const pl = JSON.parse(result.config.data);
          const element = pl.context.gameplan_element;
          const response = result.data.result;
          res[element] = response;
        });
        openSavingResultsUi(res);
      })
      .catch(function (err) {
        console.log(err.message);
        onError();
      });
  };

  useEffect(() => {
    start();
  }, []);

  return (
    <Container alignItems="stretch" p={1}>
      <AiLoadingIndicator />
      <Typography variant="h6">Decoding your strategy</Typography>
      <Typography variant="body2">Extracting understanding from numbers</Typography>
    </Container>
  );
}

export default SuggesterUi;
