import { useEffect, useMemo, useState } from 'react';
import { DialogActions, DialogContent, Typography } from '@mui/material';
import Panel from 'Components/Library/BaseComponents/Panel';
import Button from 'Components/Library/BaseComponents/Button';
import { nanoid } from 'nanoid';
import { useDispatch, useSelector } from 'react-redux';
import { facilitationActions, facilitationSelectors } from 'state/ducks/facilitation';
import CandidateLabel from '../CandidateLabel';
import Container from 'Components/Library/BaseComponents/Container';

type AnswerCategory = {
  id: string;
  name: string;
  description: string;
};

type AnswerCategorization = {
  id: string;
  categorization: Array<string>;
};

type AnswerDataClassification = {
  categories: Array<AnswerCategory>;
  categorization: Array<AnswerCategorization>;
};

type SuggestedData = {
  [key: number]: AnswerDataClassification;
};

interface ReviewUiProps {
  startClassicVote(): void;
  onClose(): void;
  data: SuggestedData;
  sessionId: string;
  teamId: string;
}

function ReviewUi(props: ReviewUiProps) {
  const { startClassicVote, data, teamId, sessionId, onClose } = props;
  const [categorizationRequestId, setCategorizationRequestId] = useState(null);

  // TODO: ensure inputs that the AI might have missed
  // are categorized as "other" or similar..
  const inputs = useSelector((state: any) =>
    facilitationSelectors.selectFacilitationInputs(state.main.facilitation, sessionId),
  );

  const facilitationData = useSelector((state: any) =>
    facilitationSelectors.selectFacilitationSession(state.main.facilitation, sessionId),
  );

  const actionlog = useSelector((state: any) => state.main.facilitation.actionlog);
  const dispatch = useDispatch();

  const questionsArr = facilitationData?.questions;

  // Compute visualization data
  const dataset = useMemo(() => {
    const d = {};
    for (const qIndex in data) {
      d[qIndex] = [];
      data[qIndex].categories.forEach(categoryItem => {
        const candidateItem = {
          category_name: categoryItem.name,
          category_description: categoryItem.description,
          category_id: categoryItem.id,
          inputs: [],
        };
        data[qIndex].categorization.forEach(input => {
          if (input.categorization.includes(candidateItem.category_id)) {
            candidateItem.inputs.push(input.id);
          }
        });
        d[qIndex].push(candidateItem);
      });
    }
    return d;
  }, [data, sessionId, teamId]);

  // Compute payload
  const payload = useMemo(() => {
    const payload = {
      requestID: nanoid(10),
      teamID: teamId,
      sessionID: sessionId,
      categorization: {},
    };
    Object.keys(data).forEach(questionKey => {
      const questionData = [];
      data[questionKey].categories.forEach(category => {
        const categoryObject = {
          category_name: category.name,
          category_description: category.description,
          inputs: [],
        };
        const temporaryCategoryId = category.id;
        data[questionKey].categorization.forEach(input => {
          if (input.categorization.includes(temporaryCategoryId)) {
            categoryObject.inputs.push(input.id);
          }
        });
        questionData.push(categoryObject);
      });
      payload.categorization[questionKey] = questionData;
    });
    return payload;
  }, [data, sessionId, teamId]);

  const submitCategorization = async () => {
    dispatch(facilitationActions.submitCategorization(payload));
    setCategorizationRequestId(payload.requestID);
  };

  // Close dialog after everything is done
  useEffect(() => {
    if (categorizationRequestId in actionlog) {
      onClose();
    }
  }, [categorizationRequestId, actionlog]);

  return (
    <>
      <DialogContent>
        <Typography
          variant="h6"
          color="text.strong"
          component="span"
          display="block"
          sx={{ mb: 2 }}
        >
          Review grouping
        </Typography>
        <Container spacing={3} alignItems="stretch">
          {
            // @ts-ignore
            Object.keys(dataset).map((questionKey: number) => (
              <Panel alignItems="stretch" spacing={2} sx={{ flexBasis: 10 }}>
                <Typography
                  variant="h6"
                  color="text.strong"
                  component="span"
                  display="block"
                  sx={{ mb: 2 }}
                >
                  {questionsArr[questionKey]}
                </Typography>
                {dataset[questionKey].map((categoryItem, index) => (
                  <CandidateLabel
                    questionIndex={questionKey}
                    candidateItem={categoryItem}
                    highlight={false}
                    sessionId={sessionId}
                  />
                ))}
              </Panel>
            ))
          }
        </Container>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => startClassicVote()}
          name="dfac-confirm-managerdialog-cancel-btn"
          size="large"
          variant="text"
        >
          Ehh let's go back to the classics
        </Button>
        <Button
          name="dfac-confirm-managerdialog-submit-btn"
          variant="contained"
          size="large"
          color="secondary"
          onClick={() => submitCategorization()}
          disabled={Boolean(categorizationRequestId)}
        >
          Nice, let's go!
        </Button>
      </DialogActions>
    </>
  );
}

export default ReviewUi;
