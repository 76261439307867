import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { nanoid } from 'nanoid';
import { contextActions, contextSelectors } from 'state/ducks/context';
import { validate } from '../CardComponents';
import ContextCardEditor from './ContextCardEditor';

const validateAll = items => {
  const errors = [];
  if (!!items && items.length > 0) {
    items.forEach((item, index) => {
      if (!validate(item)) {
        errors.push(index);
      }
    });
  }
  return errors;
};

const imgToDataUrl = (src, callback) => {
  const img = new Image();
  img.crossOrigin = 'Use-Credentials';
  // eslint-disable-next-line func-names
  img.onload = function () {
    const canvas = document.createElement('CANVAS');
    const ctx = canvas.getContext('2d');
    canvas.height = this.naturalHeight;
    canvas.width = this.naturalWidth;
    ctx.drawImage(this, 0, 0);
    const dataURL = canvas.toDataURL('image/jpeg');
    callback(dataURL);
  };

  img.src = src;
};

class ContextCardEditorContainer extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.submitted && !!prevState.requestID) {
      if (prevState.requestID in nextProps.actionlog) {
        if (nextProps.actionlog[prevState.requestID].result === 'ok') {
          return { submitStatus: 1, submitted: false };
        }
        return {
          submitStatus: -1,
          submitted: false,
          serverError: nextProps.actionlog[prevState.requestID].message,
        };
      }
    }
    return null;
  }

  state = {
    elements: [],
    cardtheme: 'light',
    isNew: true,
    highlightErrors: null,
    submitStatus: 0,
    submitted: false,
    latestItemId: null,
    deleteStack: {},
  };

  componentDidMount() {
    const { card } = this.props;
    if (!!card) {
      const newState = { ...card, elements: [...card.elements], isNew: false };
      for (const el of newState.elements) {
        if (el.type === 'image') {
          // eslint-disable-next-line no-await-in-loop
          let url = `${process.env.REACT_APP_MEDIA_URL}/${this.props.tenantId}/${card.id}_${el.id}.jpg`;
          if (!!el.version) {
            url = `${process.env.REACT_APP_MEDIA_URL}/${this.props.tenantId}/${card.id}_${el.id}_${el.version}.jpg`;
          }
          imgToDataUrl(url, b64str => this.setImageData(el.id, b64str));
        }
      }
      this.setState(newState);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { submitted, submitStatus } = this.state;
    if (prevState.submitted === true && submitStatus === 1 && submitted === false) {
      this.cancel();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    clearTimeout(this.timeout2);
  }

  setImageData(elementId, b64str) {
    const elements = [...this.state.elements];
    for (const el of elements) {
      if (el.id === elementId) {
        el.src = b64str;
      }
    }
    this.setState({ elements });
  }

  computeImageCount = () => {
    let images = 0;
    if (!!this.props.fullContext && this.props.fullContext.ok) {
      for (const column of this.props.fullContext.data) {
        for (const card of column) {
          if (card.id !== this.state.id) {
            for (const element of card.elements) {
              if (element.type === 'image') {
                images += 1;
              }
            }
          }
        }
      }
    }
    for (const element of this.state.elements) {
      if (element.type === 'image') {
        images += 1;
      }
    }
    return images;
  };

  addItem = (type, atIndex, props = {}) => {
    const elements = [...this.state.elements];
    const item = { type, id: nanoid(10), canSave: false, __cfg: {}, ...props };
    if (type === 'slider') {
      item.leftLabel = '';
      item.rightLabel = '';
      item.values = [33, 66];
    } else if (type === 'video') {
      item.src = null;
      item.__cfg.settingsOpen = true;
    } else if (type === 'attachment') {
      item.src = null;
      item.__cfg.settingsOpen = true;
    } else if (type === 'objective') {
      item.src = null;
      item.__cfg.settingsOpen = true;
    } else if (type === 'trafficlight') {
      item.status = 'green';
    }
    elements.splice(atIndex + 1, 0, item);
    this.setState({ elements, latestItemId: item.id });
  };

  deleteItem = (index, skipUndo = false) => {
    const elements = [...this.state.elements];
    const deleted = elements.splice(index, 1)[0];
    const newState = { elements };

    if (!skipUndo) {
      deleted.index = index;
      this.timeout = setTimeout(() => {
        this.clearFromDeleteStack(deleted.id);
      }, 9000);
      newState.deleteStack = { ...this.state.deleteStack, [deleted.id]: deleted };
    }

    this.setState(newState);
  };

  clearFromDeleteStack = id => {
    const { deleteStack } = this.state;
    delete deleteStack[id];
    this.setState({ deleteStack });
  };

  undoDelete = id => {
    const { deleteStack, elements } = this.state;
    const item = deleteStack[id];
    const atIndex = item.index;
    delete item.index;
    elements.splice(atIndex, 0, item);

    delete deleteStack[id];
    this.setState({ deleteStack, elements });
  };

  reorder = (sourceIndex, destIndex) => {
    const result = Array.from(this.state.elements);
    const [removed] = result.splice(sourceIndex, 1);
    result.splice(destIndex, 0, removed);
    this.setState({
      elements: result,
    });
  };

  onEdit = (index, values) => {
    const { elements } = this.state;
    const item = { ...elements[index], ...values };
    item.canSave = validate(item);
    elements[index] = item;
    this.setState({ elements });
  };

  openSettings = index => {
    const { elements } = this.state;
    const item = {
      ...elements[index],
      __cfg: !!elements[index].__cfg ? elements[index].__cfg : {},
    };
    item.__cfg.settingsOpen = true;
    item.canSave = validate(item);

    elements[index] = item;
    this.setState({ elements });
  };

  closeSettings = index => {
    const { elements } = this.state;
    const item = {
      ...elements[index],
      __cfg: !!elements[index].__cfg ? elements[index].__cfg : {},
    };
    item.__cfg.settingsOpen = false;
    item.canSave = validate(item);

    elements[index] = item;
    this.setState({ elements });
  };

  changeTheme = e => {
    this.setState({ cardtheme: e.target.value });
  };

  save = () => {
    const canSave = validateAll(this.state.elements).length === 0 && this.state.elements.length > 0;

    if (!canSave) {
      this.setState({ highlightErrors: true });
      this.timeout2 = setTimeout(
        // eslint-disable-next-line func-names
        function () {
          this.setState({ highlightErrors: null });
        }.bind(this),
        500,
      );
    } else {
      const elements = [...this.state.elements].map(element => {
        const copy = { ...element };
        delete copy.__cfg; // Don't submit editor variables
        delete copy.originalSrc;
        if (copy.type === 'image' && !!copy.src) {
          delete copy.src;
        }
        return copy;
      });
      const card = { elements, cardtheme: this.state.cardtheme };
      const requestID = nanoid(10);
      if (!!this.state.isNew) {
        this.props.dispatch(
          contextActions.createContextCard({
            requestID,
            contextID: this.props.contextID,
            contextType: this.props.contextType,
            card,
          }),
        );
      } else {
        card.id = this.state.id;
        this.props.dispatch(
          contextActions.editContextCard({
            requestID,
            contextID: this.props.contextID,
            contextType: this.props.contextType,
            card,
          }),
        );
      }
      this.setState({ submitted: true, submitStatus: 0, requestID });
    }
  };

  setVisibility = (mode, index, value) => {
    const attr = {};
    attr[`hide_${mode}`] = value;
    this.onEdit(index, attr);
  };

  cancel = () => {
    this.props.onClose();
  };

  canSave = errors => {
    if (errors.length === 0 && !!this.state.elements && this.state.elements.length > 0) {
      for (const e in this.state.elements) {
        if (!this.state.elements[e].hide_minified) {
          return true;
        }
      }
      return false;
    }
    return false;
  };

  render() {
    const { contextType, contextID, zIndexModifier } = this.props;
    const { elements, cardtheme, highlightErrors, isNew, submitted, submitStatus } = this.state;
    const errors = validateAll(elements);
    const imageCount = this.computeImageCount();
    return (
      <ContextCardEditor
        elements={elements}
        addItem={this.addItem}
        deleteItem={this.deleteItem}
        openSettings={this.openSettings}
        closeSettings={this.closeSettings}
        changeTheme={this.changeTheme}
        isNew={isNew}
        cardtheme={cardtheme}
        onEdit={this.onEdit}
        setVisibility={this.setVisibility}
        reorder={this.reorder}
        errorIndices={errors}
        onSave={this.save}
        onCancel={this.cancel}
        highlightErrors={highlightErrors}
        canSubmit={this.canSave(errors)}
        submitted={submitted}
        submitStatus={submitStatus}
        latestItemId={this.state.latestItemId}
        totalImages={imageCount}
        deleteStack={this.state.deleteStack}
        contextType={contextType}
        contextID={contextID}
        undoDelete={this.undoDelete}
        zIndexModifier={zIndexModifier}
      />
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  actionlog: state.main.context.actionlog,
  tenantId: state.auth.tenantID,
  fullContext: contextSelectors.selectContext(
    state.main.context,
    ownProps.contextID,
    ownProps.contextType,
  ),
  card: ownProps.cardID
    ? contextSelectors.selectContextCard(
        state.main.context,
        ownProps.contextID,
        ownProps.contextType,
        ownProps.cardID,
      )
    : null,
});

ContextCardEditorContainer.propTypes = {
  actionlog: PropTypes.object.isRequired,
  card: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  contextID: PropTypes.string.isRequired,
  contextType: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  fullContext: PropTypes.object,
  tenantId: PropTypes.string,
  zIndexModifier: PropTypes.number,
};

export default connect(mapStateToProps)(ContextCardEditorContainer);
