import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { SCHEMA_FULL } from 'Components/Library/RichTextEditor/schemas';
import party from 'party-js';
import { objectivesActions } from 'state/ducks/objectives';
import { getKeyResultCompletionPct } from 'config/helpers';

import Form from 'Components/Library/Forms';
import LinearIndicator from 'Components/Library/Forms/Elements/LinearIndicator';
import { getKeyResultConfidenceString, ragConfidenceToInt } from 'state/ducks/objectives/helpers';

const schema = {
  type: 'object',
  properties: {
    status: { type: 'number' },
    confidence: { type: 'string' },
    comment: { richtextschema: SCHEMA_FULL, minLength: 0, maxLength: 8000 },
  },
  required: ['status'],
  additionalProperties: true,
};

const createReduxAction = params => {
  const { confidence, ...rest } = params;
  const actionCreator = objectivesActions.updateKeyresult;

  rest.confidence = ragConfidenceToInt(confidence).toString();
  return actionCreator(rest);
};

function KrStatusForm(props) {
  const { children, keyresultData, useButton, name = 'kr-status-update', onSuccess } = props;

  const ref = useRef(null);
  const theme = useTheme();
  const startParty = (dispatchedParams, formRef) => {
    const { baseline, target } = keyresultData;
    const { status } = dispatchedParams;
    const nextValue = getKeyResultCompletionPct({ status, baseline, target });
    // Launch confetti animation if submitting will take the keyresult to 100%:
    if (nextValue >= 100 && ref.current) {
      party.confetti(formRef.current, {
        count: 120,
        size: 0.5,
        spread: party.variation.skew(90, 0.7),
        angle: 180,
        velocity: party.variation.skew(500, 0.7),
        color: [
          party.Color.fromHex(theme.palette.secondary.main),
          party.Color.fromHex(theme.palette.secondary.light),
          party.Color.fromHex(theme.palette.secondary.light),
          party.Color.fromHex(theme.palette.secondary.dark),
          party.Color.fromHex(theme.palette.confidence.red),
          party.Color.fromHex(theme.palette.warning.main),
          party.Color.fromHex(theme.palette.error.main),
        ],
      });
    }
  };

  return (
    <Form
      name={name}
      schema={schema}
      onSubmitSuccess={onSuccess}
      initialValues={{
        status: keyresultData.status,
        confidence: getKeyResultConfidenceString(keyresultData),
      }}
      onSubmitDispatched={dispatchedParams => startParty(dispatchedParams, ref)}
      forwardRef={ref}
      debouncedAutoSubmit={Boolean(useButton) ? undefined : 950}
      submitActionCreator={createReduxAction}
      stateSlice="main.objectives"
      allowRefreshData
      additionalProperties={{ keyresultID: keyresultData.keyresultID }}
      preventSaveIncomplete
    >
      <LinearIndicator positioning="absolute" />
      {children}
    </Form>
  );
}

KrStatusForm.propTypes = {
  keyresultData: PropTypes.object,
  useButton: PropTypes.bool,
  name: PropTypes.string,
  onSuccess: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default KrStatusForm;
