import getVideoId from 'get-video-id';

export default function parseVideoFromUrl(url) {
  const answer = getVideoId(url);
  answer.message = 'Could not find ID for video, please check the url.';

  if (answer.service && !answer.service.includes('youtube') && !answer.service.includes('vimeo')) {
    // disable vine and videopress if they are discovered
    answer.id = undefined;
    answer.service = undefined;
    answer.message =
      'Please use supported video formats. Currently only Youtube and Vimeo are supported.';
  }

  if (answer.id) {
    if (answer.id.length !== 11 && answer.service.includes('youtube')) {
      answer.id = undefined;
      answer.message = 'Youtube video ID length should be 11 characters.';
    } else {
      answer.message = `ID (${answer.id}) for video identified succesfully.`;
    }
  }

  return {
    type: answer.service ? answer.service : null,
    id: answer.id ? answer.id : null,
    validates: answer.id !== undefined,
    message: answer.message,
  };
}
