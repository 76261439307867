import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Group } from '@visx/group';
import { AutoSizer } from 'react-virtualized';
import { Circle } from '@visx/shape';
import withStyles from '@mui/styles/withStyles';
import { scaleLinear } from '@visx/scale';
import Typography from '@mui/material/Typography';

const x = d => d.x;
const y = d => d.y;
const r = d => d.num;

const styles = theme => ({
  root: {
    marginTop: theme.spacing(-1),
  },
  chartContainer: {
    width: '100%',
    height: '100%',
    minHeight: 360,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    ...theme.shape,
    padding: theme.spacing(3),
    boxSizing: 'border-box',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    flexGrow: 10,
    paddingTop: 4,
    paddingBottom: 4,
    '&:first-of-type': {
      flexGrow: 0,
      alignItems: 'center',
      paddingTop: 0,
    },
    '&:last-child': {
      flexGrow: 0,
      alignItems: 'center',
      paddingBottom: 0,
    },
  },
  chart: {
    flexGrow: 10,
    display: 'flex',
  },
  svgcontainer: {
    display: 'inline-block',
    width: '100%',
    height: '100%',
    backgroundColor: '#FFFFFF', // For some reason SVG doesn't render on mobile without this..
  },
  svg: {
    overflow: 'visible',
  },
  label: {
    width: '100%',
    textAlign: 'center',
    '&:first-of-type': {
      textAlign: 'left',
    },
    '&:last-child': {
      textAlign: 'right',
    },
  },
  sidelabel: {
    width: 80,
    flexGrow: 0,
    textAlign: 'center',
    margin: 'auto',
  },
  left: {
    textAlign: 'left',
  },
  right: {
    textAlign: 'right',
  },
  fade: {
    opacity: 0.1,
  },
  detailsCta: {
    display: 'block',
    marginBottom: theme.spacing(2) + 4,
    /* Increase touch area with padding  */
    paddingTop: 4,
    paddingBottom: 4,
    cursor: 'pointer',
  },
});

class QuadChart extends React.Component {
  render() {
    const { values, theme, classes, survey, outcome } = this.props;

    if (!values) {
      return null;
    }
    const data = [];

    for (const coordString of Object.keys(values)) {
      const coords = JSON.parse(coordString);
      coords.num = values[coordString];
      data.push(coords);
    }

    return (
      <div className={classes.root}>
        <div id="quadchart" className={classes.chartContainer}>
          <div className={classes.row}>
            <Typography className={classes.label} variant="caption" color="secondary.main">
              {survey.quadOptions.xminymax}
            </Typography>
            <Typography
              className={classes.label}
              variant="caption"
              color="text.primary"
              sx={{ textTransform: 'uppercase' }}
            >
              {survey.quadOptions.ymax}
            </Typography>
            <Typography className={classes.label} variant="caption" color="secondary.main">
              {survey.quadOptions.xmaxymax}
            </Typography>
          </div>
          <div className={classes.row}>
            <Typography
              className={clsx([classes.sidelabel, classes.left])}
              variant="caption"
              color="text.primary"
              sx={{ textTransform: 'uppercase' }}
            >
              {survey.quadOptions.xmin}
            </Typography>
            <div className={classes.chart}>
              <div className={classes.svgcontainer}>
                <AutoSizer defaultHeight={100} defaultWidth={100}>
                  {({ width, height }) => {
                    if (width === 0 || height === 0) {
                      return null;
                    }
                    const xScale = scaleLinear({
                      range: [0, width],
                      domain: [0, 100],
                    });
                    const yScale = scaleLinear({
                      range: [height, 0],
                      domain: [0, 100],
                    });

                    const outcomeX = xScale(outcome.x);
                    const outcomeY = yScale(outcome.y);
                    return (
                      <svg width={width} height={height} className={classes.svg}>
                        <line
                          x1={0}
                          y1={height / 2}
                          x2={width}
                          y2={height / 2}
                          stroke={theme.palette.secondary.main}
                          strokeWidth={0.75}
                        />
                        <line
                          x1={width / 2}
                          y1={0}
                          x2={width / 2}
                          y2={height}
                          stroke={theme.palette.grey[500]}
                          strokeWidth={0.75}
                        />
                        {/* "Raw" data: */}
                        <Group>
                          {data.map((point, i) => {
                            const cx = xScale(x(point));
                            const cy = yScale(y(point));
                            return (
                              <Circle
                                className="dot"
                                key={`point-${point.x}-${i}`}
                                cx={cx}
                                cy={cy}
                                r={3 + 2 * (r(point) - 1)}
                                fill={theme.palette.secondary.main}
                                fillOpacity="1"
                              />
                            );
                          })}
                        </Group>
                        {/* Averages: */}
                        <Group>
                          <line
                            x1={xScale(50)}
                            y1={outcomeY}
                            x2={outcomeX}
                            y2={outcomeY}
                            stroke={theme.palette.grey[500]}
                            strokeWidth={1}
                            strokeDasharray="2"
                          />
                          <line
                            x1={outcomeX}
                            y1={yScale(50)}
                            x2={outcomeX}
                            y2={outcomeY}
                            stroke={theme.palette.grey[500]}
                            strokeWidth={1}
                            strokeDasharray="2"
                          />
                          <Circle
                            className="dot"
                            cx={outcomeX}
                            cy={yScale(50)}
                            r={3}
                            fill={theme.palette.grey[500]}
                            strokeWidth={0}
                            fillOpacity="1"
                            paintOrder="stroke"
                          />
                          <Circle
                            className="dot"
                            cx={xScale(50)}
                            cy={outcomeY}
                            r={3}
                            fill={theme.palette.grey[500]}
                            strokeWidth={0}
                            fillOpacity="1"
                            paintOrder="stroke"
                          />
                          <Circle
                            className="dot"
                            cx={outcomeX}
                            cy={outcomeY}
                            r={5}
                            fill={theme.palette.grey[500]}
                            strokeWidth={0}
                            fillOpacity="1"
                            paintOrder="stroke"
                          />
                        </Group>
                      </svg>
                    );
                  }}
                </AutoSizer>
              </div>
            </div>
            <Typography
              className={clsx([classes.sidelabel, classes.right])}
              variant="caption"
              color="text.primary"
              sx={{ textTransform: 'uppercase' }}
            >
              {survey.quadOptions.xmax}
            </Typography>
          </div>
          <div className={classes.row}>
            <Typography className={classes.label} variant="caption" color="secondary.main">
              {survey.quadOptions.xminymin}
            </Typography>
            <Typography
              className={classes.label}
              variant="caption"
              color="text.primary"
              sx={{ textTransform: 'uppercase' }}
            >
              {survey.quadOptions.ymin}
            </Typography>
            <Typography className={classes.label} variant="caption" color="secondary.main">
              {survey.quadOptions.xmaxymin}
            </Typography>
          </div>
        </div>
      </div>
    );
  }
}

QuadChart.propTypes = {
  classes: PropTypes.exact({
    root: PropTypes.string,
    chartContainer: PropTypes.string,
    row: PropTypes.string,
    chart: PropTypes.string,
    svgcontainer: PropTypes.string,
    svg: PropTypes.string,
    label: PropTypes.string,
    sidelabel: PropTypes.string,
    left: PropTypes.string,
    right: PropTypes.string,
    fade: PropTypes.string,
    detailsCta: PropTypes.string,
  }),
  values: PropTypes.object,
  survey: PropTypes.object,
  theme: PropTypes.object,
  outcome: PropTypes.object,
};

export default withStyles(styles, { withTheme: true })(QuadChart);
