import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { nanoid } from 'nanoid';
import { facilitationActions, facilitationSelectors } from 'state/ducks/facilitation';
import { connectionSelectors } from 'state/ducks/connection';
import { peopleSelectors, peopleActions } from 'state/ducks/people';
import PanelFacilitationSession from './PanelFacilitationSession';
import { appstatusSelectors } from 'state/ducks/appstatus';
import { isEnabled } from 'config/flags';

const fetchData = (props, force) => {
  if (!props.facilitationData || !props.facilitationData.ok) {
    props.dispatch(
      facilitationActions.getSession({
        teamID: props.teamID,
        sessionID: props.sessionID,
      }),
    );
  }
  props.dispatch(peopleActions.getTeam({ teamId: props.teamID, force }));
  if (
    props.facilitationData.ok &&
    props.facilitationData.status >= 2 &&
    (!props.candidates || !props.candidates.ok)
  ) {
    props.dispatch(
      facilitationActions.getCandidates({ teamID: props.teamID, sessionID: props.sessionID }),
    );
    props.dispatch(
      facilitationActions.getInputs({ teamID: props.teamID, sessionID: props.sessionID }),
    );
  }
  if (
    props.facilitationData.ok &&
    props.facilitationData.status >= 4 &&
    (!props.votes || !props.votes.ok)
  ) {
    props.dispatch(
      facilitationActions.getVotes({ teamID: props.teamID, sessionID: props.sessionID }),
    );
  }
  if (
    props.facilitationData.ok &&
    props.facilitationData.status >= 5 &&
    (!props.summary || !props.summary.ok)
  ) {
    props.dispatch(
      facilitationActions.getSummary({ teamID: props.teamID, sessionID: props.sessionID }),
    );
  }
};

class PanelFacilitationSessionContainer extends Component {
  state = {
    submitStatus: 0,
    submitted: false,
    answers: null,
    ownvotes: [],
    notFound: false,
    doneUsers: [],
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !prevState.submitted &&
      !nextProps.facilitationData.ok &&
      !nextProps.facilitationData.loading &&
      nextProps.facilitationData.error
    ) {
      return {
        notFound: true,
      };
    }

    const doneUsers = [];
    if (nextProps.heartbeat) {
      nextProps.heartbeat.users.forEach(user => {
        if (user.state > nextProps.heartbeat.session_state) {
          doneUsers.push(user.user_id);
        }
      });
    }

    if (prevState.submitted && !!prevState.requestID) {
      if (prevState.requestID in nextProps.actionlog) {
        if (nextProps.actionlog[prevState.requestID].result === 'ok') {
          return {
            submitStatus: 0,
            submitted: false,
            doneUsers,
          };
        }
        return {
          submitStatus: -1,
          submitted: false,
          serverError: nextProps.actionlog[prevState.requestID].message,
        };
      }
    }
    if (prevState.heartbeatSubmitted && !!prevState.heartbeatRequestID) {
      if (prevState.heartbeatRequestID in nextProps.actionlog) {
        if (nextProps.actionlog[prevState.heartbeatRequestID].result === 'ok') {
          return {
            heartbeatSubmitted: false,
          };
        }
        let notFound = false;
        if (
          nextProps.isOnline &&
          nextProps.actionlog[prevState.heartbeatRequestID].message?.includes('Not found')
        ) {
          notFound = true;
        }
        return {
          heartbeatSubmitted: false,
          heartbeatServerError: nextProps.actionlog[prevState.heartbeatRequestID].message,
          notFound,
        };
      }
    }
    if (!prevState.answers && nextProps.facilitationData.ok) {
      const answers = {};
      nextProps.facilitationData.questions.forEach((q, index) => {
        answers[index] = [''];
      });
      return {
        answers,
        doneUsers,
      };
    }
    return { doneUsers };
  }

  componentDidMount() {
    fetchData(this.props, true);
    if (this.props.facilitationData.ok) {
      // ENG-890: Send the first heartbeat asap
      this.heartbeat();
      // Then create a timer to regularly send heartbeats
      this.refreshtimer = setInterval(() => {
        this.heartbeat();
      }, 1000 * 5);
    }
  }

  componentDidUpdate() {
    fetchData(this.props);
    if (this.props.facilitationData.ok && !!!this.refreshtimer) {
      // ENG-890: Send the first heartbeat asap
      this.heartbeat();
      // Then create a timer to regularly send heartbeats
      this.refreshtimer = setInterval(() => {
        this.heartbeat();
      }, 1000 * 5);
    }
  }

  componentWillUnmount() {
    clearInterval(this.refreshtimer);
    clearTimeout(this.clearTimer);
  }

  heartbeat = () => {
    const requestID = nanoid(10);
    const { dispatch, teamID, sessionID } = this.props;
    if (this.props.facilitationData.ok) {
      dispatch(facilitationActions.sendHeartbeat({ teamID, sessionID, requestID }));
    }
    this.setState({
      heartbeatSubmitted: true,
      heartbeatRequestID: requestID,
    });
  };

  onVoteToggle = targetId => {
    let voteArray = [...this.state.ownvotes];
    if (voteArray.includes(targetId)) {
      voteArray = voteArray.filter(inputId => inputId !== targetId);
    } else {
      voteArray.push(targetId);
    }
    this.setState({ ownvotes: voteArray });
  };

  onAddAnswer = question => {
    const answers = { ...this.state.answers };
    if (!answers[question]) {
      answers[question] = [''];
    } else {
      answers[question].push('');
    }
    this.setState({ answers });
  };

  onRemoveAnswer = (questionIndex, answerInder) => {
    const answers = { ...this.state.answers };
    answers[questionIndex].splice(answerInder, 1);
    this.setState({ answers });
  };

  onAnswerTextChange = (question, i, text) => {
    const answers = { ...this.state.answers };
    if (!answers[question]) {
      answers[question] = [''];
    }
    answers[question][i] = text;
    this.setState({ answers });
  };

  onSummaryChange = evt => {
    this.setState({ summary: evt.target.value });
  };

  handleSubmitInput = () => {
    const requestID = nanoid(10);
    const payload = {
      requestID,
      teamID: this.props.teamID,
      sessionID: this.props.sessionID,
      input: this.state.answers,
    };
    this.setState({ submitted: true, submitStatus: 0, requestID });
    this.props.dispatch(facilitationActions.submitInput(payload));
  };

  handleSubmitVote = () => {
    const requestID = nanoid(10);
    const payload = {
      requestID,
      teamID: this.props.teamID,
      sessionID: this.props.sessionID,
      votes: this.state.ownvotes,
    };
    this.setState({ submitted: true, submitStatus: 0, requestID });
    this.props.dispatch(facilitationActions.submitVotes(payload));
  };

  handleManagerSubmit = () => {
    const requestID = nanoid(10);
    const payload = {
      requestID,
      teamID: this.props.teamID,
      sessionID: this.props.sessionID,
      fromState: this.props.heartbeat.session_state,
      toState: this.props.heartbeat.session_state + 2,
    };
    this.setState({ submitted: true, submitStatus: 0, requestID });
    this.props.dispatch(facilitationActions.progressState(payload));
  };

  handleSubmitSummary = () => {
    const requestID = nanoid(10);
    const payload = {
      requestID,
      teamID: this.props.teamID,
      sessionID: this.props.sessionID,
      summary: this.state.summary,
    };
    this.setState({ submitted: true, submitStatus: 0, requestID });
    this.props.dispatch(facilitationActions.submitSummary(payload));
  };

  handleCancel = () => {
    this.props.onClose();
  };

  render() {
    // Get full member list:
    const { members, sessionOwnerSub, teamleadSub } = this.props;
    let memberArr = [];
    if (members && !!members.ok) {
      memberArr = members.members || [];
      if (!memberArr.includes(sessionOwnerSub)) {
        memberArr.push(sessionOwnerSub);
      }
      if (!memberArr.includes(teamleadSub)) {
        memberArr.push(teamleadSub);
      }
    }
    return (
      <PanelFacilitationSession
        submitDisabled={this.state.submitted}
        answers={this.state.answers}
        ownvotes={this.state.ownvotes}
        submitStatus={this.state.submitStatus}
        notFound={this.state.notFound}
        submitted={this.state.submitted}
        facilitationData={this.props.facilitationData}
        handleSubmitInput={this.handleSubmitInput}
        handleSubmitVote={this.handleSubmitVote}
        handleManagerSubmit={this.handleManagerSubmit}
        handleSubmitSummary={this.handleSubmitSummary}
        onSummaryChange={this.onSummaryChange}
        summaryString={this.state.summary}
        handleCancel={this.handleCancel}
        onAnswerTextChange={this.onAnswerTextChange}
        onAddAnswer={this.onAddAnswer}
        onRemoveAnswer={this.onRemoveAnswer}
        onVoteToggle={this.onVoteToggle}
        serverError={this.state.serverError}
        members={memberArr}
        heartbeat={this.props.heartbeat}
        candidates={this.props.candidates}
        votes={this.props.votes}
        summary={this.props.summary}
        sub={this.props.sub}
        isSessionOwner={this.props.isSessionOwner}
        sessionOwnerSub={sessionOwnerSub}
        doneUsers={this.state.doneUsers}
        tenantID={this.props.tenantID}
        teamID={this.props.teamID}
        sessionID={this.props.sessionID}
        zIndexModifier={this.props.zIndexModifier}
        enableAi={this.props.enableAi}
      />
    );
  }
}

PanelFacilitationSessionContainer.propTypes = {
  teamID: PropTypes.string,
  tenantID: PropTypes.string,
  dispatch: PropTypes.func,
  actionlog: PropTypes.object,
  onClose: PropTypes.func,
  heartbeat: PropTypes.object,
  isOnline: PropTypes.bool,
  sub: PropTypes.string,
  sessionID: PropTypes.string,
  facilitationData: PropTypes.object,
  members: PropTypes.object,
  candidates: PropTypes.object,
  votes: PropTypes.object,
  summary: PropTypes.object,
  isSessionOwner: PropTypes.bool,
  sessionOwnerSub: PropTypes.string,
  teamleadSub: PropTypes.string,
  zIndexModifier: PropTypes.number,
  enableAi: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
  const facilitationData = facilitationSelectors.selectFacilitationSession(
    state.main.facilitation,
    ownProps.sessionID,
  );
  let sessionOwnerSub = '';
  if (facilitationData && facilitationData.ok) {
    sessionOwnerSub = facilitationData.owner;
  }

  return {
    tenantID: state.auth.tenantID,
    accessToken: state.auth.tokens.access_token,
    facilitationData,
    enableAi: isEnabled('DFAC-AI', appstatusSelectors.selectStatus(state.main.appstatus)),
    heartbeat: facilitationSelectors.selectHeartbeat(state.main.facilitation, ownProps.sessionID),
    actionlog: state.main.facilitation.actionlog,
    members: peopleSelectors.selectTeamMembers(state.main.people, ownProps.teamID),
    teamleadSub: peopleSelectors.selectTeamManager(state.main.people, ownProps.teamID),
    sub: state.auth.userID,
    isSessionOwner: sessionOwnerSub === state.auth.userID,
    sessionOwnerSub,
    votes: facilitationSelectors.selectFacilitationVotes(
      state.main.facilitation,
      ownProps.sessionID,
    ),
    candidates: facilitationSelectors.selectFacilitationCandidates(
      state.main.facilitation,
      ownProps.sessionID,
    ),
    summary: facilitationSelectors.selectFacilitationSummary(
      state.main.facilitation,
      ownProps.sessionID,
    ),
    isOnline: connectionSelectors.selectOnlineStatus(state.main.connection),
  };
};

export default connect(mapStateToProps)(PanelFacilitationSessionContainer);
