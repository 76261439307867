import { roundPercent, round } from 'config/helpers';

export function getNpsData(scores) {
  const result = {
    scores: { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0 },
    sum: 0,
    promoters: 0,
    detractors: 0,
    passives: 0,
    nps: null,
  };
  for (const key in scores) {
    result.scores[key] = scores[key];
    result.sum += scores[key];
    if (key < 7) {
      result.detractors += scores[key];
    } else if (key < 9) {
      result.passives += scores[key];
    } else {
      result.promoters += scores[key];
    }
  }

  result.nps =
    result.sum === 0
      ? 0
      : roundPercent(result.promoters / result.sum - result.detractors / result.sum);
  result.avg = result.nps;
  return result;
}

export function getCsatData(scores) {
  const result = { scores: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 }, sum: 0, avg: null };
  let amount = 0;
  for (const key in scores) {
    result.scores[key] = scores[key];
    result.sum += key * scores[key];
    amount += scores[key];
  }
  result.avg = amount === 0 ? 1 : Math.round((result.sum * 10) / amount) / 10;
  result.amount = amount;
  return result;
}

export function getQuadData(scores) {
  // The quad data is in the format of an object
  // with the key as a string representation of a JSON object with the x and y coordinates
  // and the value as the number of scores at the specified coordinates
  let sumX = 0;
  let sumY = 0;
  let totalAmount = 0;
  for (const key in scores) {
    const coords = JSON.parse(key);
    const amount = scores[key];
    totalAmount += amount;
    sumX += coords.x * amount;
    sumY += coords.y * amount;
  }
  const x = sumX / totalAmount || 0;
  const y = sumY / totalAmount || 0;
  return {
    x: round(x),
    y: round(y),
    score: round((x + y) / 2),
  };
}

export function getMultichoiceData(numReceived, scores) {
  const percents = {};
  for (const key in scores) {
    percents[key] = (scores[key] / numReceived) * 100;
  }
  return percents;
}

export function getSurveyOutcome(topicType, numSent, numReceived, scores) {
  if (topicType === 'csat') {
    return getCsatData(scores);
  }
  if (topicType === 'nps') {
    return getNpsData(scores);
  }
  if (topicType === 'quad') {
    return getQuadData(scores);
  }
  if (topicType === 'multiplechoice') {
    return getMultichoiceData(numReceived, scores);
  }
  // Default to answering percentage
  return { avg: (numReceived / numSent) * 100 };
}

const mergeData = (survey, dataArr, schemaVersion) => {
  let comments = [];
  const scores = {};
  let numSent = 0;
  let numReceived = 0;
  let answerPct = 0;

  const seenAnswers = new Set();

  const isSliderSurvey = survey.topicType === 'sliders';
  const isLegacySchema = schemaVersion < 3;

  for (const data of dataArr) {
    /* this function can receive multiple team's datasets, iterate and merge */
    if (data.status === 'ok') {
      numSent += data.data.numSent;

      // Backwards compatibility...
      if (!isSliderSurvey || isLegacySchema) {
        comments = comments.concat(data.data.comments);
        numReceived += data.data.numReceived;
        for (const key in data.data.scores) {
          if (isSliderSurvey) {
            const sliderType = survey.sliders[key].type;
            if (sliderType === 'basic') {
              if (!(key in scores)) {
                scores[key] = [];
              }
              scores[key] = [
                ...scores[key],
                // Round to nearest 5:
                ...data.data.scores[key].map(score => Math.round(score / 5) * 5),
              ];
            } else if (sliderType === 'delta') {
              if (!(key in scores)) {
                scores[key] = { current: [], goal: [] };
              }

              scores[key].current = [
                ...scores[key].current,
                // Round to nearest 5:
                ...data.data.scores[key].current.map(score => Math.round(score / 5) * 5),
              ];

              scores[key].goal = [
                ...scores[key].goal,
                // Round to nearest 5:
                ...data.data.scores[key].goal.map(score => Math.round(score / 5) * 5),
              ];
            }
          } else {
            if (!scores[key]) {
              scores[key] = 0;
            }
            scores[key] += data.data.scores[key];
          }
        }
      } else {
        // Modern data model, avoids duplicating votes of people
        // in multiple teams.
        // Currently only works with slider surveys
        numReceived += data.data.numReceived;
        for (const c of data.data.comments) {
          if (!seenAnswers.has(c.id)) {
            seenAnswers.add(c.id);
            comments.push(c);
            for (const key in c.score) {
              if (!(key in scores)) {
                scores[key] = [];
              }
              const sliderType = survey.sliders[key].type;
              if (sliderType === 'basic') {
                scores[key].push(Math.round(c.score[key] / 5) * 5);
              } else if (sliderType === 'delta') {
                if (!(key in scores)) {
                  scores[key] = { current: [], goal: [] };
                }
                scores[key].current.push(Math.round(c.score[key].current / 5) * 5);
                scores[key].goal.push(Math.round(c.score[key].goal / 5) * 5);
              }
            }
          }
        }
      }
    }
  }

  answerPct = roundPercent(numReceived / numSent);

  if (isSliderSurvey && !isLegacySchema) {
    // We have the resolution in the data to filter out duplicates
    numReceived = seenAnswers.size;
    comments.filter((obj1, i, arr) => comments.findIndex(obj2 => obj2.id === obj1.id) === i);
  }

  return {
    scores,
    comments,
    numSent,
    numReceived,
    answerPct,
    outcome: getSurveyOutcome(survey.topicType, numSent, numReceived, scores),
  };
};

export function getTeamData(survey, orgTree, cascade, teamId = null, schemaVersion = 1) {
  function recurse(obj, dataArr, targetTeamSeen) {
    let targetSeen = targetTeamSeen;
    if (obj.id === teamId || !!targetSeen) {
      targetSeen = true;
      dataArr.push(obj.data);
      if (!cascade) {
        return;
      }
    }
    for (const child of obj.children) {
      recurse(child, dataArr, targetSeen);
    }
  }
  const data = [];
  // if the teamId is falsy we'll assume that we want the
  // overall scores of the org
  recurse(orgTree, data, !!!teamId);
  return mergeData(survey, data, schemaVersion);
}
