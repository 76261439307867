import React from 'react';
import { Typography } from '@mui/material';
import Container from 'Components/Library/BaseComponents/Container';
import Button from 'Components/Library/BaseComponents/Button';

interface SuccessUiProps {
  onSuccess(): void;
}

function SuccessUi(props: SuccessUiProps) {
  const { onSuccess } = props;

  return (
    <Container alignItems="stretch" p={1}>
      <Typography variant="h6">Success!</Typography>
      <Typography variant="body2">
        Your strategy and context has been successfully analyzed and we have pre-filled your Game
        Plan!
      </Typography>

      <Typography variant="body2">
        You can still edit and update the content as you wish.
      </Typography>

      <Container direction="row" sx={{ pt: 2 }} justifyContent="flex-end">
        <Button variant="text" onClick={onSuccess}>
          Close
        </Button>
      </Container>
    </Container>
  );
}

export default SuccessUi;
