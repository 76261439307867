import { FormControlLabel, RadioGroup, Radio, Typography } from '@mui/material';
import { RadioButtonChecked } from '@mui/icons-material';
import { Widget } from './index';
import { useTranslation } from 'react-i18next';
import ObjectivesIcon from '../Icons/ObjectivesIcon';
import Container from 'Components/Library/BaseComponents/Container';
import React from 'react';

export type OkrConfig = {
  opt: 'create_widget' | 'copy_objectives' | 'copy_full_okrs';
};

interface ObjectivesOptionsProps {
  okrWidgets: Array<Widget>;
  otherAvailableOptions: Array<String>;
  setOkrOptions(OkrConfig): void;
  okrOptions: OkrConfig;
}

export function ObjectivesOptions(props: ObjectivesOptionsProps) {
  const { okrWidgets, otherAvailableOptions, setOkrOptions, okrOptions } = props;
  const { t } = useTranslation();

  const setOkrCopyMode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOkrOptions({
      ...okrOptions,
      opt: e.target.value,
    });
  };

  if (okrWidgets.length > 0) {
    return (
      <Container spacing={1} alignItems="stretch">
        <Typography sx={{ pt: 2 }} variant="subtitle2">
          {t('gameplans.copyMenu.okrsTitle')}
        </Typography>
        <RadioGroup
          sx={{
            ml: 0,
            mr: 0,
          }}
          value={okrOptions.opt}
          onChange={setOkrCopyMode}
        >
          <Container m={0} alignItems="stretch">
            <FormControlLabel
              control={
                <Radio size="small" checkedIcon={<RadioButtonChecked color="secondary" />} />
              }
              value="create_widget"
              sx={{
                borderRadius: 1,
                bgcolor: okrOptions.opt === 'create_widget' ? 'background.surface' : 'transparent',
                minHeight: 40,
                ml: 0,
                mr: 0,
                '& .MuiFormControlLabel-label': {
                  flexGrow: 10,
                  ml: 2,
                  color: 'text.secondary',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                },
              }}
              labelPlacement="start"
              label={
                <>
                  <ObjectivesIcon />
                  <Typography sx={{ ml: 2 }} variant="subtitle2" color="text.secondary">
                    {t('gameplans.copyMenu.okrsOptionFresh')}
                  </Typography>
                </>
              }
            />
            {otherAvailableOptions.includes('copy_objectives') && (
              <FormControlLabel
                control={
                  <Radio size="small" checkedIcon={<RadioButtonChecked color="secondary" />} />
                }
                value="copy_objectives"
                sx={{
                  borderRadius: 1,
                  bgcolor:
                    okrOptions.opt === 'copy_objectives' ? 'background.surface' : 'transparent',
                  minHeight: 40,
                  ml: 0,
                  mr: 0,
                  '& .MuiFormControlLabel-label': {
                    flexGrow: 10,
                    ml: 2,
                    color: 'text.secondary',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  },
                }}
                labelPlacement="start"
                label={
                  <>
                    <ObjectivesIcon />
                    <Typography sx={{ ml: 2 }} variant="subtitle2" color="text.secondary">
                      {t('gameplans.copyMenu.okrsOptionContinueObjectives')}
                    </Typography>
                  </>
                }
              />
            )}
            {otherAvailableOptions.includes('copy_keyresults') && (
              <FormControlLabel
                control={
                  <Radio size="small" checkedIcon={<RadioButtonChecked color="secondary" />} />
                }
                value="copy_full_okrs"
                sx={{
                  borderRadius: 1,
                  bgcolor:
                    okrOptions.opt === 'copy_full_okrs' ? 'background.surface' : 'transparent',
                  minHeight: 40,
                  ml: 0,
                  mr: 0,
                  '& .MuiFormControlLabel-label': {
                    flexGrow: 10,
                    ml: 2,
                    color: 'text.secondary',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                  },
                }}
                labelPlacement="start"
                label={
                  <>
                    <ObjectivesIcon />
                    <Typography sx={{ ml: 2 }} variant="subtitle2" color="text.secondary">
                      {t('gameplans.copyMenu.okrsOptionContinueOKR')}
                    </Typography>
                  </>
                }
              />
            )}
          </Container>
        </RadioGroup>
      </Container>
    );
  }
  return null;
}
