import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';
import PersonasAvatar from 'Components/Common/PersonasAvatar';

import { peopleSelectors } from 'state/ducks/people';

const styles = theme => ({
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 8,
    paddingRight: 8,
    margin: 8 / 2,
    marginBottom: 8,
    ...theme.shape,
  },
  menuItemColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginLeft: 8,
  },
});

class TeamSearchResult extends Component {
  render() {
    const { classes, suggestion, selectTeamManager, selectUser } = this.props;

    const managerSub = selectTeamManager(suggestion.teamId);
    const managerDetails = selectUser(managerSub);

    return (
      <MenuItem
        component="div"
        className={classes.menuItem}
        id={`org-search-team-result-${suggestion.teamId}`}
        name="org-search-team-result"
      >
        <PersonasAvatar sub={suggestion.teamId} size="small" type="team" />
        <div className={classes.menuItemColumn}>
          <Typography variant="subtitle2">{`${suggestion.teamName}`}</Typography>
          <Typography variant="caption">
            {!!managerDetails.ok && managerDetails.fullName}
          </Typography>
        </div>
      </MenuItem>
    );
  }
}

const mapStateToProps = state => ({
  selectTeamManager: teamId => peopleSelectors.selectTeamManager(state.main.people, teamId),
  selectUser: sub => peopleSelectors.selectUser(state.main.people, sub),
});

TeamSearchResult.propTypes = {
  classes: PropTypes.exact({
    menuItemColumn: PropTypes.string,
    menuItem: PropTypes.string,
  }),
  suggestion: PropTypes.object,
  selectTeamManager: PropTypes.func,
  selectUser: PropTypes.func,
};

export default connect(mapStateToProps)(withStyles(styles)(TeamSearchResult));
