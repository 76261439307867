import { isEnabled } from 'config/flags';
import { useSelector } from 'react-redux';
import { appstatusSelectors } from 'state/ducks/appstatus';

function useFeatureFlag(flag: string) {
  const appStatus = useSelector((state: any) =>
    appstatusSelectors.selectStatus(state.main.appstatus),
  );
  return isEnabled(flag, appStatus);
}

export default useFeatureFlag;
