import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Ajv from 'ajv';
import { get, set, isEmpty, pickBy, debounce } from 'lodash';
import { nanoid } from 'nanoid';
// import { formsActions, formsSelectors } from 'state/ducks/forms';
import { filterMentions } from './helpers';
import FormContext from './FormContext';
import useTimeout from 'hooks/useTimeout';
import { getNodeType } from '../GraphElementResolver/helpers';
import { NodeType } from '../Graph/types';
import { useDispatch, useSelector } from 'react-redux';
import { formsActions, formsSelectors } from 'state/ducks/forms';
const ajv = new Ajv({ allErrors: true, strict: false });

ajv.addKeyword({
  keyword: 'richtextschema',
  code(cxt) {
    const { data } = cxt;
    // @ts-ignore
    return !!data && data.type === 'doc';
  },
});

function defaultSubmitActionCreator(payload) {
  const { id, description, ...rest } = payload;
  if (description && [NodeType.Commitment, NodeType.Interlock].includes(getNodeType(id))) {
    rest.description = JSON.stringify(description);
  }
  return {
    id: payload.id,
    body: rest,
  };
}

function ReactQueryForm(props) {
  const {
    // TODO: check default props
    children,
    allowRefreshData = false,
    name,
    formIdentifier,
    forwardRef,
    key,
    schema,

    apiEndpointBaseUrl,
    refreshedDataSafeDeltaSeconds = 0,
    mutation,

    preValidationTransform,
    debouncedAutoSubmit = false,
    submitActionCreator = defaultSubmitActionCreator,
    preventSaveIncomplete,
    initialValues,
    persistedData,
    persistedDataBlacklist = [],
    submitOnlyDirty = false,
    additionalProperties = {},
    customDispatchFunc,
    onValueChange,
    onSubmitDispatched,
    onSubmitSuccess,
  } = props;
  const validator = useRef(ajv.compile(schema));
  const [setTimeout, cancelTimeout] = useTimeout();

  const richTextReferences = useRef({});

  console.log('mutation: ', mutation);

  // const submit = debouncedAutoSubmit ? () => {} : () => {};

  const [values, setValues] = useState({});
  const valuesRef = useRef(values);
  const [touchedFields, setTouchedFields] = useState({});
  const [apiError, setApiError] = useState(false);

  // TODO: get the API error message from the mutate object
  const [apiErrorMessage, setApiErrorMessage] = useState(null);

  const [isValid, setIsValid] = useState({});
  const [showErrors, setShowErrors] = useState({});

  const [fieldErrors, setFieldErrors] = useState({});

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [lastSubmittedValues, setLastSubmittedValues] = useState(false);

  const [dirtyFields, setDirtyFields] = useState({});

  const persistedFormData = useSelector(state =>
    formsSelectors.selectFormState(state.main.forms, name, formIdentifier),
  );

  const dispatch = useDispatch();

  // For the debounced submit to work (have access to latest state)
  // in a functional component, we need to keep a ref to the latest
  // state...
  useEffect(() => {
    valuesRef.current = values;
  }, [values]);

  const validate = useCallback(
    values => {
      let isValid;
      if (!!preValidationTransform) {
        isValid = validator.current(preValidationTransform(values));
      } else {
        isValid = validator.current(values);
      }

      const fieldErrors = {};
      if (!isValid) {
        validator.current.errors.forEach(error => {
          let fieldName = error.instancePath.substring(1);
          if (fieldName.includes('/')) {
            // nested field, we need to manipulate the path format
            // ajv returns paths in the format of array[0].key
            // but what we need is array.0.key
            fieldName = fieldName.replace(/\//g, '.');
          }
          if (!(fieldName in fieldErrors)) {
            fieldErrors[fieldName] = [];
          }
          fieldErrors[fieldName].push(error.message);
        });
      }

      // Custom validation for fields that are not directly compatible with ajv
      // json-schema validation
      // eg. the only sane way to validate rich text content in any way is to
      // use methods of the remirror library
      for (const fieldName in richTextReferences.current) {
        const ref = richTextReferences.current[fieldName];
        const fieldSchema = get(schema.properties, fieldName, {});
        if (ref.current) {
          const errors = ref.current.validate(fieldSchema);

          if (errors.length > 0) {
            isValid = false;
            fieldErrors[fieldName] = errors;
          }
        }
      }
      return [isValid, fieldErrors];
    },
    [preValidationTransform, schema],
  );

  // mounting
  useEffect(() => {
    // TODO: handle incomplete form state saving and rehydrating
    // from redux state
    console.log('RQFORM: mounting');
    if (!!initialValues) {
      console.log('RQFORM: has values');
      const filteredPeristedData = {};
      if (!!persistedData && !debouncedAutoSubmit && !preventSaveIncomplete) {
        filteredPeristedData.values = pickBy(
          persistedData.values,
          (value, key) => !persistedDataBlacklist.includes(key),
        );
        filteredPeristedData.dirtyFields = pickBy(
          persistedData.dirtyFields,
          (value, key) => !persistedDataBlacklist.includes(key),
        );
      }
      console.log('RQFORM: Setting initialvalues: ', {
        ...initialValues,
        ...filteredPeristedData.values,
      });
      setValues({ ...initialValues, ...filteredPeristedData.values });
      setDirtyFields({ ...dirtyFields, ...filteredPeristedData.dirtyFields });
      const [isValid, fieldErrors] = validate(getValuesToSubmit(values));
      setIsValid(isValid);
      setFieldErrors(fieldErrors);
    }
    // We're emulating componentDidMount:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log('RQFORM values 1:', values);

  // Handle updates to the object being rendered -
  // eg. we received new data from the API for the form
  // where someone else might've done changes.
  useEffect(() => {
    if (!!initialValues) {
      const now = Date.now();
      const changedValues = {};
      for (const fieldName in initialValues) {
        /*
        Receiving potentially new values, check if we should update the values in state.
        Update if:
          - we did not yet have a value for this field
          - we had a value, but:
            - allowRefreshData is true
            - AND the field is not dirty (edited, not yet submitted)
            - AND if refreshedDataSafeDeltaSeconds is set (non-zero), atleast that amount of
              seconds have elapsed since the last edit to that field (used for "autosubmit scenarios")
        */

        /*
        console.log('RQForm update condition 1: ', !(fieldName in values));
        console.log('RQForm update condition 2a: ', allowRefreshData);
        console.log('RQForm update condition 2b: ', !get(dirtyFields, fieldName, false));
        console.log('RQForm update condition 2c1: ', refreshedDataSafeDeltaSeconds === 0);
        console.log(
          'RQForm update condition 2c2: ',
          get(touchedFields, fieldName, 0) + refreshedDataSafeDeltaSeconds * 1000 < now,
        );
        console.log('RQForm: ', get(touchedFields, fieldName, 0));
        console.log('RQForm: ', refreshedDataSafeDeltaSeconds * 1000);
        */
        if (
          !(fieldName in values) ||
          (allowRefreshData &&
            !get(dirtyFields, fieldName, false) &&
            (refreshedDataSafeDeltaSeconds === 0 ||
              get(touchedFields, fieldName, 0) + refreshedDataSafeDeltaSeconds * 1000 < now))
        ) {
          if (initialValues[fieldName] !== values[fieldName]) {
            console.log(`RQForm setting value of ${fieldName} to: ${initialValues[fieldName]}`);
            changedValues[fieldName] = initialValues[fieldName];
          }
        }
      }
      if (!isEmpty(changedValues)) {
        const newValues = { ...values, ...changedValues };
        setValues(newValues);
        // Changes in some incoming value could now cause a validation error
        // with some of the local user edits: perform validation
        const [isValid, fieldErrors] = validate(newValues);
        setIsValid(isValid);
        setFieldErrors(fieldErrors);
      }
    }
    // We're emulating componentDidMount:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, allowRefreshData, refreshedDataSafeDeltaSeconds]);

  useEffect(
    () => () => {
      // Component is unmounting:

      if (!debouncedAutoSubmit && !isEmpty(dirtyFields) && !preventSaveIncomplete) {
        if (mutation.isSuccess) {
          // Last mutation was successful, clear persisted form state
          dispatch(
            formsActions.clearForm({
              formName: name,
              formIdentifier,
            }),
          );
        } else {
          dispatch(
            formsActions.saveForm({
              formName: name,
              formState: { values: valuesRef.current, dirtyFields },
              formIdentifier,
            }),
          );
        }
      }
    },
    // We're emulating componentWillUnmount:
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const getValuesToSubmit = useCallback(
    v => {
      if (!submitOnlyDirty) {
        return { ...v };
      }
      return Object.keys(v)
        .filter(key => key in dirtyFields)
        .reduce((obj, key) => {
          obj[key] = v[key];
          return obj;
        }, {});
    },
    [submitOnlyDirty, dirtyFields],
  );

  const registerRef = (fieldName, r) => {
    richTextReferences.current[fieldName] = r;
  };

  const castValues = useCallback(
    v => {
      for (const fieldName in v) {
        const fieldSchema = schema.properties[fieldName];
        if (fieldSchema?.richtextschema) {
          v[fieldName] = filterMentions(v[fieldName]);
        }
      }
      console.log('RQFORM cast values returning: ', v);
      return v;
    },
    [schema],
  );

  const submit = useCallback(
    event => {
      const valuesToSubmit = valuesRef.current;
      console.log('RQForm submit called for', valuesToSubmit);
      const payload = castValues(getValuesToSubmit(valuesToSubmit));
      console.log('RQForm submitting: ', payload);
      const [isValid, fieldErrors] = validate(payload);
      if (isValid) {
        const requestID = nanoid(10);
        const params = {
          ...payload,
          ...additionalProperties,
          requestID,
        };
        if (!Boolean(customDispatchFunc)) {
          mutation.mutate(submitActionCreator(params));
          if (Boolean(onSubmitDispatched)) {
            onSubmitDispatched(params, event);
          }
        } else {
          customDispatchFunc(submitActionCreator(params));
        }
        setLastSubmittedValues(payload);
      } else {
        setIsValid(false);
        setFieldErrors(fieldErrors);

        if (!Boolean(debouncedAutoSubmit)) {
          // The source of the action was user interaction
          // Show the user the error instantly
          setShowErrors(true);
        } else {
          // Let's give the user a bit of time before we show
          // what's wrong, they might've just erased a field
          // and want to think for a bit!
          cancelTimeout();
          setTimeout(() => {
            setShowErrors(true);
          }, 3000);
        }
      }
    },
    [mutation],
  );

  const debouncedSubmit = useCallback(debounce(submit, debouncedAutoSubmit), []);

  const contextValueObject = useMemo(() => {
    const enableShowErrors = () => {
      setShowErrors(true);
    };

    const onFieldChange = (fieldName, value) => {
      const newTouchedFields = { ...touchedFields };
      let newDirtyFields = { ...dirtyFields };
      let newValues = { ...values };

      set(newValues, fieldName, value);
      const rootField = fieldName.split('.')[0];
      // Mark field as edited?
      newTouchedFields[rootField] = Date.now();
      dirtyFields[rootField] = value !== initialValues[rootField];

      // Process onValueChange hooks
      if (!!onValueChange) {
        newValues = onValueChange(rootField, newValues);
      }

      const [isValid, fieldErrors] = validate(getValuesToSubmit(newValues));
      setValues(newValues);
      setIsValid(isValid);
      setFieldErrors(fieldErrors);
      setTouchedFields(newTouchedFields);
      setDirtyFields(newDirtyFields);

      // hide errors if the user now fixed them
      if (showErrors && isValid) {
        setShowErrors(false);
      }

      if (Boolean(debouncedAutoSubmit)) {
        console.log('RQForm enqueue autosubmit: ', newValues);
        debouncedSubmit();
      }
    };

    return {
      onFieldChange: onFieldChange,
      formName: name,
      onSubmit: submit,
      schema,
      values,
      isValid,
      showErrors,
      enableShowErrors: enableShowErrors,
      fieldErrors,
      submitting: mutation.isPending,
      apiError,
      apiErrorMessage,
      submitSuccess: mutation.isSuccess,
      isAutoSubmit: Boolean(debouncedAutoSubmit),
      dirtyFields,
      registerRef: registerRef,
    };
  }, [
    name,
    submit,
    schema,
    values,
    isValid,
    showErrors,
    fieldErrors,
    mutation,
    apiError,
    apiErrorMessage,
    debouncedAutoSubmit,
    dirtyFields,
    registerRef,
  ]);

  return (
    <div name={name} ref={forwardRef} key={key}>
      <FormContext.Provider
        // TODO: performance optimization
        // Right now, everytime Form renders a new value object is created
        // And all consumers of this context will re-render
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        value={contextValueObject}
      >
        {children}
      </FormContext.Provider>
    </div>
  );
}

export default ReactQueryForm;
