import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';
import PersonasAvatar from 'Components/Common/PersonasAvatar';

import { peopleSelectors } from 'state/ducks/people';

const styles = theme => ({
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 8,
    paddingRight: 8,
    margin: 8 / 2,
    marginBottom: 8,
    ...theme.shape,
  },
  menuItemColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginLeft: 8,
  },
});

class UserSearchResult extends Component {
  render() {
    const { classes, suggestion, selectTeamName } = this.props;

    const teamName = selectTeamName(suggestion.teamId);

    return (
      <MenuItem
        component="div"
        className={classes.menuItem}
        id={`org-search-user-result-${suggestion.sub}`}
        name="org-search-user-result"
      >
        <PersonasAvatar sub={suggestion.sub} size="small" />
        <div className={classes.menuItemColumn}>
          <Typography variant="subtitle2">{`${suggestion.fullName}`}</Typography>
          <Typography variant="caption">{teamName}</Typography>
        </div>
      </MenuItem>
    );
  }
}

const mapStateToProps = state => ({
  selectTeamName: teamId => peopleSelectors.selectTeamName(state.main.people, teamId),
});

UserSearchResult.propTypes = {
  classes: PropTypes.exact({
    menuItemColumn: PropTypes.string,
    menuItem: PropTypes.string,
  }),
  suggestion: PropTypes.object,
  selectTeamName: PropTypes.func,
};

export default connect(mapStateToProps)(withStyles(styles)(UserSearchResult));
