import React, { useState } from 'react';
import LinkIcon from '@mui/icons-material/OpenInNew';
import { SxProps } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { instance as axiosInstance } from 'config/axios';
import { API_GRAPH_URL } from 'state/constants/api';
import EditIcon from '@mui/icons-material/Edit';
import DataObjectChip from '../DataObjectChip';
import { useTranslation } from 'react-i18next';
import EditLinkModal from './EditLinkModal';
interface LinkChipProps {
  graphId: string;
  name?: string;
  sx?: SxProps;
  color?: string;
  variant?: string;
  wrapText?: boolean;
  raised?: boolean;
  secondaryActions?: any;
  canEdit?: boolean;
}

function newURL(string) {
  let url;
  try {
    url = new URL(string);

    if (!url.hostname) {
      // cases where the hostname was not identified
      // ex: user:password@www.example.com, example.com:8000
      url = new URL(`https://${string}`);
    }
  } catch (error) {
    url = new URL(`https://${string}`);
  }

  return url;
}

function LinkChip(props: LinkChipProps) {
  const { graphId, name, sx, color, variant, wrapText, raised, canEdit, secondaryActions } = props;
  const { t } = useTranslation();
  const [editAnchorEl, setEditAnchorEl] = useState(null);

  const openEditLink = event => {
    event.preventDefault();
    event.stopPropagation();
    setEditAnchorEl(event.currentTarget);
  };

  const appliedActions =
    canEdit && secondaryActions
      ? [
          ...secondaryActions,
          {
            name: 'edit-relation',
            label: t('general.edit'),
            Icon: EditIcon,
            onClick: e => openEditLink(e),
          },
        ]
      : [];
  const tenantID = useSelector((state: any) => state.auth.tenantID);

  const { isLoading, error, data } = useQuery({
    queryKey: [graphId],

    queryFn: async () => {
      const response = await axiosInstance.get(
        `${API_GRAPH_URL}/${tenantID}/node?${new URLSearchParams({
          id: graphId,
        })}`,
      );
      return response.data.result.node;
    },
  });

  const handleClick = () => {
    if (!isLoading && !error && data?.url) {
      const { url } = data;
      const newWindow = window.open(newURL(url), '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    }
  };

  return (
    <>
      <DataObjectChip
        name={name ?? `link-chip-${graphId}`}
        Icon={LinkIcon}
        sx={sx}
        color={color}
        dataChipType="link"
        text={data?.display_name || data?.url}
        onClick={handleClick}
        actionable={true}
        variant={variant}
        wrapText={wrapText || variant === 'xl'}
        raised={raised}
        secondaryActions={appliedActions}
      />

      {Boolean(editAnchorEl) && (
        <EditLinkModal
          graphId={graphId}
          text={data?.display_name || ''}
          url={data?.url}
          modalAnchorElement={editAnchorEl}
          onClose={() => setEditAnchorEl(null)}
        />
      )}
    </>
  );
}

export default LinkChip;
