import { Box, Typography } from '@mui/material';
// @ts-ignore
import EmptyStateGraphic from './empty_state_graphic.svg';
// @ts-ignore
import EmptyStateGraphicDark from './empty_state_graphic_dark.svg';
import Container from 'Components/Library/BaseComponents/Container';

interface EmptyStateProps {
  title?: String;
  subtitle?: String;
  actions?: React.ReactNode;
}

function EmptyState(props: EmptyStateProps) {
  const { title = '', subtitle = '', actions } = props;
  return (
    <Box
      sx={{
        width: '100%',
        minHeight: 360,
        backgroundImage: theme =>
          theme.palette.mode === 'dark'
            ? `url(${EmptyStateGraphicDark})`
            : `url(${EmptyStateGraphic})`,
        backgroundPosition: '50% 0%',
        backgroundRepeat: 'no-repeat',
        bgcolor: 'background.surface',
        border: '2px solid',
        borderColor: 'action.border',
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <Box
        sx={{
          marginTop: '92px',
        }}
      >
        <Box
          sx={{
            width: '76px',
            height: '76px',
            //backgroundImage: `url(${GamePlanIconLarge})`,
            backgroundPosition: '50% 50%',
            backgroundRepeat: 'no-repeat',
            borderRadius: 1,
            border: '2px solid',
            borderColor: 'action.border',
            color: 'text.strong',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <svg
            width="60"
            height="34"
            viewBox="0 0 60 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M27.2952 20.4965H28.2952V19.4965V14.0868V13.0868H27.2952H20.1806V5.97222V4.97222H19.1806H13.7709H12.7709V5.97222V13.0868H5.6563H4.6563V14.0868V19.4965V20.4965H5.6563H12.7709V27.6111V28.6111H13.7709H19.1806H20.1806V27.6111V20.4965H27.2952ZM5.6563 1.5625H54.3438C56.7669 1.5625 58.7535 3.54916 58.7535 5.97222V27.6111C58.7535 30.0342 56.7669 32.0208 54.3438 32.0208H5.6563C3.23324 32.0208 1.24658 30.0342 1.24658 27.6111V5.97222C1.24658 3.54916 3.23324 1.5625 5.6563 1.5625ZM34.4098 20.849C34.4098 23.6463 36.6697 25.9063 39.4671 25.9063C42.2644 25.9063 44.5244 23.6463 44.5244 20.849C44.5244 18.0516 42.2644 15.7917 39.4671 15.7917C36.6697 15.7917 34.4098 18.0516 34.4098 20.849ZM45.2292 12.7344C45.2292 15.5317 47.4892 17.7917 50.2865 17.7917C53.0838 17.7917 55.3438 15.5317 55.3438 12.7344C55.3438 9.93706 53.0838 7.67708 50.2865 7.67708C47.4892 7.67708 45.2292 9.93706 45.2292 12.7344Z"
              stroke="currentColor"
              strokeWidth="2"
            />
          </svg>
        </Box>
      </Box>
      <Box
        sx={{
          paddingTop: '32px',
          textAlign: 'center',
        }}
      >
        <Typography variant="h6">{title}</Typography>
      </Box>
      <Box
        sx={{
          marginTop: '16px',
          textAlign: 'center',
          pl: 2,
          pr: 2,
          maxWidth: {
            xs: '100%',
            sm: '80%',
            md: '60%',
          },
        }}
      >
        <Typography color="text.secondary" variant="subtitle2">
          {subtitle}
        </Typography>
      </Box>
      <Container direction="row" sx={{ mt: 3, mb: 12 }}>
        {actions}
      </Container>
    </Box>
  );
}

export default EmptyState;
