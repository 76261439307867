import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Button } from '@mui/material';
import imageCompression from 'browser-image-compression';
import TgProgress from 'Components/Common/TgProgress';
import ProtectedImage from 'Components/Common/ProtectedImage';

import { CTX_IMAGE_UPLOAD_MAX_SIZE } from 'config/constants';

export const validate = () => true;

const styles = theme => ({
  root: {
    padding: 0,
    margin: 0,
    overflow: 'hidden',
  },
  img: {
    marginBottom: -6,
    color: theme.palette.primary.main,
  },
  error: {
    color: theme.palette.error.main,
  },
  progress: {
    margin: '0 auto',
  },
  reactCrop: {
    display: 'block',
  },
});

const getCroppedImg = async (image, crop) => {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext('2d');

  // adding a white background to transparent images, like svgs
  // if (this.props.item.originalSrc.startsWith('data:image/svg+xml')) {
  //   ctx.fillStyle = 'white';
  //   ctx.fillRect(0, 0, canvas.width, canvas.height);
  // }

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height,
  );
  // TODO: the canvas is not deleted and stays to pollute the DOM, make sure delete also works as this is async, use variable to store the value

  return new Promise(resolve => {
    resolve(canvas.toDataURL('image/jpeg'));
  });
};

const getImg = async image => {
  const canvas = document.createElement('canvas');
  canvas.width = image.naturalWidth;
  canvas.height = image.naturalHeight;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(image, 0, 0);
  // TODO: the canvas is not deleted and stays to pollute the DOM, make sure delete also works as this is async, use variable to store the value

  return new Promise(resolve => {
    resolve(canvas.toDataURL('image/jpeg'));
  });
};

// TODO: this is not an optimal way of getting the image size for jpegs for example
const getImageSize = src => {
  if (!!src) {
    const base64str = src.split('base64,')[1];
    const decoded = atob(base64str);
    return decoded.length;
  }
  return null;
};

const b64toBlob = base64 => fetch(`${base64}`).then(res => res.blob());

const blobToBase64 = blob => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

class CardImage extends Component {
  state = {
    crop: {
      unit: '%',
      height: 100,
      width: 100,
      x: 0,
      y: 0,
    },
    processing: false,
    valid: true,
  };

  componentDidMount() {
    if (this.props.item.originalSrc) {
      const length = getImageSize(this.props.item.originalSrc);

      this.setState({
        firstCropDialogOpen: !!!this.props.item.src && !!this.props.item.originalSrc,
        fileSize: length,
      });
    } else if (this.props.item.src) {
      const length = getImageSize(this.props.item.src);

      this.setState({
        firstCropDialogOpen: !!!this.props.item.src && !!this.props.item.originalSrc,
        fileSize: length,
      });
    }
    this.setValid();
  }

  setValid = (crop = this.state.crop) => {
    if (!crop) {
      this.setState({ valid: true });
    } else if (
      // eslint-disable-next-line no-restricted-globals
      isNaN(crop.height) ||
      // eslint-disable-next-line no-restricted-globals
      isNaN(crop.width) ||
      // eslint-disable-next-line no-restricted-globals
      isNaN(crop.x) ||
      // eslint-disable-next-line no-restricted-globals
      isNaN(crop.y) ||
      (!!this.props.item.originalSrc &&
        (this.props.item.originalSrc.startsWith('data:image/heif') ||
          this.props.item.originalSrc.startsWith('data:image/tiff') ||
          this.props.item.originalSrc.startsWith('data:image/svg+xml') ||
          !this.props.item.originalSrc.startsWith('data:image/')))
    ) {
      this.setState({ valid: false });
    } else {
      this.setState({ valid: true });
    }
  };

  save = async () => {
    this.setState({
      processing: true,
    });
    let src;
    // no crop
    if (
      (this.state.crop.height === 0 && this.state.crop.width === 0) ||
      (this.state.crop.unit === '%' &&
        this.state.crop.height === 100 &&
        this.state.crop.width === 100)
    ) {
      src = await getImg(this.state.imageRef);
      // crop
    } else {
      src = await getCroppedImg(this.state.imageRef, this.state.crop);
    }
    const croppedFileSize = getImageSize(src);
    this.setState({
      croppedFileSize,
    });

    if (croppedFileSize > CTX_IMAGE_UPLOAD_MAX_SIZE) {
      const newBlob = await b64toBlob(src);

      const options = {
        maxSizeMB: CTX_IMAGE_UPLOAD_MAX_SIZE / 1000000,
        useWebWorker: true,
      };
      src = await imageCompression(newBlob, options);
      src = await blobToBase64(src);
      const compressedFileSize = getImageSize(src);
      this.setState({
        compressedFileSize,
      });
    }
    this.setState({
      firstCropDialogOpen: false,
      processing: false,
    });
    this.props.onEdit({
      b64str: src,
      __cfg: { settingsOpen: false },
    });
    this.setValid();
  };

  onImageLoaded = imageRef => {
    this.setState({
      imageRef,
      crop: {
        unit: '%',
        height: 100,
        width: 100,
        x: 0,
        y: 0,
      },
    });

    this.setValid();
    return false;
  };

  onCropChange = crop => {
    this.setState({ crop });
    this.setValid(crop);
  };

  closeDialog = () => {
    const { item, closeSettings, deleteItem } = this.props;
    const { src, b64str } = item;
    if (!src && !b64str) {
      // this item was just being created, let's abort creating it
      deleteItem(true);
    } else {
      closeSettings();
    }
  };

  renderTitle = () => {
    if (!this.state.valid) {
      return (
        <>
          <Typography variant="h6" className={this.props.classes.error} gutterBottom>
            {this.props.t('general.errorOccured')}
          </Typography>
          <Typography variant="body2" className={this.props.classes.error} id="card-image-error">
            {this.props.t('contextv2.cards.badfileerror')}
          </Typography>
        </>
      );
    }
    if (this.state.fileSize > CTX_IMAGE_UPLOAD_MAX_SIZE) {
      return (
        <>
          <Typography variant="h6" gutterBottom>
            {this.props.t('contextv2.cards.cropimagetitle')}
          </Typography>
          <Typography variant="body2" id="card-image-warning">
            {this.props.t('contextv2.cards.sizewarning', {
              fileSize: (this.state.fileSize / 1000000).toFixed(1),
              maxFileSize: CTX_IMAGE_UPLOAD_MAX_SIZE / 1000000,
            })}
          </Typography>
        </>
      );
    }
    return (
      <Typography variant="h6" gutterBottom>
        {this.props.t('contextv2.cards.cropimagetitle')}
      </Typography>
    );
  };

  generateAltText = () => {
    if (
      !!this.state.fileSize &&
      (!!this.state.croppedFileSize || !!this.state.compressedFileSize)
    ) {
      let text = `${this.props.t('contextv2.cards.original')} ${(
        this.state.fileSize / 1000000
      ).toFixed(1)}MB.`;
      if (this.state.fileSize !== this.state.croppedFileSize) {
        text += ` ${this.props.t('contextv2.cards.cropped')} ${(
          this.state.croppedFileSize / 1000000
        ).toFixed(1)}MB.`;
      }
      if (!!this.state.compressedFileSize) {
        text += ` ${this.props.t('contextv2.cards.compressed')} ${(
          this.state.compressedFileSize / 1000000
        ).toFixed(1)}MB.`;
      }
      return text;
    }
    return null;
  };

  render() {
    const { classes, className, item, editable, key, id, cardId, t } = this.props;
    const { originalSrc, src, b64str, __cfg = {} } = item;
    const { settingsOpen } = __cfg;
    const { firstCropDialogOpen, crop, processing } = this.state;

    const appliedSrc = b64str || src;
    const altText = this.generateAltText();

    return (
      <div
        className={clsx([classes.root, className])}
        data-testid="ctx-card-el-img"
        key={key}
        id={id}
      >
        {!!editable ? (
          !!altText ? (
            <Tooltip placement="bottom" title={this.generateAltText()}>
              <img src={appliedSrc} width="100%" alt="Card media" className={classes.img} />
            </Tooltip>
          ) : (
            <img src={appliedSrc} width="100%" alt="Card media" className={classes.img} />
          )
        ) : (
          <ProtectedImage
            filepath={
              !!item.version
                ? `${cardId}_${item.id}_${item.version}.jpg`
                : `${cardId}_${item.id}.jpg`
            }
            width="100%"
            alt="Card media"
            className={classes.img}
          />
        )}
        {!!processing && (
          <Dialog open>
            <TgProgress color="secondary" size={80} className={classes.progress} />
          </Dialog>
        )}
        {!processing && !!editable && (!!firstCropDialogOpen || !!settingsOpen) && (
          <Dialog open>
            <DialogContent>
              {this.renderTitle()}
              <br />
              <ReactCrop
                className={classes.reactCrop}
                disabled={false}
                src={originalSrc || src}
                crop={crop}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropChange}
                onChange={this.onCropChange}
                keepSelection
              />
            </DialogContent>
            <DialogActions>
              <Button id="card-image-cancel" onClick={this.closeDialog}>
                {t('general.cancel')}
              </Button>
              <Button
                id="card-image-save"
                color="secondary"
                variant="contained"
                onClick={this.save}
                disabled={!this.state.valid}
              >
                {t('general.save')}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    );
  }
}

CardImage.propTypes = {
  item: PropTypes.object,
  classes: PropTypes.exact({
    root: PropTypes.string,
    img: PropTypes.string,
    error: PropTypes.string,
    progress: PropTypes.string,
    reactCrop: PropTypes.string,
  }),
  onEdit: PropTypes.func,
  closeSettings: PropTypes.func,
  deleteItem: PropTypes.func,
  editable: PropTypes.bool,
  key: PropTypes.string,
  id: PropTypes.string,
  cardId: PropTypes.string,
  t: PropTypes.func,
};

export default withTranslation()(withStyles(styles)(CardImage));
