import React from 'react';
import PropTypes from 'prop-types';
import PeriodNavigator from 'Components/Features/Objectives/PeriodNavigator';
import Box from '@mui/material/Box';
import ObjectivesTablev2 from 'Components/Features/Objectives/ObjectivesTablev2';
import useDomainOkrs from 'hooks/useDomainOkrs';

function ObjectivesWidgetExpanded({
  teamId,
  appliedPeriod,
  selectPeriod,
  usePeriodicGameplans,
  prevShortTermPeriod,
  nextShortTermPeriod,
}) {
  const domain = { t: 'team', d: teamId };
  const { okrs } = useDomainOkrs([domain], appliedPeriod);
  return (
    <div>
      {!usePeriodicGameplans && (
        <Box sx={{ mb: 1 }}>
          <PeriodNavigator
            selectedPeriod={appliedPeriod}
            handlePeriodChange={p => selectPeriod(p.stPeriodId)}
            prevPeriod={prevShortTermPeriod}
            nextPeriod={nextShortTermPeriod}
          />
        </Box>
      )}
      <ObjectivesTablev2
        open
        period={appliedPeriod}
        domain={domain}
        okrData={okrs[0]}
        showControls={false}
        showHeader={false}
        alwaysOpen
        showLastModified={true}
      />
    </div>
  );
}

ObjectivesWidgetExpanded.propTypes = {
  teamId: PropTypes.string,
  appliedPeriod: PropTypes.string,
  selectPeriod: PropTypes.func,
  usePeriodicGameplans: PropTypes.bool,
  prevShortTermPeriod: PropTypes.string,
  nextShortTermPeriod: PropTypes.string,
};

export default ObjectivesWidgetExpanded;
