import { enrich } from '../../helpers';

export const selectFacilitationSession = (slice, sessionID) => {
  if (!!slice.sessions[sessionID]) return slice.sessions[sessionID];

  return enrich();
};

export const selectHeartbeat = (slice, sessionID) => slice.heartbeats[sessionID];

export const selectFacilitationInputs = (slice, sessionID) => {
  if (!!slice.inputs[sessionID]) return slice.inputs[sessionID];

  return enrich();
};

export const selectFacilitationCandidates = (slice, sessionID) => {
  if (!!slice.candidates[sessionID]) return slice.candidates[sessionID];

  return enrich();
};

export const selectFacilitationVotes = (slice, sessionID) => {
  if (!!slice.votes[sessionID]) return slice.votes[sessionID];

  return enrich();
};

export const selectFacilitationSummary = (slice, sessionID) => {
  if (!!slice.summaries[sessionID]) return slice.summaries[sessionID];

  return enrich();
};

export const selectTeamSessionList = (slice, teamID) => {
  if (!!slice.teamSessions[teamID]) {
    return slice.teamSessions[teamID];
  }
  return enrich();
};
