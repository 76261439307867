import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import { get } from 'lodash';
import MenuItem from '@mui/material/MenuItem';
import StandardContentDialog from 'Components/Library/StandardContentDialog';
import TeamDropDown from 'Components/Library/TeamDropDown';
import DataObjectChip from 'Components/Library/DataObjectChip';
import DialogDownloadSurveyResults from 'Components/Features/Topics/DialogDownloadSurveyResults';
import SurveyTypeChip from 'Components/Features/Topics/SurveyTypeChip';
import { round, roundPercent } from 'config/helpers';
import EmphasizedSection from 'Components/Library/EmphasizedSection';
import UserChip from 'Components/Library/UserChip';
import SurveyPieChart from '../Visualizations/SurveyPieChart';
import NpsChart from '../Visualizations/NpsChart';
import CsatChart from '../Visualizations/CsatChart';
import Legend from '../Visualizations/Legend';
import QuadChart from '../Visualizations/QuadChart';
import SliderChart from '../Visualizations/SliderChart';
import SurveyComments from './SurveyComments';
import PulseSelector from './PulseSelector';

const NUMBERS = { 0: 0, 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, 9: 9, 10: 10 };

const styles = theme => ({
  root: {
    overflowX: 'hidden',
    margin: 0,
    padding: 0,
    flexDirection: 'column',
    justifyContent: 'stretch',
    flex: 10,
    overflowY: 'auto',
    display: 'block',
  },
  answersTitle: {
    marginTop: 40,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  overview: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  header: {
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(),
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  teamDropDown: {
    maxWidth: '100%',
  },
  centeredRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'stretch',
    marginTop: theme.spacing(2),
  },
  chartContainer: {
    width: '100%',
  },
  topicText: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  topicTextMobile: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  pulseSelector: {
    marginTop: theme.spacing(),
    marginBottom: 0,
  },
  legend: {
    minWidth: 120,
  },
  label: {
    marginLeft: 0,
  },
  statsRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    paddingTop: theme.spacing(),
    '& span': {
      // color the labels:
      color: theme.palette.primary[600],
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
  },
  statsColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    margin: theme.spacing(2),
    '&:first-of-type': {
      marginLeft: 0,
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  deltaGreen: {
    marginLeft: theme.spacing(),
    marginBottom: 1,
    color: theme.palette.confidence.green,
  },
  deltaRed: {
    marginLeft: theme.spacing(),
    marginBottom: 1,
    color: theme.palette.confidence.red,
  },
  statsNumbers: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  score: {
    marginBottom: -3,
    [theme.breakpoints.down('md')]: {
      ...theme.typography.h4,
      marginBottom: -2,
    },
  },
  stat: {
    [theme.breakpoints.down('md')]: {
      ...theme.typography.h6,
      marginBottom: -1,
    },
  },
});

const shouldDisableTeam = team => !team.data || team.data.status !== 'ok';

class PanelViewSurveyResults extends Component {
  state = {
    downloadOpen: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      !!nextProps.topic &&
      !(this.props.topic && this.props.topic.ok && !this.props.topic.loading)
    ) {
      return true;
    }
    if (nextState.downloadOpen !== this.state.downloadOpen) {
      return true;
    }
    if (nextProps.doNotCascade !== this.props.doNotCascade) {
      return true;
    }
    if (nextProps.isOnline !== this.props.isOnline) {
      return true;
    }
    if (nextProps.isPulse !== this.props.isPulse) {
      return true;
    }
    if (nextProps.teamID !== this.props.teamID) {
      return true;
    }
    if (get(nextProps, 'values', []).length !== get(this.props, 'values', []).length) {
      return true;
    }
    if (get(nextProps.surveyStats, 'lastFetched') !== get(this.props.surveyStats, 'lastFetched')) {
      return true;
    }
    if (get(nextProps.surveyStats, 'fetchStatus') !== get(this.props.surveyStats, 'fetchStatus')) {
      return true;
    }
    return false;
  }

  onTeamSelected = team => {
    const rootTeamId = this.props.surveyStats.data.orgTree.id;
    this.props.selectTeam(team.teamId === rootTeamId ? null : team.teamId, this.props.doNotCascade);
  };

  toggleCascade = event => {
    this.props.selectTeam(this.props.teamID, !event.target.checked);
  };

  handleOpenDownload = () => {
    this.setState({ downloadOpen: true });
  };

  handleCloseDownload = () => {
    this.setState({ downloadOpen: false });
  };

  render() {
    const {
      t,
      open,
      classes,
      surveyStats,
      survey,
      surveyID,
      teamID,
      canTriggerPulse,
      isPulse,
      topic,
      onClose,
      openCreateSurveyPanel,
      doNotCascade,
      isOnline,
      values,
      pulsesFetched,
      surveys,
      zIndexModifier,
    } = this.props;
    const cascade = !doNotCascade;
    const audienceType = !!survey && survey.audienceType;
    const { downloadOpen } = this.state;

    let content;
    const menuItems = [];

    if (!survey || !surveyStats || !surveyStats.ok || !values || values.length === 0) {
      content = (
        <div open={this.props.open} size="large">
          <div className={classes.root} key={`view-survey-panel-content-${surveyID}`}>
            <div className={classes.overview}>
              <Grid container>
                <Grid item xs={12} md={12} xl={12}>
                  {isOnline ? (
                    <React.Fragment>
                      <Typography variant="h4" className={classes.topicTextMobile}>
                        {t('general.loading')}
                      </Typography>
                      <Typography variant="h3" className={classes.topicText}>
                        {t('general.loading')}
                      </Typography>
                      <div>
                        <CircularProgress name="CircularProgress" />
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Typography variant="h4" className={classes.topicTextMobile}>
                        {t('general.disconnected')}
                      </Typography>
                      <Typography variant="h3" className={classes.topicText}>
                        {t('general.disconnected')}
                      </Typography>
                      <Typography variant="h6">
                        {t('insights.disconnectedpleaseconnect')}
                      </Typography>
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      );
    } else {
      const surveyData = surveyStats.data;
      let selectedDataPoint;
      let prevDataPoint;
      let prev;

      for (const dp of values) {
        if (dp.surveyID === surveyID) {
          selectedDataPoint = dp;
          prevDataPoint = prev;
          break;
        }
        prev = dp;
      }
      const answerPct =
        selectedDataPoint.answerPct ??
        (roundPercent(selectedDataPoint.numReceived / selectedDataPoint.numSent) || 0);
      const answerPctDelta = !!prevDataPoint
        ? prevDataPoint.answerPct ??
          roundPercent(
            (selectedDataPoint.numReceived / selectedDataPoint.numSent || 0) -
              (prevDataPoint.numReceived / prevDataPoint.numSent || 0),
          )
        : 0;
      const numReceivedDelta = !!prevDataPoint
        ? selectedDataPoint.numReceived - prevDataPoint.numReceived
        : 0;

      let scoreDelta = 0;
      let deltaContent;

      if (prevDataPoint) {
        switch (survey.topicType) {
          case 'quad':
            scoreDelta = selectedDataPoint.outcome.score - prevDataPoint.outcome.score;
            break;
          case 'nps':
            scoreDelta = selectedDataPoint.outcome.nps - prevDataPoint.outcome.nps;
            break;
          case 'csat':
            scoreDelta = selectedDataPoint.outcome.avg - prevDataPoint.outcome.avg;
            break;
          default:
            break;
        }
        scoreDelta = round(scoreDelta);
        deltaContent = (
          <Typography
            variant="body1"
            className={scoreDelta >= 0 ? classes.deltaGreen : classes.deltaRed}
          >
            {`${scoreDelta >= 0 ? '+' : ''}${scoreDelta}`}
          </Typography>
        );
      }

      if (!!canTriggerPulse) {
        menuItems.push(
          <MenuItem
            name="panel-view-survey-results-ask-again-btn"
            onClick={() => openCreateSurveyPanel()}
          >
            {t('insights.requestagaincta')}
          </MenuItem>,
        );
      }
      menuItems.push(
        <MenuItem
          name="panel-view-survey-results-export-btn"
          onClick={() => this.handleOpenDownload()}
        >
          {t('insights.exportcta')}
        </MenuItem>,
      );
      content = (
        <div className={classes.root}>
          <div className={classes.overview}>
            <Grid container>
              <Grid item xs={12} md={12} xl={12}>
                <Typography variant="h4" className={classes.topicTextMobile}>
                  {!!survey && survey.topicText}
                </Typography>
                <Typography variant="h3" className={classes.topicText}>
                  {!!survey && survey.topicText}
                </Typography>
              </Grid>
              <UserChip sub={survey.submitter} />
              <SurveyTypeChip survey={survey} sx={{ ml: 2 }} />
              <EmphasizedSection sx={{ mt: 2 }}>
                {audienceType === 'PRG' && (
                  <div className={classes.header}>
                    <TeamDropDown
                      className={classes.teamDropDown}
                      orgTree={surveyData.orgTree}
                      color="white"
                      variant="test"
                      onTeamSelected={this.onTeamSelected}
                      selected={teamID}
                      disableCondition={shouldDisableTeam}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="cbox"
                          checked={cascade}
                          onChange={this.toggleCascade}
                          value="cascade"
                          color="primary"
                        />
                      }
                      label={t('insights.orgtreecascadeoptlabel')}
                      className={classes.label}
                    />
                  </div>
                )}
                <Grid item xs={12}>
                  {!!selectedDataPoint && (
                    <div className={classes.statsRow}>
                      {survey.topicType === 'quad' && (
                        <div className={clsx([classes.column, classes.statsColumn])}>
                          <Typography variant="caption">
                            {t('insights.panelViewResults.quadResultsScoreLabel')}
                          </Typography>
                          <div className={classes.statsNumbers}>
                            <Typography
                              name="panel-view-survey-results-score"
                              variant="h3"
                              className={classes.score}
                            >
                              {selectedDataPoint.numReceived === 0
                                ? '-'
                                : selectedDataPoint.outcome.score}
                            </Typography>
                            {deltaContent}
                          </div>
                        </div>
                      )}

                      {survey.topicType === 'csat' && (
                        <div className={clsx([classes.column, classes.statsColumn])}>
                          <Typography variant="caption">{t('insights.csatcharttitle')}</Typography>
                          <div className={classes.statsNumbers}>
                            <Typography
                              name="panel-view-survey-results-score"
                              variant="h3"
                              className={classes.score}
                            >
                              {selectedDataPoint.numReceived === 0
                                ? '-'
                                : selectedDataPoint.outcome.avg}
                            </Typography>
                            {deltaContent}
                          </div>
                        </div>
                      )}
                      {survey.topicType === 'nps' && (
                        <div className={clsx([classes.column, classes.statsColumn])}>
                          <Typography variant="caption">{t('insights.npscharttitle')}</Typography>
                          <div className={classes.statsNumbers}>
                            <Typography
                              name="panel-view-survey-results-score"
                              variant="h3"
                              className={classes.score}
                            >
                              {selectedDataPoint.numReceived === 0
                                ? '-'
                                : selectedDataPoint.outcome.nps}
                            </Typography>
                            {deltaContent}
                          </div>
                        </div>
                      )}
                      <div className={clsx([classes.column, classes.statsColumn])}>
                        <Typography variant="caption">
                          {t('insights.panelViewResults.answerRateLabel')}
                        </Typography>
                        <div className={classes.statsNumbers}>
                          <Typography
                            name="panel-view-survey-results-answer-pct"
                            variant="h5"
                            className={classes.stat}
                          >
                            {`${answerPct}%`}
                          </Typography>
                          {!!prevDataPoint && (
                            <Typography
                              variant="body1"
                              className={
                                answerPctDelta >= 0 ? classes.deltaGreen : classes.deltaRed
                              }
                            >
                              {`${answerPctDelta >= 0 ? '+' : ''}${answerPctDelta}`}
                            </Typography>
                          )}
                        </div>
                      </div>
                      <div className={clsx([classes.column, classes.statsColumn])}>
                        <Typography variant="caption">
                          {t('insights.panelViewResults.answerAmountLabel')}
                        </Typography>
                        <div className={classes.statsNumbers}>
                          <Typography
                            name="panel-view-survey-results-answer-num"
                            variant="h5"
                            className={classes.stat}
                          >
                            {selectedDataPoint.numReceived}
                          </Typography>
                          {!!prevDataPoint && (
                            <Typography
                              variant="body1"
                              className={
                                numReceivedDelta >= 0 ? classes.deltaGreen : classes.deltaRed
                              }
                            >
                              {`${numReceivedDelta >= 0 ? '+' : ''}${numReceivedDelta}`}
                            </Typography>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} md={12} xl={12}>
                  {survey && survey.topicType === 'multiplechoice' && (
                    <div className={classes.centeredRow}>
                      <div className={classes.chartContainer}>
                        <SurveyPieChart
                          key={`survey-chart-${surveyID}`}
                          keys={surveyData && surveyData.choiceOptions}
                          values={selectedDataPoint.scores}
                        />
                      </div>
                      <Legend
                        className={classes.legend}
                        keys={surveyData && surveyData.choiceOptions}
                        values={surveyData && surveyData.scores}
                      />
                    </div>
                  )}
                  {survey && survey.topicType === 'nps' && (
                    <div className={classes.centeredRow}>
                      <div className={classes.chartContainer}>
                        <NpsChart
                          key={`survey-chart-${surveyID}`}
                          values={selectedDataPoint.scores}
                          outcome={selectedDataPoint.outcome}
                        />
                      </div>
                    </div>
                  )}
                  {survey && survey.topicType === 'csat' && (
                    <div className={classes.centeredRow}>
                      <div className={classes.chartContainer}>
                        <CsatChart
                          key={`survey-chart-${surveyID}`}
                          values={selectedDataPoint.scores}
                          outcome={selectedDataPoint.outcome}
                        />
                      </div>
                    </div>
                  )}
                  {survey && survey.topicType === 'quad' && (
                    <div className={classes.centeredRow}>
                      <div className={classes.chartContainer}>
                        <QuadChart
                          key={`survey-chart-${surveyID}`}
                          survey={survey}
                          values={selectedDataPoint.scores}
                          outcome={selectedDataPoint.outcome}
                        />
                      </div>
                    </div>
                  )}
                  {survey && survey.topicType === 'sliders' && (
                    <div className={classes.centeredRow}>
                      <div className={classes.chartContainer}>
                        <SliderChart
                          key={`survey-chart-${surveyID}`}
                          survey={survey}
                          values={selectedDataPoint.scores}
                          outcome={selectedDataPoint.outcome}
                        />
                      </div>
                    </div>
                  )}
                </Grid>

                {isPulse && (
                  <PulseSelector
                    className={classes.pulseSelector}
                    topic={topic}
                    selected={surveyID}
                    teamID={teamID}
                    cascade={cascade}
                    values={values}
                    fetched={pulsesFetched}
                    surveys={surveys}
                  />
                )}
              </EmphasizedSection>
            </Grid>
          </div>
          <SurveyComments
            comments={selectedDataPoint.comments}
            survey={survey}
            keys={!!surveyData && !!surveyData.choiceOptions ? surveyData.choiceOptions : NUMBERS}
          />
          {downloadOpen && (
            <DialogDownloadSurveyResults onClose={this.handleCloseDownload} surveyID={surveyID} />
          )}
        </div>
      );
    }

    return (
      <StandardContentDialog
        size="large"
        open={open}
        onBackdropClick={onClose}
        onCloseClick={onClose}
        zIndexModifier={zIndexModifier}
        menuButtonProps={{
          id: 'view-survey-results-actions-menu',
        }}
        closeButtonProps={{
          id: 'view-survey-results-close-btn',
        }}
        name="view-survey-panel-content"
        menuItems={menuItems}
        title={<DataObjectChip Icon={null} text={t('programs.navmenuinsights')} variant="small" />}
      >
        {content}
      </StandardContentDialog>
    );
  }
}

PanelViewSurveyResults.propTypes = {
  classes: PropTypes.exact({
    root: PropTypes.string,
    answersTitle: PropTypes.string,
    row: PropTypes.string,
    column: PropTypes.string,
    overview: PropTypes.string,
    header: PropTypes.string,
    teamDropDown: PropTypes.string,
    centeredRow: PropTypes.string,
    chartContainer: PropTypes.string,
    topicText: PropTypes.string,
    topicTextMobile: PropTypes.string,
    pulseSelector: PropTypes.string,
    statsRow: PropTypes.string,
    legend: PropTypes.string,
    statsColumn: PropTypes.string,
    label: PropTypes.string,
    score: PropTypes.string,
    deltaGreen: PropTypes.string,
    deltaRed: PropTypes.string,
    statsNumbers: PropTypes.string,
    stat: PropTypes.string,
  }),
  selectTeam: PropTypes.func,
  surveyStats: PropTypes.object,
  survey: PropTypes.object,
  orgTree: PropTypes.object,
  surveyID: PropTypes.string,
  teamID: PropTypes.string,
  canTriggerPulse: PropTypes.bool,
  isPulse: PropTypes.bool,
  topic: PropTypes.object,
  openCreateSurveyPanel: PropTypes.func,
  doNotCascade: PropTypes.bool,
  isOnline: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  t: PropTypes.func,
  values: PropTypes.array,
  pulsesFetched: PropTypes.bool,
  surveys: PropTypes.array,
  zIndexModifier: PropTypes.number,
};

export default withTranslation()(withStyles(styles)(PanelViewSurveyResults));
