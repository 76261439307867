import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/AddCircle';
import Tooltip from '@mui/material/Tooltip';

import AddItemMenu from './AddItemMenu';

import imgFileToDataUrl from '../CardComponents/CardImage/helpers';

const styles = () => ({
  root: {
    width: '100%',
    height: 48,
    position: 'relative',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  button: {
    position: 'absolute',
    left: -80,
  },
  helptext: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    color: 'rgba(0,0,0,0.5)',
    transform: 'translate(-50%, -50%)',
  },
  dark: {
    color: 'rgba(255,255,255,0.87)',
  },
});

class AddfirstButton extends Component {
  state = {
    menuOpen: false,
  };

  openMenu = e => {
    this.setState({ menuOpen: e.currentTarget });
  };

  closeMenu = () => {
    this.setState({ menuOpen: null });
  };

  addItem = type => {
    this.props.addItem(type, 0);
    this.closeMenu();
  };

  addImageItem = async e => {
    this.closeMenu();
    const b64img = await imgFileToDataUrl(e);
    this.props.addItem('image', 0, { originalSrc: b64img });
  };

  render() {
    const { t, classes, cardtheme, totalImages, contextType } = this.props;
    const { menuOpen } = this.state;

    return (
      <React.Fragment>
        <div className={classes.root} onClick={this.openMenu}>
          <Typography variant="body2" className={clsx([classes.helptext, classes[cardtheme]])}>
            {t('contextv2.cards.addfirstcta')}
          </Typography>
          <Tooltip placement="bottom" title={t('contextv2.cards.addfirstcta')}>
            <IconButton
              aria-controls="ctx-card-editor-add-item-menu"
              color="primary"
              id="ctx-card-editor-add-item-btn-0"
              name="ctx-card-editor-add-item-btn"
              className={classes.button}
              size="large"
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </div>
        {Boolean(menuOpen) && (
          <AddItemMenu
            index={0}
            totalImages={totalImages}
            menuOpen={menuOpen}
            addItem={this.addItem}
            addImageItem={this.addImageItem}
            onClose={this.closeMenu}
            contextType={contextType}
          />
        )}
      </React.Fragment>
    );
  }
}

AddfirstButton.propTypes = {
  addItem: PropTypes.func,
  classes: PropTypes.exact({
    root: PropTypes.string,
    button: PropTypes.string,
    helptext: PropTypes.string,
    dark: PropTypes.string,
  }),
  cardtheme: PropTypes.string,
  totalImages: PropTypes.number,
  contextType: PropTypes.string,
  t: PropTypes.func,
};

export default withTranslation()(withStyles(styles)(AddfirstButton));
