import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import List from '@mui/material/List';
import OkrIcon from '@mui/icons-material/TrackChanges';
import CommitmentsIcon from '@mui/icons-material/Task';
import ViewsIcon from '@mui/icons-material/AccountTree';
import AdminIcon from '@mui/icons-material/SupervisorAccount';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import FeatureFlag from 'Components/Library/FeatureFlag';
import GlobalSearch from 'Components/Library/GlobalSearch';
import InterlockIcon from 'Components/Features/Interlocks/InterlockIcon';
import { isEnabled } from 'config/flags';
import { StackedLineChart } from '@mui/icons-material';
import Notifications from './Notifications';
import LinkItem from './LinkItem';
import TagsMenuItem from './TagsMenuItem';

function ToolSelector({ isAdmin, classes }) {
  const { t } = useTranslation();

  return (
    <div className={classes.selectorRoot}>
      <List dense component="div">
        <GlobalSearch
          searchFor={
            isEnabled('DASHBOARDS')
              ? ['teams', 'teamsledby', 'people', 'nodes']
              : ['teams', 'teamsledby', 'nodes']
          }
        />
        <Notifications />
        <FeatureFlag flag="DASHBOARDS">
          <LinkItem
            to="dashboards"
            linkIcon={<AssignmentIndIcon color="featureDashboards" />}
            linkText={t('dashboards.navMenu')}
            variant="large"
          />
        </FeatureFlag>
        {isAdmin && (
          <LinkItem
            to="admin"
            linkIcon={<AdminIcon fontSize="small" />}
            linkText={t('admin.pageTitle')}
            variant="large"
          />
        )}
        <LinkItem
          to="objectives"
          linkIcon={<OkrIcon fontSize="small" color="featureOkr" />}
          linkText={t('objectives.pageTitle')}
          variant="large"
        />
        <LinkItem
          to="commitments"
          linkIcon={<CommitmentsIcon fontSize="small" color="featureCommitments" />}
          linkText={t('commitments.pageTitle')}
          variant="large"
        />
        <LinkItem
          to="interlocks"
          linkIcon={<InterlockIcon color="featureInterlocks" viewBox="0 -3 18 18" />}
          linkText={t('interlocks.pageTitle')}
          variant="large"
        />
        <TagsMenuItem />
        {isEnabled('H&R') && (
          <LinkItem
            to="health"
            linkIcon={<StackedLineChart color="secondary" fontSize="small" />}
            linkText={t('hnr.pageTitle')}
            variant="large"
          />
        )}
        <LinkItem
          to="views"
          linkIcon={<ViewsIcon fontSize="small" color="featureViews" />}
          linkText={t('views.navTitle')}
          variant="large"
        />
      </List>
    </div>
  );
}

const mapStateToProps = state => ({
  isAdmin: state.auth.isAdmin,
});

ToolSelector.propTypes = {
  classes: PropTypes.shape({
    selectorRoot: PropTypes.string,
  }),
  isAdmin: PropTypes.bool,
};

export default connect(mapStateToProps)(ToolSelector);
