import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Dialog from '@mui/material/Dialog';
import Snackbar from '@mui/material/Snackbar';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import SavingIndicator from 'Components/Common/SavingIndicatorv2';

import { types } from '../CardComponents';

import ActionsOverlay from './ActionsOverlay';
import AddfirstButton from './AddfirstButton';
import ContextCard from '../ContextCard';

// Map the element.type to the localization keys
const TYPEMAP = {
  overline: 'overline',
  title: 'title',
  subtitle: 'subtitle',
  paragraph: 'paragraph',
  image: 'image',
  video: 'video',
  attachment: 'link',
  slider: 'slider',
  objective: 'okr',
  trafficlight: 'traffic',
  divider: 'divider',
  teamokrs: 'teamokrs',
};

const styles = theme => ({
  dialogpaper: {
    minHeight: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    overflow: 'hidden !important',
  },
  title: {
    padding: theme.spacing(2),
  },
  row: {
    flexGrow: 3,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'stretch',
    overflow: 'hidden',
  },
  left: {
    flexGrow: 2,
    flexShrink: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    alignItems: 'stretch',
  },
  right: {
    flexGrow: 0,
    flexShrink: 0,
    width: 240,
  },
  tabBar: {
    borderBottom: `1px solid ${theme.palette.primary[200]}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 48,
  },
  closeIconButton: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
  middle: {
    flexGrow: 2,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    backgroundColor: theme.palette.background.box,
    overflow: 'hidden',
  },
  editArea: {
    minHeight: 10,
    height: '100%',
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  editContent: {
    display: 'flex',
    padding: theme.spacing(6),
    justifyContent: 'center',
    alignItems: 'center',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.primary[200]}`,
  },
  cancel: {
    marginRight: theme.spacing(3),
  },
  options: {
    flexGrow: 1,
    padding: theme.spacing(3),
    maxWidth: 300,
  },
  cardContent: {
    width: 'auto',
  },
  cardPreview: {
    overflow: 'visible',
    padding: 0,
    margin: 0,
    '&:hover': {
      '& .actionsContainer': {
        opacity: 1,
      },
    },
  },
  minified: {
    width: 400,
  },
  expanded: {
    minWidth: 420,
    width: 800,
    maxWidth: 'calc(90% - 200px)',
    boxShadow: theme.shadows[8],
  },
  draggedElement: {
    padding: 0,
    boxShadow: theme.shadows[6],
    '& .actionsContainer': {
      visibility: 'hidden !important',
    },
  },
  elementWrapper: {
    position: 'relative',
    left: -120,
    right: -120,
    top: 0,
    bottom: 0,
    width: 'auto',
    height: 'auto',
    paddingLeft: 120,
    paddingRight: 180,
    marginRight: -300,
    '&:hover': {
      '& .actionsContainer': {
        visibility: 'visible',
      },
    },
    '&:first-of-type': {
      '& $element': {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
      },
    },
    '&:last-child': {
      '& $element': {
        borderBottomLeftRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
      },
    },
  },
  cardElement: {
    padding: 0,
    boxSizing: 'border-box',
    position: 'relative',

    '&:hover': {
      boxShadow: theme.shadows[1],
      zIndex: 2,
      '& .actionsContainer': {
        visibility: 'visible',
      },
    },
  },
  element: {
    transition: 'background-color 500ms',
  },
  error: {
    backgroundColor: 'rgba(255,20,0,0.45) !important',
  },
  themelight: {
    backgroundColor: '#f2f2f2',
    '& $draggedElement $element': {
      backgroundColor: '#fff',
    },
    '& $cardElement $element': {
      backgroundColor: '#eeeeee',
      '&:hover': {
        backgroundColor: '#fff',
      },
    },
  },
  themedark: {
    backgroundColor: theme.palette.primary[1000],
    '& $draggedElement $element': {
      backgroundColor: theme.palette.primary[900],
    },
    '& $cardElement $element': {
      backgroundColor: theme.palette.primary[900],
    },
    '& $error': {
      backgroundColor: 'rgba(255,100,0,0.6) !important',
    },
  },
  inTransit: {
    '& .actionsContainer': {
      visibility: 'hidden !important',
    },
  },
  currentHidden: {
    opacity: 0.4,
    backgroundAttachment: 'fixed',
    backgroundImage:
      'linear-gradient(135deg, rgba(255,255,255,0.2) 25%, rgba(0, 0, 0, 0.04) 25%, rgba(0, 0, 0, 0.04) 50%, rgba(255,255,255,0.2) 50%, rgba(255,255,255,0.2) 75%, rgba(0, 0, 0, 0.04) 75%, rgba(0, 0, 0, 0.04) 100%)',
    backgroundSize: '16.97px 16.97px',
  },
});

class ContextCardEditor extends Component {
  state = {
    previewType: 'minified',
    optionsOpen: true,
    isEditing: true,
  };

  handlePreviewTypeChange = (e, previewType) => {
    this.setState({ previewType });
  };

  onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    this.props.reorder(result.source.index, result.destination.index);
  };

  toggleEdit = e => {
    this.setState({ isEditing: !e.target.checked });
  };

  render() {
    const {
      classes,
      elements,
      canSubmit,
      cardtheme,
      changeTheme,
      onSave,
      onCancel,
      undoDelete,
      highlightErrors,
      errorIndices,
      isNew,
      submitted,
      submitStatus,
      t,
      totalImages,
      deleteStack,
      contextType,
      contextID,
      zIndexModifier,
    } = this.props;
    const { previewType, optionsOpen, isEditing } = this.state;

    return (
      <Dialog
        classes={{ paper: classes.dialogpaper }}
        open
        fullWidth
        maxWidth="lg"
        sx={{ zIndex: 1250 + zIndexModifier }}
      >
        <SavingIndicator submitted={submitted} submitStatus={submitStatus} />
        <div className={classes.title}>
          <Typography variant="h5">
            {isNew ? t('contextv2.cards.createcardtitle') : t('contextv2.cards.editcardtitle')}
          </Typography>
        </div>
        <div className={classes.row}>
          <div className={classes.left}>
            <div className={classes.tabBar}>
              <Tabs
                value={previewType}
                indicatorColor="primary"
                textColor="primary"
                onChange={this.handlePreviewTypeChange}
                aria-label={t('contextv2.cards.ariatabspreviewtype')}
              >
                <Tab label={t('contextv2.cards.tablabelminified')} value="minified" />
                <Tab label={t('contextv2.cards.tablabelexpanded')} value="expanded" />
              </Tabs>
            </div>
            <div className={classes.middle}>
              <div className={classes.editArea}>
                <div className={classes.editContent}>
                  {!!isEditing && (
                    <DragDropContext onDragEnd={this.onDragEnd}>
                      <Card
                        id="ctx-card-editor-card-area"
                        className={clsx([
                          classes.cardPreview,
                          classes[`theme${cardtheme}`],
                          classes[previewType],
                        ])}
                      >
                        <div className={classes.cardContent}>
                          {elements.length > 0 && (
                            <Droppable droppableId="ctx-card-editor-dropzone">
                              {(provided, snapshot) => (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                  className={snapshot.isDraggingOver ? classes.inTransit : null}
                                >
                                  {elements.map((item, index) => {
                                    const Element = types[item.type];
                                    const overlayandelement = (
                                      <div
                                        name={`context-card-editor-element-wrapper-${item.type}`}
                                      >
                                        <ActionsOverlay
                                          addItem={this.props.addItem}
                                          setVisibility={this.props.setVisibility}
                                          deleteItem={this.props.deleteItem}
                                          openSettings={this.props.openSettings}
                                          index={index}
                                          item={item}
                                          totalImages={totalImages}
                                          editMode={previewType}
                                          contextType={contextType}
                                        />
                                        <Element
                                          className={clsx([
                                            classes.element,
                                            !!item[`hide_${previewType}`]
                                              ? classes.currentHidden
                                              : null,
                                            !!highlightErrors &&
                                            errorIndices &&
                                            errorIndices.includes(index)
                                              ? classes.error
                                              : null,
                                          ])}
                                          cardTheme={cardtheme}
                                          item={item}
                                          isLast={index + 1 === elements.length}
                                          editable
                                          autoFocus={item.id === this.props.latestItemId}
                                          mode={previewType}
                                          deleteItem={skipUndo =>
                                            this.props.deleteItem(index, skipUndo)
                                          }
                                          onEdit={values => this.props.onEdit(index, values)}
                                          closeSettings={() => this.props.closeSettings(index)}
                                          contextID={contextID}
                                        />
                                      </div>
                                    );
                                    return (
                                      <Draggable
                                        draggableId={`ctx-card-editor-item-${item.type}-${item.id}`}
                                        index={index}
                                        key={`ctx-card-editor-item-${item.type}-${item.id}`}
                                      >
                                        {(provided2, snapshot2) => (
                                          <div className={classes.elementWrapper}>
                                            <div
                                              ref={provided2.innerRef}
                                              {...provided2.draggableProps}
                                              {...provided2.dragHandleProps}
                                              className={clsx([
                                                snapshot2.isDragging
                                                  ? classes.draggedElement
                                                  : classes.cardElement,
                                              ])}
                                              style={provided2.draggableProps.style}
                                            >
                                              {overlayandelement}
                                            </div>
                                          </div>
                                        )}
                                      </Draggable>
                                    );
                                  })}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          )}

                          {elements.length === 0 && (
                            <AddfirstButton
                              cardtheme={cardtheme}
                              addItem={this.props.addItem}
                              totalImages={totalImages}
                              contextType={contextType}
                            />
                          )}
                        </div>
                      </Card>
                    </DragDropContext>
                  )}
                  {!isEditing && (
                    <ContextCard
                      className={classes[previewType]}
                      card={{ elements, cardtheme }}
                      mode={previewType}
                      disabled
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.right}>
            {optionsOpen && (
              <div className={classes.options}>
                <Typography variant="overline">{t('contextv2.cards.theme')}</Typography>
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={cardtheme}
                  onChange={changeTheme}
                >
                  <FormControlLabel
                    value="light"
                    control={<Radio id="ctx-card-editor-theme-light" />}
                    label={t('contextv2.cards.themeoptlightlabel')}
                  />
                  <FormControlLabel
                    value="dark"
                    control={<Radio id="ctx-card-editor-theme-dark" />}
                    label={t('contextv2.cards.themeoptdarklabel')}
                  />
                </RadioGroup>
                <br />
                <br />
                <Typography variant="overline">{t('contextv2.cards.toggle')}</Typography>
                <br />
                <Switch checked={!isEditing} onChange={this.toggleEdit} value="isEditing" />
              </div>
            )}
          </div>
        </div>
        {!!deleteStack &&
          Object.keys(deleteStack).map((id, index) => (
            <Snackbar
              open
              key={`ctx-card-editor-undo-snackbar-${index}`}
              message={t('contextv2.cards.undoCta', {
                type: t(`contextv2.cards.types.${TYPEMAP[deleteStack[id].type]}`),
              })}
              action={
                <Button color="secondary" variant="text" onClick={() => undoDelete(id)}>
                  {t('contextv2.cards.undoButton')}
                </Button>
              }
            />
          ))}
        <div className={classes.actions}>
          <Button
            name="ctx-card-editor-cancel-btn"
            className={classes.cancel}
            onClick={onCancel}
            disabled={submitted}
          >
            {t('general.cancel')}
          </Button>
          <Button
            name="ctx-card-editor-save-btn"
            variant="contained"
            color="secondary"
            sx={
              !!canSubmit ? {} : { backgroundColor: theme => `${theme.palette.secondary.main}5F` }
            }
            onClick={onSave}
            /* Note: do not disable this button, when clicking a button with
              canSubmit == False, the user will see faulty elements highlighted
              in red */
          >
            {t('general.save')}
          </Button>
        </div>
      </Dialog>
    );
  }
}

ContextCardEditor.propTypes = {
  classes: PropTypes.exact({
    dialogpaper: PropTypes.string,
    title: PropTypes.string,
    row: PropTypes.string,
    left: PropTypes.string,
    right: PropTypes.string,
    tabBar: PropTypes.string,
    closeIconButton: PropTypes.string,
    middle: PropTypes.string,
    editArea: PropTypes.string,
    editContent: PropTypes.string,
    actions: PropTypes.string,
    cancel: PropTypes.string,
    options: PropTypes.string,
    cardContent: PropTypes.string,
    cardPreview: PropTypes.string,
    minified: PropTypes.string,
    expanded: PropTypes.string,
    draggedElement: PropTypes.string,
    elementWrapper: PropTypes.string,
    cardElement: PropTypes.string,
    element: PropTypes.string,
    error: PropTypes.string,
    themelight: PropTypes.string,
    themedark: PropTypes.string,
    inTransit: PropTypes.string,
    currentHidden: PropTypes.string,
  }),
  reorder: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  changeTheme: PropTypes.func.isRequired,
  addItem: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  undoDelete: PropTypes.func.isRequired,
  setVisibility: PropTypes.func.isRequired,
  openSettings: PropTypes.func.isRequired,
  closeSettings: PropTypes.func.isRequired,
  elements: PropTypes.array,
  errorIndices: PropTypes.array,
  canSubmit: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  submitted: PropTypes.bool.isRequired,
  submitStatus: PropTypes.number.isRequired,
  highlightErrors: PropTypes.bool,
  cardtheme: PropTypes.string.isRequired,
  t: PropTypes.func,
  latestItemId: PropTypes.string,
  totalImages: PropTypes.number,
  deleteStack: PropTypes.object,
  contextType: PropTypes.string,
  contextID: PropTypes.string,
  zIndexModifier: PropTypes.number,
};

export default withTranslation()(withStyles(styles)(ContextCardEditor));
