import React, { Component, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CommitmentsIcon from '@mui/icons-material/Task';
import { GroupAdd, StackedLineChart } from '@mui/icons-material';
import ContentPage from 'Components/Library/ContentPage';
import FacilitationSessionGrid from 'Components/Features/DigitalFacilitation/FacilitationSessionGrid';
import StartFacilitationButton from 'Components/Features/DigitalFacilitation/StartFacilitationButton';
import InsightsOverview from 'Components/Features/Topics/InsightsOverview';
import GamePlan from 'Components/Features/GamePlans/GamePlanDashboard';
import CreateTopicButton from 'Components/Features/Topics/CreateTopicButton';
import AddObjectiveButton from 'Components/Features/Objectives/AddObjectiveButton';
import TeamCommitments from 'screens/Features/Commitments/CommitmentsGrid/TeamCommitments';

import ObjectivesIcon from 'Components/Features/Objectives/ObjectivesIcon';
import InsightsIcon from 'Components/Features/Topics/InsightsIcon';
import DfacIcon from 'Components/Features/DigitalFacilitation/DfacIcon';
import ContextIcon from 'Components/Features/Contextv2/ContextIcon';
import AddCommitmentButton from 'Components/Features/Commitments/AddCommitmentButton';
import AddGamePlanButton from 'Components/Features/GamePlans/AddGamePlanButton';

import TeamInterlocks from 'screens/Features/Interlocks/InterlocksGrid/TeamInterlocks';
import AddInterlockButton from 'Components/Features/Interlocks/AddInterlockButton';
import FeatureFlag from 'Components/Library/FeatureFlag';
import InterlockIcon from 'Components/Features/Interlocks/InterlockIcon';
import TeamHnR from 'screens/Features/HnR/TeamHnR';
import Headline from './Headline';
import MembersTab from './MembersTab';
import ObjectivesTab from './ObjectivesTab';

const ProgramContext = lazy(() => import('Components/Features/Contextv2/ProgramContext'));
const CreateContextButton = lazy(() => import('Components/Features/Contextv2/AddContextButton'));

const styles = () => ({
  root: {
    overflow: 'hidden',
    '-webkit-overflow-scrolling': 'touch',
    width: '100%',
    padding: 0,
    margin: 0,
    justifyContent: 'flex-start',
    display: 'flex',
    flexGrow: 20,
    flexDirection: 'column',
  },
  bottom: {
    position: 'relative',
    flexGrow: 10,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  tab: {
    '&>.Mui-focusVisible': {
      border: '1px solid #007B23',
    },
  },
});

class TeamPage extends Component {
  render() {
    const {
      t,
      classes,
      teamId,
      page,
      isManager,
      isManagerOfManager,
      isChangeManager,
      isMember,
      isProvisioned,
      facilitationSessions,
      fetchSessions,
      periodConfig,
      subpage,
      navigate,
      teamIsInvitable,
      isDialog,
      onClose,
      usePeriodicGameplans,
    } = this.props;

    // going to objectives, but the periodConfig is not loaded yet
    if (!periodConfig.ok && page === 'objectives') {
      return null;
    }

    return (
      <Suspense fallback={null}>
        <div className={classes.root}>
          <Headline
            key={`team-info-component-${teamId}`}
            usePeriodicGameplans={usePeriodicGameplans}
            teamId={teamId}
            page={page}
            isDialog={isDialog}
            onClose={onClose}
            tabs={
              <Tabs value={page} variant="scrollable" scrollButtons="auto">
                <FeatureFlag flag="GAMEPLANS" value="gameplan">
                  <Tab
                    icon={<ContextIcon fontSize="small" color="featureContextSharing" />}
                    value="gameplan"
                    onClick={() => navigate('gameplan')}
                    label={t('teams.tabGameplan')}
                    name="team-page-gameplan"
                    className={classes.tab}
                  />
                </FeatureFlag>
                <FeatureFlag flag="TEAM_CONTEXT_SHARING" value="overview">
                  <Tab
                    value="overview"
                    icon={<ContextIcon fontSize="small" color="featureContextSharing" />}
                    onClick={() => navigate('overview')}
                    label={t('programs.navmenucontext')}
                    name="team-page-context"
                  />
                </FeatureFlag>
                {!!periodConfig.ok && (
                  <Tab
                    value="objectives"
                    icon={<ObjectivesIcon fontSize="small" color="featureOkr" />}
                    onClick={() => navigate('objectives')}
                    label={t('teams.tabOkrs')}
                    name="team-page-objectives"
                  />
                )}
                <Tab
                  value="commitments"
                  icon={<CommitmentsIcon fontSize="small" color="featureCommitments" />}
                  onClick={() => navigate('commitments')}
                  label={t('commitments.pageTitle')}
                  name="team-page-commitments"
                />
                <Tab
                  value="facilitation"
                  icon={<DfacIcon fontSize="small" color="featureDfac" />}
                  onClick={() => navigate('facilitation')}
                  label={t('teams.tabDfac')}
                  name="team-page-facilitation"
                />
                <Tab
                  value="insights"
                  icon={<InsightsIcon fontSize="small" color="featureInsights" />}
                  onClick={() => navigate('insights')}
                  label={t('programs.navmenuinsights')}
                  name="team-page-insights"
                />
                <Tab
                  value="interlocks"
                  icon={<InterlockIcon color="featureInterlocks" />}
                  onClick={() => navigate('interlocks')}
                  label={t('interlocks.pageTitle')}
                  name="team-page-interlocks"
                />
                <FeatureFlag flag="H&R" value="hnr">
                  <Tab
                    icon={<StackedLineChart color="secondary" fontSize="small" />}
                    value="hnr"
                    onClick={() => navigate('hnr')}
                    label={t('teams.tabHealth')}
                    name="team-page-hnr"
                    className={classes.tab}
                  />
                </FeatureFlag>
                {!!teamIsInvitable && isManager && (
                  <Tab
                    value="managemembers"
                    icon={<GroupAdd fontSize="small" color="secondary" />}
                    onClick={() => navigate('managemembers')}
                    label={t('teams.tabManageMembers')}
                    name="team-page-managemembers"
                  />
                )}
              </Tabs>
            }
            fab={
              <>
                {isMember && page === 'facilitation' && <StartFacilitationButton teamID={teamId} />}
                {(isManager || isManagerOfManager || isChangeManager) && page === 'overview' && (
                  <CreateContextButton contextID={teamId} contextType="team" />
                )}
                {(isManager || isManagerOfManager || isChangeManager) && page === 'insights' && (
                  <CreateTopicButton audienceID={teamId} audienceType="TEAM" />
                )}
                {(isManager || isManagerOfManager || isChangeManager || isMember) &&
                  page === 'objectives' &&
                  !!subpage && <AddObjectiveButton stperiod={subpage} teamId={teamId} />}
                {page === 'commitments' && isProvisioned && <AddCommitmentButton teamId={teamId} />}
                {page === 'gameplan' && (isManager || isManagerOfManager || isChangeManager) && (
                  <AddGamePlanButton teamId={teamId} selectedPeriod={subpage} />
                )}
                {page === 'interlocks' && isProvisioned && <AddInterlockButton teamId={teamId} />}
              </>
            }
          />
          <div className={clsx(classes.bottom, 'scrollable')}>
            <ContentPage>
              {page === 'facilitation' && (
                <FacilitationSessionGrid
                  sessions={facilitationSessions}
                  isManager={isManager}
                  isMember={isMember}
                  fetchSessions={fetchSessions}
                />
              )}
              {page === 'objectives' && !!periodConfig.ok && !!subpage && (
                <ObjectivesTab
                  teamId={teamId}
                  key={teamId}
                  selectedPeriod={subpage}
                  handlePeriodChange={p => navigate('objectives', p.stPeriodId)}
                />
              )}
              {page === 'overview' && (
                <ProgramContext key={`team${teamId}`} contextType="team" contextID={teamId} />
              )}
              {page === 'insights' && (
                <InsightsOverview
                  audienceType="TEAM"
                  audienceID={teamId}
                  isMember={isMember}
                  isManager={isManager}
                  isManagerOfManager={isManagerOfManager}
                />
              )}
              {page === 'managemembers' && <MembersTab teamId={teamId} key={teamId} />}
              {page === 'gameplan' && (
                <FeatureFlag flag="GAMEPLANS">
                  <GamePlan
                    teamId={teamId}
                    selectedPeriod={subpage}
                    handlePeriodChange={p => navigate('gameplan', p.stPeriodId)}
                  />
                </FeatureFlag>
              )}
              {page === 'commitments' && <TeamCommitments teamId={teamId} />}
              {page === 'interlocks' && <TeamInterlocks teamId={teamId} />}
              {page === 'hnr' && <TeamHnR teamID={teamId} />}
            </ContentPage>
          </div>
        </div>
      </Suspense>
    );
  }
}

TeamPage.propTypes = {
  classes: PropTypes.exact({
    root: PropTypes.string,
    bottom: PropTypes.string,
    tab: PropTypes.string,
  }),
  teamId: PropTypes.string,
  page: PropTypes.string,
  navigate: PropTypes.func,
  isManager: PropTypes.bool,
  isManagerOfManager: PropTypes.bool,
  isChangeManager: PropTypes.bool,
  isMember: PropTypes.bool,
  usePeriodicGameplans: PropTypes.bool,
  facilitationSessions: PropTypes.object,
  subpage: PropTypes.string,
  fetchSessions: PropTypes.func,
  periodConfig: PropTypes.object,
  t: PropTypes.func,
  teamIsInvitable: PropTypes.bool,
  isDialog: PropTypes.bool,
  isProvisioned: PropTypes.bool,
  onClose: PropTypes.func,
};

export default withTranslation()(withStyles(styles)(TeamPage));
