import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { orderBy } from 'lodash';
import { withTooltip } from '@visx/tooltip';
import withStyles from '@mui/styles/withStyles';
import { Group } from '@visx/group';
import { AreaStack } from '@visx/shape';
import { scaleTime, scaleLinear } from '@visx/scale';
import { curveMonotoneX } from '@visx/curve';
import { AutoSizer } from 'react-virtualized';
import MultichoiceTimelineTooltip from './MultichoiceTimelineTooltip';

const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
  },
  svgChart: {
    overflow: 'visible',
  },
  surveyTickVerticalLine: {
    stroke: theme.palette.primary[400],
    transition: 'all .14s ease-out',
  },
  selectedSurveyTickVerticalLine: {
    stroke: theme.palette.secondary.main,
    transition: 'all .14s ease-out',
  },
  surveyTick: {
    '&:hover': {
      cursor: 'pointer',
      '& $surveyTickVerticalLine': {
        stroke: theme.palette.secondary.main,
      },
    },
  },
});

const xSelector = d => new Date(d.data.timestamp);

const xSelectorTicks = d => new Date(d.timestamp);

const ySelector0 = d => d[0];
const ySelector1 = d => d[1];

const colorSelector = (theme, key) => theme.palette.visualizations.pie[key];

const BOTTOM_MARGIN = 40;

class MultichoiceTimelineChart extends React.PureComponent {
  handleMouseOver = (cx, dataPoint, prev) => {
    this.props.showTooltip({
      tooltipLeft: cx,
      tooltipTop: 0,
      tooltipData: { selected: dataPoint, prev },
    });
  };

  render() {
    const {
      theme,
      classes,
      selected,
      onSelect,
      xExtent,
      values,
      yDomain = [0, 100],
      teamID,
      cascade,
      tooltipData,
      tooltipLeft,
      tooltipOpen,
      hideTooltip,
      topic,
    } = this.props;

    return (
      <div className={classes.root}>
        <AutoSizer>
          {({ height, width }) => {
            if (width === 0 || values.length === 0) {
              return null;
            }
            const areaValues = orderBy(
              values.map(v => ({ ...v.outcome, timestamp: v.timestamp })),
              ['timestamp'],
              ['asc'],
            );

            // Add dummy data points to continue the chart into the margin areas
            areaValues.unshift({
              ...areaValues[0],
              timestamp: xExtent[0].format(),
            });
            areaValues.push({
              ...areaValues[areaValues.length - 1],
              timestamp: xExtent[1].format(),
            });

            const keys = Object.keys(areaValues).filter(k => k !== 'timestamp');

            // adding missing keys into values that would otherwise cause errors
            for (const av in areaValues) {
              const avKeys = Object.keys(areaValues[av]);
              for (const key in keys) {
                if (!avKeys.includes(key)) {
                  areaValues[av][key] = '0';
                }
              }
            }

            const xScale = scaleTime({
              range: [0, width],
              domain: xExtent,
            });

            const yScale = scaleLinear({
              range: [height - BOTTOM_MARGIN, 0],
              domain: yDomain,
            });

            return (
              <svg width={width} height={height} className={classes.svgChart}>
                <defs>
                  <clipPath id="multichoice-timeline-chart-cliparea">
                    <rect width={width} height={height - BOTTOM_MARGIN} rx={10} ry={10} />
                  </clipPath>
                </defs>
                <rect
                  width={width}
                  height={height - BOTTOM_MARGIN}
                  rx={10}
                  ry={10}
                  fill={theme.palette.background.paper}
                />
                <Group>
                  <Group clipPath="url(#multichoice-timeline-chart-cliparea)">
                    <AreaStack
                      data={areaValues}
                      keys={keys}
                      x={d => xScale(xSelector(d))}
                      y0={d => yScale(ySelector0(d))}
                      y1={d => yScale(ySelector1(d))}
                      color={key => colorSelector(theme, key)}
                      curve={curveMonotoneX}
                    />
                  </Group>
                  {values.map((d, i) => {
                    const prev = i > 0 ? values[i - 1] : null;
                    const cx = xScale(xSelectorTicks(d));

                    let textColor = theme.palette.primary[500];
                    let strokeWidth = 0.8;
                    let isSelected = false;
                    if (d.surveyID === selected) {
                      textColor = theme.palette.background.box;
                      strokeWidth = 2;
                      isSelected = true;
                    }

                    return (
                      <g
                        key={`survey-tick-${i}`}
                        className={clsx([
                          classes.surveyTick,
                          isSelected ? 'selectedSurveyTick' : null,
                        ])}
                        name={`survey-tick-${teamID}-${cascade}-${d.surveyID}`}
                        onClick={() => onSelect(d)}
                        onMouseMove={() => this.handleMouseOver(cx, d, prev)}
                        onMouseOut={() => hideTooltip()}
                      >
                        {/*
                            invisible rect to increase touch area size
                          */}
                        <rect
                          width={40}
                          height={height}
                          x={cx - 20}
                          y={0}
                          fill="#fff"
                          opacity={0}
                          className="survey-tick-click-area"
                        />
                        <line
                          x1={cx}
                          x2={cx}
                          y1={0}
                          y2={height - BOTTOM_MARGIN + 6}
                          className={clsx([
                            isSelected ? classes.selectedSurveyTickVerticalLine : null,
                            classes.surveyTickVerticalLine,
                          ])}
                          strokeWidth={strokeWidth}
                        />
                        {d.surveyID === selected && (
                          <rect
                            width={66}
                            height={20}
                            fill={theme.palette.secondary.main}
                            transform={`translate(${cx - 33}, ${height - BOTTOM_MARGIN + 6})`}
                            rx={10}
                          />
                        )}
                        <text
                          transform={`translate(${cx}, ${height - BOTTOM_MARGIN + 20})`}
                          fontSize={10}
                          textAnchor="middle"
                          fill={textColor}
                        >
                          {d.timestamp.substring(0, 10)}
                        </text>
                      </g>
                    );
                  })}
                </Group>
              </svg>
            );
          }}
        </AutoSizer>
        {tooltipOpen && (
          <MultichoiceTimelineTooltip
            key={Math.random()}
            cx={tooltipLeft}
            tooltipData={tooltipData}
            topic={topic}
          />
        )}
      </div>
    );
  }
}

MultichoiceTimelineChart.propTypes = {
  classes: PropTypes.exact({
    root: PropTypes.string,
    svgChart: PropTypes.string,
    surveyTickVerticalLine: PropTypes.string,
    selectedSurveyTickVerticalLine: PropTypes.string,
    surveyTick: PropTypes.string,
  }),
  theme: PropTypes.object,
  selected: PropTypes.string,
  onSelect: PropTypes.func,
  xExtent: PropTypes.array,
  values: PropTypes.array,
  yDomain: PropTypes.string,
  teamID: PropTypes.string,
  cascade: PropTypes.bool,
  showTooltip: PropTypes.func,
  tooltipData: PropTypes.object,
  tooltipLeft: PropTypes.number,
  tooltipOpen: PropTypes.bool,
  hideTooltip: PropTypes.func,
  topic: PropTypes.object,
};

export default withStyles(styles, { withTheme: true })(withTooltip(MultichoiceTimelineChart));
